export const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			}
		};
		reader.onerror = error => reject(error);
	});

export const getByteArray = (file: File): Promise<ArrayBuffer> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => {
			if (typeof reader.result !== 'string' && reader.result !== null) {
				resolve(reader.result);
			}
		};
		reader.onerror = error => reject(error);
	});

export const stringToByteArray = (data: string): number[] => {
	const split = data.split(',');
	const dataString = split[split.length === 1 ? 0 : 1];
	const binaryString = window.atob(dataString);
	const binaryLen = binaryString.length;
	const bytes: number[] = [];
	for (let i = 0; i < binaryLen; i++) {
		const ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
};

