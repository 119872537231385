import { DateTime, Interval } from 'luxon';
import { ApiQuery, Metadata } from '../../types';
import { toDateTime } from 'Helpers';

export const canFetch = (pollIntervalInMs: number, metadata: Metadata): boolean => {
	if (!metadata.lastFetched) {
		return true;
	}

	const now = DateTime.now();
	const lastFetched = toDateTime(metadata.lastFetched); // Could be a string if coming from local storage
	const interval = Interval.fromDateTimes(lastFetched, now);

	// If the data is from yesterday then refetch
	const diffInDays = interval.length('days');
	if (diffInDays >= 1) return true;

	// Otherwise diff with the poll interval arg
	const diffInSeconds = interval.length('seconds');
	return diffInSeconds >= pollIntervalInMs;
};

export const canFetchQuery = (apiQuery: ApiQuery, metadata: Metadata): boolean => canFetch(apiQuery.pollIntervalInMs, metadata);
