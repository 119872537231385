import React from 'react';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, useIonModal } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { GetMobileV2MachineLocations, MachineConsumptionType } from 'GQL';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { ChangeMachineLocationInput } from '../MachineActions';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import CloseableModal from 'Components/CloseableModal';
import { useOfflineHandlingQuery } from 'Hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Checkbox from 'Components/Checkbox';
import ScannerModal, { Props as ScannerModalProps } from './ScannerModalTwo';

const GET_MACHINE_LOCATIONS = loader('src/GQL/Machines/GetMobileV2MachineLocations.gql');

export interface Props {
	changeMachineLocation(input: ChangeMachineLocationInput): void;
	onDismiss(): void;
	caseNo?: string;
	lastConsumption: number;
	consumptionType: string;
	loading: boolean;
}
export const consumptionLimit = 5000;

const ChangeMachineLocationModal: React.FC<Props> = ({ changeMachineLocation, onDismiss, caseNo, lastConsumption, consumptionType, loading }) => {
	const { t } = useTranslation();

	const newPlannerMachineLocationScanFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_MACHINE_LOCATION_SCAN);
	const { register, handleSubmit, setValue } = useForm<ChangeMachineLocationInput>({
		defaultValues: { caseNo: caseNo },
	});
	const [locationInfo, setLocationInfo] = React.useState({
		consumption: '',
		location: '',
	});

	const { data: machineLocationData, loading: locationsLoading, error } = useOfflineHandlingQuery<GetMobileV2MachineLocations>(GET_MACHINE_LOCATIONS);
	const [consumptionLess, setConsumptionLess] = React.useState(false);
	const [consumptionMore, setConsumptionMore] = React.useState<'ERROR' | 'OVERRIDE' | undefined>(undefined);

	const consumptionBlocker = (newConsumption: number): void => {
		setConsumptionLess(newConsumption < lastConsumption);

		setConsumptionMore(newConsumption - lastConsumption > consumptionLimit ? 'ERROR' : undefined);

	};

	const [selectedCarNo, setSelectedCarNo] = React.useState('');

	const [presentScanner, dissmissScanner] = useIonModal(ScannerModal, {
		onDismiss: () => dissmissScanner(),
		onScanSuccess: (id: string) => {
			setSelectedCarNo(id);
			dissmissScanner();
		},
	} as ScannerModalProps);

	React.useEffect(() => {
		if (selectedCarNo.length > 0) {
			const location = machineLocationData?.machineLocations.find(l => l.name.endsWith(selectedCarNo.replace('p:', '')));
			if (location) {
				setLocationInfo(current => ({ ...current, location: location.locationCode }));
				setValue('location', location.locationCode);
			}
		}
	}, [machineLocationData?.machineLocations, selectedCarNo, setValue]);

	if (locationsLoading) return <Loading />;
	if (error || machineLocationData == null) return <p>{t('machine.noLocationsFound')}</p>;

	const locations = machineLocationData.machineLocations;
	// TODO: Use when CR deploy is allowed
	// const locations = machineLocationData.machineLocations
	//     .filter(l => l.name.length)
	//     .map((l): SelectOption => ({ value: l.locationCode, label: l.name }))
	//     .filter(l => l.label.toLowerCase().includes(searchLocationText.toLowerCase()));

	const handleChange = (name: string, value: string | undefined | null) => {
		setLocationInfo(current => ({ ...current, [name]: value }));
	};

	return (
		<CloseableModal title={t('machines.changeLocation')} onDismiss={onDismiss}>
			<div className="flex flex-col gap-2">
				<p>
					{t('machines.lastConsumption')}: {lastConsumption} {consumptionType === MachineConsumptionType.NONE ? `(${t('common.missingUnit')})` : consumptionType}
				</p>
				<Button icon={faArrowDown} primary onClick={() => setValue('consumption', lastConsumption)}>
					{t('machines.lastConsumption')}
				</Button>
			</div>

			<form onSubmit={handleSubmit(changeMachineLocation)} className="flex flex-col gap-2">
				<IonItem>
					<IonLabel position="stacked" className="mb-2">
						{t('machines.consumption')}
					</IonLabel>
					<IonInput
						name="consumption"
						type="number"
						ref={register}
						onIonChange={e => {
							handleChange('consumption', e.detail.value);
							consumptionBlocker(Number(e.detail.value));
						}}
						step="1"
					/>
					{consumptionLess && <p className="text-orange font-semibold">{t('machines.consumptionLess')}</p>}
					{consumptionMore && (
						<div className="py-2">
							{consumptionMore === 'ERROR' && <p className="text-orange font-semibold">{t('machines.consumptionMore', { number: consumptionLimit })}</p>}
							<Checkbox name="ignoreMePls" onChange={e => setConsumptionMore(e.currentTarget.checked ? 'OVERRIDE' : 'ERROR')} title="machines.consumptionMoreConfirm" />
						</div>
					)}
				</IonItem>
				<IonRow >
					<IonCol size={newPlannerMachineLocationScanFlag ? '8' : '12'}>
						<IonItem className="">


							<IonLabel position="stacked">{t('machines.selectLocation')}</IonLabel>
							<IonSelect
								name="location"
								ref={register}
								onIonChange={e => handleChange('location', e.detail.value)}
								placeholder={t('machines.selectLocation')}
								interface="action-sheet"
								okText={t('common.ok')}
								cancelText={t('common.cancel')}
								interfaceOptions={{ cssClass: 'ssg-action-sheet' }}
								value={locationInfo.location}
							>
								{locations
									.filter(l => l.name.length)
									.map(location => (
										<IonSelectOption key={location.locationCode} value={location.locationCode} children={location.name} />
									))}
							</IonSelect>
						</IonItem>
					</IonCol>
					<IonCol size='4'>


						{newPlannerMachineLocationScanFlag &&
							<Button primary fullWidth onClick={() => presentScanner()}>
								{t('machines.scanCarLabel')}
							</Button>
						}

						{/* 
						TODO: Use when CR deploy is allowed
						<SearchableSelect
							control={control}
							name="location"
							title={t('machines.selectLocation')}
							options={locations}
							searchFn={searchText => setSearchLocationText(searchText)}
							onSelect={value => handleChange('location', value)}
							onBlur={() => undefined}
							minInputLength={-1}
							isLoading={locationsLoading}
							className="w-full"
						/> */}
					</IonCol>
				</IonRow>

				<Button success submit disabled={locationInfo.consumption.length === 0 || locationInfo.location.length === 0 || consumptionLess || consumptionMore === 'ERROR'} loading={loading}>
					{t('machines.changeLocation')}
				</Button>
			</form>
		</CloseableModal>
	);
};

export default ChangeMachineLocationModal;
