import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonButtons, IonContent, IonIcon, IonLabel, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { UserPhoto } from 'Hooks/usePhotoCapture';
import Button from './Button';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileInput } from 'GQL';

export enum AddingFilesType {
	Photos,
	Files,
}

export interface Props {
	addingFilesType: AddingFilesType;
	initialFiles: (FileInput & { blob: string })[] | (UserPhoto & { blob: string })[];
	onSubmit: (files: (FileInput & { blob: string })[] | (UserPhoto & { blob: string })[] | File, comments?: string) => Promise<void>;
	onDismiss: () => Promise<void>;
}

const getTranslations = (addingFilesType: AddingFilesType) => {
	switch (addingFilesType) {
		case AddingFilesType.Photos:
			return {
				title: 'common.uploadPictures',
				type: 'common.picture(s)LowerCase',
			};
		case AddingFilesType.Files:
		default:
			return {
				title: 'common.uploadFiles',
				type: 'common.file(s)Lowercase',
			};
	}
};

const AddCommentsToFilesModal: React.FC<Props> = ({ addingFilesType, initialFiles, onSubmit, onDismiss }) => {
	const { t } = useTranslation();

	const [comments, setComments] = React.useState<string>();

	const translations = getTranslations(addingFilesType);

	return (
		<>
			<IonToolbar color="primary">
				<IonTitle>{t(translations.title)}</IonTitle>
				<IonButtons slot="end">
					<Button onClick={onDismiss}>
						<IonIcon name="close" slot="icon-only"></IonIcon>
					</Button>
				</IonButtons>
			</IonToolbar>
			<IonContent className="ion-padding">
				<div className="flex h-full flex-col">
					<Button color="primary" expand="block" loading={initialFiles.length === 0} disabled={initialFiles.length === 0} onClick={() => onSubmit(initialFiles, comments)}>
						<FontAwesomeIcon icon={faCheck} className="mr-2" />
						{t('common.confirm')}
					</Button>

					<div className="py-10 text-center ">
						<p className="font-semibold text-blue">
							{initialFiles.length > 0
								? <>{initialFiles.length} {t(translations.type)} {t('common.uploadingReady')}</>
								: <>{t('common.uploadingPrepare')}</>
							}
						</p>
					</div>

					<hr className="my-2" />

					<IonLabel className="text-blue font-semibold">{t('common.comments')}</IonLabel>
					<IonTextarea placeholder={t('common.insertOptionalComments')} onIonChange={e => setComments(e.detail.value ?? undefined)} />
				</div>
			</IonContent>
		</>
	);
};

export interface PropsRest {
	addingFilesType: AddingFilesType;
	onSubmit: (files: File[], comment?: string) => Promise<void>;
	onDismiss: () => Promise<void>;
	type: {
		multiple: boolean;
		capture: 'environment' | undefined;
	}
	skipComments?: boolean;
}

export const AddCommentsToFilesModalREST: React.FC<PropsRest> = ({ addingFilesType, onSubmit, onDismiss, type, skipComments = false }) => {
	const { t } = useTranslation();

	const [comments, setComments] = React.useState<string>();
	const [count, setCount] = React.useState<number>(0);
	const [loading, setLoading] = React.useState<boolean>(false);
	const translations = getTranslations(addingFilesType);

	const inputPhotoRef = React.useRef<HTMLInputElement | null>(null);
	React.useEffect(() => {
		inputPhotoRef.current?.click();
		return () => {
			inputPhotoRef.current = null;
		};
	}, []);


	return (
		<>
			<IonToolbar color="primary">
				<IonTitle>{t(translations.title)}</IonTitle>
				<IonButtons slot="end">
					<Button onClick={onDismiss}>
						<IonIcon name="close" slot="icon-only"></IonIcon>
					</Button>
				</IonButtons>
			</IonToolbar>
			<IonContent className="ion-padding">
				<div className="flex h-full flex-col">

					<Button color="primary" expand="block" loading={count === 0 || loading} disabled={count === 0} onClick={() => {
						setLoading(true);
						onSubmit(Array.from(inputPhotoRef.current?.files || []), comments);
					}}>
						<FontAwesomeIcon icon={faCheck} className="mr-2" />
						{t('common.confirm')}
					</Button>

					<div className="py-10 text-center ">
						<p className="font-semibold text-blue">
							{count > 0
								? <>{count} {t(translations.type)} {t('common.uploadingReady')}</>
								: <>{t('common.uploadingPrepare')}</>
							}
						</p>
					</div>

					<hr className="my-2" />

					<IonLabel className="text-blue font-semibold">{t('common.comments')}</IonLabel>

					<input name="files" type="file" accept="image/*" ref={inputPhotoRef} onChange={(e) => {
						if (skipComments) {
							setLoading(true);
							onSubmit(Array.from(e.target.files || []), comments);
						} else {
							setCount(e.target.files?.length ?? 0);
						}
					}} className="hidden" multiple={type.multiple} capture={type.capture} />
					<IonTextarea name="comment" placeholder={t('common.insertOptionalComments')} onIonChange={e => setComments(e.detail.value ?? undefined)} />
				</div>
			</IonContent>
		</>
	);
};
export default AddCommentsToFilesModal;
