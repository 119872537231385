import { useRef, useState } from 'react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { isSafari, isMobileSafari } from 'react-device-detect';

import { base64FromBlob } from './usePhotoCapture';

export interface UserVideo {
	filepath: string;
	format: string;
	base64EncodedData: string;
}

interface Props {
	video: UserVideo | undefined;
	startCapture: () => Promise<void>;
	stopCapture: () => Promise<void>;
	deleteCapture: () => Promise<void>;
	getVideoFromLocalStorage: (localStorageFileName: string) => Promise<UserVideo | undefined>;
	isRecording: boolean;
	recordingMediaStream: MediaStream | null; // Real-time stream of the video being recorded
}

const directory = Directory.Data;

enum MimeType {
	MP4 = 'video/mp4',
	WebM = 'video/webm',
}

const getVideoFormat = () => (isSafari || isMobileSafari ? MimeType.MP4 : MimeType.WebM); // 'webm' is not supported in Safari

export const getPath = (fullPath: string): string => fullPath.substring(fullPath.lastIndexOf('/') + 1);

export function useVideoCapture(): Props {
	const [video, setVideo] = useState<UserVideo>();

	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

	const mediaRecorder = useRef<MediaRecorder>();

	return {
		video,
		startCapture: async () => {
			if (!navigator.mediaDevices) {
				alert('Media devices not supported');
				return;
			}

			setIsRecording(true);
			setVideo(undefined);

			let stream: MediaStream | undefined;
			try {
				stream = await navigator.mediaDevices.getUserMedia({
					video: {
						facingMode: 'environment',
					},
					audio: true,
				});
			} catch (e) {
				alert('Unable to record media. Error: ' + e);
			}

			if (!stream) {
				return;
			}

			setMediaStream(stream);

			const videoFormat = getVideoFormat();

			try {
				mediaRecorder.current = new MediaRecorder(stream, {
					mimeType: videoFormat,
				});
			} catch (e) {
				// We may be emulating a device and it's causing problems knowing which video format to use.
				// Therefore try and use the other video format. If that doesn't work then an error will be thrown.
				mediaRecorder.current = new MediaRecorder(stream, {
					mimeType: videoFormat === MimeType.MP4 ? MimeType.WebM : MimeType.MP4,
				});
			}

			mediaRecorder.current.start();

			const chunks: BlobPart[] = [];

			mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
				if (event.data?.size > 0) {
					chunks.push(event.data);
				}
			};

			mediaRecorder.current.onstop = async _ => {
				const videoBuffer = new Blob(chunks, { type: videoFormat });
				const result = await storeVideo(videoBuffer);
				setVideo({
					filepath: result.uri,
					format: videoFormat,
					base64EncodedData: result.base64Data,
				});
			};
		},
		stopCapture: async () => {
			mediaRecorder.current?.stop();
			mediaRecorder.current = undefined;
			setMediaStream(null);
			setIsRecording(false);
		},
		deleteCapture: async () => {
			if (video) {
				try {
					await Filesystem.deleteFile({
						path: getPath(video.filepath),
						directory,
					});
				} catch (error: unknown) {
					// File may not exist
					console.error(error);
				} finally {
					setVideo(undefined);
				}
			}
		},
		getVideoFromLocalStorage: async (localStorageFileName: string) => {
			const videoFormat = getVideoFormat();
			try {
				const file = await Filesystem.readFile({
					path: getPath(localStorageFileName),
					directory,
				});
				return {
					filepath: localStorageFileName,
					format: videoFormat,
					base64EncodedData: `data:${videoFormat};base64,${file.data}`,
				};
			} catch (error: unknown) {
				// File may not exist
				console.error(error);
			}
		},
		isRecording,
		recordingMediaStream: mediaStream,
	};
}

async function storeVideo(blob: Blob): Promise<{ uri: string; base64Data: string }> {
	// Store video as .mp4 extension regardless of video format
	const fileName = new Date().getTime() + '.mp4';
	const base64Data = (await base64FromBlob(blob)) as string;

	const savedFile = await Filesystem.writeFile({
		path: fileName,
		data: base64Data,
		directory,
	});

	return {
		uri: savedFile.uri,
		base64Data,
	};
}
