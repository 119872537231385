import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import FormFieldHeader from './FormFieldHeader';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import 'react-phone-number-input/style.css';

interface Props {
	className?: string;
	innerRef?: React.Ref<HTMLInputElement>;
	required?: boolean;
	title: string;
	errorMessage?: string;
	helpText?: string;
	showHelpText?: boolean;
	name: string;
	disabled?: boolean;
	control: UseFormMethods['control'];
	trigger: UseFormMethods['trigger'];
	searchFn(phonenumber: string): void;
	onCountryChange: (countryCode?: string) => void;
	defaultCountry?: string;
	defaultPhoneNumber?: string;
}

const InputPhone: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	innerRef,
	title,
	required = false,
	disabled = false,
	errorMessage,
	helpText,
	showHelpText,
	name,
	control,
	trigger,
	searchFn,
	onCountryChange,
	defaultCountry,
	defaultPhoneNumber,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();
	const [phonenumber, setPhonenumber] = React.useState('');
	const minInputLength = 0;

	const phonenumberRef = React.useRef<string>('');

	const searchFnRef = React.useRef(searchFn);

	React.useEffect((): (() => void) => {
		const timeout = window.setTimeout((): void => {
			if (phonenumber !== null && phonenumber.length > minInputLength && phonenumber !== phonenumberRef.current) {
				phonenumberRef.current = phonenumber;
				searchFnRef.current(phonenumber);
			}
		}, 500);

		return (): void => window.clearTimeout(timeout);
	}, [phonenumber, minInputLength]);

	React.useEffect((): void => {
		if (typeof phonenumber === 'undefined') {
			setPhonenumber('');
		}
	}, [phonenumber]);

	return (
		<>
			<FormFieldHeader htmlFor={name} required={required} title={title} />
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<PhoneInputWithCountry
				international
				id={name}
				name={name}
				type="text"
				control={control}
				value={defaultPhoneNumber ?? ''}
				onChange={(value: string): void => setPhonenumber(value)}
				disabled={disabled}
				autoComplete="nope"
				defaultCountry={defaultCountry}
				onCountryChange={onCountryChange}
				numberInputProps={{
					className: classNames('border-1 border-gray-600 rounded-default block focus:outline-none p-1 w-full', {
						'border-2 border-red': errorMessage,
						'bg-gray-300 cursor-not-allowed': disabled,
					}),
					...restProps,
				}}
				className={classNames(className, 'w-full', {
					'cursor-not-allowed bg-gray-300': disabled,
				})}
			/>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};
export default InputPhone;
