import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FormHelpText from './FormHelpText';

interface Props {
	className?: string;
	text: string;
	name: string;
	innerRef?: React.Ref<HTMLInputElement>;
	defaultChecked?: boolean;
	helpText?: string;
	showHelpText?: boolean;
	disabled?: boolean;
}

const Toggle: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	text,
	name,
	innerRef,
	defaultChecked = false,
	helpText,
	showHelpText,
	disabled = false,

	...restProps
}): React.ReactElement => {
	const [isChecked, setIsChecked] = React.useState(defaultChecked);
	const { t } = useTranslation();

	React.useEffect(() => {
		setIsChecked(defaultChecked);
	}, [defaultChecked]);

	return (
		<>
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<div className={classNames('relative mr-2 inline-block w-10 select-none align-middle transition duration-200 ease-in', className)}>
				<input
					type="checkbox"
					name={name}
					id={name}
					className={classNames('toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white text-white focus:ring-offset-0', {
						'cursor-not-allowed bg-gray-200': restProps.readOnly,
					})}
					ref={innerRef}
					disabled={disabled || restProps.readOnly}
					onChange={() => setIsChecked(!isChecked)}
					checked={isChecked}
					{...restProps}
				/>
				<label
					htmlFor={name}
					className={classNames('toggle-label block h-6 cursor-pointer overflow-hidden rounded-full bg-gray-300', {
						'cursor-not-allowed bg-gray-500': restProps.readOnly,
					})}
				></label>
			</div>
			<label htmlFor={name}>
				<span className="text-default text-blue select-none font-semibold">{t(text)}</span>
			</label>
		</>
	);
};
export default Toggle;
