import { OperationVariables, QueryHookOptions, WatchQueryFetchPolicy } from '@apollo/client';
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { UseApiQueryProps } from './types';
import { useOfflineHandlingQuery } from 'Hooks';
import { createQueryContext, useAppSelector } from 'Store';
import { isUndefined } from 'lodash';

export const useApiQuery = <TQuery, TData, TVariables extends OperationVariables>({
	apiQueryKey,
	debatch,
	canFetchQuery,
	documentNode,
	showOfflineWarningMessage = true,
	variables,
	dataSelector,
	setCanFetchQuery,
	setData,
	options,
}: UseApiQueryProps<TQuery, TData, TVariables>): void => {
	const connected = useAppSelector(state => state.localData.networkConnectionStatus);
	const fetchPolicy: WatchQueryFetchPolicy = connected ? 'cache-and-network' : 'cache-only';
	const nextFetchPolicy: WatchQueryFetchPolicy = connected ? 'cache-and-network' : 'cache-only';
	// const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network';
	// const nextFetchPolicy: WatchQueryFetchPolicy = 'network-only';
	const theOptions: QueryHookOptions<TQuery, TVariables> = {
		context: options?.context,
		fetchPolicy: options?.fetchPolicy ?? fetchPolicy,
		nextFetchPolicy: options?.nextFetchPolicy ?? nextFetchPolicy,
		variables: variables,
		skip: options?.skip,
	};
	// if (options) {
	// 	if (options.fetchPolicy) {
	// 		fetchPolicy = options.fetchPolicy;
	// 	}
	// 	if (options.nextFetchPolicy) {
	// 		nextFetchPolicy = options.nextFetchPolicy as WatchQueryFetchPolicy;
	// 	}
	// }

	const { data, error, fetchMore, networkStatus, refetch } = useOfflineHandlingQuery<TQuery, TVariables>(documentNode, theOptions, showOfflineWarningMessage);

	useEffect(() => {
		// Only run when we have actual data
		if (!data) return;
		setData({
			data: dataSelector(data),
			metadata: {
				lastFetched: DateTime.now().toJSDate(),
				origin: 'remote',
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		// Only run when error changes
		setData({ error });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		// Only run when networkStatus changes
		setData({ loading: isNetworkRequestInFlight(networkStatus) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkStatus]);

	useEffect(() => {
		// Only run once
		setData({ fetchMore, refetch });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const previousVariablesRef = useRef<TVariables | undefined>(variables);

	useEffect(() => {
		// Compare variables changing and allow requery accordingly
		const previousVariables = previousVariablesRef.current;
		const newVariables = variables;

		if (isUndefined(previousVariables) && isUndefined(newVariables)) return;

		const variablesChanged = JSON.stringify(previousVariables) !== JSON.stringify(newVariables);
		if (variablesChanged) {
			previousVariablesRef.current = newVariables;

			// This will re-invoke the query
			if (!canFetchQuery) {
				setCanFetchQuery(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canFetchQuery, variables]);
};

