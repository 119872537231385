import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames, { Argument as ClassNamesArgument } from 'classnames';

interface Props {
	titles: string[];
	onClick(title: string): void;
	active: string;
	icons?: (IconDefinition | null)[];
	full?: boolean;
	pushLast?: boolean;
	count?: number[];
	className?: ClassNamesArgument;
}

const Tabs: React.FC<Props> = ({ children, titles, onClick, active, icons, full = false, pushLast = false, count, className }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div className="mt-4">
			<div className="text-blue -mb-px flex flex-row flex-wrap">
				{titles.map((title, i) => {
					const icon = icons && icons[i];
					return (
						<button
							type="button"
							className={classNames('border-1 rounded-t-default whitespace-nowrap bg-white py-1 text-sm focus:outline-none', {
								'mb-px border-b-0 border-transparent px-3': title !== active,
								'border-blue -mt-px border-b-0 px-3 font-medium': title === active,
								'ml-auto mr-0': i === titles.length - 1 && pushLast,
							})}
							key={'tab' + title}
							onClick={() => onClick(title)}
						>
							{icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
							{t(title, { nsSeparator: false })} {count && ` (${count[i]})`}
						</button>
					);
				})}
			</div>
			<div
				className={classNames('border-1 border-blue rounded-default bg-white', {
					'rounded-b-default rounded-l-none': titles[0] === active,
					'rounded-b-default rounded-r-none': titles[titles.length - 1] === active && pushLast,
				})}
			>
				<div
					className={classNames('flex flex-col overflow-auto py-2', className, {
						'h-full': full,
						'h-96': !full,
					})}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Tabs;
