import { IonIcon, IonRouterOutlet, IonSkeletonText, IonTabBar, IonTabButton, IonTabs, useIonModal } from '@ionic/react';
import { briefcase, cart, document, informationCircle, newspaper, peopleSharp, time } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useParams } from 'react-router-dom';
import BasePage from 'Components/Layout/BasePage';
import TimeRegLockedModal, { Props as TimeRegLockedModalProps } from 'Components/TimeRegLockedModal';
import UnhandledError from 'Components/UnhandledError';
import Routes from 'Routes';
import RequisitionsOverview from '../../Requisitions/RequisitionsOverview';
import TimeRegistrationOverview from '../../TimeRegistration/TimeRegistrationOverview';
import ContactPersonsOverview from './ContactPersons/ContactPersonsOverview';
import DrivingSlipOverview from './DrivingSlipOverview';
import DrivingSlipFilesOverview from './Files/DrivingSlipFilesOverview';
import DrivingSlipMachineTab from './Machines/DrivingSlipMachineTab';
import DrivingSlipOfferTab from './Offers/DrivingSlipOfferTab';
import DrivingSlipOfferView from './Offers/DrivingSlipOfferView';
import DrivingSlipQuestionnaireOverview from './Questionnaire/DrivingSlipQuestionnaireOverview';
import InspectionReport from './Reports/InspectionReportForm';
import SelectReport from './Reports/SelectReport';
import TrygWaterDamageReport from './Reports/TrygWaterDamageReportForm';
import WaterDamageReport from './Reports/WaterDamageReportForm';
import FalckDehumidifierReport from './Reports/FalckDehumidifierReportForm';
import IndoorClimateReportForm from './Reports/IndoorClimateReportForm';
import QuestionnaireSyncProvider from './Questionnaire/QuestionnaireSyncContext';
import { setSelectedDrivingSlipId, useAppDispatch, useAppSelector } from 'Store';
import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetMobileV2DrivingSlip, GetMobileV2DrivingSlipVariables } from 'GQL';
import InspectionMovablesReport from './Reports/InspectionMovablesReportForm';
import TimeRegistration from 'Pages/TimeRegistration/TimeRegistration';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_DRIVING_SLIP = loader('src/GQL/DrivingSlips/GetMobileV2DrivingSlip.gql');

const DrivingSlipTabs: React.FC = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();

	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);

	const dispatch = useAppDispatch();

	const apolloClient = useApolloClient();

	const { selectedDrivingSlipId } = useAppSelector(state => state.localData);

	const {
		drivingSlip: { data: drivingSlip, error: drivingSlipError, loading: drivingSlipLoading },
		drivingSlips: { data: drivingSlips, error: drivingSlipsError, loading: drivingSlipsLoading },
	} = useAppSelector(state => state.apiData);

	useEffect(() => {
		const cachedDrivingSlip = drivingSlips.find(ds => ds.id === id);

		if (!drivingSlip || drivingSlip.id !== id) {
			if (cachedDrivingSlip) {
				// TODO: Replace with writeFragment

				try {
					// Update Apollo Cache with cached driving slip in Redux
					apolloClient.writeQuery<GetMobileV2DrivingSlip, GetMobileV2DrivingSlipVariables>({
						query: GET_DRIVING_SLIP,
						variables: {
							id: id,
						},
						data: {
							drivingSlip: { ...cachedDrivingSlip },
						},
					});
				} catch (error) {
					console.error('SEE ME! Error writing driving slip to cache', { drivingSlip, error });
				}
			}

			// Update the selected driving slip id. This will cause the drivingSlipQuery to fire.
			dispatch(setSelectedDrivingSlipId(id));
			return;
		}

		if (drivingSlip && drivingSlip.id !== selectedDrivingSlipId) {
			dispatch(setSelectedDrivingSlipId(drivingSlip.id));
		}
	}, [apolloClient, dispatch, drivingSlip, drivingSlips, id, selectedDrivingSlipId]);

	const [showTimeRegLockedModal, closeTimeRegLockedModal] = useIonModal(TimeRegLockedModal, {
		close: () => closeTimeRegLockedModal(),
		case: drivingSlip?.case,
	} as TimeRegLockedModalProps);

	const renderNoSelectedDrivingSlip = ({ error, loading }: { error?: unknown; loading: boolean }) => {
		if (error) {
			return <UnhandledError apolloError={drivingSlipError || drivingSlipsError} />;
		}

		if (loading) {
			return (
				<>
					<IonSkeletonText animated className="w-full pt-5" />
					<IonSkeletonText animated className="w-full pt-5" />
				</>
			);
		}

		return <>{t('drivingSlips.unableToRetrieve')}</>;
	};

	if (!drivingSlip) {
		return (
			<BasePage backButtonDefaultHref={Routes.DrivingSlipsOverview}>
				{renderNoSelectedDrivingSlip({
					error: drivingSlipsError,
					loading: drivingSlipsLoading || drivingSlipLoading,
				})}
			</BasePage>
		);
	}

	return (
		<QuestionnaireSyncProvider drivingSlip={drivingSlip}>
			<IonTabs>
				<IonRouterOutlet>
					<Redirect path={Routes.DrivingSlipTabs} to={Routes.DrivingSlipInfo} exact />

					<Route path={Routes.DrivingSlipInfo} render={() => <DrivingSlipOverview drivingSlip={drivingSlip} />} exact />

					<Route path={Routes.DrivingSlipQuestionnaire} render={() => <DrivingSlipQuestionnaireOverview drivingSlip={drivingSlip} />} exact />

					{newTimeregistration
						? <Route path={Routes.DrivingSlipTimeRegistration} render={() => <TimeRegistration />} exact />
						: <Route path={Routes.DrivingSlipTimeRegistration} render={() => <TimeRegistrationOverview />} exact />
					}

					<Route path={Routes.DrivingSlipFiles} render={() => <DrivingSlipFilesOverview caseNo={drivingSlip.case.erpNo} series={drivingSlip.series} />} exact />
					<Route path={Routes.DrivingSlipRequisitions} render={() => <RequisitionsOverview caseData={drivingSlip.case} />} exact />
					<Route path={Routes.DrivingSlipMachines} render={() => <DrivingSlipMachineTab caseNo={drivingSlip.case.erpNo} />} exact />
					<Route path={Routes.DrivingSlipSelectReports} render={() => <SelectReport caseData={drivingSlip.case} />} exact />
					<Route path={Routes.DrivingSlipInspectionReport} exact render={() => <InspectionReport drivingSlipId={drivingSlip.id} drivingSlipCase={drivingSlip.case} />} />
					<Route
						path={Routes.DrivingSlipInspectionMovablesReport}
						exact
						render={() => <InspectionMovablesReport drivingSlipId={drivingSlip.id} drivingSlipCase={drivingSlip.case} />}
					/>
					<Route path={Routes.DrivingSlipWaterReport} exact render={() => <WaterDamageReport drivingSlipId={drivingSlip.id} drivingSlipCase={drivingSlip.case} />} />
					<Route path={Routes.DrivingSlipWaterReportTryg} exact render={() => <TrygWaterDamageReport drivingSlipId={drivingSlip.id} drivingSlipCase={drivingSlip.case} />} />

					<Route path={Routes.DrivingSlipIndoorClimateReport} exact render={() => <IndoorClimateReportForm drivingSlipId={drivingSlip.id} drivingSlipCase={drivingSlip.case} />} />

					<Route
						path={Routes.DrivingSlipFalckDehumidifierReport}
						exact
						render={() => (
							<FalckDehumidifierReport
								caseId={drivingSlip.case.id}
								caseNo={drivingSlip.case.erpNo}
								drivingSlipId={drivingSlip.id}
								address={drivingSlip.case.damage.contact.address.addressLine ?? 'Ingen address på sagen'}
								defaultCustomerEmail={drivingSlip.case.damage.contact.email}
							/>
						)}
					/>

					<Route path={Routes.DrivingSlipOffers} render={() => <DrivingSlipOfferView caseNo={drivingSlip.case.erpNo} caseId={drivingSlip.case.id} />} exact />
					<Route
						path={Routes.DrivingSlipCreateOffer}
						render={() => (
							<DrivingSlipOfferTab
								caseId={drivingSlip.case.id}
								caseNo={drivingSlip.case.erpNo}
								assortmentCode={drivingSlip.case.debitor.assortmentCode ?? ''}
								refetchOffers={() => {
									throw new Error('Function not implemented.');
								}}
							/>
						)}
						exact
					/>
					<Route path={Routes.DrivingSlipContactPersons} render={() => <ContactPersonsOverview caseId={drivingSlip.case.id} />} exact />
				</IonRouterOutlet>
				<IonTabBar slot="bottom" className="border-t-1 border-solid border-gray-200">
					<IonTabButton tab="info" href={Routes.DrivingSlipInfo.replace(':id', id)}>
						<IonIcon icon={informationCircle} title={t('common.general')} />
					</IonTabButton>

					{newTimeregistration
						? (
							drivingSlip.case.timeRegistration ? (
								<IonTabButton tab="timeregistration" className="relative" href={Routes.DrivingSlipTimeRegistration.replace(':id', id)}>
									<IonIcon icon={time} title={t('timeRegistration.overviewTitle')} />
								</IonTabButton>
							) : (
								<IonTabButton tab="timeregistration" className="relative">
									<div className="absolute top-0 left-0 z-10 h-full w-full" onClick={() => showTimeRegLockedModal()} />
									<IonIcon icon={time} title={t('timeRegistration.overviewTitle')} />
								</IonTabButton>
							)
						)
						: (
							drivingSlip.case.timeRegistration ? (
								<IonTabButton tab="timeregistration" href={Routes.DrivingSlipTimeRegistration.replace(':id', id)}>
									<IonIcon icon={time} title={t('timeRegistration.overviewTitle')} />
								</IonTabButton>
							) : (
								<IonTabButton tab="timeregistration" className="relative">
									<div className="absolute top-0 left-0 z-10 h-full w-full" onClick={() => showTimeRegLockedModal()} />
									<IonIcon icon={time} title={t('timeRegistration.overviewTitle')} />
								</IonTabButton>
							)
						)
					}

					<IonTabButton tab="machines" href={Routes.DrivingSlipMachines.replace(':id', id)}>
						<IonIcon icon={briefcase} title={t('machines.overviewTitle')} />
					</IonTabButton>

					<IonTabButton tab="files" href={Routes.DrivingSlipFiles.replace(':id', id)}>
						<IonIcon icon={document} title={t('common.files')} />
					</IonTabButton>

					<IonTabButton tab="requisitions" href={Routes.DrivingSlipRequisitions.replace(':id', id)}>
						<IonIcon icon={cart} title={t('requisitions.overviewTitle')} />
					</IonTabButton>
					<IonTabButton tab="reports" href={Routes.DrivingSlipSelectReports.replace(':id', id)}>
						<IonIcon icon={newspaper} title={t('reports.overviewTitle')} />
					</IonTabButton>

					{/* <IonTabButton tab="offers" href={Routes.DrivingSlipOffers.replace(':id', id)}>
                    	<IonIcon icon={receipt} title={t('offers.title')} />
                	</IonTabButton> */}
					<IonTabButton tab="contacts" href={Routes.DrivingSlipContactPersons.replace(':id', id)}>
						<IonIcon icon={peopleSharp} title={t('case.contactPersons')} />
					</IonTabButton>
				</IonTabBar>
			</IonTabs>
		</QuestionnaireSyncProvider>
	);
};

export default DrivingSlipTabs;
