export interface IQueue<T extends Record<string, any>> {
	/**
	 * Name of the queue.
	 *
	 * @return {string}
	 */
	name: string;

	/**
	 * Instantiates the queue and underlying database asynchronously.
	 */
	initialize: () => Promise<void>;

	/**
	 * Stores the passed entry in IndexedDB (with its timestamp and any
	 * metadata) at the end of the queue.
	 *
	 * @param {Object} entry
	 * @param {Data} entry.data The data to store in the queue.
	 * @param {Object} [entry.metadata] Any metadata you want associated with the
	 *     stored entry. When requests are replayed you'll have access to this
	 *     metadata object in case you need to modify the request beforehand.
	 * @param {number} [entry.timestamp] The timestamp (Epoch time in
	 *     milliseconds) when the entry was first added to the queue. This is
	 *     used along with `maxRetentionTime` to remove outdated entries. In
	 *     general you don't need to set this value, as it's automatically set
	 *     for you (defaulting to `Date.now()`), but you can update it if you
	 *     don't want particular entries to expire.
	 */
	pushEntry: (entry: QueueEntry<T>) => Promise<void>;

	/**
	 * Stores the passed request in IndexedDB (with its timestamp and any
	 * metadata) at the beginning of the queue.
	 *
	 * @param {Object} entry
	 * @param {Data} entry.data The data to store in the queue.
	 * @param {Object} [entry.metadata] Any metadata you want associated with the
	 *     stored entry. When entries are replayed you'll have access to this
	 *     metadata object in case you need to modify the entry beforehand.
	 * @param {number} [entry.timestamp] The timestamp (Epoch time in
	 *     milliseconds) when the entry was first added to the queue. This is
	 *     used along with `maxRetentionTime` to remove outdated entries. In
	 *     general you don't need to set this value, as it's automatically set
	 *     for you (defaulting to `Date.now()`), but you can update it if you
	 *     don't want particular entries to expire.
	 */
	unshiftEntry: (entry: QueueEntry<T>) => Promise<void>;

	/**
	 * Removes and returns the last entry in the queue (along with its
	 * timestamp and any metadata). The returned object takes the form:
	 * `{entry, timestamp, metadata}`.
	 *
	 * @return {Promise<Object>}
	 */
	popEntry: () => Promise<QueueEntry<T> | undefined>;

	/**
	 * Removes and returns the first entry in the queue (along with its
	 * timestamp and any metadata). The returned object takes the form:
	 * `{entry, timestamp, metadata}`.
	 *
	 * @return {Promise<Object>}
	 */
	shiftEntry: () => Promise<QueueEntry<T> | undefined>;

	/**
	 * Deletes the given entry from the queue.
	 *
	 * @param {number} entry The entry id to remove.
	 */
	deleteEntry: (id: number) => Promise<void>;

	/**
	 * Returns all the entries that have not expired (per `maxRetentionTime`).
	 * Any expired entries are removed from the queue.
	 *
	 * @return {Promise<Array<QueueEntry<T>>>}
	 */
	getAll: () => Promise<QueueEntry<T>[]>;

	/**
	 * Returns the count of mutations in the queue
	 *
	 * @return {Promise<Array<QueueEntry<T>>>}
	 */
	getCount: () => Promise<number>;

	updateEntry: (entry: QueueEntry<T>) => Promise<void>;
}

export interface QueueOptions {
	maxRetentionTime?: number;
}

export interface QueueEntry<T extends Record<string, any>> {
	data: T;
	id?: number;
	timestamp?: number;
	metadata: QueueEntryMetadata;
}

export interface QueueEntryMetadata {
	lastError?: any;
	numberOfRetries: number;
	maxNumberOfRetries: number;
	status: QueueEntryStatus;
	sendStartTime?: Date;
}

export enum QueueEntryStatus {
	waiting,
	sending,
	done,
	error,
}

export interface UnidentifiedQueueStoreEntry<T extends Record<string, any>> {
	data: T;
	timestamp: number;
	id?: number;
	queueName?: string;
	metadata?: QueueEntryMetadata;
}

export interface QueueStoreEntry<T extends Record<string, any>> extends UnidentifiedQueueStoreEntry<T> {
	id: number;
}

export interface GetAllMatchingOptions {
	index?: string;
	query?: IDBValidKey | IDBKeyRange | null;
	direction?: IDBCursorDirection;
	count?: number;
	includeKeys?: boolean;
}

