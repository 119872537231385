import { useState, useEffect } from 'react';
import pwaInstallHandler from 'pwa-install-handler';

export function usePWAInstall(): (() => Promise<boolean>) | null {
	const [canInstall, setCanInstall] = useState(false);

	useEffect(() => {
		pwaInstallHandler.addListener(setCanInstall);
		return () => {
			pwaInstallHandler?.removeListener(setCanInstall);
		};
	}, []);

	return canInstall ? pwaInstallHandler.install : null;
}
