import { ApolloError } from '@apollo/client';
import { useIonViewWillEnter } from '@ionic/react';
import Loading from '@ssg/common/Components/Loading';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetMobileV2MachineLocations, GetMobileV2MachinesOnCase, GetMobileV2MachinesOnCaseVariables } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';

interface Props {
	caseNo: string;
}

const GET_MACHINES_ON_CASE = loader('src/GQL/Machines/GetMobileV2MachinesOnCase.gql');
const GET_MACHINE_LOCATIONS = loader('src/GQL/Machines/GetMobileV2MachineLocations.gql');

const ActiveMachines: React.FC<Props> = ({ caseNo }) => {
	const { t } = useTranslation();

	useOfflineHandlingQuery<GetMobileV2MachineLocations>(GET_MACHINE_LOCATIONS);

	const getMachinesQuery = useOfflineHandlingQuery<GetMobileV2MachinesOnCase, GetMobileV2MachinesOnCaseVariables>(GET_MACHINES_ON_CASE, { variables: { caseNo } });

	useIonViewWillEnter(() => {
		getMachinesQuery.refetch();
	});

	if (getMachinesQuery.loading) return <Loading />;
	if (getMachinesQuery.error instanceof ApolloError || getMachinesQuery.data == null) return <p>Error</p>;

	const machines = getMachinesQuery.data.machinesOnCase;

	return (
		<div className="flex flex-col gap-1">
			{machines.length === 0 && <p>{t('machines.noneOnCase')}</p>}

			{machines.map(machine => (
				<div className="flex gap-3" key={machine.erpReferenceNo}>
					<div>
						<strong>{t('machines.no')}</strong>
						<p>{machine.erpReferenceNo}</p>
					</div>

					<div>
						<strong>{t('common.type')}</strong>
						<p>{machine.name}</p>
					</div>

					<div>
						<strong>{t('common.placement')}</strong>
						<p>{machine.placementDescription ? machine.placementDescription : t('common.notSpecified')}</p>
					</div>

					<div>
						<strong>{t('common.location')}</strong>
						<p className="capitalize">{machine.originLocation.toLowerCase()}</p>
					</div>

					<div>
						<strong>{t('machines.moved')}</strong>
						<p>{machine.dateStart && dateToDateTimeString(machine.dateStart + ' ' + machine.startTime)}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default ActiveMachines;
