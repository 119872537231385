import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonThumbnail } from '@ionic/react';
import { playCircle } from 'ionicons/icons';
import { faFileDownload, faSpinner, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as WebVPPlugin from 'capacitor-video-player';
import { CapacitorVideoPlayerPlugin } from 'capacitor-video-player';

import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import FullyCenteredDisplayText from 'Components/Layout/FullyCenteredDisplayText';
import DrivingSlipFilesListElementLoading from './DrivingSlipFilesListElementLoading';
import { DrivingSlipFilesListProps } from './DrivingSlipFilesListProps';
import { useVideoCapture } from '../../../../Hooks/useVideoCapture';
import { useTranslation } from 'react-i18next';
import { FileType } from '@ssg/common/Helpers/fileTypeHelper';

const videoPlayerElement = 'video-list-player';

const DrivingSlipFilesVideoList: React.FC<DrivingSlipFilesListProps> = ({ files, loadingUploadFile, loadingDeleteFile, loadingDownloadFile, uploadingFileType, onDelete, onDownload }) => {
	const { t } = useTranslation();

	const ionListRef = useRef<HTMLIonListElement>(null);
	const videoPlayer = useRef<CapacitorVideoPlayerPlugin>(WebVPPlugin.CapacitorVideoPlayer);
	const [videoToPlay, setVideoToPlay] = useState<{ url: string }>();

	const { getVideoFromLocalStorage } = useVideoCapture();

	useEffect(() => {
		if (videoToPlay) {
			videoPlayer.current.initPlayer({
				mode: 'fullscreen',
				playerId: videoPlayerElement,
				componentTag: 'div',
				url: videoToPlay.url,
			});
		}
	}, [videoToPlay]);

	return (
		<>
			<div id={videoPlayerElement} slot="fixed" />

			<IonAccordionGroup value="videos">
				<IonAccordion toggleIconSlot="start" value="videos">
					<IonItem slot="header">
						<IonLabel className="text-blue font-semibold">{t('common.videos')}</IonLabel>
					</IonItem>
					<IonList ref={ionListRef} slot="content">
						{loadingUploadFile && uploadingFileType && (uploadingFileType & FileType.Video) === FileType.Video && <DrivingSlipFilesListElementLoading />}
						{!loadingUploadFile && files.length === 0 ? (
							<FullyCenteredDisplayText>{t('catalog.noFiles')}</FullyCenteredDisplayText>
						) : (
							files.map((file, index) => (
								<IonItemSliding key={index}>
									<IonItemOptions side="start">
										<IonItemOption color="primary" onClick={() => onDownload(file)} disabled={loadingDownloadFile}>
											{loadingDownloadFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faFileDownload} className="mr-2" />}

											{t('common.download')}
										</IonItemOption>
										<IonItemOption
											color="danger"
											onClick={async () => {
												const confirm = window.confirm(t('catalog.wantToDelete'));
												if (confirm) {
													await onDelete(file);
												}
												ionListRef.current?.closeSlidingItems();
											}}
											disabled={loadingDeleteFile}
										>
											{loadingDeleteFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faTrash} className="mr-2" />}
											{t('common.remove')}
										</IonItemOption>
									</IonItemOptions>
									<IonItem
										onClick={async () => {
											const videoDataFromLocalStorage = await getVideoFromLocalStorage(file.name);
											const url = videoDataFromLocalStorage ? videoDataFromLocalStorage.base64EncodedData : decodeURI(file.url);
											setVideoToPlay({ url });
										}}
									>
										<IonThumbnail slot="start" className="mt-auto flex justify-items-center">
											{/* Render a placeholder thumbnail as SharePoint does not generate video thumbnails */}
											<IonIcon icon={playCircle} size="large" />
										</IonThumbnail>
										<IonLabel>
											<h2>{file.name}</h2>
											<h3>{file.url}</h3>
											<p>{dateToDateTimeString(file.metadata.find(m => m.key === 'Modified')?.value ?? '')}</p>
										</IonLabel>
									</IonItem>
								</IonItemSliding>
							))
						)}
					</IonList>
				</IonAccordion>
			</IonAccordionGroup>
		</>
	);
};

export default DrivingSlipFilesVideoList;
