import { ApiQueries, ApiQueryKey } from './types';

export const ONE_SECOND = 1000; // Milliseconds
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;

export const INTERVAL_DELAY_IN_MS = ONE_MINUTE;

export const API_QUERIES: ApiQueries = {
	[ApiQueryKey.CaseAdminData]: { pollIntervalInMs: ONE_DAY },
	[ApiQueryKey.CollectionItems]: { pollIntervalInMs: ONE_DAY },
	[ApiQueryKey.Debitors]: { pollIntervalInMs: ONE_DAY },
	[ApiQueryKey.DrivingSlip]: { pollIntervalInMs: ONE_DAY }, // todo
	[ApiQueryKey.DrivingSlips]: { pollIntervalInMs: ONE_HOUR },
	[ApiQueryKey.RiskEvaluationCategories]: { pollIntervalInMs: ONE_DAY },
	[ApiQueryKey.RiskEvaluationQuestions]: { pollIntervalInMs: ONE_DAY },
	[ApiQueryKey.QuestionnaireTemplatesByDrivingSlips]: {
		pollIntervalInMs: ONE_DAY,
	},
	[ApiQueryKey.Cars]: {
		pollIntervalInMs: ONE_DAY,
	}, // todo
};

