import React from 'react';
import whiteLogo from '../../Assets/belfor-logo-white.jpg';
import blackLogo from '../../Assets/belfor-logo-black.jpg';

interface Props {
	className: string;
	black?: boolean;
}

const BigLogo: React.FC<Props> = ({ className, black = false }): React.ReactElement => {
	const imgSrc = black ? blackLogo : whiteLogo;

	return <img src={imgSrc} className={className} alt="BELFOR Logo" />;
};

export default BigLogo;
