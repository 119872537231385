/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import { combinedReducer, rootReducer } from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import { Storage } from '@ionic/storage';
import { IonicStorageWrapper } from 'apollo3-cache-persist';
import { getPersistConfig } from 'redux-deep-persist';
import { API_QUERIES } from './Slices/ApiData/constants';
import { API_DATA_KEY } from './Slices/ApiData/apiDataSlice';
import { LOCAL_DATA_KEY } from './Slices/LocalData/localDataSlice';
import createCompressor from 'redux-persist-transform-compress';

const storage = new Storage();
storage.create();

const storageWrapper = new IonicStorageWrapper(storage);

// Uses lz-string to compress the text in Prod
const compressor = createCompressor();

const getKeysToWhitelist = () => {
	const getApiDataSliceKeys = () => {
		const propertiesToWhitelist = ['data', 'metadata'];
		return Object.keys(API_QUERIES).reduce((arr: string[], key: string) => {
			return arr.concat(propertiesToWhitelist.map(prop => `${API_DATA_KEY}.${key}.${prop}`));
		}, []);
	};

	const getLocalDataSliceKeys = () => {
		const keys = ['currentUser', 'drivingSlipsFilters', 'prefetchedDrivingSlipsMetadata', 'selectedDrivingSlip'];
		return keys.map(key => `${LOCAL_DATA_KEY}.${key}`);
	};

	return [...getApiDataSliceKeys(), ...getLocalDataSliceKeys()];
};

const config = getPersistConfig<RootState>({
	key: '5c.mobile.redux.persist',
	keyPrefix: '',
	storage: storageWrapper,
	transforms: process.env.NODE_ENV === 'development' ? undefined : [compressor],
	whitelist: getKeysToWhitelist(),
	rootReducer,
});

const persistedReducer = persistReducer(config, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: persistedReducer,
		preloadedState,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: process.env.NODE_ENV !== 'development',
			}),
		devTools: false,
	});
};

export const store = setupStore({});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
