import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const visitationSchema = yup.object().shape({
	calledBack: yup.boolean(),

	calledBackDescription: yup.string(),
});
