import { useApolloClient } from '@apollo/client';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import {
	EsdhFile,
	FileMetadataField,
	GetCaseFilesDocument,
	GetCaseFilesQuery,
	GetCaseFilesQueryVariables,
	GetDrivingSlipFilesDocument,
	GetDrivingSlipFilesQuery,
	GetDrivingSlipFilesQueryVariables,
} from '@ssg/common/GraphQL/indexV2';
import { imageFileExtensions } from '@ssg/common/Helpers/fileTypeHelper';
import { useFlag } from '@unleash/proxy-client-react';
import heic2any from 'heic2any';
import { isConnected } from 'NetworkContext';
import React from 'react';
import { ApiMutationContext, ApiMutationKey } from 'Store';
import UserContext from 'UserContext';

export interface REST_UploadDrivingSlipFileVariables {
	caseNo: string;
	drivingSlipId: string;
	fileName: string;
	metadata: Array<Record<string, any>>;
	file: File;
	uploadLogId: string;
}

const heicPath = '/heicthumb.jpeg';

export function useUploadDrivingSlipFilesRest() {
	const { baseApiUrl } = React.useContext(EnvironmentVariableContext);
	const { user } = React.useContext(UserContext);
	const apolloClient = useApolloClient();
	const { queueOfflineMutation } = React.useContext(ApiMutationContext);
	const forceQueueMobileAppFlag = useFlag(FeatureFlagEnums.FORCE_QUEUE_MOBILE_APP);
	const { getTokens } = useMicrosoftAuth();
	async function uploadDrivingSlipFile(caseNo: string, drivingSlipId: string, metadata: Array<{ key: string; value: string }>, fileName: string, file: File, index?: number): Promise<void> {
		const uploadLogId = crypto.randomUUID();
		if (!forceQueueMobileAppFlag && isConnected()) {
			await postFile(baseApiUrl, { caseNo, drivingSlipId, fileName, metadata, file, uploadLogId });
		} else {
			queueOfflineMutation({
				key: ApiMutationKey.REST_DrivingSlip_UploadFile,
				variables: {
					caseNo,
					drivingSlipId,
					fileName,
					metadata,
					file,
					uploadLogId,
				},
			});
		}
	}

	const addFilesToCache = async (caseNo: string, drivingSlipId: string, metadata: Array<{ key: string; value: string }>, files: File[]) => {
		const newFilesForCache = await getImageObjectsForCache(files, caseNo, drivingSlipId, metadata);

		const cachedRequest = apolloClient.readQuery<GetDrivingSlipFilesQuery, GetDrivingSlipFilesQueryVariables>({
			query: GetDrivingSlipFilesDocument,
			variables: {
				caseNo: caseNo,
				drivingSlipId: drivingSlipId,
			},
		});
		apolloClient.writeQuery<GetDrivingSlipFilesQuery, GetDrivingSlipFilesQueryVariables>({
			query: GetDrivingSlipFilesDocument,
			variables: {
				caseNo: caseNo,
				drivingSlipId: drivingSlipId,
			},
			data: {
				drivingSlipFiles: [...newFilesForCache.map(n => n.obj), ...(cachedRequest?.drivingSlipFiles ?? [])],
			},
		});

		const cachedRequestCaseFiles = apolloClient.readQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
			query: GetCaseFilesDocument,
			variables: {
				caseErpNo: caseNo,
				folder: caseNo,
			},
		});
		apolloClient.writeQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
			query: GetCaseFilesDocument,
			variables: {
				caseErpNo: caseNo,
				folder: caseNo,
			},
			data: {
				caseFiles: [...newFilesForCache.map(n => n.obj), ...(cachedRequestCaseFiles?.caseFiles ?? [])],
			},
		});

		return newFilesForCache.map(n => n.fileName);
	};

	const getImageObjectsForCache = async (files: File[], caseNo: string, drivingSlipId: string, metadata: Array<{ key: string; value: string }>) => {
		const date = new Date();

		// const fileObjs = files.map(async (file, index) => {
		// 	const nameExt = getNameString(date, file.name, index);
		// 	const metaDataArr = [{ key: 'UploadedBy', value: user?.id ?? '' }, { key: 'Modified', value: date.toISOString() }, ...metadata];
		// 	const thumbnail = nameExt.extension === 'heic' ? URL.createObjectURL(await getFileDataUrl(file)) : URL.createObjectURL(file);
		// 	if (nameExt.extension === 'heic') {
		// 		nameExt.extension = 'jpeg';
		// 	}
		// 	return {
		// 		obj: {
		// 			comments: metadata.find(item => item.key === FileMetadataField.Comments)?.value ?? '',
		// 			created: date.toISOString(),
		// 			extension: nameExt.extension,
		// 			fileId: '234324' + Math.random(),
		// 			groupingKey: '',
		// 			metadata: metaDataArr,
		// 			name: nameExt.fileName,
		// 			thumbnail,
		// 			url: thumbnail + '.' + nameExt.extension,
		// 			__typename: 'ESDHFile',
		// 		} as EsdhFile,
		// 		fileName: nameExt.fileName,
		// 	};
		// });

		const fileObjs: {
			obj: EsdhFile;
			fileName: string;
		}[] = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			const nameExt = getNameString(date, file.name, index);
			const metaDataArr = [{ key: 'UploadedBy', value: user?.id ?? '' }, { key: 'Modified', value: date.toISOString() }, ...metadata];

			let thumbnail;
			if (nameExt.extension === 'heic') {
				thumbnail = heicPath;
				nameExt.extension = 'jpeg';
			} else {
				thumbnail = URL.createObjectURL(file);
			}
			fileObjs.push({
				obj: {
					comments: metadata.find(item => item.key === FileMetadataField.Comments)?.value ?? '',
					created: date.toISOString(),
					extension: nameExt.extension,
					fileId: '234324' + Math.random(),
					groupingKey: '',
					metadata: metaDataArr,
					name: nameExt.fileName,
					thumbnail,
					url: thumbnail + '.' + nameExt.extension,
					__typename: 'ESDHFile',
				} as EsdhFile,
				fileName: nameExt.fileName,
			});
		}

		// return Promise.all(fileObjs);
		return fileObjs;
	};

	async function getFileDataUrl(file: File): Promise<Blob> {
		const imageType = 'image/jpeg';
		const conversionResult = await heic2any({
			blob: file,
			toType: imageType,
			quality: 0.5, // cuts the quality and size by half. No need to use our `compressImage` function.
		});
		return Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
	}

	// const compressFiles = async (files: File[]) => {
	// 	if (noCompressionFlag) {
	// 		return files;
	// 	}
	// 	return await Promise.all(
	// 		files.map(async file => {
	// 			const fileSplit = file.name.split('.');
	// 			const name = fileSplit[0];
	// 			const extension = fileSplit[fileSplit.length - 1];
	// 			if (imageFileExtensions.includes('.' + extension)) {
	// 				file = await compressImage(file);
	// 			} else if (extension === 'heic') {
	// 				const imageType = 'image/jpeg';
	// 				const conversionResult = await heic2any({
	// 					blob: file,
	// 					toType: imageType,
	// 					quality: 0.5, // cuts the quality and size by half. No need to use our `compressImage` function.
	// 				});

	// 				const blob = Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
	// 				file = new File([blob], name + '.jpeg', { type: blob.type, lastModified: Date.now() });
	// 			}
	// 			return file;
	// 		}),
	// 	);
	// };

	const postFile = async (baseApiUrl: string, variables: REST_UploadDrivingSlipFileVariables) => {
		const url = `${baseApiUrl}/upload/drivingSlipFile`;
		const { appToken } = await getTokens();
		try {
			await logBeforeUpload(baseApiUrl, variables.uploadLogId);

			const formData = new FormData();
			formData.append('caseNo', variables.caseNo);
			formData.append('drivingSlipId', variables.drivingSlipId);
			formData.append('fileName', variables.fileName);
			variables.metadata.forEach((item, index) => {
				formData.append(`metadata[${index}].key`, item.key);
				formData.append(`metadata[${index}].value`, item.value);
			});
			formData.append('file', variables.file);
			const response = await fetch(url, {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: `Bearer ${appToken}`,
				},
			});
			if (!response.ok) {
				throw new Error(`Failed to upload file: ${response.statusText}`);
			}

			await logUploadDone(baseApiUrl, variables.uploadLogId);
		} catch (error) {
			throw error; // Ensure the error is thrown
		}
	};

	return {
		uploadDrivingSlipFile,
		addFilesToCache,
		postFile,
	};
}

export const logBeforeUpload = async (baseApiUrl: string, loggingId: string) => {
	const url = `${baseApiUrl}/upload/beforeUpload`;
	const body = JSON.stringify({
		id: loggingId,
	});

	const response = await fetch(url, {
		method: 'POST',
		body,
		headers: {
			'Content-Type': 'application/json',
		},
	});
	return response;
};

export const logUploadDone = async (baseApiUrl: string, loggingId: string) => {
	const url = `${baseApiUrl}/upload/uploadDone`;
	const body = JSON.stringify({
		id: loggingId,
	});

	const response = await fetch(url, {
		method: 'POST',
		body,
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (!response.ok) {
		throw new Error(`Failed to log file: ${response.statusText}`);
	}

	return response;
};

const getNameString = (d: Date, fileName: string, index?: number) => {
	const year = d.getFullYear();
	const month = `${('0' + (d.getMonth() + 1)).slice(-2)}`;
	const date = `${('0' + d.getDate()).slice(-2)}`;

	const hour = `${('0' + d.getHours()).slice(-2)}`;
	const minute = `${('0' + d.getMinutes()).slice(-2)}`;
	const seconds = `${('0' + d.getSeconds()).slice(-2)}`;
	const fileSplit = fileName.split('.');
	const extension = fileSplit[fileSplit.length - 1];
	return {
		fileName: [...imageFileExtensions, '.heic'].includes('.' + extension)
			? `${year}-${month}-${date}_${hour}-${minute}-${seconds}${index ? `-${index}` : ''}.${extension === 'heic' ? 'jpeg' : extension}`
			: fileName,
		extension,
	};
};

