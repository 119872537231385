import { ApolloQueryResult } from '@apollo/client';

export type DataOrigin = 'local' | 'remote';

export interface Metadata {
	lastFetched: Date | null;
	origin?: DataOrigin;
}

export interface Data<TQuery, TData, TVariables> {
	data: TData;
	error?: unknown; // cannot use ApolloError due to readonly property issues
	loading: boolean;
	metadata: Metadata;
	fetchMore: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TQuery> | undefined>;
	refetch: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TQuery> | undefined>;
}

export enum ApiQueryKey {
	CaseAdminData = 'caseAdminData',
	CollectionItems = 'collectionItems',
	Debitors = 'debitors',
	DrivingSlip = 'drivingSlip',
	DrivingSlips = 'drivingSlips',
	RiskEvaluationCategories = 'riskEvaluationCategories',
	RiskEvaluationQuestions = 'riskEvaluationQuestions',
	QuestionnaireTemplatesByDrivingSlips = 'questionnaireTemplatesByDrivingSlips',
	Cars = 'cars',
}

export interface ApiQuery {
	pollIntervalInMs: number;
}

export type ApiQueries = {
	[key in ApiQueryKey]: ApiQuery;
};

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>; // Makes all fields optional except at least one

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>; // Make all fields optional except one which is required

