import { IonCol, IonLabel } from '@ionic/react';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import React from 'react';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';

import Button from '@ssg/common/Components/Button';
import { ESDHFileFragment } from 'GQL';

interface Props {
	signatureFile: ESDHFileFragment | undefined;
	onSubmit: (signature: { base64: string; extension: string }) => unknown;
	onClear: () => unknown;
	label?: string;
	errorMessage?: string;
	isUploading: boolean;
}

const extension = 'png';

const SignatureInput: React.FC<Props> = ({ onSubmit, onClear, signatureFile, label, errorMessage, isUploading }) => {

	const canvasRef = React.createRef<ReactSketchCanvasRef>();

	return (
		<IonCol size="12">
			{label != null && <IonLabel className=" text-blue text-base font-semibold">{label}<span className="text-red">*</span></IonLabel>}

			{signatureFile != null && <img src={signatureFile.thumbnail} alt="signature" style={{ height: 250 }} />}

			{signatureFile == null && <ReactSketchCanvas height="250px" strokeColor="black" ref={canvasRef} />}

			{errorMessage && <FormErrorText text={errorMessage} />}

			<div className="flex justify-between mt-2">
				<Button
					success
					loading={isUploading}
					disabled={signatureFile != null}
					onClick={async () => {
						if (canvasRef.current == null) return;
						const base64 = await canvasRef.current.exportImage(extension);
						onSubmit({ base64, extension });
					}}
					text="reports.signatureInput.save"
				/>

				<Button
					danger
					onClick={() => {
						canvasRef.current?.clearCanvas();
						onClear();
					}}
					text="reports.signatureInput.clear"
				/>
			</div>
		</IonCol>
	);
};

export default SignatureInput;
