/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import {
	CreateFalckDehumidifierReport,
	CreateFalckDehumidifierReportVariables,
	CreateIndoorClimateReport,
	CreateIndoorClimateReportVariables,
	CreateMobileV2InspectionReport,
	CreateMobileV2InspectionReportVariables,
	CreateMobileV2MoistureReport,
	CreateMobileV2MoistureReportVariables,
	CreateMobileV2MoistureTrygReport,
	CreateMobileV2MoistureTrygReportVariables,
	ModifyDrivingSlipFileMetadata,
	ModifyDrivingSlipFileMetadataVariables,
	SetDrivingSlipDrivingStarted,
	SetDrivingSlipDrivingStartedVariables,
	SetDrivingSlipFacadePictureTaken,
	SetDrivingSlipFacadePictureTakenVariables,
	SetDrivingSlipNoWasteUsed,
	SetDrivingSlipNoWasteUsedVariables,
	SetMobileV2DrivingSlipQuestionnaire,
	SetDrivingSlipQuestionnaireCompletionStatus,
	SetDrivingSlipQuestionnaireCompletionStatusVariables,
	SetMobileV2DrivingSlipQuestionnaireVariables,
	SetDrivingSlipRiskEvaluationStatus,
	SetDrivingSlipRiskEvaluationStatusVariables,
	SetMobileV2DrivingSlipStatus,
	SetMobileV2DrivingSlipStatusVariables,
	UploadDrivingSlipFiles,
	UploadDrivingSlipFilesVariables,
	CreateMobileV2InspectionMovablesReport,
	CreateMobileV2InspectionMovablesReportVariables,
	QueueUploadDrivingSlipFiles,
	QueueUploadDrivingSlipFilesVariables,
} from 'GQL';
import { useOfflineHandlingMutation } from 'Hooks';
import { loader } from 'graphql.macro';

const SET_DRIVING_SLIP_DRIVING_STARTED = loader('src/GQL/DrivingSlips/SetDrivingSlipDrivingStarted.gql');
const SET_DRIVING_SLIP_FACADE_PICTURE_TAKEN = loader('src/GQL/DrivingSlips/SetDrivingSlipFacadePictureTaken.gql');
const SET_DRIVING_SLIP_NO_WASTE_USED = loader('src/GQL/DrivingSlips/SetDrivingSlipNoWasteUsed.gql');
const SET_DRIVING_SLIP_QUESTIONNAIRE = loader('src/GQL/DrivingSlips/SetMobileV2DrivingSlipQuestionnaire.gql');
const SET_DRIVING_SLIP_QUESTIONNAIRE_STATUS = loader('src/GQL/DrivingSlips/SetDrivingSlipQuestionnaireCompletionStatus.gql');
const SET_DRIVING_SLIP_STATUS = loader('src/GQL/DrivingSlips/SetMobileV2DrivingSlipStatus.gql');
const SET_DRIVING_SLIP_RISK_EVALUATION_STATUS = loader('src/GQL/DrivingSlips/SetDrivingSlipRiskEvaluationStatus.gql');
const UPLOAD_DRIVING_SLIP_FILES = loader('src/GQL/Files/UploadDrivingSlipFiles.gql');
const QUEUE_UPLOAD_DRIVING_SLIP_FILES = loader('src/GQL/Files/QueueUploadDrivingSlipFiles.gql');
const MODIFY_DRIVING_SLIP_FILE_METADATA = loader('src/GQL/Files/ModifyDrivingSlipFileMetadata.gql');

//Reports
const CREATE_INSPECTION_REPORT = loader('src/GQL/Reports/CreateMobileV2InspectionReport.gql');
const CREATE_INSPECTION_MOVABLES_REPORT = loader('src/GQL/Reports/CreateMobileV2InspectionMovablesReport.gql');
const CREATE_MOISTURE_REPORT = loader('src/GQL/Reports/CreateMobileV2MoistureReport.gql');
const CREATE_MOISTURE_TRYG_REPORT = loader('src/GQL/Reports/CreateMobileV2MoistureTrygReport.gql');
const CREATE_INDOOR_CLIMATE_REPORT = loader('src/GQL/Reports/CreateMobileIndoorClimateReport.gql');
const CREATE_FALCK_DEHUMIDIFIER_REPORT = loader('src/GQL/Reports/CreateFalckDehumidifierReport.gql');

export const useSetDrivingSlipDrivingStarted = () => useOfflineHandlingMutation<SetDrivingSlipDrivingStarted, SetDrivingSlipDrivingStartedVariables>(SET_DRIVING_SLIP_DRIVING_STARTED);

export const useSetDrivingSlipFacadePictureTaken = () => useOfflineHandlingMutation<SetDrivingSlipFacadePictureTaken, SetDrivingSlipFacadePictureTakenVariables>(SET_DRIVING_SLIP_FACADE_PICTURE_TAKEN);

export const useSetDrivingSlipNoWasteUsed = () => useOfflineHandlingMutation<SetDrivingSlipNoWasteUsed, SetDrivingSlipNoWasteUsedVariables>(SET_DRIVING_SLIP_NO_WASTE_USED);

export const useSetDrivingSlipQuestionnaire = () => useOfflineHandlingMutation<SetMobileV2DrivingSlipQuestionnaire, SetMobileV2DrivingSlipQuestionnaireVariables>(SET_DRIVING_SLIP_QUESTIONNAIRE);

export const useSetDrivingSlipQuestionnaireStatus = () =>
	useOfflineHandlingMutation<SetDrivingSlipQuestionnaireCompletionStatus, SetDrivingSlipQuestionnaireCompletionStatusVariables>(SET_DRIVING_SLIP_QUESTIONNAIRE_STATUS);

export const useSetDrivingSlipStatus = () => useOfflineHandlingMutation<SetMobileV2DrivingSlipStatus, SetMobileV2DrivingSlipStatusVariables>(SET_DRIVING_SLIP_STATUS);

export const useSetDrivingSlipRiskEvaluationStatus = () =>
	useOfflineHandlingMutation<SetDrivingSlipRiskEvaluationStatus, SetDrivingSlipRiskEvaluationStatusVariables>(SET_DRIVING_SLIP_RISK_EVALUATION_STATUS);

export const useUploadDrivingSlipFile = () => {
	const queueDrivingSlipFileUploadFeatureFlag = useFlag(FeatureFlagEnums.QUEUE_DRIVING_SLIP_FILE_UPLOAD);

	const queueUploadMutation = useOfflineHandlingMutation<QueueUploadDrivingSlipFiles, QueueUploadDrivingSlipFilesVariables>(QUEUE_UPLOAD_DRIVING_SLIP_FILES, {
		fetchPolicy: 'no-cache',
		ignoreResults: true,
	});

	const uploadMutation = useOfflineHandlingMutation<UploadDrivingSlipFiles, UploadDrivingSlipFilesVariables>(UPLOAD_DRIVING_SLIP_FILES, {
		fetchPolicy: 'no-cache',
		ignoreResults: true,
	});

	return queueDrivingSlipFileUploadFeatureFlag ? queueUploadMutation : uploadMutation;
};

export const useQueueUploadDrivingSlipFile = () => useOfflineHandlingMutation<QueueUploadDrivingSlipFiles, QueueUploadDrivingSlipFilesVariables>(QUEUE_UPLOAD_DRIVING_SLIP_FILES);

export const useModifyDrivingSlipFileMetadata = () => useOfflineHandlingMutation<ModifyDrivingSlipFileMetadata, ModifyDrivingSlipFileMetadataVariables>(MODIFY_DRIVING_SLIP_FILE_METADATA);
//Reports
export const useCreateInspectionReport = () => useOfflineHandlingMutation<CreateMobileV2InspectionReport, CreateMobileV2InspectionReportVariables>(CREATE_INSPECTION_REPORT);

export const useCreateInspectionMovablesReport = () =>
	useOfflineHandlingMutation<CreateMobileV2InspectionMovablesReport, CreateMobileV2InspectionMovablesReportVariables>(CREATE_INSPECTION_MOVABLES_REPORT);

export const useCreateMoistureReport = () => useOfflineHandlingMutation<CreateMobileV2MoistureReport, CreateMobileV2MoistureReportVariables>(CREATE_MOISTURE_REPORT);

export const useCreateMoistureTrygReport = () => useOfflineHandlingMutation<CreateMobileV2MoistureTrygReport, CreateMobileV2MoistureTrygReportVariables>(CREATE_MOISTURE_TRYG_REPORT);

export const useCreateIndoorClimateReport = () => useOfflineHandlingMutation<CreateIndoorClimateReport, CreateIndoorClimateReportVariables>(CREATE_INDOOR_CLIMATE_REPORT);

export const useCreateFalckDehumidifierReport = () => useOfflineHandlingMutation<CreateFalckDehumidifierReport, CreateFalckDehumidifierReportVariables>(CREATE_FALCK_DEHUMIDIFIER_REPORT);
