import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonGrid, IonLabel, IonRow, IonSkeletonText, useIonModal } from '@ionic/react';
import { getExtensionIcon } from '@ssg/common/Helpers/fileExtensionHelper';
import DebitorRestrictedArea from '@ssg/common/Components/DebitorRestrictedArea';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from 'Components/Button';
import DocumentViewerModal, { Props as DocumentViewerModalProps } from 'Components/DocumentViewerModal';
import BasePage from 'Components/Layout/BasePage';
import { GetCaseESDHFiles_case_documents, GetMobileV2CaseFile, GetMobileV2CaseFileVariables, GetCaseFiles, GetCaseFilesVariables, GetMobileV2DrivingSlip_drivingSlip_case } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';
import Routes from 'Routes';
import { FileToDownload } from '../Files/DrivingSlipFilesOverview';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');
const GET_CASE_FILE = loader('src/GQL/Files/GetMobileV2CaseFile.gql');

// Disabling PDF viewer due to large, problematic PDF files
const enablePdfViewer = false;

interface Metadata {
	key: string;
	value: string;
}

interface Props {
	caseData: GetMobileV2DrivingSlip_drivingSlip_case;
}

const SelectReport: React.FC<Props> = ({ caseData }) => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const inspectionMovablesReportFlag = useFlag(FeatureFlagEnums.INSPECTION_MOVABLES_REPORT);

	const {
		data: caseFiles,
		loading: caseFilesLoading,
		refetch: caseFilesRefetch,
	} = useOfflineHandlingQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, {
		variables: {
			caseErpNo: caseData.erpNo,
			folder: caseData.erpNo,
		},
	});

	const [fileToDownload, setFileToDownload] = useState<FileToDownload>({
		fileName: '',
		extension: '',
		folder: '',
	});
	const { data: caseFileToDownload } = useOfflineHandlingQuery<GetMobileV2CaseFile, GetMobileV2CaseFileVariables>(GET_CASE_FILE, {
		variables: {
			fileName: fileToDownload.fileName,
			folder: fileToDownload.folder,
		},
		skip: fileToDownload.fileName === '',
	});

	const [presentDocumentViewer, dismissDocumentViewer] = useIonModal(DocumentViewerModal, {
		file: caseFileToDownload?.caseFile
			? {
				name: caseFileToDownload.caseFile.filename,
				extension: fileToDownload.extension,
				data: caseFileToDownload.caseFile.fileData,
				base64Data: caseFileToDownload.caseFile.fileDataBase64,
			}
			: undefined,
		onDismiss: () => dismissDocumentViewer(),
	} as DocumentViewerModalProps);

	const onView = (file: GetCaseESDHFiles_case_documents) => {
		setFileToDownload({
			fileName: file.name,
			extension: file.extension,
			folder: caseData.erpNo,
			downloadType: 'view',
		});
		presentDocumentViewer();
	};

	const isReportType = (metadata: Array<Metadata>): boolean => {
		return metadata.some(o => o.key === 'DokumentType' && o.value === 'Rapport');
	};

	const reports = React.useMemo(() => caseFiles?.caseFiles.filter(f => isReportType(f.metadata)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFiles]);

	return (
		<BasePage title={t('reports.overviewTitle')} refreshFunc={caseFilesRefetch} shouldOfflineWarn offlineMessage="reports.filesOffline">
			<IonLabel>
				<div className="flex justify-between">
					<h2 className="text-blue font-semibold">{t('reports.pageTitle')}</h2>
				</div>
			</IonLabel>

			<IonGrid className="mb-2">
				{caseFilesLoading ? (
					<IonRow class="border-b-1 py-1">
						<IonCol size="auto" className="flex items-center">
							<IonSkeletonText animated className="pt-5" style={{ width: '30px' }} />
						</IonCol>
						<IonCol>
							<IonSkeletonText animated className="pt-5" />
						</IonCol>
					</IonRow>
				) : (
					reports.map((file, index) => (
						<IonRow
							class="border-b-1 py-1"
							key={index}
							onClick={() => {
								// For PDFs use the in-app document viewer.
								// Microsoft Office documents are supported however it requires we create..
								// ..a custom unauthenticated API endpoint to serve the file (and not just the raw data).
								if (enablePdfViewer && file.extension === 'pdf') {
									onView?.(file);
									return;
								}

								// Otherwise open the Sharepoint url and let Sharepoint serve the file
								window.open(file.url, '_blank');
							}}
						>
							<IonCol size="auto" className="flex items-center">
								<FontAwesomeIcon icon={getExtensionIcon(file.extension)} size="lg" />
							</IonCol>
							<IonCol>
								<span className="text-sm">{file.name}</span>
							</IonCol>
						</IonRow>
					))
				)}
			</IonGrid>

			<IonLabel>
				<div className="flex justify-between">
					<h2 className="text-blue font-semibold">{t('reports.newTitle')}</h2>
				</div>
			</IonLabel>

			<Button expand="block" routerLink={Routes.DrivingSlipInspectionReport.replace(':id', id)}>
				{t('reports.reportTypes.inspection')}
			</Button>
			{inspectionMovablesReportFlag &&
				<Button expand="block" routerLink={Routes.DrivingSlipInspectionMovablesReport.replace(':id', id)}>
					{t('reports.reportTypes.inspectionMovables')}
				</Button>
			}
			<Button expand="block" routerLink={Routes.DrivingSlipWaterReport.replace(':id', id)}>
				{t('reports.reportTypes.water')}
			</Button>
			<DebitorRestrictedArea requiredDebitorNos={['0073', '0098', '17493', '18161']} requiredDebitorGroup="FORS_TRYG" debitor={caseData.debitor}>
				<Button expand="block" routerLink={Routes.DrivingSlipWaterReportTryg.replace(':id', id)}>
					{t('reports.reportTypes.trygWater')}
				</Button>
			</DebitorRestrictedArea>
			<DebitorRestrictedArea requiredDebitorNos={['2835']} requiredDebitorGroup="FALCK" debitor={caseData.debitor}>
				<Button expand="block" routerLink={Routes.DrivingSlipFalckDehumidifierReport.replace(':id', id)}>
					{t('reports.reportTypes.falckDehumidifier')}
				</Button>
			</DebitorRestrictedArea>

			<Button expand="block" routerLink={Routes.DrivingSlipIndoorClimateReport.replace(':id', id)}>
				{t('reports.reportTypes.indoorClimate')}
			</Button>
		</BasePage>
	);
};

export default SelectReport;
