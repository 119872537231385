import React from 'react';
import { IonInput, IonItem, IonLabel, useIonModal } from '@ionic/react';
import Button from '@ssg/common/Components/Button';
import ScannerModal, { Props as ScannerModalProps } from './Modals/ScannerModalTwo';
import { useTranslation } from 'react-i18next';

interface Props {
	onFind(machineNr: string): void;
}

const FindMachine: React.FC<Props> = ({ onFind }) => {
	const { t } = useTranslation();

	const [machineNumber, setMachineNumber] = React.useState('');

	const [presentScanner, dissmissScanner] = useIonModal(ScannerModal, {
		onDismiss: () => dissmissScanner(),
		onScanSuccess: (id: string) => {
			setMachineNumber(id);
			onFind(id);
			dissmissScanner();
		},
	} as ScannerModalProps);

	return (
		<div className="flex flex-col gap-2">
			<IonItem>
				<IonLabel position="stacked">{t('machines.no')}</IonLabel>
				<IonInput value={machineNumber} onIonChange={e => setMachineNumber(e.detail.value ?? '')} name="maskinenummer" />
			</IonItem>

			<Button primary onClick={() => onFind(machineNumber)}>
				{t('machines.find')}
			</Button>

			<Button primary onClick={() => presentScanner()}>
				{t('machines.scanLabel')}
			</Button>
		</div>
	);
};

export default FindMachine;
