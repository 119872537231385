import React from 'react';
import { Configuration, PublicClientApplication, InteractionStatus, AccountInfo } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';

interface AuthContextValues {
	getTokens: () => Promise<{
		appToken: string | undefined;
		bcToken: string | undefined;
	}>;
	logout: () => Promise<void>;
	login: () => Promise<void>;
	account: AccountInfo | undefined;
	isAuthenticated: boolean;
}

const MicrosoftAuthContext = React.createContext<AuthContextValues>({
	getTokens: async () => ({ appToken: undefined, bcToken: undefined }),
	logout: async () => undefined,
	login: async () => undefined,
	account: undefined,
	isAuthenticated: false,
});

export const useMicrosoftAuth = (): AuthContextValues => React.useContext(MicrosoftAuthContext);

type Scopes = string[];
interface ProviderProps {
	msalConfig: Configuration;
	msalScopesApp: Scopes;
	msalScopesBC: Scopes;
	autoLogin?: boolean;
}

export const MicrosoftAuthProvider: React.FC<ProviderProps> = ({ msalConfig, msalScopesApp, msalScopesBC, autoLogin, children }) => {
	const msalInstance = new PublicClientApplication(msalConfig);

	return (
		<MsalProvider instance={msalInstance}>
			<MicrosoftAuthProviderWrapped msalScopesApp={msalScopesApp} msalScopesBC={msalScopesBC} autoLogin={autoLogin}>
				{children}
			</MicrosoftAuthProviderWrapped>
		</MsalProvider>
	);
};

const MicrosoftAuthProviderWrapped: React.FC<Omit<ProviderProps, 'msalConfig'>> = ({ msalScopesApp, msalScopesBC, autoLogin = false, children }) => {
	const { instance, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [account, setAccount] = React.useState<AccountInfo>();

	const getActiveAccount = () => instance.getActiveAccount() ?? instance.getAllAccounts()[0];

	React.useEffect(() => {
		if (autoLogin && !isAuthenticated && inProgress === InteractionStatus.None) {
			instance.loginRedirect({ scopes: msalScopesApp });
		}
	}, [autoLogin, isAuthenticated, inProgress, instance, msalScopesApp]);

	React.useEffect(() => {
		if (isAuthenticated) {
			setAccount(getActiveAccount());
		}
	}, [instance, isAuthenticated]);

	const context: AuthContextValues = {
		isAuthenticated: isAuthenticated && typeof account !== 'undefined',
		getTokens: async () => {
			// In rare async cases this executes before the account has been set above
			// Therefore get active account if account is not yet defined
			const acc = account ?? getActiveAccount();
			const [appToken, bcToken] = await Promise.all([
				instance.acquireTokenSilent({ scopes: msalScopesApp, account: acc }).then(res => res.accessToken),
				instance.acquireTokenSilent({ scopes: msalScopesBC, account: acc }).then(res => res.accessToken),
			]);
			return { appToken, bcToken };
		},
		logout: () => instance.logout(),
		login: () => instance.loginRedirect(),
		account: account,
	};

	return <MicrosoftAuthContext.Provider value={context}>{children}</MicrosoftAuthContext.Provider>;
};
