import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@ssg/common/Components/Button';
import Textarea from '@ssg/common/Components/Textarea';
import CloseableModal from 'Components/CloseableModal';

export interface Props {
	onDismiss: () => void;
	oldComment: string;
	fileId: string;
	fileName: string;
	changeComment: (fileName: string, fileId: string, comment: string) => Promise<string>;
}

const ChangeCommentModal: React.FC<Props> = ({ onDismiss, oldComment, fileName, fileId, changeComment }): React.ReactElement => {
	const { t } = useTranslation();

	const [comment, setComment] = React.useState(oldComment);

	return (
		<CloseableModal title={t('common.comment')} onDismiss={onDismiss}>
			<div>
				<Textarea name="comment" title="" defaultValue={comment} onChange={e => setComment(e.target.value)} />

				<div className="mt-3 flex">
					<Button
						success
						text="common.confirm"
						className="mr-3"
						onClick={async () => {
							await changeComment(fileName, fileId, comment);
							onDismiss();
						}}
					/>
					<Button danger text="common.cancel" onClick={() => onDismiss()} />
				</div>
			</div>
		</CloseableModal>
	);
};

export default ChangeCommentModal;
