import React from 'react';
import { Permissions } from '@ssg/common/GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { formatDate, SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	CreateTimeTracking,
	CreateTimeTrackingVariables,
	DeleteTimeTracking,
	DeleteTimeTrackingVariables,
	GetMobileV2CasesByErpNo,
	GetMobileV2CasesByErpNoVariables,
	GetMobileV2CasesForTimeTracking,
	GetMobileV2CasesForTimeTrackingVariables,
	GetClosedDayByUser,
	GetClosedDayByUserVariables,
	GetMobileV2DrivingSlip,
	GetDrivingSlipCategories,
	GetMobileV2DrivingSlipsNoCase,
	GetMobileV2DrivingSlipsNoCaseVariables,
	GetMobileV2DrivingSlipsNoCase_drivingSlips,
	GetMobileV2DrivingSlipVariables,
	GetLatestDayByUser,
	GetLatestDayByUserVariables,
	GetPaymentSupplements,
	GetTimeTrackingsForUser,
	GetTimeTrackingsForUserVariables,
	GetTimeTrackingsForUser_timeTrackingsForUser_case,
	JobTasks,
	JobTasksVariables,
	SearchMobileV2Users,
	SearchMobileV2UsersVariables,
	SubmitDay,
	SubmitDayVariables,
	SupplementType,
	TimeTrackingInputType,
	TimeTrackingStatus,
	TrackingType,
	UserBasisTime,
	UserBasisTimeVariables,
	GetRejectedTimeTrackingsForUser,
	GetRejectedTimeTrackingsForUserVariables,
} from 'GQL';
import { yupResolver } from '@hookform/resolvers/yup';
import { TimeRegistrationSchema } from 'Schemas/TimeRegistrationSchema';
import { ITimeRegistration } from 'Schemas/ITimeRegistration';
import { DateTime, Interval } from 'luxon';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { useOfflineHandlingMutation, useOfflineHandlingQuery } from 'Hooks';
import { useFlag } from '@unleash/proxy-client-react';
import UserContext from 'UserContext';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Table from '@ssg/common/Components/Table';
import Textarea from '@ssg/common/Components/Textarea';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import TextButton from '@ssg/common/Components/TextButton';
import BasePage from 'Components/Layout/BasePage';
import SupplementLine from './SupplementLine';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import RejectedTimeRegistrationWarningModal from './RejectedTimeRegistrationModal/RejctedTimeRegistrationWarningModal';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_CASES_FOR_TIME_TRACKING = loader('src/GQL/Cases/GetMobileV2Cases.gql');
const GET_CASES_BY_ERPNO = loader('src/GQL/Cases/GetMobileV2CasesByErpNo.gql');
const GET_TIME_TRACKING_FOR_USER = loader('src/GQL/TimeTracking/GetTimeTrackingForUser.gql');
const GET_JOB_TASKS = loader('src/GQL/Offers/GetJobTask.gql');
const GET_PAYMENT_SUPPLEMENTS = loader('src/GQL/TimeTracking/GetPaymentSupplements.gql');
const DELETE_TIME_TRACKING = loader('src/GQL/TimeTracking/DeleteTimeTracking.gql');
const CREATE_TIME_TRACKING = loader('src/GQL/TimeTracking/CreateTimeTracking.gql');
const GET_DRIVINGSLIP = loader('src/GQL/DrivingSlips/GetMobileV2DrivingSlip.gql');
const GET_DRIVINGSLIPS_NO_CASE = loader('src/GQL/DrivingSlips/GetMobileV2DrivingSlipsNoCase.gql');
const GET_DRIVINGSLIP_CATEGORIES = loader('src/GQL/DrivingSlips/GetDrivingSlipCategories.gql');
const GET_CLOSED_DAY = loader('src/GQL/TimeTracking/GetClosedDayByUser.gql');
const USER_BASIS_TIME = loader('src/GQL/TimeTracking/UserBasisTime.gql');
const SEARCH_USERS = loader('src/GQL/Users/SearchMobileV2Users.gql');
const SUBMIT_DAY = loader('src/GQL/TimeTracking/SubmitDay.gql');
const GET_LATEST_DAY = loader('src/GQL/TimeTracking/GetLatestDayByUser.gql');
const GET_REJECTED_TIME_TRACKINGS = loader('src/GQL/TimeTracking/GetRejectedTimeTrackings.gql');

const fakeActivityCode: SelectOption[] = [
	{ label: 'Intern', value: 'INTERN' },
	{ label: 'Køreseddel', value: 'DRIVINGSLIP' },
	{ label: 'Sag', value: 'CASE' },
];

const TimeRegistrationOverview: React.FC = (): React.ReactElement => {
	const RejectedTimeRegistrationWarningFlag = useFlag(FeatureFlagEnums.REJECTED_TIME_REGISTRATION_WARNING);
	const jobNoDefaultValue = useFlag(FeatureFlagEnums.TIMEREG_JOB_NO_DEFAULT_CASE);
	const changeUserContextToAccount = useFlag(FeatureFlagEnums.CHANGE_MOBILE_TIMEREG_USER_CONTEXT);

	const { t } = useTranslation();
	const { id } = useParams<{ id?: string }>();

	const userContext = React.useContext(UserContext);

	const { account } = useMicrosoftAuth();

	const accountId = account?.username || '';

	const formObj = useForm<ITimeRegistration>({
		resolver: yupResolver(TimeRegistrationSchema),
		defaultValues: {
			task: typeof id !== 'undefined' ? 'DRIVINGSLIP' : '',
			drivingSlip: id,
			substitute: false,
		},
		mode: 'all',
		reValidateMode: 'onChange',
		shouldUnregister: false,
	});
	const { handleSubmit, register, getValues, setValue, errors, control, watch, reset } = formObj;

	const { data: drivingSlipData, loading: loadingDrivingSlip } = useOfflineHandlingQuery<GetMobileV2DrivingSlip, GetMobileV2DrivingSlipVariables>(GET_DRIVINGSLIP, {
		skip: id === '' || typeof id === 'undefined',
		variables: { id: id ?? '' },
	});

	React.useEffect(() => {
		if (typeof drivingSlipData !== 'undefined') {
			setValue('case', drivingSlipData.drivingSlip.case.id);
			setValue('jobNo', drivingSlipData.drivingSlip.case.erpNo);
			setValue('drivingSlip', id, { shouldValidate: true });
		}
	}, [drivingSlipData, id, setValue]);

	const [caseSearchText, setCaseSearchText] = React.useState('');
	const [overruleDate, setOverruleDate] = React.useState(false);

	const [selectedCase, setSelectedCase] = React.useState<SelectOption | undefined>(
		typeof drivingSlipData !== 'undefined'
			? {
				label: `${drivingSlipData.drivingSlip.case.erpNo} - ${drivingSlipData.drivingSlip.case.damage.contact.address.road} ${drivingSlipData.drivingSlip.case.damage.contact.address.houseNumber}, ${drivingSlipData.drivingSlip.case.damage.contact.address.postalCode} ${drivingSlipData.drivingSlip.case.damage.contact.address.city} (${drivingSlipData.drivingSlip.case.damage.category.name}/${drivingSlipData.drivingSlip.case.damage.cause.name})`,
				value: drivingSlipData.drivingSlip.case.erpNo,
			}
			: undefined,
	);

	const jobTaskERPRef = React.useMemo(() => {
		if (typeof selectedCase === 'undefined') {
			return '';
		}

		return selectedCase.value;
	}, [selectedCase]);

	const { data: jobTaskData, loading: jobTaskLoading } = useOfflineHandlingQuery<JobTasks, JobTasksVariables>(GET_JOB_TASKS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			erpReferenceNo: jobTaskERPRef,
		},
		skip: jobTaskERPRef === '',
	});

	const [jobTask, setJobTask] = React.useState(id ? 'DRIVINGSLIP' : '');
	const [timeRegistration, setTimeRegistration] = React.useState(false);
	const [jobSearchText, setJobSearchText] = React.useState('');

	const [showSupplement, setShowSupplement] = React.useState(false);
	const [supplementLines, setSupplementLines] = React.useState<string[]>([]);

	const [indicateOnCall, setIndicateOnCall] = React.useState(false);

	const [showOvertimeWarning, setShowOvertimeWarning] = React.useState(false);

	const [substitutionReg, setSubstitutionReg] = React.useState(false);

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const [selectedDrivingSlip, setSelectedDrivingSlip] = React.useState<GetMobileV2DrivingSlipsNoCase_drivingSlips>();
	const [overEstimate, setOverEstimate] = React.useState(false);
	const [prevTrackingNotClosed, setPrevTrackingNotClosed] = React.useState(false);
	const [showCloseDayModal, setShowCloseDayModal] = React.useState(false);

	const [searchUserText, setSearchUserText] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState('');
	const [showRejectedRegistrationWarningModal, setShowRejectedregistrationWarningModal] = React.useState<boolean>(false);

	const formRef = React.useRef<HTMLFormElement>(null);
	const allowOvertime = React.useRef(false);

	const userAccountId = changeUserContextToAccount ? accountId : userContext?.user?.id;

	const userId = substitutionReg ? selectedUser : userAccountId ?? '';

	const { data: closedDay, refetch: refetchClosedDay } = useOfflineHandlingQuery<GetClosedDayByUser, GetClosedDayByUserVariables>(GET_CLOSED_DAY, {
		fetchPolicy: 'no-cache',
		variables: {
			user: userId,
		},
		skip: userId === '',
	});

	// Returns latest open or closed day. Differentiate on TrackingType
	const { data: latestDay, refetch: refetchLatestDay } = useOfflineHandlingQuery<GetLatestDayByUser, GetLatestDayByUserVariables>(GET_LATEST_DAY, {
		fetchPolicy: 'no-cache',
		variables: {
			user: userId,
		},
		skip: userId === '',
	});

	const { data: rejectedTimeTrackingData } = useOfflineHandlingQuery<GetRejectedTimeTrackingsForUser, GetRejectedTimeTrackingsForUserVariables>(GET_REJECTED_TIME_TRACKINGS, {
		fetchPolicy: 'no-cache',
		variables: { user: userId },
		skip: userId === '' || !RejectedTimeRegistrationWarningFlag,
	});

	React.useEffect(() => {
		if (!RejectedTimeRegistrationWarningFlag) {
			return;
		}
		if (typeof rejectedTimeTrackingData?.rejectedTimeTrackingsForUser !== 'undefined') {
			const hasRejected = rejectedTimeTrackingData?.rejectedTimeTrackingsForUser.length > 0;
			const showRejectModalIfNotSubstitute = hasRejected && !substitutionReg;
			setShowRejectedregistrationWarningModal(showRejectModalIfNotSubstitute);
		}
	}, [rejectedTimeTrackingData]);

	const remoteSubmit = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	const drivingSlipWatch = watch('drivingSlip', drivingSlipData?.drivingSlip.id);
	const task = watch('task');
	const lines = watch('addonLines');

	const { data: users, loading: loadingUsers } = useOfflineHandlingQuery<SearchMobileV2Users, SearchMobileV2UsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: searchUserText,
			checkInBc: true,
			onlyEmployees: true,
		},
		skip: searchUserText === '',
	});

	const searchedUsers = React.useMemo(() => users?.searchUsers.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [], [users]);

	const [selectedDate, setSelectedDate] = React.useState('');

	const {
		data: timeTrackingDataRaw,
		loading: loadingTimeTracking,
		refetch: refetchTimeTracking,
	} = useOfflineHandlingQuery<GetTimeTrackingsForUser, GetTimeTrackingsForUserVariables>(GET_TIME_TRACKING_FOR_USER, {
		fetchPolicy: 'no-cache',
		variables: {
			user: userId,
			minDate: selectedDate,
			maxDate: selectedDate,
		},
		skip: selectedDate === '' || userId === '',
	});

	const timeTrackingData = React.useMemo(() => {
		return timeTrackingDataRaw?.timeTrackingsForUser.filter(t => t.timeTrackingStatus !== TimeTrackingStatus.REJECTED) ?? [];
	}, [timeTrackingDataRaw]);

	// Controls current timereg. date to query for
	React.useEffect(() => {
		if (typeof latestDay !== 'undefined' && latestDay.latestDayByUser !== null) {
			const latestTimereg = latestDay.latestDayByUser;

			if (latestTimereg?.trackingType !== TrackingType.TIMEREGISTRATION) {
				const latestClosedDay = latestTimereg?.date
					? DateTime.fromFormat(latestTimereg?.date, 'yyyy-MM-dd')
						.plus({ days: 1 })
						.toISODate()
					: formatDateForInput(new Date());
				setSelectedDate(latestClosedDay);
				setValue('date', latestClosedDay);
			} else {
				setSelectedDate(latestTimereg.date);
				setValue('date', latestTimereg.date);
			}
		} else {
			setSelectedDate(formatDateForInput(new Date()));
			//setValue('date', undefined);
		}
	}, [latestDay, setValue]);

	const { data: basisTimeData } = useOfflineHandlingQuery<UserBasisTime, UserBasisTimeVariables>(USER_BASIS_TIME, {
		fetchPolicy: 'cache-and-network',
		variables: {
			user: substitutionReg ? selectedUser : userId ?? '',
			date: selectedDate,
		},
		skip: (selectedDate === '' || substitutionReg) && selectedUser === '',
	});

	const timetrackingCaseErpNos =
		timeTrackingData
			.flatMap(t => t.case)
			.filter((tc): tc is GetTimeTrackingsForUser_timeTrackingsForUser_case => tc !== null)
			.map(tc => tc.erpNo) ?? [];

	const { data: caseData, loading: loadingCases } = useOfflineHandlingQuery<GetMobileV2CasesForTimeTracking, GetMobileV2CasesForTimeTrackingVariables>(GET_CASES_FOR_TIME_TRACKING, {
		fetchPolicy: 'cache-and-network',
		skip: loadingDrivingSlip || loadingTimeTracking || timetrackingCaseErpNos.length === 0,
		variables: {
			erpReferenceNos: timetrackingCaseErpNos,
		},
	});

	const { data: erpNoCases } = useOfflineHandlingQuery<GetMobileV2CasesByErpNo, GetMobileV2CasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: {
			erpReferenceNo: caseSearchText,
			closedCases: false,
			appliedClosed: false,
		},
		skip: caseSearchText.length < 3,
	});

	const searchedCases = React.useMemo(() => {
		return (
			erpNoCases?.cases
				.filter(c => c.timeRegistration)
				.map(
					(c): SelectOption => ({
						label: `${c.erpNo} - ${c.damage.contact.address.road} ${c.damage.contact.address.houseNumber}, ${c.damage.contact.address.postalCode} ${c.damage.contact.address.city} (${c.damage.category.name}/${c.damage.cause.name})`,
						value: c.id,
					}),
				) ?? []
		);
	}, [erpNoCases]);

	const { data: drivingSlipsData } = useOfflineHandlingQuery<GetMobileV2DrivingSlipsNoCase, GetMobileV2DrivingSlipsNoCaseVariables>(GET_DRIVINGSLIPS_NO_CASE, {
		variables: {
			case: erpNoCases?.cases.find(c => c.erpNo === selectedCase?.value)?.id,
		},
		skip: loadingDrivingSlip || jobTask !== 'DRIVINGSLIP' || typeof selectedCase === 'undefined' || typeof erpNoCases === 'undefined',
	});

	const { data: drivingSlipCategoriesData } = useOfflineHandlingQuery<GetDrivingSlipCategories>(GET_DRIVINGSLIP_CATEGORIES);

	const { data: paymentSupplementsData } = useOfflineHandlingQuery<GetPaymentSupplements>(GET_PAYMENT_SUPPLEMENTS);

	const paymentSupplements = React.useMemo(
		() =>
			paymentSupplementsData?.paymentSupplements.filter(ps => {
				switch (jobTask) {
					case 'INTERN':
						return ps?.templateJobNo === '+INTERN';
					case 'DRIVINGSLIP':
					case 'CASE':
						return ps?.templateJobNo === '+STANDARD';
					default:
						return ps?.templateJobNo;
				}
			}) ?? [],
		[jobTask, paymentSupplementsData?.paymentSupplements],
	);

	const paymentSupplementsSelect = React.useMemo(
		() =>
			paymentSupplementsData?.paymentSupplements
				.filter(s => s?.blocked === false)
				.filter(s => s?.code !== '2100')
				.filter(s => s?.code !== '2101')
				.filter(ps => {
					switch (jobTask) {
						case 'INTERN':
							return ps?.templateJobNo === '+INTERN';
						case 'DRIVINGSLIP':
						case 'CASE':
							return ps?.templateJobNo === '+STANDARD';
						default:
							return ps?.templateJobNo;
					}
				})
				.map<SelectOption>(s => ({
					label: `${s?.description} (${s?.code})`,
					value: s?.code ?? '',
				})) ?? [],
		[jobTask, paymentSupplementsData?.paymentSupplements],
	);

	const [deleteTimeTracking] = useOfflineHandlingMutation<DeleteTimeTracking, DeleteTimeTrackingVariables>(DELETE_TIME_TRACKING);
	const [createTimeTracking, { loading: createTimeTrackingLoading }] = useOfflineHandlingMutation<CreateTimeTracking, CreateTimeTrackingVariables>(CREATE_TIME_TRACKING);
	const [submitDay, { loading: submitLoading }] = useOfflineHandlingMutation<SubmitDay, SubmitDayVariables>(SUBMIT_DAY);

	const jobTaskNoWatcher = useWatch({ control, name: 'jobTaskNo' });

	React.useEffect(() => {
		if (typeof drivingSlipData !== 'undefined') {
			setSelectedCase({
				label: `${drivingSlipData.drivingSlip.case.erpNo} - ${drivingSlipData.drivingSlip.case.damage.contact.address.road} ${drivingSlipData.drivingSlip.case.damage.contact.address.houseNumber}, ${drivingSlipData.drivingSlip.case.damage.contact.address.postalCode} ${drivingSlipData.drivingSlip.case.damage.contact.address.city} (${drivingSlipData.drivingSlip.case.damage.category.name}/${drivingSlipData.drivingSlip.case.damage.cause.name})`,
				value: drivingSlipData.drivingSlip.case.erpNo,
			});
		}
	}, [drivingSlipData]);

	const drivingSlipJobTasks = React.useMemo(() => {
		const category =
			typeof drivingSlipData !== 'undefined' && drivingSlipData.drivingSlip.id === drivingSlipWatch
				? drivingSlipData.drivingSlip.category
				: drivingSlipsData?.drivingSlips.find(ds => ds.id === drivingSlipWatch)?.category;

		if (typeof category === 'undefined' || category === null || category?.length === 0) {
			return;
		}

		return drivingSlipCategoriesData?.drivingSlipCategories.find(dsc => dsc.code === category)?.erpReferenceTasks;
	}, [drivingSlipCategoriesData?.drivingSlipCategories, drivingSlipData, drivingSlipWatch, drivingSlipsData?.drivingSlips]);

	const jobTasks = React.useMemo(
		() =>
			jobTaskData?.jobTasks
				?.filter(jt => typeof drivingSlipJobTasks === 'undefined' || drivingSlipJobTasks.includes(jt?.jobTaskNo ?? '?'))
				.filter(jw => jw?.workTypeCode.length !== 0)
				.map<SelectOption>(j => ({
					label: j?.description ?? '',
					value: j?.jobTaskNo ?? '',
				})) ?? [],
		[drivingSlipJobTasks, jobTaskData?.jobTasks],
	);

	const drivingSlips = React.useMemo<SelectOption[]>(
		() =>
			[
				...(typeof drivingSlipData !== 'undefined'
					? [
						{
							label: dateToDateTimeString(new Date(drivingSlipData.drivingSlip.start)).replace(':', '.'),
							value: drivingSlipData.drivingSlip.id,
						},
					]
					: []),
				...(drivingSlipsData?.drivingSlips.map<SelectOption>(d => ({
					label: dateToDateTimeString(new Date(d.start)).replace(':', '.'),
					value: d.id,
				})) ?? []),
			].filter((ds, i, a) => a.findIndex(ads => ads.value === ds.value) === i),
		[drivingSlipData, drivingSlipsData],
	);

	React.useEffect(() => {
		if (jobTasks.length === 1) {
			setValue('jobTaskNo', jobTasks[0].value);
		}
	}, [jobTasks, setValue]);

	// Resets form unless timereg. is from drivingslip
	const resetForm = (): void => {
		setJobSearchText('');
		setIndicateOnCall(false);
		setValue('jobTaskNo', undefined);

		if (substitutionReg) {
			setValue('substitute', true, { shouldValidate: true });
			setValue('user', selectedUser, { shouldValidate: true });
		} else {
			setValue('substitute', false, { shouldValidate: true });
			setValue('user', '', { shouldValidate: true });
			setSelectedUser('');
			setSearchUserText('');
		}

		setShowSupplement(false);
		setSupplementLines([]);

		if (typeof drivingSlipData === 'undefined') {
			setSelectedCase(undefined);
			setCaseSearchText('');
			setJobTask('');
			setValue('case', undefined);
			setValue('drivingslip', undefined);
		}
		allowOvertime.current = false;
		reset();

		if (typeof drivingSlipData !== 'undefined' && typeof selectedCase !== 'undefined') {
			setValue('case', drivingSlipData.drivingSlip.case.id);
			setValue('jobNo', drivingSlipData.drivingSlip.case.erpNo);
		}
	};

	const userRegisteredHours = timeTrackingData.filter(tt => tt.date === selectedDate).reduce((acc, curr) => acc + (curr.hours ?? 0), 0);

	const beforeSubmit = (data: TimeTrackingInputType) => {
		// If registrations on selected date exceeds userBasisTime, show modal with warning
		const accHoursOnSelectedDate = (userRegisteredHours ?? 0) + data.hours;
		const basisTime = typeof basisTimeData?.userBasisTime !== 'undefined' ? basisTimeData?.userBasisTime : 0;

		if (accHoursOnSelectedDate > basisTime && !allowOvertime.current.valueOf()) {
			setShowOvertimeWarning(true);
		} else {
			setShowOvertimeWarning(false);
			onSubmit(data);
		}
	};

	const onSubmit = async (data: TimeTrackingInputType) => {
		const track = caseData?.cases.find(c => c.id === data.case)?.track ?? 0;
		const jobNo = drivingSlipData?.drivingSlip.case.erpNo ?? [...(caseData?.cases ?? []), ...(erpNoCases?.cases ?? [])].find(c => c.id === data.case)?.erpNo ?? 'INTERN';

		if (typeof data.drivingSlip === 'string' && data.drivingSlip.length === 0) {
			data.drivingSlip = null;
		}

		const addonLinesArray =
			data.addonLines?.map<TimeTrackingInputType>(l => ({
				user: substitutionReg ? data.user : userContext.user?.id ?? '',
				date: formatDateForInput(new Date(data.date)),
				case: data.case,
				jobNo: data.jobNo,
				jobTaskNo: data.jobTaskNo,
				workTypeCode: l.workTypeCode !== '' ? l.workTypeCode : data.workTypeCode,
				remark: l.paymentSupplementName,
				paymentSupplementCode: l.paymentSupplementCode,
				paymentSupplementName: l.paymentSupplementName,
				supplementUom: l.supplementUom,
				supplementType: l.supplementType ?? null,
				trackingType: TrackingType.TIMEREGISTRATION,
				onCall: data.onCall ?? false,
				hours: l.supplementType === SupplementType.PIECEWORK ? 1 : l.hours,
				pieceworkAmount: l.supplementType === SupplementType.PIECEWORK ? l.hours : 0,
			})) ?? [];

		// Check if overtime supplement should be added to registration
		const userBasisTime = basisTimeData?.userBasisTime ?? 0;

		const basisRegDiff = userBasisTime - userRegisteredHours;
		const regDiff = basisRegDiff - data.hours;

		if (jobTaskNoWatcher !== '1900' && userRegisteredHours + data.hours > userBasisTime) {
			const calculatedOvertime = basisRegDiff >= 0 ? Math.abs(regDiff) : Math.abs(basisRegDiff - regDiff);

			// Find overtime supplement
			const overtimeSupplement = paymentSupplements.find(ps => ps?.workTypeCode === '2100');

			// Push overtime supplement to registration
			addonLinesArray?.push({
				user: substitutionReg ? data.user : userContext.user?.id ?? '',
				date: formatDateForInput(new Date(data.date)),
				case: data.case,
				jobNo: data.jobNo,
				jobTaskNo: data.jobTaskNo,
				workTypeCode: overtimeSupplement?.workTypeCode,
				paymentSupplementCode: overtimeSupplement?.code,
				paymentSupplementName: overtimeSupplement?.description,
				supplementUom: overtimeSupplement?.uOM,
				supplementType: SupplementType.SURCHARGE,
				trackingType: TrackingType.TIMEREGISTRATION,
				onCall: data.onCall ?? false,
				hours: calculatedOvertime,
			});
		}

		try {
			await createTimeTracking({
				variables: {
					timeTracking: {
						user: substitutionReg ? data.user : userContext.user?.id ?? '',
						date: formatDateForInput(new Date(data.date)),
						case: data.case,
						jobNo: jobNo,
						trackingType: TrackingType.TIMEREGISTRATION,
						timeTrackingStatus: track === 1 ? TimeTrackingStatus.APPROVED : TimeTrackingStatus.AWAITING,
						drivingSlip: data.drivingSlip,
						hours: data.hours,
						workTypeCode: data.workTypeCode,
						jobTaskNo: data.jobTaskNo,
						onCall: data.onCall ?? false,
						jobTaskName: jobTasks.find(jt => jt.value === data.jobTaskNo)?.label ?? '',
						remark: data?.remark ?? '',
						supplementType: SupplementType.TIMEREGISTRATION,
						addonLines: addonLinesArray,
					},
				},
			});

			resetForm();
			refetchTimeTracking();
		} catch (e) {
			// console.log(e);
			// alert(t('error.timereg'));
		}
	};

	const closeDay = async () => {
		const date = getValues('date');

		try {
			await submitDay({
				variables: {
					timeTracking: {
						date: date.toString(),
						trackingType: TrackingType.DAYCLOSED,
						user: substitutionReg ? selectedUser : userContext.user?.id ?? '',
						addonLines: [],
						hours: 0,
					},
				},
			});

			setShowCloseDayModal(false);

			// Refetch closed day for sub, so new timereqs. is showing if selcting same sub
			setSelectedDate(DateTime.fromFormat(date.toString(), 'yyyy-MM-dd').plus({ days: 1 }).toISODate());
			setSubstitutionReg(false);
			resetForm();

			await refetchClosedDay();
			await refetchLatestDay();
			await refetchTimeTracking();
		} catch (e) {
			// console.log(e);
			// alert(t('error.timereg'));
		}

		setShowCloseDayModal(false);
	};

	// Clear fields on jobtask change
	React.useEffect(() => {
		if (task === 'INTERN') {
			setSelectedCase(fakeActivityCode.find(fac => fac.value === 'INTERN'));
			setValue('jobNo', 'INTERN');
			setValue('case', undefined);
			setValue('drivingSlip', undefined);
		}

		if (task === 'CASE') {
			setValue('jobNo', 'CASE');
			setValue('drivingSlip', undefined);
		}

		if (task === 'DRIVINGSLIP') {
			if (jobNoDefaultValue) {
				setValue('jobNo', 'CASE');
			}
		}
	}, [setValue, task]);

	React.useEffect(() => {
		setTimeRegistration(caseData?.cases.find(c => c.id === selectedCase?.value)?.timeRegistration ?? true);
	}, [selectedCase, caseData]);

	// Prev day not closed
	React.useEffect(() => {
		const latestClosedDay = closedDay?.closedDayByUser?.date;
		const latestTimeTracking = timeTrackingData.slice(-1)[0]?.date ?? new Date();
		const prevTrackingStillOpen = latestTimeTracking !== latestClosedDay;

		setPrevTrackingNotClosed(prevTrackingStillOpen && selectedDate > latestTimeTracking);
	}, [closedDay?.closedDayByUser?.date, selectedDate, timeTrackingData]);

	const [disableHours, setDisableHours] = React.useState(false);

	React.useEffect(() => {
		if (!lines || lines.some(l => l.supplementType !== undefined && (l.supplementType.toString() === '' || l.supplementType === SupplementType.SURCHARGE))) {
			setDisableHours(false);
		} else {
			setValue('hours', undefined, { shouldValidate: true });
			setDisableHours(true);
		}
	}, [lines, setValue]);

	// Reset jobTaskNo guides and indicate-on-call on case switch
	const caseWatch = watch('case');
	React.useEffect(() => {
		setJobSearchText('');
		setValue('jobTaskNo', '');
		setValue('workTypeCode', '');
		setIndicateOnCall(false);
	}, [caseWatch, setValue]);

	// Reset supplments on showSupplements change
	React.useEffect(() => {
		if (showSupplement) {
			return;
		}
		setSupplementLines([]);

		// Dirty solution for clearing addonLines because formstate is not set or updating when resetting without timeout
		setTimeout(() => {
			setValue('addonLines', undefined);
		}, 400);
	}, [showSupplement, setValue]);

	const accOvetimeHours = timeTrackingData.flatMap(a => a.addonLines).reduce((acc, curr) => acc + (curr?.workTypeCode === '2100' ? curr?.hours ?? 0 : 0), 0);

	return (
		<BasePage title={t('timeRegistration.overviewTitle')} shouldOfflineWarn>
			<div>
				<div className="text-blue mb-4">
					{loadingDrivingSlip && loadingCases && typeof drivingSlipData === 'undefined' && typeof caseData === 'undefined' ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<form ref={formRef} onSubmit={handleSubmit(beforeSubmit)}>
							<div className="flex flex-col">
								<div className="flex justify-between">
									<Checkbox
										id="substitute"
										name="substitute"
										title="timeRegistration.substitute"
										innerRef={register}
										checkedControlled={substitutionReg}
										onClick={() => setSubstitutionReg(!substitutionReg)}
									/>
									{userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER) && (
										<Button text={overruleDate ? 'timeRegistration.lockDate' : 'timeRegistration.unlockDate'} secondary onClick={() => setOverruleDate(!overruleDate)} />
									)}
								</div>

								<div
									className={classNames({
										hidden: !substitutionReg,
									})}
								>
									<SearchableSelect
										control={control}
										name="user"
										title="timeRegistration.substituteName"
										options={searchedUsers.filter(u => u.label.toLowerCase().includes(searchUserText.toLowerCase()))}
										searchFn={searchText => setSearchUserText(searchText)}
										onSelect={value => {
											setSelectedUser(value);
											setValue('user', value, {
												shouldValidate: true,
											});
										}}
										required
										onBlur={() => undefined}
										initialSelection={
											substitutionReg
												? {
													label: selectedUser.split('@')[0],
													value: selectedUser,
												}
												: undefined
										}
										minInputLength={-1}
										isLoading={loadingUsers}
										errorMessage={errors.user?.message}
										disabled={!substitutionReg}
									/>
								</div>

								{substitutionReg && (
									<p className="text-xs font-semibold">
										{t('common.selectedSubstituteNo')}: {users?.searchUsers.filter(u => u.id === selectedUser)[0]?.employeeId ?? ''}
									</p>
								)}

								<Datepicker
									name="date"
									title="common.date"
									defaultValue={selectedDate}
									min={
										overruleDate
											? undefined
											: DateTime.fromFormat(closedDay?.closedDayByUser?.date ?? '', 'yyyy-MM-dd')
												.plus({ days: 1 })
												.toISODate()
									}
									max={
										overruleDate
											? undefined
											: DateTime.fromFormat(closedDay?.closedDayByUser?.date ?? '', 'yyyy-MM-dd')
												.plus({ days: 1 })
												.toISODate()
									}
									// TODO: CR active
									// max={substitutionReg && !prevTrackingNotClosed
									//     ? undefined
									//     : DateTime.fromFormat(closedDay?.closedDayByUser?.date ?? '', 'yyyy-MM-dd')
									//         .plus({ days: 1 })
									//         .toISODate()
									// }
									onChange={e => setSelectedDate(e.target.value)}
									innerRef={register}
									disabled={!timeRegistration}
									errorMessage={errors.date?.message}
								/>

								<p className="text-xs font-semibold">
									{t('common.selectedDate')}:{' '}
									{DateTime.fromFormat(selectedDate, 'yyyy-MM-dd').toLocaleString({
										weekday: 'long',
										month: 'long',
										day: '2-digit',
										year: 'numeric',
									})}
								</p>

								{prevTrackingNotClosed && <p className="text-red font-medium">{t('timeRegistration.previousDayNotClosed')}</p>}

								<div>
									{typeof id !== 'string' ? (
										<Dropdown
											className={classNames({
												hidden: typeof id === 'string',
											})}
											name="task"
											title="timeRegistration.task"
											data={[{ label: '', value: '' }, ...fakeActivityCode]} // Change once BC has data
											innerRef={register}
											required
											onChange={e => {
												setJobTask(e.target.value);

												if (e.target.value !== 'INTERN') {
													setSelectedCase(undefined);
												}
											}}
											defaultValue={jobTask}
											errorMessage={errors.task?.message}
										/>
									) : (
										<>
											<FormFieldHeader htmlFor="task" required title={t('timeRegistration.task')} />
											<Input name="task" defaultValue={fakeActivityCode.find(fa => fa.value === jobTask)?.label} readOnly />
										</>
									)}
								</div>

								{jobTask !== 'INTERN' && jobTask !== '' ? ( // Check if it's case otherwise select intern time code
									<div>
										{typeof id !== 'string' ? (
											<SearchableSelect
												control={control}
												name="case"
												title="common.case"
												options={searchedCases.filter(c => c.label.toLowerCase().includes(caseSearchText.toLowerCase()))}
												searchFn={searchText => setCaseSearchText(searchText)}
												onSelect={value => {
													setValue('case', value, {
														shouldValidate: true,
													});
													setSelectedCase({
														label: '',
														value: erpNoCases?.cases.find(c => c.id === value)?.erpNo ?? '',
													});
													setValue('jobNo', [...(caseData?.cases ?? []), ...(erpNoCases?.cases ?? [])].find(c => c.id === value)?.erpNo ?? undefined);
												}}
												required
												onBlur={() => undefined}
												minInputLength={2}
												isLoading={loadingCases}
												initialSelection={selectedCase}
												className={classNames('w-full lg:w-full', {
													'border-red border-2': !timeRegistration,
												})}
												disabled={jobTask === '' || prevTrackingNotClosed}
												errorMessage={errors.case?.message}
												readOnly={typeof id === 'string'}
											/>
										) : (
											<Input title="common.case" name="" defaultValue={selectedCase?.label} readOnly />
										)}
										<Input
											name="jobNo"
											innerRef={register}
											className="hidden"
											defaultValue={typeof drivingSlipData !== 'undefined' ? drivingSlipData.drivingSlip.case.erpNo : ''}
										/>
									</div>
								) : (
									<div className="hidden">
										<Input
											name="jobNo"
											title="timeRegistration.internTask"
											innerRef={register}
											defaultValue="INTERN" // This will change depending on what we figure out with BC
										/>
									</div>
								)}

								{selectedCase && !timeRegistration && (
									<div>
										<p className="text-red font-medium">{t('timeRegistration.stoppedOnCase')}</p>
									</div>
								)}

								<div
									className={classNames('w-full', {
										hidden: jobTask !== 'DRIVINGSLIP',
									})}
								>
									{typeof id !== 'string' ? (
										<Dropdown
											name="drivingSlip"
											title="common.drivingSlip"
											data={[{ label: '', value: '' }, ...drivingSlips]}
											innerRef={register}
											defaultValue={id}
											onChange={e => setSelectedDrivingSlip(drivingSlipsData?.drivingSlips.find(ds => ds.id === e.target.value))}
											disabled={typeof selectedCase === 'undefined' || !timeRegistration || prevTrackingNotClosed}
											errorMessage={errors.drivingSlip?.message}
										/>
									) : (
										<>
											<FormFieldHeader htmlFor="drivingSlip" required title={t('common.drivingSlip')} />
											<Input name="drivingSlip" className="text-sm" defaultValue={drivingSlips.find(s => s.value === id)?.label} readOnly />
										</>
									)}
								</div>

								<Dropdown
									name="jobTaskNo"
									title="offer.jobTask"
									data={[
										{ value: '', label: '' },
										...jobTasks.filter(c => c.label.toLowerCase().includes(jobSearchText.toLowerCase())).sort((a, b) => a.label.localeCompare(b.label)),
									]}
									innerRef={register}
									required
									onChange={e => {
										setValue('jobTaskNo', e.target.value, {
											shouldValidate: true,
										});
										setValue('workTypeCode', jobTaskData?.jobTasks?.find(t => t?.jobTaskNo === e.target.value)?.workTypeCode ?? '');
									}}
									loading={jobTaskLoading}
									errorMessage={errors.jobTaskNo?.message}
								/>

								{false &&
									jobTaskNoWatcher === '0601' && ( // "Vagt og udkald timer"-task
										<>
											<Checkbox
												name="onCall"
												title="timeRegistration.indicateOnCall"
												onChange={() => setIndicateOnCall(!indicateOnCall)}
												checkedControlled={indicateOnCall}
												className="mt-2"
												innerRef={register}
											/>

											<p>{t('timeRegistration.onCallDescription')}</p>

											{/* {indicateOnCall && <p>{t('timeRegistration.onCallDescription')}</p>} */}
										</>
									)}

								{jobTaskNoWatcher === '1900' && ( // "Akkord"-task
									<p className="mt-2">{t('timeRegistration.pieceWorkDescription')}</p>
								)}

								<Input name="workTypeCode" innerRef={register} className="hidden" />

								<Textarea
									name="remark"
									title="common.description"
									rows={2}
									maxLength={100}
									innerRef={register}
									disabled={!timeRegistration || prevTrackingNotClosed}
									errorMessage={errors.remark?.message}
								/>

								<Input
									name="hours"
									title="common.hours"
									type="number"
									step={0.01}
									lang="da"
									inputMode="decimal"
									innerRef={register({ valueAsNumber: true })}
									onChange={e => {
										if (jobTask !== 'DRIVINGSLIP' || isNaN(e.target.valueAsNumber)) {
											return;
										}

										setOverEstimate(e.target.valueAsNumber > Interval.fromISO(`${selectedDrivingSlip?.start}/${selectedDrivingSlip?.end}`).length('hours'));
									}}
									required={!disableHours}
									readOnly={!timeRegistration || prevTrackingNotClosed || disableHours}
									errorMessage={errors.hours?.message}
								/>
								{overEstimate && <p className="text-orange text-sm font-semibold">{t('timeRegistration.exceedesEstimate')}</p>}

								{control.formState.dirtyFields.hours && (
									<p className="mt-2 font-bold">
										<FontAwesomeIcon icon={faExclamationCircle} /> {t('timeRegistration.rememberSupplement')}
									</p>
								)}

								<Checkbox
									name="addSupplement"
									title="timeRegistration.addSupplement"
									innerRef={register}
									onChange={() => setShowSupplement(!showSupplement)}
									checkedControlled={showSupplement}
									className="mt-2"
								/>
							</div>

							{showSupplement && (
								<div>
									<div className="border-b-1 mt-3 font-semibold">{t('timeRegistration.supplementType')}</div>
									{supplementLines.map((id, i) => (
										<SupplementLine
											key={id}
											lineIndex={i}
											paymentSupplements={paymentSupplements}
											paymentSupplementsSelect={paymentSupplementsSelect}
											removeLine={() => setSupplementLines(supplementLines.filter(l => l !== id))}
											subForm={formObj}
											timeRegistration
										/>
									))}

									<TextButton icon={faPlus} text="common.addLine" onClick={() => setSupplementLines(currentLines => [...currentLines, new Date().toString()])} className="mt-3" />
								</div>
							)}

							<div className="flex justify-between">
								<Button
									submit
									primary
									text="common.register"
									className="mt-3"
									disabled={!timeRegistration || prevTrackingNotClosed || !formObj.formState.isValid}
									loading={createTimeTrackingLoading}
								/>
								<Button primary text="timeRegistration.closeDay" onClick={() => setShowCloseDayModal(true)} disabled={prevTrackingNotClosed} className="mt-3" loading={submitLoading} />
							</div>
						</form>
					)}
				</div>

				<div>
					<div>
						<Table
							data={timeTrackingData ?? []}
							columns={[
								{
									label: 'common.date',
									numeric: true,
									selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
									sortFn: (a, b) => a.date.localeCompare(b.date),
								},
								{
									label: 'common.case',
									selectFn: t => (
										<div>
											<p>{t.case ? `${t.case.erpNo}` : ''}</p>
											<p>
												{t.case
													? `${t.case.damage.contact.address.road} ${t.case.damage.contact.address.houseNumber}, ${t.case.damage.contact.address.postalCode} ${t.case.damage.contact.address.city}`
													: ''}
											</p>
											<p>{t.case ? `${t.case.damage.category.name}/${t.case.damage.cause.name}` : ''}</p>
										</div>
									),
									sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
								},
								{
									label: 'offer.jobTask',
									selectFn: t => <p>{t.jobTaskName}</p>,
									sortFn: (a, b) => (a.jobTaskName ?? '').localeCompare(b.jobTaskName ?? ''),
								},
								{
									label: 'timeRegistration.onCall',
									hideColumn: true,
									selectFn: tr => <p>{t(tr.onCall ? 'common.yes' : 'common.no')}</p>,
								},
								{
									label: 'common.comment',
									noTruncate: true,
									classNameTh: 'w-1/2',
									selectFn: t => <p>{t.remark}</p>,
								},
								{
									label: 'common.hours',
									selectFn: t => <p>{t.hours}</p>,
									sortFn: (a, b) => (a.hours.toString() ?? '').localeCompare(b.hours.toString() ?? ''),
									numeric: true,
								},
								{
									label: 'common.debitor',
									selectFn: t => <p>{t.case?.debitor.company}</p>,
									sortFn: (a, b) => (a.case?.debitor.company ?? '').localeCompare(b.case?.debitor.company ?? ''),
								},
								{
									label: '',
									selectFn: t => (
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											className="text-red cursor-pointer"
											onClick={async () => {
												await deleteTimeTracking({
													variables: { id: t.id },
												});
												refetchTimeTracking();
											}}
										/>
									),
								},
							]}
							sublineData={timeTrackingData.map(s => {
								return s.addonLines ?? [];
							})}
							subColumns={[
								{
									label: 'common.date',
									selectFn: s => '-',
								},
								{
									label: 'common.case',
									selectFn: s => <p>{`${t('timeRegistration.supplement')}: ${s?.paymentSupplementName} (${s?.paymentSupplementCode})`}</p>,
								},
								{
									label: 'offer.jobTask',
									selectFn: s => '-',
								},
								{
									label: 'timeRegistration.onCall',
									hideColumn: true,
									selectFn: s => <p>{t(s?.onCall ? 'common.yes' : '-')}</p>,
								},
								{
									label: 'common.comment',
									selectFn: s => '-',
								},
								{
									label: 'common.hours',
									selectFn: s => (
										<p>
											{`${s?.supplementType === SupplementType.PIECEWORK ? s?.pieceworkAmount : s?.hours}

                                            ${paymentSupplements.find(ps => ps?.code === s?.paymentSupplementCode)?.uOM ?? ''}`}
										</p>
									),
								},
								{
									label: 'common.debitor',
									selectFn: s => '-',
								},
								{
									label: '',
									selectFn: s => '-',
								},
							]}
							keySelector={t => t.id}
							noDataFoundText="timeRegistration.noTimeRegFound"
							loading={loadingTimeTracking || createTimeTrackingLoading}
						/>
					</div>

					<div className="border-t-1 flex justify-end pt-2">
						<span className="mr-1 font-semibold">{`${t('timeRegistration.totalHours')}:`}</span>
						{timeTrackingData.reduce((acc, curr) => acc + (curr.hours ?? 0), 0)}
						{accOvetimeHours > 0 && (
							<span className="ml-1">
								{accOvetimeHours === 1 ? (
									<Trans
										t={t}
										i18nKey="timeRegistration.overtimeHours_one"
										count={accOvetimeHours}
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								) : (
									<Trans
										t={t}
										i18nKey="timeRegistration.overtimeHours_many"
										count={accOvetimeHours}
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								)}
							</span>
						)}
					</div>
				</div>
			</div>

			<IonModal isOpen={showOvertimeWarning}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{t('timeRegistration.registrationExceedsBasisTime')}</IonTitle>
						<IonButtons slot="end">
							<Button text={t('common.close')} onClick={() => setShowOvertimeWarning(false)} />
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<p>
						{t('timeRegistration.registratingWillExceedBasisTime')} <strong className="mr-1">{basisTimeData?.userBasisTime ?? 0}</strong>{' '}
						<span className="mr-1 lowercase">{t('timeRegistration.totalHours')}.</span>
						<Trans t={t} i18nKey="timeRegistration.overtimeSuppWillBeAdded" components={[<strong />]} />
					</p>

					<p className="italic">{t('timeRegistration.checkTimeDraft')}</p>

					<div className="mt-2 flex justify-between">
						<Button
							secondary
							text="common.cancel"
							onClick={() => {
								setShowOvertimeWarning(false);
								allowOvertime.current = false;
							}}
						/>
						<Button
							primary
							text="common.register"
							onClick={() => {
								allowOvertime.current = true;
								remoteSubmit();
							}}
							loading={createTimeTrackingLoading}
						/>
					</div>
				</IonContent>
			</IonModal>

			<IonModal isOpen={showCloseDayModal}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{t('timeRegistration.closeDay')}</IonTitle>
						<IonButtons slot="end">
							<Button onClick={() => setShowCloseDayModal(false)}>{t('common.close')}</Button>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<div>
						{(userRegisteredHours ?? 0) < (basisTimeData?.userBasisTime ?? 0) ? (
							<div>
								<p>
									<Trans
										t={t}
										i18nKey="timeRegistration.hoursRegisteredOnDay"
										values={{
											hours: userRegisteredHours,
										}}
										components={[<strong />]}
									/>
								</p>

								<p>
									<Trans
										t={t}
										i18nKey="timeRegistration.notEnoughHoursRegistered"
										values={{
											hours: basisTimeData?.userBasisTime ?? 0,
										}}
										components={[<strong />]}
									/>
								</p>
								<p className="mb-4 italic">{t('timeRegistration.useFlexHours')}</p>
							</div>
						) : (
							<div>
								<Trans
									t={t}
									i18nKey="timeRegistration.wantToCloseDay"
									values={{
										date: DateTime.fromISO(selectedDate).toFormat('dd/MM/yyyy'),
									}}
								/>
							</div>
						)}

						{accOvetimeHours > 0 && (
							<>
								{accOvetimeHours === 1 ? (
									<Trans
										t={t}
										i18nKey="timeRegistration.totalOvertime_one"
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								) : (
									<Trans
										t={t}
										i18nKey="timeRegistration.totalOvertime_many"
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								)}
							</>
						)}

						<div className="mt-2 flex justify-between">
							<Button secondary text="common.cancel" onClick={() => setShowCloseDayModal(false)} />
							<Button
								primary
								text="timeRegistration.closeDay"
								onClick={() => closeDay()}
								disabled={(userRegisteredHours ?? 0) < (basisTimeData?.userBasisTime ?? 0)}
								loading={submitLoading}
							/>
						</div>
					</div>
				</IonContent>
			</IonModal>
			{RejectedTimeRegistrationWarningFlag && (
				<RejectedTimeRegistrationWarningModal
					visible={showRejectedRegistrationWarningModal}
					close={() => {
						setShowRejectedregistrationWarningModal(false);
					}}
				/>
			)}
		</BasePage>
	);
};

export default TimeRegistrationOverview;
