import { QueueEntry } from 'Helpers';
import { ApiMutation, QueuedMutationEvent } from './types';
import { UseOfflineMutationReturn } from 'Hooks/Apollo/Mutations/types';
import { OperationVariables } from '@apollo/client';

export const toQueuedMutationEvent = (queueEntry: QueueEntry<ApiMutation>, queueEntryPosition: number, error?: any): QueuedMutationEvent => {
	return {
		id: queueEntry.id,
		position: queueEntryPosition,
		date: new Date(),
		error: error,
		data: queueEntry.data,
		metadata: queueEntry.metadata,
	};
};

export const createEmptyMutation = <TMutation, TVariables extends OperationVariables>(): UseOfflineMutationReturn<TMutation, TVariables> => [async () => undefined, { loading: false }];

export const findPreviousEntryPredicateById = (id: string) => (entry: QueueEntry<ApiMutation>) => {
	const variables = (entry.data.variables ?? {}) as Record<string, any>;
	return variables.id === id;
};
