import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormErrorText from './FormErrorText';
import FormHelpText from './FormHelpText';
import FormFieldHeader from './FormFieldHeader';
import { SelectOption } from '../Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

interface Props {
	className?: string;
	title?: string;
	data?: SelectOption[];
	name: string;
	innerRef?: React.Ref<HTMLSelectElement>;
	errorMessage?: string;
	required?: boolean;
	helpText?: string;
	showHelpText?: boolean;
	disabled?: boolean; // IMPORTANT: Note that setting this to true will cause the value to be `undefined` in React Hook Form
	readonly?: boolean;
	emptyDefault?: boolean;
	disableSortLabelAlphabetically?: boolean;
	loading?: boolean;
}

const Dropdown: React.FC<Props & React.SelectHTMLAttributes<HTMLSelectElement>> = ({
	className,
	title,
	data,
	innerRef,
	errorMessage,
	required = false,
	name,
	helpText,
	showHelpText,
	disabled = false,
	readonly = false,
	disableSortLabelAlphabetically = false,
	loading = false,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	const selectOptions = useMemo(() => {
		let options = data ?? [];

		if (!disableSortLabelAlphabetically) {
			options = options.sort((a, b) => a.label.localeCompare(b.label));
		}

		return options;
	}, [data, disableSortLabelAlphabetically]);

	return (
		<>
			{title && <FormFieldHeader htmlFor={name} required={required} title={title} />}
			{showHelpText && helpText && <FormHelpText text={helpText} />}

			{loading ? (
				<FontAwesomeIcon icon={faSpinner} className="animate-spin align-middle" />
			) : (
				<>
					<select
						{...restProps}
						name={name}
						id={name}
						ref={innerRef}
						disabled={disabled || readonly}
						className={classNames('border-1 rounded-default w-full border-gray-600 p-1 text-sm focus:outline-none', className, {
							'border-red border-2': errorMessage,
							'cursor-not-allowed bg-gray-300 opacity-100': disabled || readonly,
						})}
					>
						{selectOptions.map(({ value, label }, i) => (
							<option key={value + i} value={value} className="hover:bg-blue text-sm ring-0 hover:text-white">
								{t(label)}
							</option>
						))}
					</select>
					{errorMessage && <FormErrorText text={t(errorMessage)} />}
				</>
			)}
		</>
	);
};
export default Dropdown;
