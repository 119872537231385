export enum FeatureFlagEnums {
	UNLOCK_SCREENING = 'UnlockScreening',
	GDPR_AGREEMENT = 'GDPRAgreement',
	IS_MOVABLE_NEW_FILTERS = 'IsMovableNewFilters',
	EXPORT_LIST_TO_EXCEL = 'ExportListToExcel',
	ZIPPED_CASE_IMAGES = 'ZippedCaseImages',
	SINGLE_CASE_HREF_IMAGE_DOWNLOAD = 'singleCaseHrefImageDownload',
	REJECTED_TIME_REGISTRATION_WARNING = 'RejectedTimeRegistrationWarning',
	NEGATIVE_INVOICE_INPUT_FIX = 'NegativeInvoiceInputFix',
	AUTO_RESIZE_TEXT_AREA = 'AutoResizeTextArea',
	CONSUMPTION_LIMIT = 'ConsumptionLimit',
	NUMBER_INPUT_NEGATIVE = 'NumberInputNegative',
	NEW_MACHINE_STATUS = 'NewMachineStatus',
	IMAGE_CATEGORIZATION_UI = 'imageCategorizationUI',
	GET_ADDRESS_FROM_DEBITOR = 'GetAddressFromDebitor',
	SET_POLICY_HOLDER_AS_DAMAGE_CONTACT = 'SetPolicyHolderAsDamageContact',
	REJECTED_HOURS_USER_SELECT = 'rejectedHoursUserSelect',
	LOCK_CASE_HOURLY_RATE = 'LockCaseHourlyRate',
	CLOSE_DRIVING_SLIPS = 'CloseDrivingSlips',
	MACHINE_UNAVAILABLE_REASON = 'MachineUnavailableReason',
	INSPECTION_MOVABLES_REPORT = 'InspectionMovablesReport',
	MANAGER_FAVORITES = 'ManagerFavoritesFilter',
	SAVE_BUTTON_REPORTS = 'SaveButtonReports',
	TIMEREG2 = 'Timereg2',
	PRIORITYCUSTOMER = 'PriorityCustomer',
	ACTIVE_PURCHASE_DOCUMENTS = 'ActivePurchaseDocuments',
	CASE_SEARCH_FIELD = 'CaseSearchField',
	AI_INVOICE_PREDICTIONS_FINAL = 'AIInvoicePredictionsFinal',
	AI_INVOICE_PREDICTIONS_SCREENING = 'AIInvoicePredictionsScreening',
	MANAGER_REJECTED_HOURS_VIEW = 'ManagerRejectedHoursView',
	DELETE_INVOICED_TIMEREGS = 'DeleteInvoicedTimeRegs',
	REOPEN_DAY_ENTRY = 'ReopenDayEntry',
	ALL_REGS_OVERVEW_SEARCH_WORKER = 'allRegsOverviewSearchWorker',
	SHOW_ONCALL_HOURS = 'showOnCallHours',
	PIECEWORK_VALIDATION = 'pieceworkValidation',
	ALLOW_EDIT_OF_TIMEREG = 'allowEditOfTimereg',
	FILTER_OUT_DISCONTINUED_MACHINES = 'filterOutDiscontinuedMachines',
	CHECK_BC_VALUE_IN_SLUTFAKTURA = 'checkBcValueInSlutFaktura',
	OFFER_MODULE = 'offerModule',
	MANAGER_WORKER_FLEX_VIEW = 'managerWorkerFlexView',
	IGVA2024 = 'IGVA2024',

	NEW_PLANNER_ACCESS = 'NewPlannerAccess',
	NEW_PLANNER_MESSAGE = 'NewPlannerMessage',
	NEW_PLANNER_VEHICLES = 'NewPlannerVehicles',
	NEW_PLANNER_ADD_DRIVER_CAR_TO_DRIVINGSLIP = 'NewPlannerAddDriverCarToDrivingSlip',
	NEW_PLANNER_IMMEDIATELY_PLAN_ON_EDIT = 'NewPlannerimmediatelyPlanOnEdit',
	NEW_PLANNER_KILL_OLD_PLANNER = 'NewPlannerKillOldPlanner',
	NEW_PLANNER_HALF_DAY = 'NewPlannerHalfDay',
	// Admin
	DELETE_INVOICE_ADMIN = 'DeleteInvoiceAdmin',
	ADMIN_SET_MIN_CASE_HOURLY_RATE = 'AdminSetMinCaseHourlyRate',
	EXTERNAL_USERS_VIEW = 'ExternalUsersView',
	ADD_REMOVE_ALL_CATALOG_CUSTOMERS = 'AddRemoveAllCatalogCustomers',
	CATALOG_CUSTUMER_ALL_DELETE = 'CatalogCustumerAllDelete',
	// Mobile
	GET_ALL_DS_ITEMS = 'GetAllDSItems',
	MOBILE_IMAGE_COMMENT = 'MobileImageComment',
	SCANNER_LIGHT = 'scannerLight',
	TIMEREG_JOB_NO_DEFAULT_CASE = 'TimeregJobNoDefaultCASE',
	CHANGE_MOBILE_TIMEREG_USER_CONTEXT = 'changeMobileTimeregUserContext',
	MOBILE_V2_SHOULD_COMPRESS = 'MobileV2ShouldCompress',
	MOBILE_FACADE_IMAGE_SELECT = 'MobileFacadeImageSelect',
	NEW_PLANNER_MACHINE_LOCATION_SCAN = 'NewPlannerMachineLocationScan',
	FILE_UPLOAD_MOBILE_V2 = 'fileUploadMobileV2',
	// ALSO IN BACKEND
	MOBILE_TRACK_LOCATION_DATA = 'MobileTrackLocationData',
	// ALSO IN BACKEND
	VEHICLES_MOBILE_APP = 'VehiclesMobileApp',
	QUEUE_DRIVING_SLIP_FILE_UPLOAD = 'QueueDrivingSlipFileUpload',
	FORCE_QUEUE_MOBILE_APP = 'ForceQueueMobileApp',
	NO_POP_QUEUE = 'NoPopQueue',
}

