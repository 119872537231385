import React from 'react';
import Button from '@ssg/common/Components/Button';
import { useTranslation } from 'react-i18next';
import CloseableModal from 'Components/CloseableModal';
import { BaseMachineFragment } from 'GQL';

export interface Props {
	machine: BaseMachineFragment;
	onDismiss(): void;
	onDecline(): void;
	onAccept(): void;
}

const EducationRequirementsModal: React.FC<Props> = ({ machine, onDismiss, onDecline, onAccept }) => {
	const { t } = useTranslation();

	return (
		<CloseableModal title={t('machines.eduReqTitle')} onDismiss={onDismiss}>
			<div className="text-blue-calm">
				<div className="text-lg">{t('machines.eduReqText1')}</div>
				<div>{`${t('machines.eduReqText2')} ${machine.name}:`}</div>
				<div className="my-2 ml-3">
					<ol>
						{machine.educationPrerequisit &&
							machine.educationPrerequisit.map((edu, i) => (
								<li key={i} className="font-bold">
									{edu}
								</li>
							))}
					</ol>
				</div>
				<div className="mt-4 flex justify-between">
					<Button text={t('common.accept')} onClick={onAccept} success fullWidth className="mr-2" />
					<Button text={t('common.decline')} onClick={() => onDecline()} fullWidth danger className="ml-2" />
				</div>
			</div>
		</CloseableModal>
	);
};

export default EducationRequirementsModal;
