import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { setupIonicReact } from '@ionic/react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApiQueryManager } from './Store';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Routes from './Routes';
import Main from './Components/Layout/Main';
import Login from './Pages/Login';
import DrivingSlipsOverview from './Pages/DrivingSlips/DrivingSlipsOverview';
import DrivingSlipTabs from './Pages/DrivingSlips/DrivingSlip/DrivingSlipTabs';
import RequisitionsOverview from './Pages/Requisitions/RequisitionsOverview';
import TimeRegistrationOverview from './Pages/TimeRegistration/TimeRegistrationOverview';
import MachinesOverview from './Pages/Machines/MachinesOverview';
import TimeRegistration from 'Pages/TimeRegistration/TimeRegistration';

/* Polyfills */
import 'core-js/actual/string/replace-all'; // For devices running Chrome version < 84

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

const App: React.FC<{ telemetryPlugin: ReactPlugin }> = ({ telemetryPlugin }): React.ReactElement => {
	setupIonicReact();

	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);

	const { isAuthenticated } = useMicrosoftAuth();
	if (!isAuthenticated) {
		return (
			<IonApp>
				<Login />
			</IonApp>
		);
	}

	return (
		<AppInsightsErrorBoundary onError={() => <h1>ERROR!</h1>} appInsights={telemetryPlugin}>
			<DndProvider backend={HTML5Backend}>
				<IonApp>
					<IonReactRouter>
						<Main appInsights={telemetryPlugin}>
							<ApiQueryManager />
							<IonRouterOutlet id="main">
								{/* Driving Slips pages */}
								<Route path={Routes.DrivingSlipsOverview} exact component={DrivingSlipsOverview} />
								<Route path={Routes.DrivingSlipTabs} component={DrivingSlipTabs} />

								{/* Time registration pages */}
								{newTimeregistration ? (
									<Route path={Routes.TimeRegistrationOverview} exact component={TimeRegistration} />
								) : (
									<Route path={Routes.TimeRegistrationOverview} exact component={TimeRegistrationOverview} />
								)}

								{/* Requisitions pages */}
								<Route path={Routes.RequisitionsOverview} exact component={RequisitionsOverview} />

								{/* Machines pages */}
								<Route path={Routes.MachinesOverview} exact component={MachinesOverview} />

								{/* Default page */}
								<Redirect exact from={Routes.Default} to={Routes.DrivingSlipsOverview} />
							</IonRouterOutlet>
						</Main>
					</IonReactRouter>
				</IonApp>
			</DndProvider>
		</AppInsightsErrorBoundary>
	);
};

export default App;
