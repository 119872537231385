import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import { useFetchMoreOverride } from './useFetchMoreOverride';
import { useRefetchOverride } from './useRefetchOverride';
import { useAppSelector } from 'Store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useOfflineHandlingQuery<TQuery, TVariables extends OperationVariables = OperationVariables>(
	documentNode: DocumentNode | TypedDocumentNode<TQuery, TVariables>,
	options?: QueryHookOptions<TQuery, TVariables>,
	showOfflineWarningMessage = true,
	finder?: string,
) {
	const connected = useAppSelector(state => state.localData.networkConnectionStatus);
	const fetchPolicy: WatchQueryFetchPolicy = connected ? 'cache-and-network' : 'cache-only';
	const nextFetchPolicy: WatchQueryFetchPolicy = connected ? 'cache-and-network' : 'cache-only';

	// const theOptions: QueryHookOptions<TQuery, TVariables> = options ?? {
	// 	fetchPolicy,
	// 	nextFetchPolicy,
	// };

	const theOptions: QueryHookOptions<TQuery, TVariables> = {
		context: options?.context,
		fetchPolicy: options?.fetchPolicy ?? fetchPolicy,
		nextFetchPolicy: options?.nextFetchPolicy ?? nextFetchPolicy,
		variables: options?.variables,
		skip: options?.skip,
		onCompleted: options?.onCompleted,
	};

	const query = useQuery<TQuery, TVariables>(documentNode, theOptions);

	const fetchMoreOverride = useFetchMoreOverride<TQuery, TVariables>({
		showOfflineWarningMessage,
		fetchMore: query.fetchMore,
	});

	const refetchOverride = useRefetchOverride({
		showOfflineWarningMessage,
		refetch: query.refetch,
	});

	return { ...query, fetchMore: fetchMoreOverride, refetch: refetchOverride };
}

