import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FormErrorText from './FormErrorText';
import FormHelpText from './FormHelpText';
import FormFieldHeader from './FormFieldHeader';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
	className?: string;
	innerRef?: React.LegacyRef<HTMLInputElement>;
	errorMessage?: string;
	title: string;
	xsmallTitle?: boolean;
	titleClass?: string;
	required?: boolean;
	time?: boolean;
	defaultValue?: string;
	helpText?: string;
	showHelpText?: boolean;
	name: string;
	disabled?: boolean;
	readOnly?: boolean;
}

const Datepicker: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	innerRef,
	errorMessage,
	title,
	xsmallTitle,
	titleClass,
	required = false,
	time = false,
	defaultValue,
	helpText,
	showHelpText,
	name,
	disabled = false,
	readOnly = false,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();
	return (
		<>
			{title.length > 0 && <FormFieldHeader htmlFor={name} required={required} title={title} xsmallTitle={xsmallTitle} titleClass={titleClass} />}
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<input
				type={time ? 'datetime-local' : 'date'}
				ref={innerRef}
				name={name}
				id={name}
				defaultValue={defaultValue}
				disabled={disabled}
				readOnly={readOnly}
				className={classNames('border-1 rounded-default block w-full border-gray-600 p-1 pr-3 text-sm focus:outline-none', className, {
					'border-red border-2': errorMessage,
					'cursor-not-allowed bg-gray-200': disabled || readOnly,
				})}
				{...restProps}
			/>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};
export default Datepicker;
