import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetDrivingSlipFiles_drivingSlipFiles, GetMobileV2DrivingSlip_drivingSlip_case } from 'GQL';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { updateReportsCache, updateReportsCacheNew } from './updateReportsCache';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import useDrivingSlipImages, { DrivingSlipFile } from './useDrivingSlipImages';
import Button from 'Components/Button';
import BasePage from 'Components/Layout/BasePage';
import DrivingSlipMultiImageSelect from './Inputs/DrivingSlipMultiImageSelect';
import DrivingSlipSingleImageSelect from './Inputs/DrivingSlipSingleImageSelect';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import { ApiMutationKey, useCreateInspectionReport } from 'Store';
import dateToReportNameString from 'Helpers/dateHelper';
import { isConnected } from 'NetworkContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	drivingSlipId: string;
	drivingSlipCase: GetMobileV2DrivingSlip_drivingSlip_case;
}

const InspectionReport: React.FC<Props> = ({ drivingSlipId, drivingSlipCase }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);

	const { images } = useDrivingSlipImages(drivingSlipCase.erpNo, drivingSlipId);
	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${drivingSlipCase.id}-inspection-${name}`, initialValue);

	// Form values
	const [backgroundStory, setBackgroundStory] = useLocalStorage('backgroundStory', '');
	const [observations, setObservations] = useLocalStorage('observations', '');
	const [assessmentAndConclusion, setAssessmentAndConclusion] = useLocalStorage('assessmentAndConclusion', '');
	const [suggestedActionPlan, setSuggestedActionPlan] = useLocalStorage('suggestedActionPlan', '');
	const [comments, setComments] = useLocalStorage('comments', '');
	const [facadeImage, setFacadeImage] = useLocalStorage<DrivingSlipFile | null>('facadeImage', null);
	const [floorplanImage, setFloorplanImage] = useLocalStorage<DrivingSlipFile | null>('floorplanImage', null);
	const [otherImages, setOtherImages] = useLocalStorage<DrivingSlipFile[]>('otherImages', []);
	const isSetRef = React.useRef(false);
	React.useEffect(() => {
		const formData = removeTypenameGeneric(drivingSlipCase.caseReports?.inspectionReportFormData);
		if (formData && !isSetRef.current) {
			if (formData.backgroundStory) setBackgroundStory(formData.backgroundStory);
			if (formData.observations) setObservations(formData.observations);
			if (formData.assessmentAndConclusion) setAssessmentAndConclusion(formData.assessmentAndConclusion);
			if (formData.suggestedActionPlan) setSuggestedActionPlan(formData.suggestedActionPlan);
			if (formData.comments) setComments(formData.comments);

			if (formData.facadeImage) {
				const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], images)[0];
				if (sanitizedFacadeImage) {
					setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, images));
				} else {
					const getLatestFacadePicture = images
						?.filter(ft => ft.metadata.some(fm => fm.key === 'IsFacadePicture' && fm.value === 'True'))
						.sort((a, b) => a.created.localeCompare(b.created))[0];
					setFacadeImage(tryGetFile(getLatestFacadePicture?.name, images));
				}
			}
			if (formData.floorplanImage) {
				const sanitizedFloorPlanImage = checkFilesExist([formData.floorplanImage], images)[0];
				if (sanitizedFloorPlanImage) {
					setFloorplanImage(tryGetFile(sanitizedFloorPlanImage.fileName, images));
				}
			}
			const sanitizedOtherImages = checkFilesExist(formData.otherImages ?? [], images);
			if (sanitizedOtherImages.length > 0) {
				setOtherImages(
					sanitizedOtherImages.reduce((images: GetDrivingSlipFiles_drivingSlipFiles[], otherImage) => {
						const file = tryGetFile(otherImage.fileName, images);
						if (file) {
							images.push(file);
						}
						return images;
					}, []),
				);
			}
		}
		isSetRef.current = true;
	}, [
		drivingSlipCase.caseReports?.inspectionReportFormData,
		images,
		setAssessmentAndConclusion,
		setBackgroundStory,
		setComments,
		setFacadeImage,
		setFloorplanImage,
		setObservations,
		setOtherImages,
		setSuggestedActionPlan,
	]);

	const [submitted, setSubmitted] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [saved, setSaved] = React.useState(false);
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const [floorplanImageError, setFloorplanImageError] = React.useState(false);

	React.useEffect(() => {
		setSaved(false);
	}, [backgroundStory,
		observations,
		assessmentAndConclusion,
		suggestedActionPlan,
		comments,
		facadeImage,
		floorplanImage,
		otherImages]);

	React.useEffect(() => {
		submitted && setFacadeImageError(facadeImage == null);
	}, [facadeImage]);

	React.useEffect(() => {
		submitted && setFloorplanImageError(floorplanImage == null);
	}, [floorplanImage]);

	// Setup mutation to create report
	const [createInspectionReport, { loading: submitting }] = useCreateInspectionReport();

	async function submitReport(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setSubmitted(true);
		if (facadeImage === null) {
			setFacadeImageError(true);
		}
		if (floorplanImage === null) {
			setFloorplanImageError(true);
		}

		if (facadeImage === null || floorplanImage === null) {
			return;
		}

		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisFloorplanImage = images.find(image => image.name === floorplanImage?.name) ?? floorplanImage;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		try {
			setLoadingType('CREATE');
			await createInspectionReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						backgroundStory,
						observations,
						assessmentAndConclusion,
						suggestedActionPlan,
						comments,
						facadeImage: fileToInput(thisFacadeImage),
						floorplanImage: fileToInput(thisFloorplanImage),
						otherImages: thisOtherImages.map(fileToInput),
					},
					onlySave: false,
				},
				onOptimisticResponseOverride(cache) {
					const created = new Date().toISOString();
					const createdSplit = dateToReportNameString(created);
					const extension = 'pdf';
					const report = {
						name: `Besigtigelsesrapport_${drivingSlipCase.erpNo}_${createdSplit}.${extension} (Oprettet Offline, kan ikke tilgås endnu)`,
						comments: null,
						created: created,
						extension: extension,
						fileId: created,
						groupingKey: '',
						metadata: [{ key: 'DokumentType', value: 'Rapport' }],
						thumbnail: '',
						url: 'report.' + extension,
						__typename: 'ESDHFile',
					};
					if (!isConnected()) {
						updateReportsCacheNew(drivingSlipCase.erpNo, report, cache);
					}
					// const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// });
					// console.log(cachedRequest);
					// console.log([report, ...(cachedRequest?.caseFiles ?? [])]);
					// cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// 	data: {
					// 		caseFiles: [report, ...(cachedRequest?.caseFiles ?? [])],
					// 	},
					// });
				},
				update: updateReportsCache(drivingSlipCase.erpNo, result => result.createInspectionReport),
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateInspectionReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});

			// // Remove state from local storage
			// clearBackgroundStory();
			// clearObservations();
			// clearAssessmentAndConclusion();
			// clearSuggestedActionPlan();
			// clearComments();
			// clearFacadeImage();
			// clearFloorplanImage();
			// clearOtherImages();

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}

	async function saveReport() {
		const thisFacadeImage = facadeImage ? images.find(image => image.name === facadeImage.name) ?? facadeImage : null;
		const thisFloorplanImage = floorplanImage ? images.find(image => image.name === floorplanImage.name) ?? floorplanImage : null;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);
		try {
			setLoadingType('SAVE');
			await createInspectionReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						backgroundStory,
						observations,
						assessmentAndConclusion,
						suggestedActionPlan,
						comments,
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
						otherImages: thisOtherImages.map(fileToInput),
					},
					onlySave: true,
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateInspectionReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});
			setSaved(true);

		} catch (e) {
			console.error(e);
		}
	}

	return (
		<BasePage title={t('reports.reportTypes.inspection')}>
			<form onSubmit={submitReport}>
				<IonGrid className="mb-4 text-sm">
					<IonRow>
						<ReportStaticInfo drivingSlipCase={drivingSlipCase} />
						<DrivingSlipMultiImageSelect
							label={t('reports.uploadFacadeImage')}
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							onImageSelect={setFacadeImage}
							selectedImages={facadeImage ? [facadeImage] : []}
						/>

						{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}

						<TextAreaInput label={t('reports.backgroundStory')} placeholder={t('reports.backgroundStoryPlaceholder')} value={backgroundStory} onChange={setBackgroundStory} />

						<TextAreaInput label={t('reports.observations')} placeholder={t('reports.observationsPlaceholder')} value={observations} onChange={setObservations} />

						<TextAreaInput
							label={t('reports.assessmentAndConclusion')}
							placeholder={t('reports.assessmentAndConclusionPlaceholder')}
							value={assessmentAndConclusion}
							onChange={setAssessmentAndConclusion}
						/>

						<TextAreaInput
							label={t('reports.suggestedActionPlan')}
							placeholder={t('reports.suggestedActionPlanPlaceholder')}
							value={suggestedActionPlan}
							onChange={setSuggestedActionPlan}
						/>

						<TextAreaInput label={t('reports.comments')} placeholder={t('reports.commentsPlaceholder')} value={comments} onChange={setComments} />

						<DrivingSlipSingleImageSelect
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							noImageText={t('reports.noFloorplanImage')}
							uploadButtonText={t('reports.uploadFloorplanImage')}
							onImageSelect={setFloorplanImage}
							selectedImage={floorplanImage}
						/>
						{floorplanImageError && <FormErrorText text="reports.error.floorplanImage" />}

						<DrivingSlipMultiImageSelect
							label={t('reports.images')}
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							onImageSelect={image => setOtherImages(current => toggleArrayValue(current, image, img => img.url))}
							selectedImages={otherImages}
						/>

						<IonCol size="12">
							<Button color="success" expand="block" disabled={submitting} loading={submitting && loadingType === 'CREATE'} submit>
								{t('reports.createReport')}
							</Button>
							{saveButtonReportsFlag &&
								<Button expand="block" disabled={submitting} loading={submitting && loadingType === 'SAVE'} onClick={() => saveReport()} >
									{t(saved ? 'reports.saved' : 'reports.saveReport')}
								</Button>
							}
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasePage>
	);
};

export default InspectionReport;
