import React from 'react';
import classNames from 'classnames';
import { Controller, UseFormMethods } from 'react-hook-form';
import FormFieldHeader from './FormFieldHeader';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../Helpers/Helpers';

interface Props {
	options: SelectOption[];
	initialSelection?: SelectOption;
	control: UseFormMethods['control'];
	name: string;
	title?: string;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	errorMessage?: string;
	helpText?: string;
	showHelpText?: boolean;
	allowEmpty?: boolean;
	addressSlice?: boolean;

	isLoading: boolean;
	isInvalid?: boolean;

	onSelect(value: SelectOption['value']): void;
	onBlur(clearInput: () => void): void;

	searchFn(searchText: string): void;
	minInputLength: number;

	dataAttr?: string;
	clearCallback?(isCleared: boolean): void;
	autoFocus?: boolean;
	placeholder?: string;
	className?: string;
	style?: React.CSSProperties;
}

const SearchableSelect: React.FC<Props> = ({
	options,
	initialSelection,
	control,
	name,
	title,
	required = false,
	disabled = false,
	readOnly = false,
	errorMessage,
	helpText,
	showHelpText,
	allowEmpty = false,
	isLoading,
	isInvalid = false,
	addressSlice = false,
	onSelect,
	onBlur,
	searchFn,
	minInputLength,
	dataAttr,
	autoFocus,
	placeholder,
	className,
	style,
	clearCallback,
}): React.ReactElement => {
	const { t } = useTranslation();

	const [focusCount, setFocusCount] = React.useState(0);
	const focusCountRef = React.useRef<number>();
	const incrementFocus = (): void => setFocusCount((c): number => c + 1);
	const decrementFocus = (): unknown => window.setTimeout(() => setFocusCount((c): number => c - 1));

	const [searchText, setSearchText] = React.useState(initialSelection?.label ?? '');
	const searchTextRef = React.useRef<string>(initialSelection?.label ?? '');

	const inputRef = React.useRef<HTMLInputElement>(null);
	const elementWrapperRef = React.useRef<HTMLDivElement>(null);
	const elementFocusRef = React.useRef<number>(-1);

	React.useEffect(() => {
		if (clearCallback) {
			if (searchText === '') {
				clearCallback(true);
			}
		}
	}, [searchText]);

	const searchFnRef = React.useRef(searchFn);
	React.useEffect((): (() => void) => {
		const timeout = window.setTimeout((): void => {
			if (searchText.length > minInputLength && searchText !== searchTextRef.current) {
				searchTextRef.current = searchText;
				searchFnRef.current(searchText);
			}
		}, 500);

		return (): void => window.clearTimeout(timeout);
	}, [searchText, minInputLength]);

	React.useEffect((): void => {
		if (focusCount === 0 && focusCountRef.current === 1) {
			if (searchText.length === 0 && !allowEmpty) {
				onSelect('');
			} else if (searchText.length === 0 && allowEmpty) {
				const value = inputRef.current?.value ?? '';
				searchTextRef.current = value;
				searchFnRef.current(value);
			}

			elementFocusRef.current = -1;
			window.setTimeout(() =>
				onBlur(() => {
					if (inputRef.current !== null) {
						inputRef.current.value = '';
					}
				}),
			);
		}

		focusCountRef.current = focusCount;
	}, [focusCount, searchText, onSelect, onBlur, allowEmpty]);

	React.useEffect(() => {
		if (disabled) {
			onSelect('');
			window.setTimeout(() => {
				if (inputRef.current !== null) {
					inputRef.current.value = '';
				}
			});
		}
	}, [disabled, onSelect, onBlur]);

	const onClick = (selection: SelectOption): void => {
		if (inputRef.current !== null) {
			if (addressSlice) {
				const splitOne = selection.label.split(',')[0];
				const lastSpaceIndex = splitOne.lastIndexOf(' ');
				inputRef.current.value = splitOne.slice(0, lastSpaceIndex);
			} else {
				inputRef.current.value = selection.label;
			}
		}

		if (searchText !== searchTextRef.current) {
			setSearchText(searchTextRef.current);
		}

		onSelect(selection.value);
		decrementFocus();
	};

	return (
		<>
			{title && <FormFieldHeader htmlFor={name} required={required} title={title} />}
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<Controller
				control={control}
				name={name}
				defaultValue={null}
				render={() => (
					<div
						className="relative"
						onKeyDown={(e): void => {
							if (e.keyCode === 38) {
								elementFocusRef.current = (elementFocusRef.current - 1 + options.length) % options.length;
							} else if (e.keyCode === 40) {
								elementFocusRef.current = (elementFocusRef.current + 1) % options.length;
							}

							if (e.keyCode === 38 || e.keyCode === 40) {
								e.preventDefault();

								const element = elementWrapperRef.current?.children[elementFocusRef.current];
								if (typeof element !== 'undefined' && element.tagName === 'BUTTON') {
									(element as HTMLButtonElement).focus();
								}
							}
						}}
						data-tour={dataAttr}
					>
						<input
							id={name}
							ref={inputRef}
							type="search"
							defaultValue={initialSelection && initialSelection.label}
							onChange={(e): void => setSearchText(e.target.value)}
							onFocus={incrementFocus}
							onBlur={decrementFocus}
							className={classNames('border-1 rounded-default block w-full border-gray-600 p-1 text-sm focus:outline-none', className, {
								'border-red border-2': errorMessage,
								'is-invalid': isInvalid,
								'cursor-not-allowed bg-gray-300': disabled || readOnly,
							})}
							disabled={disabled}
							readOnly={readOnly}
							placeholder={placeholder}
							style={style}
							autoFocus={autoFocus}
							autoComplete="off"
						/>
						{/* INLINE STYLE */}
						{focusCount > 0 && searchTextRef.current.length > minInputLength && (
							<div
								ref={elementWrapperRef}
								className={classNames('border-1 rounded-default absolute w-full overflow-x-hidden border-gray-600 bg-white', className)}
								style={{
									top: 'calc(100%)',
									zIndex: 80108,
									maxHeight: 250,
								}}
							>
								{((): React.ReactElement | React.ReactElement[] => {
									if (isLoading) {
										return <div className="p-2">Loading...</div>;
									}

									if (options.length === 0) {
										return <div className="p-2">No options found for '{searchTextRef.current}'</div>;
									}

									return options.map(
										(option, index): React.ReactElement => (
											<button
												key={option.value}
												type="button"
												onClick={(): void => onClick(option)}
												onFocus={incrementFocus}
												onBlur={decrementFocus}
												className={classNames('searchable-select-option hover:bg-blue-light m-0 block w-full pl-1 text-left text-sm text-black hover:text-white', {
													'border-top': index > 0,
												})}
											>
												{t(option.label)}
											</button>
										),
									);
								})()}
							</div>
						)}
					</div>
				)}
			/>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};
export default SearchableSelect;
