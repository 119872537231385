import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonTextarea } from '@ionic/react';
import { stop, videocam } from 'ionicons/icons';
import * as WebVPPlugin from 'capacitor-video-player';
import { CapacitorVideoPlayerPlugin } from 'capacitor-video-player';

import { UserVideo, useVideoCapture } from 'Hooks/useVideoCapture';
import Video from './Video';
import FullyCenteredDisplayText from './Layout/FullyCenteredDisplayText';
import Button from './Button';
import CloseableModal from './CloseableModal';

export interface Props {
	onSubmit: (photo: UserVideo, comments?: string) => Promise<void>;
	onDismiss: () => void;
}

const videoPlayerElement = 'video-capture-player';

const VideoCaptureModal: React.FC<Props> = ({ onSubmit: onUpload, onDismiss }) => {
	const { t } = useTranslation();

	const { video, startCapture, stopCapture, deleteCapture, isRecording, recordingMediaStream } = useVideoCapture();
	const videoPlayer = useRef<CapacitorVideoPlayerPlugin>(WebVPPlugin.CapacitorVideoPlayer);
	const maximisingDiv = useRef<HTMLDivElement>(null);

	const [comments, setComments] = useState<string>();
	const [height, setHeight] = useState<number>();
	const [width, setWidth] = useState<number>();

	useEffect(() => {
		if (maximisingDiv.current) {
			// Without setTimeout the width/height is sometimes 0
			setTimeout(() => {
				setHeight(maximisingDiv.current?.offsetHeight);
				setWidth(maximisingDiv.current?.offsetWidth);
			}, 500);
		}
	}, [maximisingDiv]);

	useEffect(() => {
		if (video) {
			videoPlayer.current.initPlayer({
				mode: 'embedded',
				playerId: videoPlayerElement,
				componentTag: 'div',
				url: video.base64EncodedData,
				height: height === 0 ? 400 : height, // Fallback
				width: width === 0 ? 400 : width, // Fallback
			});
		}
	}, [video, height, width]);

	return (
		<CloseableModal
			title={t('common.uploadVideo')}
			onDismiss={() => {
				if (video) {
					deleteCapture();
				}
				onDismiss();
			}}
		>
			<>
				<div className="flex h-full flex-col">
					<div className="flex-1" ref={maximisingDiv}>
						{isRecording && <Video srcObject={recordingMediaStream} className="w-full" autoPlay loop playsInline muted controls />}

						{!isRecording && video && (
							<div>
								<div id={videoPlayerElement} slot="fixed"></div>
							</div>
						)}

						{!isRecording && !video && <FullyCenteredDisplayText>{t('common.uploadVideoPressButtonToRecord')}</FullyCenteredDisplayText>}
					</div>
					{/*
                    We need to use the visibility attribute here (instead of display) so that we can calculate..
                    ..what the correct height of the maximising div should be when the buttons below are made hidden/invisible.
                     */}
					<div
						style={{
							visibility: !isRecording && video ? 'visible' : 'hidden',
						}}
					>
						<IonLabel className="text-blue font-semibold">{t('common.comments')}</IonLabel>
						<IonItem>
							<IonTextarea placeholder={t('common.insertOptionalComments')} onIonChange={e => setComments(e.detail.value ?? undefined)} />
						</IonItem>

						<Button
							color="primary"
							expand="block"
							onClick={() => {
								if (video) {
									onUpload(video, comments);
								}
							}}
						>
							{t('common.confirm')}
						</Button>
						<Button color="light" expand="block" onClick={deleteCapture}>
							{t('common.cancel')}
						</Button>
					</div>
				</div>
				{!video && (
					<IonFab vertical="bottom" horizontal="center" slot="fixed">
						<IonFabButton onClick={isRecording ? stopCapture : startCapture} color={isRecording ? 'danger' : 'primary'}>
							<IonIcon icon={isRecording ? stop : videocam} />
						</IonFabButton>
					</IonFab>
				)}
			</>
		</CloseableModal>
	);
};

export default VideoCaptureModal;
