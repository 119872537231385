enum Routes {
	Default = '/',

	DrivingSlipsOverview = '/drivingslips',
	DrivingSlipTabs = '/drivingslips/:id',
	DrivingSlipInfo = '/drivingslips/:id/info',
	DrivingSlipFiles = '/drivingslips/:id/files',
	DrivingSlipQuestionnaire = '/drivingslips/:id/questionnaire',
	DrivingSlipTimeRegistration = '/drivingslips/:id/timeregistration',
	//DrivingSlipTimeRegistrationNew = '/drivingslips/:id/timeregistrationNew',
	DrivingSlipRequisitions = '/drivingslips/:id/requisitions',
	DrivingSlipMachines = '/drivingslips/:id/machines',
	DrivingSlipRiskEvaluation = '/drivingslips/:id/riskevaluation',
	DrivingSlipSelectReports = '/drivingslips/:id/reports',
	DrivingSlipInspectionReport = '/drivingslips/:id/reports/inspection',
	DrivingSlipInspectionMovablesReport = '/drivingslips/:id/reports/inspection-movables',
	DrivingSlipWaterReport = '/drivingslips/:id/reports/waterdamage',
	DrivingSlipWaterReportTryg = '/drivingslips/:id/reports/waterdamagetryg',
	DrivingSlipFalckDehumidifierReport = '/drivingslips/:id/reports/falckdehumidifier',
	DrivingSlipIndoorClimateReport = '/drivingslips/:id/reports/indoorclimate',
	DrivingSlipOffers = '/drivingslips/:id/offers',
	DrivingSlipCreateOffer = '/drivingslips/:id/createoffer',
	DrivingSlipContactPersons = '/drivingslips/:id/contacts',

	TimeRegistrationOverview = '/timeregistration',

	RequisitionsOverview = '/requisitions',

	MachinesOverview = '/machines',
}

export default Routes;

export function getRouteWithId(routePath: Routes, id: string): string {
	return routePath.replace(':id', id);
}
