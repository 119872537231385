import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { P } from './Text';

interface Props {
	title?: string;
	highOpacity?: boolean;
	primary?: boolean;
}

const Loading: React.FC<Props> = ({ title, highOpacity = false, primary = true }): React.ReactElement => {
	const { t } = useTranslation();
	const defaultTranslation = 'common.pleaseWait';
	let loadingText = t(title ?? defaultTranslation);

	// Display no text instead of 'common.pleaseWait' in case translations haven't loaded
	if (loadingText === defaultTranslation) loadingText = '';

	return (
		<div
			className={classNames('absolute top-0 flex h-full w-full flex-col items-center justify-center bg-opacity-25', {
				'bg-opacity-25': !highOpacity,
				'bg-opacity-50': highOpacity,
				'bg-transparrent text-white': !primary,
				'text-blue bg-white': primary,
			})}
			style={{ zIndex: 9999 }}
		>
			<FontAwesomeIcon icon={faSpinner} className="animate-spin" size="5x" />
			<P className="text-2xl">{loadingText}</P>
		</div>
	);
};

export default Loading;
