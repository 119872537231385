import { ApolloCache, FetchResult } from '@apollo/client';
import { ESDHFileFragment } from '@ssg/common/GraphQL';
import { loader } from 'graphql.macro';
import { GetCaseFiles, GetCaseFilesVariables } from 'GQL';

const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');

export function updateReportsCache<T>(erpNo: string, mutationResultFileSelector: (result: T) => ESDHFileFragment) {
	return (cache: ApolloCache<T>, mutationResult: FetchResult<T>): void => {
		if (mutationResult.data == null) return;

		const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
			query: GET_CASE_FILES,
			variables: {
				caseErpNo: erpNo,
				folder: erpNo,
			},
		});
		cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
			query: GET_CASE_FILES,
			variables: {
				caseErpNo: erpNo,
				folder: erpNo,
			},
			data: {
				caseFiles: [mutationResultFileSelector(mutationResult.data), ...(cachedRequest?.caseFiles ?? [])],
			},
		});
	};
}

export async function updateReportsCacheNew<T>(erpNo: string, reportFile: ESDHFileFragment, cache: ApolloCache<T>) {
	const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
		query: GET_CASE_FILES,
		variables: {
			caseErpNo: erpNo,
			folder: erpNo,
		},
	});

	cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
		query: GET_CASE_FILES,
		variables: {
			caseErpNo: erpNo,
			folder: erpNo,
		},
		data: {
			caseFiles: [reportFile, ...(cachedRequest?.caseFiles ?? [])],
		},
	});
}

