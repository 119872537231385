import React from 'react';
import smallWhiteLogo from '../../Assets/belfor-logo-small-white.png';
import smallBlackLogo from '../../Assets/belfor-logo-small-black.png';

interface Props {
	className: string;
	black?: boolean;
}

const SmallLogo: React.FC<Props> = ({ className, black = false }): React.ReactElement => {
	const imgSrc = black ? smallBlackLogo : smallWhiteLogo;

	return <img src={imgSrc} className={className} alt="BELFOR Logo" />;
};

export default SmallLogo;
