import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol } from '@ionic/react';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import { GetMobileV2DrivingSlips_drivingSlips_case } from 'GQL';

interface Props {
	resetForm?(): void;
	drivingSlipCase: GetMobileV2DrivingSlips_drivingSlips_case;
}

const ReportStaticInfo: React.FC<Props> = ({ resetForm, drivingSlipCase }) => {
	const { t } = useTranslation();
	const { account } = useMicrosoftAuth();

	return (
		<>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.costumer')}:</p>
				<p>{drivingSlipCase.policyHolder.name}</p>
				<p>{drivingSlipCase.policyHolder.address.addressLine}</p>
				<p>
					{drivingSlipCase.policyHolder.address.postalCode} {drivingSlipCase.policyHolder.address.city}
				</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.orderNr')}:</p>
				<p>{drivingSlipCase.erpNo}</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.requester')}</p>
				<p>{drivingSlipCase.requisitioner?.name}</p>
				<p>{drivingSlipCase.requisitioner?.email}</p>
				<p>{drivingSlipCase.requisitioner?.phone}</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.createdBy')}:</p>
				<p>{account?.name}</p>
				<p>{account?.username}</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.policeNr')}:</p>
				<p>{drivingSlipCase.debitor.policeNumber}</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.dateCreated')}:</p>
				<p>{dateToDateTimeString(Date())}</p>
			</IonCol>
			<IonCol size="6">
				<p className="text-blue text-base font-semibold">{t('reports.dateChanged')}:</p>
				<p>{dateToDateTimeString(Date())}</p>
			</IonCol>
			{typeof resetForm !== 'undefined' && (
				<IonCol size="6" className="flex justify-end">
					<Button onClick={resetForm} color="primary" className="ml-auto mr-0">
						<FontAwesomeIcon icon={faSync} className="mr-2" /> {t('reports.reset')}
					</Button>
				</IonCol>
			)}
		</>
	);
};

export default ReportStaticInfo;
