import { IonCol, IonLabel, IonTextarea } from '@ionic/react';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

interface Props {
    label: string;
    placeholder?: string;
    onChange(value: string): void;
    value: string | undefined;
    errorMessage?: string;
    required?: boolean;
    rows?: number;
}


const TextAreaInput: React.FC<Props> = ({ value, onChange, label, placeholder, errorMessage, rows = 6, required = false }) => {
    const autoResizeTextAreaFlag = useFlag(FeatureFlagEnums.AUTO_RESIZE_TEXT_AREA);
    return (
        <IonCol size="12">
            <IonLabel className="text-blue text-base font-semibold">
                {label}
                {required && <span className="text-red">*</span>}
            </IonLabel>
            <IonTextarea
                value={value}
                onIonChange={e => e.detail.value != null && onChange(e.detail.value)}
                class="rounded-md border-2 border-gray-400 p-1"
                rows={rows}
                cols={20}
                placeholder={placeholder}
                autoGrow={autoResizeTextAreaFlag}
            />
            {errorMessage && <FormErrorText text={errorMessage} />}
        </IonCol>
    );
};

export default TextAreaInput;