import {
	CreateCaseAgreementsFromQuestionnaire,
	CreateCaseAgreementsFromQuestionnaireVariables,
	CreateMobileV2CaseDraftItemUsage,
	CreateMobileV2CaseDraftItemUsageVariables,
	UpdateCase,
	UpdateCaseRiskEvaluationAnswers,
	UpdateCaseRiskEvaluationAnswersVariables,
	UpdateCaseVariables,
} from 'GQL';
import { useOfflineHandlingMutation } from 'Hooks';
import { loader } from 'graphql.macro';

const CREATE_CASE_AGREEMENTS = loader('src/GQL/Cases/CreateCaseAgreementsFromQuestionnaire.gql');
const UPDATE_CASE = loader('src/GQL/Cases/UpdateCase.gql');
const UPDATE_CASE_RISK_EVALUATION_ANSWERS = loader('src/GQL/Cases/UpdateCaseRiskEvaluationAnswers.gql');
const CREATE_ITEM_USAGE = loader('src/GQL/Cases/CreateMobileV2CaseDraftItemUsage.gql');

export const useCreateCaseAgreements = () => useOfflineHandlingMutation<CreateCaseAgreementsFromQuestionnaire, CreateCaseAgreementsFromQuestionnaireVariables>(CREATE_CASE_AGREEMENTS);

export const useUpdateCase = () => useOfflineHandlingMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

export const useUpdateCaseRiskEvaluationAnswers = () => useOfflineHandlingMutation<UpdateCaseRiskEvaluationAnswers, UpdateCaseRiskEvaluationAnswersVariables>(UPDATE_CASE_RISK_EVALUATION_ANSWERS);

export const useCreateCaseDraftItemUsage = () => useOfflineHandlingMutation<CreateMobileV2CaseDraftItemUsage, CreateMobileV2CaseDraftItemUsageVariables>(CREATE_ITEM_USAGE);

