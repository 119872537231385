import React from 'react';
import { useRef } from 'react';
import { IonAccordion, IonAccordionGroup, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonThumbnail } from '@ionic/react';
import { faFileDownload, faSpinner, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getExtensionIcon } from '@ssg/common/Helpers/fileExtensionHelper';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import FullyCenteredDisplayText from 'Components/Layout/FullyCenteredDisplayText';
import DrivingSlipFilesListElementLoading from './DrivingSlipFilesListElementLoading';
import { DrivingSlipFilesListProps } from './DrivingSlipFilesListProps';
import { useTranslation } from 'react-i18next';
import { FileType } from '@ssg/common/Helpers/fileTypeHelper';

// Disabling PDF viewer due to large, problematic PDF files
const enablePdfViewer = false;

const DrivingSlipFilesDocumentList: React.FC<DrivingSlipFilesListProps> = ({ files, loadingUploadFile, loadingDeleteFile, loadingDownloadFile, uploadingFileType, onDelete, onDownload, onView }) => {
	const { t } = useTranslation();

	const ionListRef = useRef<HTMLIonListElement>(null);

	return (
		<IonAccordionGroup value="documents">
			<IonAccordion toggleIconSlot="start" value="documents">
				<IonItem slot="header">
					<IonLabel className="text-blue font-semibold">{t('common.documents')}</IonLabel>
				</IonItem>
				<IonList ref={ionListRef} slot="content">
					{loadingUploadFile && uploadingFileType && (uploadingFileType & FileType.Document) === FileType.Document && <DrivingSlipFilesListElementLoading />}
					{!loadingUploadFile && files.length === 0 ? (
						<FullyCenteredDisplayText>{t('catalog.noFiles')}</FullyCenteredDisplayText>
					) : (
						files.map((file, index) => {
							return (
								<IonItemSliding key={index}>
									<IonItemOptions side="start">
										<IonItemOption color="primary" onClick={() => onDownload(file)} disabled={loadingDownloadFile}>
											{loadingDownloadFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faFileDownload} className="mr-2" />}

											{t('common.download')}
										</IonItemOption>
										<IonItemOption
											color="danger"
											onClick={async () => {
												const confirm = window.confirm(t('catalog.wantToDelete'));
												if (confirm) {
													await onDelete(file);
												}
												ionListRef.current?.closeSlidingItems();
											}}
											disabled={loadingDeleteFile}
										>
											{loadingDeleteFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faTrash} className="mr-2" />}
											{t('common.remove')}
										</IonItemOption>
									</IonItemOptions>
									<IonItem
										onClick={() => {
											// For PDFs use the in-app document viewer.
											// Microsoft Office documents are supported however it requires we create..
											// ..a custom unauthenticated API endpoint to serve the file (and not just the raw data).
											if (enablePdfViewer && file.extension === 'pdf') {
												onView?.(file);
												return;
											}

											// Otherwise open the Sharepoint url and let Sharepoint serve the file
											window.open(file.url, '_blank');
										}}
									>
										<IonThumbnail slot="start">
											<FontAwesomeIcon icon={getExtensionIcon(file.extension)} size="3x" />
										</IonThumbnail>
										<IonLabel>
											<h2>{file.name}</h2>
											<h3>{file.url}</h3>
											<p>{dateToDateTimeString(file.metadata.find(m => m.key === 'Modified')?.value ?? '')}</p>
										</IonLabel>
									</IonItem>
								</IonItemSliding>
							);
						})
					)}
				</IonList>
			</IonAccordion>
		</IonAccordionGroup>
	);
};

export default DrivingSlipFilesDocumentList;
