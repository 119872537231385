import { faFileAlt, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export const getExtensionIcon = (extension: string): IconDefinition => {
	switch (extension) {
		case 'pdf':
			return faFilePdf;
		case 'docx':
			return faFileWord;
		case 'xlsx':
			return faFileExcel;
		case 'ppt':
			return faFilePowerpoint;
		default:
			return faFileAlt;
	}
};
