import React from 'react';
import { IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import UnitOfMeasurement from '@ssg/common/Components/UnitOfMeasurement';
import { QuestionnaireChoiceType as ChoiceType } from 'GQL';
import { SelectableChoice } from './SelectableChoice';

interface Props {
	choice: SelectableChoice;
	onChangeChoice: (newValue: any) => void;
	isDisabled?: boolean;
}

const DrivingSlipQuestionnaireChoice: React.FC<Props> = ({ choice, onChangeChoice, isDisabled }) => {
	const { t } = useTranslation();
	const sanitiseValue = (event: CustomEvent<{ value?: any }>) => {
		return event.detail.value === '' ? null : event.detail.value;
	};

	// For radio groups and dropdowns we automatically want to update parent state
	const handleOnChange = (event: CustomEvent<{ value?: any }>): void => {
		const newValue = sanitiseValue(event);
		onChangeChoice(newValue);
	};

	const renderChoice = () => {
		switch (choice.type) {
			case ChoiceType.DROPDOWN:
				return renderDropdown();
			case ChoiceType.RADIO_GROUP:
				return renderRadioGroup();
			case ChoiceType.FREE_TEXT:
				return renderFreeTextInput();
			case ChoiceType.NUMBER:
				return renderNumberInput();
			default:
				throw new Error(`Choice type "${choice.type}" is not renderable`);
		}
	};

	const renderDropdown = () => (
		<IonSelect
			interface="popover"
			value={choice.value}
			placeholder={choice.placeholder ?? undefined}
			onIonChange={e => handleOnChange(e)}
			disabled={isDisabled}
			okText={t('common.ok')}
			cancelText={t('common.cancel')}
		>
			{choice.selectOptions.map((childChoice: SelectOption) => (
				<IonSelectOption value={childChoice.value} key={childChoice.value}>
					{childChoice.label}
				</IonSelectOption>
			))}
		</IonSelect>
	);

	const renderMultiSelectGroup = () => {
		const vertical = choice.selectOptions.length > 2;
		let multiSelectValues = choice.multiSelectValues || [];

		const handleMultiSelect = (event: any, value: string) => {
			if (event.target.checked) {
				multiSelectValues.push(value);
			} else {
				const index = multiSelectValues.findIndex(category => {
					return category === value;
				});

				multiSelectValues = multiSelectValues.filter((_, idx) => idx !== index);
			}

			onChangeChoice(multiSelectValues);
		};

		return (
			<IonRadioGroup value={choice.value} className="w-full pb-2">
				{vertical ? (
					choice.selectOptions?.map((childChoice: SelectOption) => (
						<IonItem key={childChoice.value}>
							<IonLabel>{childChoice.label}</IonLabel>
							<IonCheckbox
								onIonChange={e => handleMultiSelect(e, childChoice.value)}
								slot="start"
								checked={choice.multiSelectValues?.includes(childChoice.value)}
								value={childChoice.value}
								disabled={isDisabled}
							/>
						</IonItem>
					))
				) : (
					<IonRow>
						{choice.selectOptions.map((childChoice: SelectOption) => (
							<IonCol key={childChoice.value}>
								<IonItem>
									<IonLabel>{childChoice.label}</IonLabel>
									<IonCheckbox onIonChange={e => handleMultiSelect(e, childChoice.value)} slot="start" value={childChoice.value} disabled={isDisabled} />
								</IonItem>
							</IonCol>
						))}
					</IonRow>
				)}
			</IonRadioGroup>
		);
	};
	const renderRadioGroup = () => {
		const vertical = choice.selectOptions.length > 2;
		if (choice.isMultiSelectAllowed) {
			return renderMultiSelectGroup();
		}

		return (
			<IonRadioGroup value={choice.value} onIonChange={e => handleOnChange(e)} className="w-full pb-2">
				{vertical ? (
					choice.selectOptions?.map((childChoice: SelectOption) => (
						<IonItem key={childChoice.value}>
							<IonLabel>{childChoice.label}</IonLabel>
							<IonRadio slot="start" value={childChoice.value} disabled={isDisabled} />
						</IonItem>
					))
				) : (
					<IonRow>
						{choice.selectOptions.map((childChoice: SelectOption) => (
							<IonCol key={childChoice.value}>
								<IonItem>
									<IonLabel>{childChoice.label}</IonLabel>
									<IonRadio slot="start" value={childChoice.value} disabled={isDisabled} />
								</IonItem>
							</IonCol>
						))}
					</IonRow>
				)}
			</IonRadioGroup>
		);
	};

	const renderFreeTextInput = () => (
		<IonTextarea
			value={choice.value}
			placeholder={choice.placeholder ?? t('questionnaire.freeText')}
			className="text-left"
			onIonChange={e => handleOnChange(e)}
			debounce={500}
			disabled={isDisabled}
		/>
	);

	const renderNumberInput = () => (
		<div className="flex w-full">
			<IonInput
				value={choice.value}
				placeholder={choice.placeholder ?? undefined}
				type="number"
				className={classNames('flex-1 text-left', {
					'rounded-lg': !choice.unitOfMeasure,
					'rounded-l-lg': choice.unitOfMeasure,
				})}
				onIonChange={e => handleOnChange(e)}
				debounce={500}
				disabled={isDisabled}
			/>
			{choice.unitOfMeasure && (
				<span className="inline-flex items-center rounded-r-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
					<UnitOfMeasurement value={choice.unitOfMeasure} />
				</span>
			)}
		</div>
	);

	return renderChoice();
};

export default DrivingSlipQuestionnaireChoice;
