import React from 'react';
import { IonCol, IonInput, IonLabel, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TrygMoistureMeassurementInput } from 'GQL';
import Button from 'Components/Button';

interface Props {
	meassurements: TrygMoistureMeassurementInput[];
	onChange(meassurements: TrygMoistureMeassurementInput[]): void;
}

const EMPTY_ROW = (): TrygMoistureMeassurementInput => ({
	method: '',
	paints: '',
	material: '',
	description: '',
	construction: '',
	controlValue: '',
	referenceValue: '',
	expectedFinalValue: '',
});

const TABLE_COLUMNS: Array<keyof TrygMoistureMeassurementInput> = ['construction', 'material', 'description', 'method', 'paints', 'expectedFinalValue', 'referenceValue', 'controlValue'];

const DATE_COLUMN: Array<keyof TrygMoistureMeassurementInput> = ['paintsDate', 'expectedFinalDate', 'referenceDate', 'controlDate'];

const TrygMoistureMeassurementForm: React.FC<Props> = ({ meassurements, onChange }) => {
	const { t } = useTranslation();
	// const [dates, setDates] = React.useState({
	//     paintsDate: meassurements[0]?.paintsDate,
	//     expectedFinalDate: meassurements[0]?.expectedFinalDate,
	//     referenceDate: meassurements[0]?.referenceDate,
	//     controlDate: meassurements[0]?.controlDate,
	// });

	function handleChange(idx: number, column: keyof TrygMoistureMeassurementInput, value: string) {
		const copy = meassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	// React.useEffect(() => {
	//     setDates({
	//         paintsDate: meassurements[0]?.paintsDate,
	//         expectedFinalDate: meassurements[0]?.expectedFinalDate,
	//         referenceDate: meassurements[0]?.referenceDate,
	//         controlDate: meassurements[0]?.controlDate,
	//     });
	// },[meassurements]);

	function addRow() {
		onChange([
			...meassurements,
			{
				paintsDate: meassurements[0]?.paintsDate ?? '',
				expectedFinalDate: meassurements[0]?.expectedFinalDate ?? '',
				referenceDate: meassurements[0]?.referenceDate ?? '',
				controlDate: meassurements[0]?.controlDate ?? '',
				...EMPTY_ROW(),
			},
		]);
	}

	function removeRow(idx: number) {
		onChange(meassurements.filter((_, i) => i !== idx));
	}

	const renderField = (column: keyof TrygMoistureMeassurementInput) => {
		// let name: 'paintsDate' | 'expectedFinalDate' | 'referenceDate' | 'controlDate' = 'paintsDate';
		// switch (column) {
		//     case 'paints':
		//         name = 'paintsDate';
		//         break;
		//     case 'expectedFinalValue':
		//         name = 'expectedFinalDate';
		//         break;
		//     case 'referenceValue':
		//         name = 'referenceDate';
		//         break;
		//     case 'controlValue':
		//         name = 'controlDate';
		// }

		if (column === 'paintsDate' || column === 'expectedFinalDate' || column === 'referenceDate' || column === 'controlDate') {
			return (
				<IonInput
					className="border-2 border-gray-800"
					name={column}
					placeholder="Dato"
					value={meassurements[0][column] as any}
					onIonChange={e => handleChange(0, column, e.detail.value || '')}
				/>
			);
		}
	};

	return (
		<IonCol size="12">
			<IonLabel className="text-blue text-base font-semibold">{t('reports.moistureMeassurement.title')}</IonLabel>
			<IonRow>
				{TABLE_COLUMNS.map((column, i) => (
					<div key={'label' + column + i} className="flex-1">
						<IonLabel className="text-xs">{t(`reports.trygMoistureMeassurement.${column}`)}</IonLabel>
					</div>
				))}
			</IonRow>
			{/* <IonRow className="mb-3">
                {TABLE_COLUMNS.map((column) => 
                <div className='flex-1'>
                    {renderField(column)}
                </div>,
                )}
            </IonRow> */}
			{meassurements.map((meassurement, idx) => (
				<>
					{idx === 0 && (
						<IonRow key={'input' + idx} className="mb-3">
							{[...TABLE_COLUMNS.slice(4), ...DATE_COLUMN].map((column, i) => (
								<IonCol key={i + 'idx' + idx}>{renderField(column)}</IonCol>
							))}
						</IonRow>
					)}
					<IonRow key={idx + 'what'} className="border-1 mb-3">
						{TABLE_COLUMNS.map((column, i) => (
							<IonCol key={'input2' + i}>
								<div className="flex flex-col">
									<IonInput className="border-2 border-gray-800" value={meassurement[column]} onIonChange={e => handleChange(idx, column, e.detail.value || '')} />

									{i === TABLE_COLUMNS.length - 1 && (
										<p className="text-red pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
											x <span className="text-sm">Fjern linje</span>
										</p>
									)}
								</div>
							</IonCol>
						))}
					</IonRow>
				</>
			))}
			<Button onClick={addRow}>{t('reports.addMoistureMeassurement')}</Button>
		</IonCol>
		// <IonCol size="12">
		//     <IonLabel className="text-blue text-base font-semibold">{t('reports.moistureMeassurement.title')}</IonLabel>

		//     <div className="flex gap-2">
		//         {TABLE_COLUMNS.map(column => (
		//             <div className="flex-1">
		//                 <IonLabel className="text-xs">{t(`reports.trygMoistureMeassurement.${column}`)}</IonLabel>
		//             </div>
		//         ))}
		//     </div>

		//     <div className="flex gap-2 py-2">
		//         {TABLE_COLUMNS.map((column, i) => (
		//             <div className="flex flex-1">
		//                 {renderField(column)}
		//                 {i === TABLE_COLUMNS.length - 1 && <p className="text-red invisible cursor-pointer pl-2 pt-2 text-xl font-bold">x</p>}
		//             </div>
		//         ))}
		//     </div>

		//     {meassurements.map((meassurement, idx) => (
		//         <IonRow key={idx} className="border-1 mb-3">
		//             {TABLE_COLUMNS.map((column, i) => (
		//                 <IonCol key={i}>
		//                     <div className="flex flex-col">
		//                         {/* <IonLabel className="text-xs">{t(`reports.moistureMeassurement.${column}`)}</IonLabel> */}

		//                         <IonInput
		//                             name={column}
		//                             className="border-2 border-gray-800"
		//                             value={meassurement[column]}
		//                             onIonChange={e => handleChange(idx, column, e.detail.value!)}
		//                         />

		//                         {i === TABLE_COLUMNS.length - 1 && (
		//                             <p className="text-red cursor-pointer pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
		//                                 x <span className="text-sm">Fjern linje</span>
		//                             </p>
		//                         )}
		//                     </div>
		//                 </IonCol>
		//             ))}
		//         </IonRow>
		//     ))}
		//     <Button onClick={addRow}>{t('reports.addMoistureMeassurement')}</Button>
		// </IonCol>
	);
};

export default TrygMoistureMeassurementForm;
