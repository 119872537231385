import { loader } from 'graphql.macro';
import { CollectionItemsQuery, CollectionItems, CollectionItemsQueryVariables, setCollectionItems, useAppDispatch, useAppSelector, toArraySafe } from 'Store';
import { useApiQuery } from '../useApiQuery';
import { ApiQueryKey } from '../../types';
import { useState } from 'react';
import { UseApiQueryReturn } from '../types';
import { API_QUERIES } from '../../constants';

const COLLECTION_ITEMS = loader('src/GQL/CollectionItems/GetCollectionItems.gql');

export const useCollectionItemsQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.CollectionItems;

	const dispatch = useAppDispatch();

	const { metadata } = useAppSelector(state => state.apiData.collectionItems);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<CollectionItemsQuery, CollectionItems, CollectionItemsQueryVariables>({
		apiQueryKey,
		canFetchQuery: canFetchQuery,
		documentNode: COLLECTION_ITEMS,
		dataSelector: query => toArraySafe(query?.collectionItems),
		setCanFetchQuery,
		setData: partial => {
			dispatch(setCollectionItems({ ...partial }));
		},
		options: {
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'no-cache',
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};

