/* eslint-disable comma-dangle */
export default function dateToDateOnlyString(date: Date): string {
	return date.toISOString().substring(0, 'yyyy-mm-dd'.length);
}

export const formatDateForInput = (date: Date | null | undefined, withTime = false): string =>
	date !== null && typeof date !== 'undefined'
		? withTime
			? `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
			: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
		: '';

export const formatDateForInputEU = (date: Date | null, withTime = false, seperator?: string): string =>
	date !== null
		? withTime
			? `${('0' + date.getDate()).slice(-2)}${!seperator ? '-' : seperator}${('0' + (date.getMonth() + 1)).slice(-2)}${!seperator ? '-' : seperator}${date.getFullYear()} ${(
					'0' + date.getHours()
			  ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
			: `${('0' + date.getDate()).slice(-2)}${!seperator ? '-' : seperator}${('0' + (date.getMonth() + 1)).slice(-2)}${!seperator ? '-' : seperator}${date.getFullYear()}`
		: '';

export const formatDateForInputWithTime = (date: Date | null): string =>
	date !== null
		? `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${(
				'0' + date.getSeconds()
		  ).slice(-2)}`
		: '';
