import { IonCol, IonInput, IonLabel } from '@ionic/react';
import Input from '@ssg/common/Components/Input';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

interface Props {
	label: string;
	placeholder: string;
	onChange(value: number): void;
	value: number | undefined;
}

const NumberInput: React.FC<Props> = ({ value, onChange, label, placeholder }) => {
	const numberInputNegativeFlag = useFlag(FeatureFlagEnums.NUMBER_INPUT_NEGATIVE);
	if (numberInputNegativeFlag) return (
		<IonCol size="4">
			<IonLabel className="text-blue text-base font-semibold">{label}</IonLabel>
			<Input
				name=""
				defaultValue={value}
				type="number"
				step="0.01"
				onChange={e => onChange(Number(e.currentTarget.valueAsNumber))}
				className="rounded-md border-2 border-gray-400 p-1 py-2"
				placeholder={placeholder}
			/>
		</IonCol>
	);
	return (
		<IonCol size="4">
			<IonLabel className="text-blue text-base font-semibold">{label}</IonLabel>
			<IonInput
				value={value}
				type="number"
				step="0.01"
				onIonChange={e => e.detail.value != null && onChange(Number(e.detail.value))}
				class="rounded-md border-2 border-gray-400 p-1"
				placeholder={placeholder}
			/>
		</IonCol>
	);
};

export default NumberInput;
