import React from 'react';
import { roundArrow, Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/svg-arrow.css';

interface Props {
	children: React.ReactElement;
	content: React.ReactNode;
	placement: Placement;
	delay?: number | [number | null, number | null];
	duration?: number | [number | null, number | null];
	interactive?: boolean;
	clickTrigger?: boolean;
	hideOnClick?: boolean;
	hideArrow?: boolean;
	visible?: boolean;
	onHide?(val: undefined): void;
	zIndex?: number;
}

const Popover: React.FC<Props> = ({
	children,
	content,
	placement,
	delay,
	duration,
	clickTrigger = false,
	interactive = false,
	hideOnClick = false,
	hideArrow = false,
	visible = undefined,
	onHide,
	zIndex = 9999,
}): React.ReactElement => (
	<Tippy
		interactive={interactive}
		appendTo={document.body}
		hideOnClick={typeof visible === 'undefined' ? hideOnClick : undefined}
		trigger={typeof visible === 'undefined' ? clickTrigger ? 'click' : 'mouseenter' : undefined}
		arrow={hideArrow ? '' : roundArrow}
		content={content}
		placement={placement}
		delay={delay}
		duration={duration}
		visible={visible}
		onHide={() => onHide?.(undefined)}
		zIndex={zIndex}
	>
		{children}
	</Tippy>
);
export default Popover;
