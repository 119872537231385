import { BaseQuestionnaireTemplateFragment_sections as Section, BaseQuestionnaireTemplateFragment_sections_choices as Choice, QuestionnaireChoiceType as ChoiceType } from '../../GraphQL';
import { ISyncedChoices } from './QuestionnaireCompletionEntriesBuilder';

/* 
The "completable path" is a set of questionnaire choices for which the user must complete.
The completion of a questionnaire choice (for example, choosing a select option or inputting some free text) may unlock "next" choice(s).
The next choice(s) to show are determined by the parent/child relationship, as per the schema.
For example, if the completed choice is a dropdown, and its selected select option has child choices, then these will be shown next.
We can use the same "completable path" construct to determine the "happy/completed/answered path" of the questionnaire..
..i.e. the set of choices & answers in which the user answered to complete the set of questions.
*/

export function buildCompletablePathOfChoicesFromSection(rootIdPrefix: string, section: Section): Choice[] {
	const rootParentId = rootIdPrefix + section.label;
	const allChoices = section.choices;
	// Typically, the first element will be a dropdown/radio group.
	// However it's best we support multiple initial choices - just in case.
	const rootChoices = section.choices.filter(choice => choice.parentId === rootParentId);
	return buildCompletablePathOfChoicesFromChoices(allChoices, rootChoices);
}

function buildCompletablePathOfChoicesFromChoices(allChoices: Choice[], nextChoices: Choice[]): Choice[] {
	return nextChoices.reduce((visibleChoices: Choice[], choice: Choice) => [...visibleChoices, choice, ...buildCompletablePathOfChoicesFromChoice(allChoices, choice)], []);
}

export function buildCompletablePathOfChoicesFromChoice(allChoices: Choice[], choice: Choice): Choice[] {
	return choiceIsCompleted(choice) ? buildCompletablePathOfChoicesFromChoices(allChoices, getNextChoicesForChoiceByTypeIdAndValue(allChoices, choice.type, choice.id, choice.value)) : [];
}

export function getNextChoicesForChoiceByTypeIdAndValue(allChoices: Choice[], choiceType: ChoiceType, choiceId: string, value: string | null): Choice[] {
	if (choiceTypeHasSelectableOptions(choiceType)) {
		const selectedOption = allChoices.find(c => c.parentId === choiceId && c.value === value);
		if (selectedOption) {
			return allChoices.filter(c => c.parentId === selectedOption.id);
		}

		// This, in theory, should never be hit
		// console.error('Cannot find selected option for choice', choiceId, selectedOption);
		return [];
	}

	// Return next set of choices
	return allChoices.filter(c => c.parentId === choiceId);
}

export function choiceTypeHasSelectableOptions(choiceType?: ChoiceType): boolean {
	return choiceType === ChoiceType.DROPDOWN || choiceType === ChoiceType.RADIO_GROUP;
}

export function choiceIsCompleted(choice: Choice): boolean {
	return choice.isOptional === true || (!choice.isOptional && choice.value !== null);
}

export function syncedChoiceIsCompleted(choice: Choice, section: Section, syncedChoices: ISyncedChoices[]): boolean {
	const hasResults = syncedChoices?.some((r: ISyncedChoices) => r.section === section.label && r.value !== null);

	return choice.isOptional === true || (!choice.isOptional && choice.value !== null) || hasResults;
}

