import { IonCol, IonInput, IonLabel } from '@ionic/react';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import React from 'react';

interface Props {
	label: string;
	placeholder?: string;
	onChange(value: string): void;
	value?: string | null;
	required?: boolean;
	errorMessage?: string;
}

const TextInput: React.FC<Props> = ({ value, onChange, label, placeholder, required, errorMessage }) => (
	<IonCol size="12">
		<IonLabel className="text-blue text-base font-semibold">
			{label}
			{required && <span className="text-red">*</span>}
		</IonLabel>
		<IonInput value={value} onIonChange={e => e.detail.value != null && onChange(e.detail.value)} class="rounded-md border-2 border-gray-400 p-1" placeholder={placeholder} />
		{errorMessage && <FormErrorText text={errorMessage} />}
	</IonCol>
);

export default TextInput;
