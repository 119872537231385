import React from 'react';
import { IonItem, IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/react';

const DrivingSlipFilesListElementLoading: React.FC = () => (
	<>
		<IonItem>
			<IonThumbnail slot="start">
				<IonSkeletonText animated />
			</IonThumbnail>
			<IonLabel>
				<IonSkeletonText animated />
				<IonSkeletonText animated />
				<IonSkeletonText animated />
			</IonLabel>
		</IonItem>
	</>
);

export default DrivingSlipFilesListElementLoading;
