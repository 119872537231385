import { DateTime } from 'luxon';

const parseDate = (value: string): Date => {
	return new Date(Date.parse(value));
};

export const toDateTime = (date: Date | string): DateTime => {
	if (typeof date === 'string') {
		date = parseDate(date);
	}

	return DateTime.fromJSDate(date);
};

export default function dateToReportNameString(input: Date | string | number): string {
	const d = new Date(input);
	const year = d.getFullYear();
	const month = `${('0' + (d.getMonth() + 1)).slice(-2)}`;
	const date = `${('0' + d.getDate()).slice(-2)}`;

	const hour = `${('0' + d.getHours()).slice(-2)}`;
	const minute = `${('0' + d.getMinutes()).slice(-2)}`;

	return `${year}-${month}-${date}--${hour}-${minute}`;
}
