import { IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import Table from '@ssg/common/Components/Table';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import {
	GetDrivingSlipItems,
	DeleteMobileV2ItemUsage,
	DeleteMobileV2ItemUsageVariables,
	GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage,
	V2DrivingSlipCaseFragment,
	V2DrivingSlipCaseFragment_caseDraftItemsUsage,
} from 'GQL';
import { faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import UserContext from 'UserContext';
import Checkbox from 'Components/Checkbox';
import { useOfflineHandlingMutation } from 'Hooks';
import { ApiMutationKey, DrivingSlip, useAppSelector, useCreateCaseDraftItemUsage, useSetDrivingSlipNoWasteUsed } from 'Store';
import { generateRandomItemUsageId, removeDuplicates } from './ItemUsageModal';
import TextButton from '@ssg/common/Components/TextButton';
import { isConnected } from 'NetworkContext';

export interface Props {
	caseId: string;
	caseNo: string;
	drivingSlip: DrivingSlip;
	drivingSlipCategory: string;
	defaultNoWasteUsed: boolean;
	onDismiss: () => void;
	caseDraftUsageLines: GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage[];
}

const DELETE_ITEM_USAGE = loader('src/GQL/Cases/DeleteMobileV2ItemUsage.gql');
const V2_DRIVING_SLIP_CASE_FRAGMENT = loader('src/GQL/DrivingSlips/V2DrivingSlipCaseFragment.gql');

// TODO: This is pretty much an exact copy of the ItemUsageModal. Refactor if possible..
const WasteModal: React.FC<Props> = ({ caseId, caseNo, drivingSlip, drivingSlipCategory, defaultNoWasteUsed, onDismiss, caseDraftUsageLines }) => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const {
		caseAdminData: {
			data: { drivingSlipItems },
		},
	} = useAppSelector(state => state.apiData);

	const [createItemUsage, { loading: createItemUsageLoading }] = useCreateCaseDraftItemUsage();

	const [deleteItemUsage, { loading: deleteItemUsageLoading }] = useOfflineHandlingMutation<DeleteMobileV2ItemUsage, DeleteMobileV2ItemUsageVariables>(DELETE_ITEM_USAGE);

	const [setNoWasteUsed] = useSetDrivingSlipNoWasteUsed();

	const deletingItemErpIdRef = React.useRef<string>();

	// TODO: What's the proper way to filter waste items?
	const itemsToDisplay = useMemo(() => removeDuplicates(drivingSlipItems.filter(x => x.description.toLowerCase().includes('Affaldsafgift'.toLowerCase())) ?? []), [drivingSlipItems]);

	const usageLines = React.useMemo(() => caseDraftUsageLines.filter(x => itemsToDisplay.some(y => y.no === x.erpKey)) ?? [], [caseDraftUsageLines, itemsToDisplay]);

	const [selectedItem, setSelectedItem] = React.useState<GetDrivingSlipItems['drivingSlipItems'][number]['no']>();
	const [quantity, setQuantity] = React.useState<number>(1);

	const handleDeleteItemUsage = async (erpId: string) => {
		deletingItemErpIdRef.current = erpId;

		await deleteItemUsage({
			variables: {
				itemERPReferenceNo: erpId,
				caseERPReferenceNo: caseNo,
			},
			// update: (cache, { data }) => {
			// 	if (typeof data === 'undefined' || data === null) {
			// 		return;
			// 	}
			// 	const cachedDrivingSlip = cache.readQuery<GetDrivingSlip, GetDrivingSlipVariables>({
			// 		query: GET_DRIVING_SLIP,
			// 		variables: {
			// 			id: drivingSlip.id,
			// 		},
			// 	});

			// 	if (cachedDrivingSlip !== null && data.deleteItemUsage !== null) {
			// 		cache.writeQuery<GetDrivingSlip, GetDrivingSlipVariables>({
			// 			query: GET_DRIVING_SLIP,
			// 			variables: {
			// 				id: drivingSlip.id,
			// 			},
			// 			data: {
			// 				drivingSlip: {
			// 					...cachedDrivingSlip.drivingSlip,
			// 					case: {
			// 						...cachedDrivingSlip.drivingSlip.case,
			// 						caseDraftItemsUsage: [...data.deleteItemUsage],
			// 					},
			// 				},
			// 			},
			// 		});
			// 	}
			// },
			onOptimisticResponseOverride(cache) {
				const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
					id: 'Case:' + caseId,
					fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
					fragmentName: 'V2DrivingSlipCaseFragment',
				});
				if (oldCase) {
					const itemRemovedArr = oldCase.caseDraftItemsUsage.filter(iu => iu.erpId !== erpId);
					cache.writeFragment<V2DrivingSlipCaseFragment>({
						id: 'Case:' + caseId,
						fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
						fragmentName: 'V2DrivingSlipCaseFragment',
						data: {
							...oldCase,
							caseDraftItemsUsage: [...itemRemovedArr],
						},
					});
				}
			},
		});

		setSelectedItem(undefined);
		setQuantity(1);
	};

	return (
		<CloseableModal title={t('case.waste')} onDismiss={onDismiss}>
			<div>
				<div>
					<label className="text-blue font-semibold">{t('case.caseNo')}:</label>
					<p>{caseNo}</p>

					<div className="mt-4 flex flex-col">
						<label className="text-blue font-semibold">{t('case.waste')}</label>
						<IonSelect
							interface="action-sheet"
							onIonChange={e => setSelectedItem(e.detail.value)}
							value={selectedItem}
							okText={t('common.ok')}
							cancelText={t('common.close')}
							className="border-1 border-gray-400 px-4"
						>
							{itemsToDisplay.map(item => (
								<IonSelectOption key={item.no} value={item.no}>
									{item.description} ({item.baseUOM})
								</IonSelectOption>
							))}
						</IonSelect>
					</div>

					<div className="mt-4 flex flex-col">
						<label className="text-blue font-semibold">{t('common.quantity')}</label>

						<IonInput
							type="number"
							min="0.01"
							onIonChange={e => setQuantity(Number(e.detail.value))}
							value={quantity}
							className={classNames('border-1 px-2', {
								'border-gray-400': quantity > 0,
								'border-red': quantity <= 0,
							})}
						/>
					</div>

					<Button
						color="primary"
						expand="full"
						onClick={async () => {
							if (typeof selectedItem !== 'undefined') {
								const itemToCreate = itemsToDisplay.find(i => i.no === selectedItem);
								if (itemToCreate) {
									const newUsageLine: V2DrivingSlipCaseFragment_caseDraftItemsUsage = {
										date: new Date().toISOString().slice(0, 'yyyy-mm-dd'.length),
										description: itemToCreate.description,
										erpId: generateRandomItemUsageId(),
										erpKey: itemToCreate.no,
										title: itemToCreate.description,
										quantity: quantity,
										postedBy: (userContext.user?.id ?? '').toUpperCase(),
										__typename: 'CaseDraftUsageLine',
									};
									await createItemUsage({
										variables: {
											caseERPReferenceNo: caseNo,
											itemERPReferenceNo: selectedItem,
											itemDescription: itemsToDisplay.find(i => i.no === selectedItem)?.description ?? '',
											jobTaskERPReferenceNo: itemsToDisplay.find(i => i.no === selectedItem)?.jobTask ?? '',
											quantity,
										},
										queueOfflineMutationOptions: {
											key: ApiMutationKey.Case_CreateCaseDraftItemUsage,
											removePreviousEntry: false,
										},
										update: (cache, { data }) => {
											if (typeof data === 'undefined' || data === null || data.createCaseDraftItemUsage === null) {
												return;
											}
											const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
												id: 'Case:' + caseId,
												fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
												fragmentName: 'V2DrivingSlipCaseFragment',
											});

											if (oldCase) {
												cache.writeFragment<V2DrivingSlipCaseFragment>({
													id: 'Case:' + caseId,
													fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
													fragmentName: 'V2DrivingSlipCaseFragment',
													data: {
														...oldCase,
														caseDraftItemsUsage: data.createCaseDraftItemUsage,
													},
												});
											}
										},
										onOptimisticResponseOverride(cache) {
											const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
												id: 'Case:' + caseId,
												fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
												fragmentName: 'V2DrivingSlipCaseFragment',
											});

											if (oldCase) {
												cache.writeFragment<V2DrivingSlipCaseFragment>({
													id: 'Case:' + caseId,
													fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
													fragmentName: 'V2DrivingSlipCaseFragment',
													data: {
														...oldCase,
														caseDraftItemsUsage: [...oldCase.caseDraftItemsUsage, newUsageLine],
													},
												});
											}
										},
									});
								}
								setSelectedItem(undefined);
								setQuantity(1);
							}
						}}
						className="mt-8"
						disabled={typeof selectedItem === 'undefined' || quantity <= 0 || createItemUsageLoading}
						loading={createItemUsageLoading}
					>
						{t('common.confirm')}
					</Button>

					<div className="flex">
						<Checkbox
							name="noWasteUsed"
							defaultChecked={defaultNoWasteUsed}
							title="case.noWasteUsed"
							topDivClassName="my-4"
							onClick={async (e: any) => {
								const checked = e.target.checked;

								await setNoWasteUsed({
									variables: {
										id: drivingSlip.id,
										noWasteUsed: checked,
									},
									optimisticResponse: {
										setDrivingSlipNoWasteUsed: {
											...drivingSlip,
											noWasteUsed: checked,
										},
									},
								});
							}}
						/>
					</div>
				</div>

				{usageLines.length > 0 && (
					<div>
						<Table
							data={usageLines}
							noDataFoundText={t('common.noDataFound')}
							keySelector={iu => iu.erpId}
							columns={[
								{
									label: '',
									selectFn: iu => {
										if (!userContext.user || iu.postedBy.toUpperCase() !== userContext.user.id.toUpperCase()) {
											// Only show delete button if the line is posted by the current user
											return <></>;
										}

										const isDeleting = deleteItemUsageLoading && deletingItemErpIdRef.current === iu.erpId;

										return (
											<TextButton
												icon={isDeleting ? faSpinner : faTrashAlt}
												iconClassName="text-red text-lg"
												loading={isDeleting}
												disabled={iu.erpId === iu.erpKey}
												onClick={async () => {
													await handleDeleteItemUsage(iu.erpId);
												}}
											/>
										);
									},
									align: 'LEFT',
									hideColumn: !isConnected(),
								},
								{
									label: 'case.item',
									selectFn: iu => iu.title,
								},
								{
									label: 'common.quantity',
									selectFn: iu => iu.quantity,
								},
								{
									label: 'common.date',
									selectFn: iu => {
										return (
											<>
												<p>{iu.date}</p>
												<p
													style={{
														fontSize: 'smaller',
													}}
												>
													{iu.postedBy}
												</p>
											</>
										);
									},
									align: 'RIGHT',
								},
							]}
						/>
					</div>
				)}

				<Button color="primary" expand="full" className="mt-10" onClick={() => onDismiss()}>
					{t('case.wasteClose')}
				</Button>
			</div>
		</CloseableModal>
	);
};
export default WasteModal;
