import { BaseQuestionnaireTemplateFragment_sections as Section, ESDHFileFragment } from '../../GraphQL';
import { CompletionEntry } from './CompletionEntry';

import { buildCompletablePathOfChoicesFromSection, choiceIsCompleted, syncedChoiceIsCompleted } from './QuestionnaireChoicesBuilder';

export interface ISyncedChoices {
	id: string;
	driver: string | undefined;
	date: string;
	value: string | null;
	section: string;
	label: string;
}

export const getQuestionnaireCompletionEntriesForSection = (
	section: Section,
	rootIdPrefix: string,
	files: ESDHFileFragment[] | undefined,
	picturesLabel: string,
	validatePhotoRequired: boolean,
	syncedChoices?: ISyncedChoices[],
): CompletionEntry[] => {
	const entries: CompletionEntry[] = [];
	const choicesPath = buildCompletablePathOfChoicesFromSection(rootIdPrefix, section);

	const entry: CompletionEntry = {
		sectionName: section.label,
		sectionCaseAgreementCategory: section.caseAgreementCategory,
		isComplete: true,
		photoRequired: false,
		shouldSaveInScalePoint: section.shouldSaveInScalePoint,
		lastChoiceLabel: '',
		lastChoiceValue: null,
		triggersTrackTwoChange: section.choices
			.filter(c => c.triggersTrackTwoChange === true)
			.map(c => ({ parent: c.parentId, value: c.value }))
			.some(({ parent, value }) => choicesPath.some(c => c.id === parent && c.value === value)),
		choices: choicesPath.map(c => ({
			choiceLabel: c.label,
			choiceValue: c.value,
			choiceUnitOfMeasure: c.unitOfMeasure,
		})),
	};

	for (let index = 0; index < choicesPath.length; index++) {
		const choice = choicesPath[index];
		entry.lastChoiceLabel = choice.label;
		entry.lastChoiceValue = choice.value;

		if (syncedChoices === undefined ? !choiceIsCompleted(choice) : !syncedChoiceIsCompleted(choice, section, syncedChoices)) {
			// Not completed
			entry.isComplete = false;
			break;
		}
	}

	if (validatePhotoRequired && section.photoRequired) {
		if (!files || files.length === 0) {
			entry.isComplete = false;
			entry.photoRequired = true;
			entry.lastChoiceLabel = picturesLabel;
		}
	}

	entries.push(entry);

	return entries;
};
