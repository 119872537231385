import { ApolloError } from '@apollo/client';
import { useIonModal } from '@ionic/react';
import Button from '@ssg/common/Components/Button';
import { BaseMachineFragment } from '@ssg/common/GraphQL';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { loader } from 'graphql.macro';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoveMobileV2Machine, MoveMobileV2MachineVariables, ReserveMachine, ReserveMachineVariables } from 'GQL';
import UserContext from 'UserContext';
import AddMachineToCaseModal, { Props as AddMachineToCaseModalProps } from './Modals/AddMachineToCaseModal';
import ChangeMachineLocationModal, { Props as ChangeMachineLocationModalProps } from './Modals/ChangeMachineLocationModal';
import EducationRequirementsModal, { Props as EducationRequirementsModalProps } from './Modals/EducationRequirementsModal';
import ReserveMachineModal, { Props as ReserveMachineModalProps } from './Modals/ReserveMachineModal';
import StartHibernationModal, { Props as StartHibernationModalProps } from './Modals/StartHibernationModal';
import { useOfflineHandlingMutation } from 'Hooks';
import SetUnavailableModal, { Props as SetUnavailableModalProps } from './Modals/SetUnavailableModal';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const MOVE_MACHINE = loader('src/GQL/Machines/MoveMobileV2Machine.gql');
const RESERVE_MACHINE = loader('src/GQL/Machines/ReserveMachine.gql');

export interface AddMachineToCaseInput {
	consumption: number;
	caseNo: string;
	placementDescription?: string;
}

export interface StartHibernationInput {
	hibernationReason: string;
}

export interface SetUnavailableInput {
	unavailableReason: string;
}

export interface ReserveMachineInput {
	caseNo: string;
	startDate: string;
	endDate: string;
	description: string;
}

export interface ChangeMachineLocationInput {
	consumption: number;
	location: string;
	date: string;
	caseNo: string;
}

interface Props {
	machine: BaseMachineFragment;
	caseNo?: string;
	refetch(): void;
	// Used to limit the buttons available
	drivingslipView?: boolean;
}

const MachineActions: React.FC<Props> = ({ machine, caseNo, refetch, drivingslipView = false }) => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);
	const machineUnavailableReasonFlag = useFlag(FeatureFlagEnums.MACHINE_UNAVAILABLE_REASON);


	const [moveMachineMutation, { loading: movingMachine, error: moveMachineError }] = useOfflineHandlingMutation<MoveMobileV2Machine, MoveMobileV2MachineVariables>(MOVE_MACHINE);

	// Add machine to case
	async function addMachineToCase(input: AddMachineToCaseInput) {
		await moveMachineMutation({
			variables: {
				caseNo: input.caseNo.toUpperCase(),
				machineInput: {
					allocationType: 'Job',
					erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
					erpCaseReference: input.caseNo.toUpperCase(),
					startingDate: dateToDateOnlyString(new Date()),
					startingTime: DateTime.now().toFormat('HH:mm:ss'),
					erpLocationReference: '',
					hibernate: false,
					consumption: input.consumption,
					hibernationReason: '',
					placementDescription: input.placementDescription,
					unavailableReason: machine.unavailableReason,
				},
			},
		});

		refetch();
	}

	const [presentAddMachineToCaseModal, dismissAddMachineToCaseModal] = useIonModal(AddMachineToCaseModal, {
		caseNo,
		lastConsumption: machine.consumption,
		consumptionType: machine.consumptionType,
		onDismiss: () => dismissAddMachineToCaseModal(),
		addMachineToCase: async (input: AddMachineToCaseInput) => {
			await addMachineToCase(input);
			dismissAddMachineToCaseModal();
		},
		loading: movingMachine,
	} as AddMachineToCaseModalProps);

	const [presentEducationRequirementsModal, dismissEducationRequirementsModal] = useIonModal(EducationRequirementsModal, {
		machine,
		onDismiss: () => dismissEducationRequirementsModal(),
		onDecline: () => dismissEducationRequirementsModal(),
		onAccept: () => {
			dismissEducationRequirementsModal();
			presentAddMachineToCaseModal();
		},
	} as EducationRequirementsModalProps);

	// Toggle hibernation
	async function startHibernation(input: StartHibernationInput) {
		if (machine.eRPCaseReference == null) return;

		await moveMachineMutation({
			variables: {
				caseNo: machine.eRPCaseReference.toUpperCase(),
				machineInput: {
					allocationType: 'Job',
					erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
					erpCaseReference: machine.eRPCaseReference ?? '',
					startingDate: dateToDateOnlyString(new Date(machine.dateStart) < new Date() ? new Date() : new Date(machine.dateStart)),
					startingTime: machine.startTime,
					erpLocationReference: machine.eRPLocationReference ?? '',
					hibernate: true,
					consumption: machine.consumption,
					hibernationReason: input.hibernationReason,
					placementDescription: machine.placementDescription,
					unavailableReason: machine.unavailableReason,
				},
			},
		});

		refetch();
	}

	async function endHibernation() {
		if (machine.eRPCaseReference == null) return;

		await moveMachineMutation({
			variables: {
				caseNo: machine.eRPCaseReference.toUpperCase(),
				machineInput: {
					allocationType: 'Job',
					erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
					erpCaseReference: machine.eRPCaseReference ?? '',
					startingDate: dateToDateOnlyString(new Date(machine.dateStart) < new Date() ? new Date() : new Date(machine.dateStart)),
					startingTime: machine.startTime,
					erpLocationReference: machine.eRPLocationReference ?? '',
					hibernate: false,
					consumption: machine.consumption,
					hibernationReason: machine.hibernationReason,
					placementDescription: machine.placementDescription,
					unavailableReason: machine.unavailableReason,
				},
			},
		});

		refetch();
	}

	const [presentStartHibernationModal, dismissStartHibernationModal] = useIonModal(StartHibernationModal, {
		onDissmis: () => dismissStartHibernationModal(),
		startHibernation: async (input: StartHibernationInput) => {
			await startHibernation(input);
			dismissStartHibernationModal();
		},
		loading: movingMachine,
	} as StartHibernationModalProps);

	const [presentSetUnavailableModal, dismissSetUnavailableModal] = useIonModal(SetUnavailableModal, {
		onDissmis: () => dismissSetUnavailableModal(),
		setUnavailable: async (input: SetUnavailableInput) => {
			await markMachineAsUnavailable(input);
			dismissSetUnavailableModal();
		},
		loading: movingMachine,
	} as SetUnavailableModalProps);

	// Reserve machine
	const [reserveMachineMutation] = useOfflineHandlingMutation<ReserveMachine, ReserveMachineVariables>(RESERVE_MACHINE);

	async function reserveMachine(input: ReserveMachineInput) {
		await reserveMachineMutation({
			variables: {
				machineInput: {
					erpReferenceNo: machine.erpReferenceNo,
					reservationOnCase: input.caseNo.toUpperCase(),
					reservationEnd: formatDateForInput(new Date(input.endDate)),
					reservationStart: formatDateForInput(new Date(input.startDate)),
					reservationDescription: input.description,
					erpCaseReference: machine.eRPCaseReference ?? '',
					startingDate: machine.dateStart ?? '',
					startingTime: machine.startTime,
					consumption: machine.consumption ?? 0,
					hibernate: machine.hibernate ?? false,
					hibernationReason: machine.hibernationReason ?? '',
					erpLocationReference: machine.eRPLocationReference ?? '',
					allocationType: machine.type ?? '',
					reservationByRessource: userContext.user?.email ?? '',
					placementDescription: machine.placementDescription,
					unavailableReason: machine.unavailableReason,
				},
			},
		});

		refetch();
	}

	const [presentReserveMachineModal, dismissReserveMachineModal] = useIonModal(ReserveMachineModal, {
		caseNo,
		machine,
		onDismiss: () => dismissReserveMachineModal(),
		reserveMachine: async (input: ReserveMachineInput) => {
			await reserveMachine(input);
			dismissReserveMachineModal();
		},
		loading: movingMachine,
	} as ReserveMachineModalProps);

	// Change machine location
	async function changeMachineLocation(input: ChangeMachineLocationInput) {
		await moveMachineMutation({
			variables: {
				caseNo: machine.eRPCaseReference ?? '',
				machineInput: {
					allocationType: 'Job',
					erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
					erpCaseReference: '',
					startingDate: dateToDateOnlyString(new Date()),
					startingTime: DateTime.now().toFormat('HH:mm:ss'),
					erpLocationReference: input.location,
					hibernate: false,
					consumption: Number(input.consumption),
					hibernationReason: machine.hibernationReason,
					placementDescription: '',
					unavailableReason: machine.unavailableReason,
				},
			},
		});

		refetch();
	}

	const [presentChangeMachineLocationModal, dismissChangeMachineLocationModal] = useIonModal(ChangeMachineLocationModal, {
		caseNo,
		lastConsumption: machine.consumption,
		consumptionType: machine.consumptionType,
		onDismiss: () => dismissChangeMachineLocationModal(),
		changeMachineLocation: async (input: ChangeMachineLocationInput) => {
			await changeMachineLocation(input);
			dismissChangeMachineLocationModal();
		},
		loading: movingMachine,
	} as ChangeMachineLocationModalProps);

	// Mark machine as unavailable
	async function markMachineAsUnavailable(input: SetUnavailableInput) {
		await moveMachineMutation({
			variables: {
				caseNo: machine.eRPCaseReference ?? '',
				machineInput: {
					allocationType: 'Location',
					erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
					erpCaseReference: '',
					startingDate: dateToDateOnlyString(new Date()),
					startingTime: machine.startTime,
					erpLocationReference: 'INDISP',
					hibernate: machine.eRPLocationReference ? false : machine.hibernate ?? false,
					consumption: Number(machine.consumption),
					hibernationReason: machine.hibernationReason ?? '',
					unavailableReason: input.unavailableReason,
				},
			},
		});

		refetch();
	}

	return (
		<>
			{moveMachineError instanceof ApolloError && <p>{moveMachineError.message}</p>}

			{drivingslipView || (
				<Button primary loading={movingMachine} onClick={() => presentReserveMachineModal()}>
					{t('machines.reserveMachine')}
				</Button>
			)}

			{
				<Button primary loading={movingMachine} onClick={() => presentChangeMachineLocationModal()}>
					{t('machines.changeLocation')}
				</Button>
			}

			<Button
				primary
				loading={movingMachine}
				onClick={() => (machine.educationPrerequisit ? presentEducationRequirementsModal() : presentAddMachineToCaseModal())}
				disabled={machine.eRPCaseReference !== null || machine.eRPLocationReference === 'INDISP'}
			>
				{t('machines.addToCase')}
			</Button>
			{machine.eRPCaseReference !== null && machine.eRPLocationReference !== 'INDISP' && <p className="h-5 font-semibold">{t('machines.unavailable')}</p>}
			{machine.eRPLocationReference === 'INDISP' && <p className="h-5 font-semibold">{t('machines.unavailable')}</p>}

			<Button primary loading={movingMachine} disabled={machine.eRPCaseReference === null} onClick={() => (machine.hibernate ? endHibernation() : presentStartHibernationModal())}>
				{machine.hibernate ? t('machines.endHibernation') : t('machines.startHibernation')}
			</Button>
			{machine.eRPCaseReference === null && <p className="h-5 font-semibold">{machine.eRPCaseReference === null && t('machines.notOnCase')}</p>}

			{drivingslipView || (
				<Button primary loading={movingMachine} onClick={() => machineUnavailableReasonFlag ? presentSetUnavailableModal() : markMachineAsUnavailable({ unavailableReason: '' })}>
					{t('machines.setUnavailable')}
				</Button>
			)}
		</>
	);
};

export default MachineActions;
