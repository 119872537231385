import React from 'react';
import { IonAccordion, IonAccordionGroup, IonCol, IonItem, IonLabel, useIonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { faCommentAltEdit, faCommentAltPlus } from '@fortawesome/pro-regular-svg-icons';
import { GetDrivingSlipFiles_drivingSlipFiles } from 'GQL';
import ChangeCommentModal, { Props as ChangeCommentModalProps } from './ChangeCommentModal';
import classnames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Button from 'Components/Button';
import useDrivingSlipImages, { DrivingSlipFile } from '../useDrivingSlipImages';
import useImageModal from '../useImageModal';
import TextButton from '@ssg/common/Components/TextButton';
import { CameraSource } from '@capacitor/camera';

interface Props {
	label: string;
	onImageSelect(image: DrivingSlipFile): void;
	selectedImages: DrivingSlipFile[];
	caseNo: string;
	drivingSlipId: string;
}

const DrivingSlipMultiImageSelect: React.FC<Props> = ({ caseNo, drivingSlipId, onImageSelect, selectedImages, label }) => {
	const { t } = useTranslation();

	const { images, isUploading, upload, changeComment } = useDrivingSlipImages(caseNo, drivingSlipId);

	const presentPhotoModal = useImageModal(onImageSelect, upload);

	const [accordion, setAccordion] = React.useState<string | string[] | null | undefined>(['images']);

	const [selectedImage, setSelectedImage] = React.useState<GetDrivingSlipFiles_drivingSlipFiles | undefined>();

	const [presentChangeCommentModal, dismissChangeCommentModal] = useIonModal(ChangeCommentModal, {
		onDismiss: () => {
			dismissChangeCommentModal();
			setSelectedImage(undefined);
		},
		fileId: selectedImage?.fileId ?? '',
		fileName: selectedImage?.name ?? '',
		changeComment,
		oldComment: selectedImage?.comments ?? '',
	} as ChangeCommentModalProps);

	React.useEffect(() => {
		if (typeof selectedImage !== 'undefined') {
			presentChangeCommentModal();
		}
	}, [presentChangeCommentModal, selectedImage]);

	return (
		<>
			<IonCol size="12">
				<IonAccordionGroup value={accordion} onIonChange={value => setAccordion(value.detail.value)}>
					<IonAccordion value="images">
						<IonItem slot="header" color="light">
							<IonLabel className="text-blue text-base font-semibold">{label} ({t('reports.imagesSelected', { count: selectedImages.length })})</IonLabel>
						</IonItem>
						<div className="pt-2" slot="content">
							<Button
								expand="block"
								loading={isUploading}
								disabled={isUploading}
								onClick={async () => {
									presentPhotoModal({ photoSource: CameraSource.Camera });
								}}
							>
								{t('reports.takePhoto')}
							</Button>
							<Button
								expand="block"
								loading={isUploading}
								disabled={isUploading}
								onClick={async () => {
									presentPhotoModal();
								}}
							>
								{t('reports.uploadImages')}
							</Button>
							{images.map(image => (
								<div key={image.url} className="inline-block w-1/3 p-2" onClick={() => onImageSelect(image)}>
									<div
										className={classnames('flex flex-col items-center border-2 border-transparent', {
											'border-red-calm': selectedImages.some(img => img.url === image.url),
										})}
									>
										<img src={image.thumbnail} className="h-72 w-72 object-cover" alt={image.name} />
										<p className="text-xs">{dateToDateTimeString(image.created)}</p>
										{image.comments ? (
											<div className="m-2 flex h-12 items-center rounded-md p-2">
												<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
													<TextButton
														iconClassName="text-4xl"
														icon={faCommentAltEdit}
														className="text-blue tex cursor-pointer"
														onClick={e => {
															e.stopPropagation();
															setSelectedImage(image);
														}}
													/>
												</div>
												<div>
													<p className="text-blue font-bold">{image.comments}</p>
												</div>
											</div>
										) : (
											<div className="m-2 flex h-12 items-center rounded-md p-2">
												<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
													<TextButton
														iconClassName="text-4xl"
														icon={faCommentAltPlus}
														className="text-blue tex cursor-pointer"
														onClick={e => {
															e.stopPropagation();
															setSelectedImage(image);
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</IonAccordion>
				</IonAccordionGroup>
			</IonCol>
		</>
	);
};

export default DrivingSlipMultiImageSelect;
