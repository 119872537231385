import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonRefresher, IonRefresherContent, IonAlert, IonToast } from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import classNames from 'classnames';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import Alert from '@ssg/common/Components/Alert';
import NetworkContext from 'NetworkContext';

interface Props {
	title?: string;
	backButtonDefaultHref?: string;
	refreshFunc?: () => Promise<unknown>;
	contentClass?: string;
	children: ReactNode;
	shouldOfflineWarn?: boolean;
	offlineMessage?: string;
}

const BasePage: React.FC<Props> = ({ title, backButtonDefaultHref, refreshFunc, contentClass, children, shouldOfflineWarn = false, offlineMessage }): React.ReactElement => {
	const { t } = useTranslation();
	const { connected } = React.useContext(NetworkContext);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text={t('common.goBack')} defaultHref={backButtonDefaultHref} />
					</IonButtons>
					{title && <IonTitle>{title}</IonTitle>}
				</IonToolbar>
			</IonHeader>
			<IonContent className={classNames(contentClass, 'ion-padding')}>
				{refreshFunc && (
					<IonRefresher
						slot="fixed"
						onIonRefresh={async event => {
							await refreshFunc();
							event.detail.complete();
						}}
					>
						<IonRefresherContent pullingIcon={chevronDownCircleOutline} refreshingSpinner="circles"></IonRefresherContent>
					</IonRefresher>
				)}
				{shouldOfflineWarn && !connected && <Alert warning text={t(offlineMessage ?? 'backgroundSync.offlineDisable', { page: title })} icon={faExclamationCircle} />}
				{children}
			</IonContent>
		</IonPage>
	);
};

export default BasePage;
