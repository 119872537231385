import React, { useContext } from 'react';
import Button from '@ssg/common/Components/Button';
import { useTranslation } from 'react-i18next';
import { IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

interface Props {
	visible: boolean;
	close: () => void;
}

const RejectedTimeRegistrationWarningModal: React.FC<Props> = ({ visible, close }) => {
	const { siteUrlWeb } = useContext(EnvironmentVariableContext);
	const { t } = useTranslation();

	return (
		<>
			<IonModal isOpen={visible}>
				<IonHeader>
					<IonToolbar>
						<IonTitle className="md pt-4 pb-2 text-center">{t('timeRegistration.rejectedTimeRegistrations')}</IonTitle>
						<IonButtons slot="end">
							<Button text={t('common.close')} onClick={() => close()} />
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<p>{t('timeRegistration.rejectedTimeRegistrationWarningModal.pendingRejectedReigstrations')}</p>

					<div className="mt-2 flex justify-between">
						<Button
							primary
							text="timeRegistration.rejectedTimeRegistrationWarningModal.openWebApplication"
							onClick={() => {
								window.open(`${siteUrlWeb}timeregistration?=rejectedhours`);
							}}
						/>
						<Button
							primary
							text="Ok"
							onClick={() => {
								close();
							}}
						/>
					</div>
				</IonContent>
			</IonModal>
		</>
	);
};

export default RejectedTimeRegistrationWarningModal;
