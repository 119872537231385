import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import FormFieldHeader from './FormFieldHeader';
import { SelectOption } from '../Helpers/Helpers';
import Popover from './Popover';

interface RadioOption extends SelectOption {
	children?: React.ReactNode;
	labelInfoPopover?: React.ReactElement;
}

interface Props {
	className?: string;
	text?: string | JSX.Element;
	innerRef?: React.Ref<HTMLInputElement>;
	name: string;
	options: RadioOption[];
	errorMessage?: string;
	title: string;
	required?: boolean;
	helpText?: string;
	showHelpText?: boolean;
	dValue?: string;
	disabled?: boolean;
	labelWeight?: 'NONE' | 'BOLD';
}

const Radio: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	text,
	innerRef,
	name,
	options,
	errorMessage,
	title,
	required = false,
	disabled = false,
	helpText,
	showHelpText,
	dValue,
	labelWeight = 'BOLD',
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div>
			{title.length > 0 && <FormFieldHeader required={required} title={title} />}
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<div className="flex">
				<div
					className={classNames({
						'border-red -ml-1 border-2 px-1': errorMessage,
					})}
				>
					{options.map((o, i) => (
						<React.Fragment key={i}>
							<div key={i} className={classNames('flex items-center py-1', className)}>
								<input
									type="radio"
									ref={innerRef}
									name={name}
									value={o.value}
									id={o.label?.replace(/\s/g, '-') + name}
									disabled={disabled}
									className={classNames('text-blue ring-transparent focus:outline-none', {
										'cursor-not-allowed bg-gray-300': disabled,
										'cursor-pointer': !disabled,
									})}
									defaultChecked={o.value === dValue}
									{...restProps}
								/>

								<label
									htmlFor={o.label?.replace(/\s/g, '-') + name}
									className={classNames('text-blue pl-2', {
										'cursor-pointer': !disabled,
										'font-semibold': labelWeight === 'BOLD',
									})}
								>
									{t(o.label)}
								</label>

								{typeof o.labelInfoPopover !== 'undefined' && (
									<Popover
										hideArrow
										placement="top"
										content={<div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">{o.labelInfoPopover}</div>}
									>
										<span>
											<FontAwesomeIcon icon={faInfoCircle} className="text-blue ml-1" />
										</span>
									</Popover>
								)}
							</div>

							{o.children}
						</React.Fragment>
					))}
				</div>
			</div>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</div>
	);
};
export default Radio;
