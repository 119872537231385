import { IonCol, IonLabel } from '@ionic/react';
import React from 'react';

interface Props {
	label: string;
	value: string | undefined;
}

const ReadonlyText: React.FC<Props> = ({ value, label }) => (
	<IonCol size="12">
		<IonLabel className="text-blue text-base font-semibold">{label}</IonLabel>
		<p>{value}</p>
	</IonCol>
);

export default ReadonlyText;
