import { IonCol, IonLabel } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoistureReportObservationsInput } from 'GQL';
import TextAreaInput from './TextAreaInput';

interface Props {
	observations: MoistureReportObservationsInput;
	onChange(observations: MoistureReportObservationsInput): void;
}

const MoistureReportObservationsFrom: React.FC<Props> = ({ observations, onChange }) => {
	const { t } = useTranslation();

	function handleChange(updateFn: (copy: MoistureReportObservationsInput) => void) {
		const copy = { ...observations };
		updateFn(copy);
		onChange(copy);
	}

	return (
		<>
			{/* <TextAreaInput
                label={t('reports.moistureReportObservations.roomOrApartment')}
                placeholder={t('reports.moistureReportObservations.roomOrApartmentPlaceholder')}
                value={observations.roomOrApartment!}
                onChange={value => handleChange(copy => (copy.roomOrApartment = value))}
            /> */}

			<IonCol size="12">
				<IonLabel className="text-blue text-base font-semibold">{t('reports.moistureReportObservations.construction')}</IonLabel>
			</IonCol>

			{/* <TextInput
                label={t('reports.moistureReportObservations.construction')}
                placeholder={t('reports.moistureReportObservations.constructionPlaceholder')}
                value={observations.construction}
                onChange={value => handleChange(copy => (copy.construction = value))}
            /> */}

			<TextAreaInput
				label={t('reports.moistureReportObservations.ceiling')}
				placeholder={t('reports.moistureReportObservations.ceilingPlaceholder')}
				value={observations.ceiling || ''}
				onChange={value => handleChange(copy => (copy.ceiling = value))}
			/>

			<TextAreaInput
				label={t('reports.moistureReportObservations.walls')}
				placeholder={t('reports.moistureReportObservations.wallsPlaceholder')}
				value={observations.walls || ''}
				onChange={value => handleChange(copy => (copy.walls = value))}
			/>

			<TextAreaInput
				label={t('reports.moistureReportObservations.floor')}
				placeholder={t('reports.moistureReportObservations.floorPlaceholder')}
				value={observations.floor || ''}
				onChange={value => handleChange(copy => (copy.floor = value))}
			/>

			<TextAreaInput
				label={t('reports.moistureReportObservations.scope')}
				placeholder={t('reports.moistureReportObservations.scopePlaceholder')}
				value={observations.scope}
				onChange={value => handleChange(copy => (copy.scope = value))}
			/>

			<TextAreaInput
				label={t('reports.moistureReportObservations.recommendations')}
				placeholder={t('reports.moistureReportObservations.recommendationsPlaceholder')}
				value={observations.recommendations}
				onChange={value => handleChange(copy => (copy.recommendations = value))}
			/>
		</>
	);
};

export default MoistureReportObservationsFrom;
