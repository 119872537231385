import React from 'react';
import classNames from 'classnames';

import FullyCenteredDiv from './FullyCenteredDiv';

interface Props {
	textAlignment?: string;
	textSizeClass?: string;
	textColorClass?: string;
}

const FullyCenteredDisplayText: React.FC<Props> = ({ children, textSizeClass = 'text-lg', textColorClass = 'text-blue', textAlignment = 'text-center' }): React.ReactElement => (
	<FullyCenteredDiv>
		<p className={classNames(textSizeClass, textColorClass, textAlignment)}>{children}</p>
	</FullyCenteredDiv>
);

export default FullyCenteredDisplayText;
