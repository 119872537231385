import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonButtons, IonContent, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { arrowUndoCircle, arrowRedoCircle, brush, colorWand, closeCircle } from 'ionicons/icons';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';
import { UserPhoto, getBase64EncodedData } from 'Hooks/usePhotoCapture';
import { resizeImage } from 'Helpers/resizeImage';
import Button from './Button';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getImageFormatToExportAs = (format: string): 'png' | 'jpeg' => (format === 'png' ? format : 'jpeg');

export interface Props {
	initialPhoto: UserPhoto;
	onSubmit: (photo: UserPhoto, photoHasChanged: boolean, comments?: string) => Promise<void>;
	onDismiss: () => void;
}
const b64toUrl = (b64Data: string, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(getBase64EncodedData(b64Data));
	const byteArrays: Uint8Array[] = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });

	const url = URL.createObjectURL(blob);
	return url;
};

const ImageEditModal: React.FC<Props> = ({ initialPhoto, onSubmit, onDismiss }) => {
	const { t } = useTranslation();

	const photoEditorRef = React.createRef<ReactSketchCanvasRef>();
	const backgroundImgRef = React.useRef<HTMLImageElement>(null);

	const [photo] = React.useState<UserPhoto>(initialPhoto);
	const [comments, setComments] = React.useState<string | undefined>(initialPhoto?.metadata?.comments);

	const [resizedPhoto, setResizedPhoto] = React.useState<string>();
	const [isDirty, setIsDirty] = React.useState<boolean>(false);
	const [isEraseMode, setIsEraseMode] = React.useState<boolean>(false);
	const [strokeColor, setStrokeColor] = React.useState<string>('#ff0000');

	const [height, setHeight] = React.useState<number>();
	const [width, setWidth] = React.useState<number>();

	React.useEffect(() => {
		if (photoEditorRef.current) {
			photoEditorRef.current?.eraseMode(isEraseMode);
		}
	}, [photoEditorRef, isEraseMode]);

	React.useEffect(() => {
		if (backgroundImgRef.current && photo.webviewPath.includes('blob')) {
			// Without setTimeout the width/height is sometimes 0
			setTimeout(() => {
				if (backgroundImgRef.current == null) return;
				const { offsetHeight, offsetWidth } = backgroundImgRef.current;

				setWidth(offsetWidth);
				setHeight(offsetHeight);

				resizeImage(photo.webviewPath, getImageFormatToExportAs(photo.format), offsetWidth, offsetHeight).then(setResizedPhoto);
			}, 500);
		}
	}, [photo.webviewPath, photo.format]);

	const handleConfirm = async () => {
		if (isDirty && photoEditorRef.current) {
			const format = getImageFormatToExportAs(photo.format);
			const base64 = await photoEditorRef.current.exportImage(format);
			const webView = b64toUrl(base64);
			onSubmit({ ...photo, base64EncodedData: base64, webviewPath: webView }, true, comments);
			return;
		}

		onSubmit(photo, false, comments);
	};

	return (
		<>
			<IonToolbar color="primary">
				<IonTitle>{t('common.uploadPictures')}</IonTitle>
				<IonButtons slot="end">
					<Button onClick={() => onDismiss()}>
						<IonIcon name="close" slot="icon-only"></IonIcon>
					</Button>
				</IonButtons>
			</IonToolbar>
			<IonContent className="ion-padding">
				<div className="flex h-full flex-col">
					<Button color="primary" expand="block" onClick={handleConfirm}>
						<FontAwesomeIcon icon={faCheck} className="mr-2" />
						{t('common.confirm')}
					</Button>

					<hr className="my-2" />

					<IonToolbar>
						<IonButtons>
							<div className="border-r-1 border-solid border-gray-500">
								<Button onClick={() => photoEditorRef.current?.undo()} title={t('common.undo')}>
									<IonIcon slot="icon-only" icon={arrowUndoCircle} />
								</Button>
								<Button onClick={() => photoEditorRef.current?.redo()} title={t('common.redo')}>
									<IonIcon slot="icon-only" icon={arrowRedoCircle} />
								</Button>
							</div>
							<div className="border-r-1 border-solid border-gray-500">
								<input type="color" value={strokeColor} onChange={e => setStrokeColor(e.target.value)} className="mx-2 align-middle" title={t('common.color')} />
							</div>
							<div className="border-r-1 border-solid border-gray-500">
								<IonSegment scrollable onIonChange={e => setIsEraseMode(e.detail.value === 'true')} value={isEraseMode.toString()} className="mx-2">
									<IonSegmentButton value="false" title={t('common.pen')}>
										<IonIcon icon={brush} />
									</IonSegmentButton>
									<IonSegmentButton value="true" title={t('common.eraser')}>
										<IonIcon icon={colorWand} />
									</IonSegmentButton>
								</IonSegment>
							</div>
							<Button onClick={() => photoEditorRef.current?.clearCanvas()} title={t('common.clear')}>
								<IonIcon slot="icon-only" icon={closeCircle} />
							</Button>
						</IonButtons>
					</IonToolbar>

					<div className="my-2 flex flex-1 justify-center" style={{ maxHeight: '60%' }}>
						{photo.webviewPath.includes('blob') && <img ref={backgroundImgRef} src={photo.webviewPath} className="invisible text-center" alt="" />}
						{width && height && resizedPhoto && (
							<ReactSketchCanvas
								ref={photoEditorRef}
								style={{ marginLeft: -width }} // Remove default styles
								strokeWidth={4}
								strokeColor={strokeColor}
								eraserWidth={20}
								backgroundImage={resizedPhoto}
								exportWithBackgroundImage
								onStroke={() => setIsDirty(true)}
								height={`${height}px`}
								width={`${width}px`}
							/>
						)}
						{!photo.webviewPath.includes('blob') && <p>Billedet kan ikke hentes fra SharePoint</p>}
					</div>

					<hr className="my-2" />

					<IonLabel className="text-blue font-semibold">{t('common.comments')}</IonLabel>
					<IonTextarea placeholder={t('common.insertOptionalComments')} onIonChange={e => setComments(e.detail.value ?? undefined)} value={comments} />
				</div>
			</IonContent>
		</>
	);
};

export default ImageEditModal;
