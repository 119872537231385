import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconDefinition, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
	className?: string;
	text?: string;
	tooltip?: string;
	icon?: IconDefinition;
	link?: boolean;
	iconSize?: SizeProp;
	iconClassName?: string;
	textClassName?: string;
	disabled?: boolean;
	innerRef?: React.LegacyRef<HTMLButtonElement>;
	loading?: boolean;
}

const TextButton: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = ({
	className,
	tooltip,
	text,
	icon,
	iconClassName,
	link = false,
	disabled = false,
	loading = false,
	innerRef,
	iconSize,
	textClassName,

	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<button
			ref={innerRef}
			type="button"
			{...restProps}
			className={classNames('text-hover-blue flex items-center justify-center text-sm font-medium focus:outline-none', className, {
				underline: link,
				'text-blue': !disabled,
				'cursor-not-allowed text-gray-500 opacity-50': disabled,
				'cursor-wait': loading,
			})}
			disabled={disabled || loading}
			title={tooltip ? t(tooltip) : undefined}
		>
			<>
				{icon && (
					<div className={classNames('flex w-6 justify-center', iconClassName)}>
						{loading ? <FontAwesomeIcon className="animate-spin" icon={faSpinner} size={iconSize} /> : <FontAwesomeIcon icon={icon} size={iconSize} />}
					</div>
				)}
				{text && <div className={classNames('whitespace-nowrap', { 'ml-1': icon }, textClassName)}>{t(text)}</div>}
			</>
		</button>
	);
};

export default TextButton;
