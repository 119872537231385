import React from 'react';
import classnames from 'classnames';
import Button from './Button';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle as faQuistionCircleFull } from '@fortawesome/pro-solid-svg-icons';

export enum ModalSize {
	SMALL,
	MEDIUM,
	LARGE,
	XLARGE,
}

interface Props {
	visible: boolean;
	size?: ModalSize;
	close: () => unknown;
	nestedModal?: boolean;

	helpButtonClick?(): void;
	showHelpText?: boolean;
	helpButton?: boolean;

	title: string;
	body: React.ReactElement | null;
	footer?: React.ReactElement;
}

const AutoFocusDiv: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ children, ...props }) => {
	const ref = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		ref.current?.focus();
	}, []);

	return (
		<div ref={ref} {...props}>
			{children}
		</div>
	);
};

const Modal: React.FC<Props> = ({ visible, size = ModalSize.MEDIUM, close, nestedModal = false, helpButtonClick, showHelpText, helpButton, title, body, footer }) => {
	const { t } = useTranslation();
	const zIndex = React.useMemo(() => (nestedModal ? 5100 : 5000), [nestedModal]);

	return ReactDOM.createPortal(
		<div
			onClick={clickEvent => clickEvent.stopPropagation()}
			onDoubleClick={clickEvent => clickEvent.stopPropagation()}
			className={classnames('fixed top-0 left-0 flex h-full w-full items-center justify-center py-8 transition-opacity duration-200', {
				'pointer-events-none opacity-0': !visible,
			})}
			style={{ zIndex: visible ? zIndex : undefined }}
		>
			<div className="absolute h-full w-full bg-gray-800 opacity-50 hover:text-gray-900" />

			{visible && (
				<AutoFocusDiv
					onKeyUp={e => {
						if (e.keyCode === 27) {
							// Esc key
							close();
						}
					}}
					className={classnames('mx-auto flex w-11/12 flex-col rounded bg-white shadow-lg outline-none md:max-h-full', {
						'md:max-w-sm': size === ModalSize.SMALL,
						'md:max-w-3xl': size === ModalSize.MEDIUM,
						'md:max-w-6xl': size === ModalSize.LARGE,
						'md:max-w-8xl': size === ModalSize.XLARGE,
					})}
					style={{ zIndex }}
				>
					<div className="flex items-center justify-between px-4 pt-3 pb-1">
						<div className="flex flex-row items-center">
							<span className="text-blue select-none text-lg font-semibold">{t(title)}</span>
							{helpButton && (
								<div onClick={helpButtonClick} className="text-blue flex h-8 w-8 cursor-pointer items-center justify-center">
									{showHelpText ? <FontAwesomeIcon icon={faQuistionCircleFull} size="lg" /> : <FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
								</div>
							)}
						</div>
						<Button onClick={close} icon={faTimes} className="text-blue" />
					</div>

					<div className="overflow-y-auto px-4">{body}</div>

					<div className="flex space-x-4 px-4 py-3">{footer}</div>
				</AutoFocusDiv>
			)}
		</div>,
		document.body,
	);
};

export default Modal;
