import React from 'react';
import { useTranslation } from 'react-i18next';
import BasePage from 'Components/Layout/BasePage';
import Section from 'Components/Section';
import ActiveMachines from './ActiveMachines';
import FindMachine from '../../../Machines/FindMachine';
import SelectedMachine from '../../../Machines/SelectedMachine';

interface Props {
	caseNo: string;
}

const DrivingSlipMachineTab: React.FC<Props> = ({ caseNo }) => {
	const { t } = useTranslation();

	const [selectedMachineNo, setSelectedMachineNo] = React.useState<string>();

	return (
		<BasePage title={t('machines.overviewTitle')} shouldOfflineWarn>
			<Section title={t('machines.activeMachines')} className="flex flex-col gap-2">
				<div>
					<strong>{t('case.caseNo')}</strong>
					<p>{caseNo}</p>
				</div>

				<ActiveMachines caseNo={caseNo} />

				<FindMachine onFind={nr => setSelectedMachineNo(nr)} />

				{typeof selectedMachineNo === 'string' && <SelectedMachine drivingslipView caseNo={caseNo} machineNo={selectedMachineNo} />}
			</Section>
		</BasePage>
	);
};

export default DrivingSlipMachineTab;
