import * as yup from 'yup';
import { SupplementType, TimeTrackingInputType } from 'GQL';

yup.setLocale({
	mixed: {
		required: 'error.required',
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
		positive: 'error.positive',
	},
	boolean: {
		isValue: 'error.required',
	},
});

const addonLines = yup.object({
	paymentSupplementCode: yup.string().required(),

	paymentSupplementWorkTypeCode: yup.string(),

	paymentSupplementName: yup.string(),

	supplementType: yup.string(),

	supplementUom: yup.string(),

	hours: yup.number().positive().required(),
});

export const TimeRegistrationSchema = yup.object().shape({
	substitute: yup.boolean(),

	user: yup.string().when('substitute', {
		is: (substitute: boolean) => substitute,
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	date: yup.date().required(),

	task: yup.string().required(),

	case: yup.string().when('task', {
		is: (task: string) => task !== 'INTERN' && task !== '',
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	drivingSlip: yup.string().when('task', {
		is: (task: string) => task === 'DRIVINGSLIP',
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	workTypeCode: yup.string().required(),

	jobTaskName: yup.string(),

	jobTaskNo: yup.string().required(),

	remark: yup.string(),

	hours: yup.number().when('addonLines', {
		is: (addonLines: TimeTrackingInputType[]) => !addonLines || addonLines.some(l => l.supplementType === SupplementType.SURCHARGE),
		then: yup.number().positive().required(),
		otherwise: yup
			.number()
			.nullable(true)
			.transform((_, val) => (val ? Number(val) : 0)),
	}),

	addSupplement: yup.boolean(),

	addonLines: yup.array().when('addSupplement', {
		is: (addSupplement: boolean) => addSupplement,
		then: yup.array().of(addonLines).required(),
		otherwise: yup.array(),
	}),
});

