import { createSlice } from '@reduxjs/toolkit';
import {
	GetMobileV2CaseAdminData,
	GetCollectionItems,
	GetAllDebitors,
	GetMobileV2DrivingSlips,
	GetMobileV2DrivingSlipsVariables,
	GetRiskEvaluationCategories,
	GetRiskEvaluationQuestions,
	GetMobileV2DrivingSlip,
	GetMobileV2DrivingSlipVariables,
	GetQuestionnaireTemplatesByDrivingSlips,
	GetQuestionnaireTemplatesByDrivingSlipsVariables,
	GetVehicles,
	GetVehiclesVariables,
} from 'GQL';
import { createEmptyData } from './helper';
import { OperationVariables } from '@apollo/client';
import { ApiQueryKey, Data } from './types';

export const API_DATA_KEY = 'apiData';

export type CaseAdminDataQuery = GetMobileV2CaseAdminData;
export type CaseAdminData = CaseAdminDataQuery;
export type CaseAdminDataQueryVariables = OperationVariables;

export type CollectionItemsQuery = GetCollectionItems;
export type CollectionItems = CollectionItemsQuery['collectionItems'];
export type CollectionItemsQueryVariables = OperationVariables;

export type DebitorsQuery = GetAllDebitors;
export type Debitors = DebitorsQuery['allDebitors'];
export type DebitorsQueryVariables = OperationVariables;

export type DrivingSlipQuery = GetMobileV2DrivingSlip;
export type DrivingSlip = DrivingSlipQuery['drivingSlip'];
export type DrivingSlipQueryVariables = GetMobileV2DrivingSlipVariables;
export type DrivingSlipOrNull = DrivingSlip | null;

export type DrivingSlipsQuery = GetMobileV2DrivingSlips;
export type DrivingSlips = DrivingSlipsQuery['drivingSlips'];
export type DrivingSlipsQueryVariables = GetMobileV2DrivingSlipsVariables;

export type RiskEvaluationCategoriesQuery = GetRiskEvaluationCategories;
export type RiskEvaluationCategories = RiskEvaluationCategoriesQuery['riskEvaluationCategories'];
export type RiskEvaluationCategoriesQueryVariables = OperationVariables;

export type RiskEvaluationQuestionsQuery = GetRiskEvaluationQuestions;
export type RiskEvaluationQuestions = RiskEvaluationQuestionsQuery['riskEvaluationQuestions'];
export type RiskEvaluationQuestionsQueryVariables = OperationVariables;

export type QuestionnaireTemplateByDrivingSlipQuery = GetQuestionnaireTemplatesByDrivingSlips;
export type QuestionnaireTemplateByDrivingSlip = QuestionnaireTemplateByDrivingSlipQuery['questionnaireTemplatesByDrivingSlips'];
export type QuestionnaireTemplateByDrivingSlipQueryVariables = GetQuestionnaireTemplatesByDrivingSlipsVariables;

export type CarsQuery = GetVehicles;
export type Cars = CarsQuery['vehicles'];
export type CarsQueryVariables = GetVehiclesVariables;

interface ApiDataState {
	[ApiQueryKey.CaseAdminData]: Data<CaseAdminDataQuery, CaseAdminData, CaseAdminDataQueryVariables>;
	[ApiQueryKey.CollectionItems]: Data<CollectionItemsQuery, CollectionItems, CollectionItemsQueryVariables>;
	[ApiQueryKey.Debitors]: Data<DebitorsQuery, Debitors, DebitorsQueryVariables>;
	[ApiQueryKey.DrivingSlip]: Data<DrivingSlipQuery, DrivingSlipOrNull, DrivingSlipQueryVariables>;
	[ApiQueryKey.DrivingSlips]: Data<DrivingSlipsQuery, DrivingSlips, DrivingSlipsQueryVariables>;
	[ApiQueryKey.RiskEvaluationCategories]: Data<RiskEvaluationCategoriesQuery, RiskEvaluationCategories, RiskEvaluationCategoriesQueryVariables>;
	[ApiQueryKey.RiskEvaluationQuestions]: Data<RiskEvaluationQuestionsQuery, RiskEvaluationQuestions, RiskEvaluationQuestionsQueryVariables>;
	[ApiQueryKey.QuestionnaireTemplatesByDrivingSlips]: Data<QuestionnaireTemplateByDrivingSlipQuery, QuestionnaireTemplateByDrivingSlip, QuestionnaireTemplateByDrivingSlipQueryVariables>;
	[ApiQueryKey.Cars]: Data<CarsQuery, Cars, CarsQueryVariables>;
}

const initialState: ApiDataState = {
	caseAdminData: createEmptyData<CaseAdminDataQuery, CaseAdminData, CaseAdminDataQueryVariables>({
		damageCategories: [],
		damageCauses: [],
		departments: [],
		locations: [],
		drivingSlipCategories: [],
		drivingSlipItems: [],
	}),
	collectionItems: createEmptyData<CollectionItemsQuery, CollectionItems, CollectionItemsQueryVariables>([]),
	debitors: createEmptyData<DebitorsQuery, Debitors, DebitorsQueryVariables>([]),
	drivingSlip: createEmptyData<DrivingSlipQuery, DrivingSlipOrNull, DrivingSlipQueryVariables>(null),
	drivingSlips: createEmptyData<DrivingSlipsQuery, DrivingSlips, DrivingSlipsQueryVariables>([]),
	riskEvaluationCategories: createEmptyData<RiskEvaluationCategoriesQuery, RiskEvaluationCategories, RiskEvaluationCategoriesQueryVariables>([]),
	riskEvaluationQuestions: createEmptyData<RiskEvaluationQuestionsQuery, RiskEvaluationQuestions, RiskEvaluationQuestionsQueryVariables>([]),
	questionnaireTemplatesByDrivingSlips: createEmptyData<QuestionnaireTemplateByDrivingSlipQuery, QuestionnaireTemplateByDrivingSlip, QuestionnaireTemplateByDrivingSlipQueryVariables>([]),
	cars: createEmptyData<CarsQuery, Cars, CarsQueryVariables>([]),
};

const apiDataSlice = createSlice({
	name: API_DATA_KEY,
	initialState,
	reducers: {
		setCaseAdminData: (
			state,
			action: {
				payload: Partial<ApiDataState['caseAdminData']>;
			},
		) => {
			state.caseAdminData = { ...state.caseAdminData, ...action.payload };
		},
		setCollectionItems: (
			state,
			action: {
				payload: Partial<ApiDataState['collectionItems']>;
			},
		) => {
			state.collectionItems = {
				...state.collectionItems,
				...action.payload,
			};
		},
		setDebitors: (
			state,
			action: {
				payload: Partial<ApiDataState['debitors']>;
			},
		) => {
			state.debitors = { ...state.debitors, ...action.payload };
		},
		setDrivingSlip: (
			state,
			action: {
				payload: Partial<ApiDataState['drivingSlip']>;
			},
		) => {
			state.drivingSlip = { ...state.drivingSlip, ...action.payload };
		},
		setDrivingSlips: (
			state,
			action: {
				payload: Partial<ApiDataState['drivingSlips']>;
			},
		) => {
			state.drivingSlips = { ...state.drivingSlips, ...action.payload };
		},
		setRiskEvaluationCategories: (
			state,
			action: {
				payload: Partial<ApiDataState['riskEvaluationCategories']>;
			},
		) => {
			state.riskEvaluationCategories = {
				...state.riskEvaluationCategories,
				...action.payload,
			};
		},
		setRiskEvaluationQuestions: (
			state,
			action: {
				payload: Partial<ApiDataState['riskEvaluationQuestions']>;
			},
		) => {
			state.riskEvaluationQuestions = {
				...state.riskEvaluationQuestions,
				...action.payload,
			};
		},
		setQuestionnaireTemplatesByDrivingSlips: (
			state,
			action: {
				payload: Partial<ApiDataState['questionnaireTemplatesByDrivingSlips']>;
			},
		) => {
			state.questionnaireTemplatesByDrivingSlips = {
				...state.questionnaireTemplatesByDrivingSlips,
				...action.payload,
			};
		},
		setCars: (
			state,
			action: {
				payload: Partial<ApiDataState['cars']>;
			},
		) => {
			state.cars = {
				...state.cars,
				...action.payload,
			};
		},
	},
});

export default apiDataSlice.reducer;

export const {
	setCaseAdminData,
	setCollectionItems,
	setDebitors,
	setDrivingSlip,
	setDrivingSlips,
	setRiskEvaluationCategories,
	setRiskEvaluationQuestions,
	setQuestionnaireTemplatesByDrivingSlips,
	setCars,
} = apiDataSlice.actions;

