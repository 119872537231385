import { IonCol, IonInput, IonLabel, IonRow } from '@ionic/react';
import Button from 'Components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IndoorClimateMeassurementInput } from 'GQL';

interface Props {
	indoorClimateMeassurements: IndoorClimateMeassurementInput[];
	onChange(indoorClimateMeassurements: IndoorClimateMeassurementInput[]): void;
}

const EMPTY_ROW = (): IndoorClimateMeassurementInput => ({
	room: '',
	construction: '',
	material: '',
	description: '',
	instrument: '',
	value: '',
	referenceValue: '',
	calculatedValue: '',
});

const TABLE_COLUMNS: Array<keyof IndoorClimateMeassurementInput> = ['room', 'construction', 'material', 'description', 'instrument', 'value', 'referenceValue', 'calculatedValue'];

const IndoorClimateMeassurementForm: React.FC<Props> = ({ indoorClimateMeassurements, onChange }) => {
	const { t } = useTranslation();

	function handleChange(idx: number, column: keyof IndoorClimateMeassurementInput, value: string) {
		const copy = indoorClimateMeassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	function addRow() {
		// if (indoorClimateMeassurements.length === 0) {
		//     onChange([...indoorClimateMeassurements, EMPTY_ROW(), EMPTY_ROW()]);

		// } else {
		//     onChange([...indoorClimateMeassurements, EMPTY_ROW()]);
		// }
		onChange([...indoorClimateMeassurements, EMPTY_ROW()]);
	}

	function removeRow(idx: number) {
		onChange(indoorClimateMeassurements.filter((_, i) => i !== idx));
	}

	return (
		<IonCol size="12">
			<IonLabel className="text-blue text-base font-semibold">{t('reports.indoorClimateMeassurements')}</IonLabel>
			{indoorClimateMeassurements.map((meassurement, idx) => (
				<IonRow key={idx} className="border-1 mb-3">
					{TABLE_COLUMNS.map((column, i) => (
						<IonCol key={i}>
							<div className="flex flex-col">
								<IonLabel className="text-xs">{t(`reports.indoorClimateMeassurement.${column}`)}</IonLabel>

								<IonInput className="border-2 border-gray-800" value={meassurement[column]} onIonChange={e => handleChange(idx, column, e.detail.value || '')} />

								{i === TABLE_COLUMNS.length - 1 && (
									<p className="text-red pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
										x <span className="text-sm">Fjern linje</span>
									</p>
								)}
							</div>
						</IonCol>
					))}
				</IonRow>
			))}
			<Button onClick={addRow}>{t('reports.addIndoorClimateMeassurement')}</Button>
		</IonCol>
	);
};

export default IndoorClimateMeassurementForm;
