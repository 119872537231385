import React from 'react';
import { IonFooter, IonToolbar, IonTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePWAInstall } from 'Hooks/usePWAInstall';
import Button from './Button';

const PWAInstallerFooter: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const install = usePWAInstall();

	if (!install) {
		return <></>;
	}

	return (
		<IonFooter>
			<IonToolbar>
				<IonTitle size="small" className="pt-4 pb-2 text-center text-sm">
					{t('common.installPWAText')}
				</IonTitle>
				<Button
					expand="block"
					className="mb-2 px-2"
					onClick={async () => {
						const result = await install();
						if (result) {
							console.log('PWA installed successfully');
						} else {
							console.warn('PWA did not install successfully');
						}
					}}
				>
					{t('common.install')}
				</Button>
			</IonToolbar>
		</IonFooter>
	);
};

export default PWAInstallerFooter;
