import { IonCol, IonGrid, IonLabel, IonRow, IonSkeletonText } from '@ionic/react';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import BasePage from 'Components/Layout/BasePage';
import { GetCaseDamage, GetCaseDamageVariables } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';

const GET_CASE_DAMAGE = loader('src/GQL/Cases/GetCaseDamage.gql');

interface Props {
	caseId: string;
}

const ContactPersonsOverview: React.FC<Props> = ({ caseId }) => {
	const { t } = useTranslation();

	const { data: caseData, loading: caseLoading } = useOfflineHandlingQuery<GetCaseDamage, GetCaseDamageVariables>(GET_CASE_DAMAGE, {
		variables: {
			id: caseId,
		},
	});

	return (
		<BasePage title={t('contactPersons.overviewTitle')}>
			<IonLabel>
				<div className="flex justify-between">
					<h2 className="text-blue font-semibold">{t('contactPersons.pageTitle')}</h2>
				</div>
			</IonLabel>

			{caseData?.case.damage.contacts && caseData?.case.damage.contacts.length > 0 && (
				<IonGrid className="mb-2">
					{caseLoading ? (
						<IonRow>
							<IonCol size="6" className="flex items-center">
								<IonSkeletonText animated className="w-full pt-5" />
							</IonCol>
							<IonCol size="6">
								<IonSkeletonText animated className="w-full pt-5" />
							</IonCol>
						</IonRow>
					) : (
						caseData?.case.damage.contacts.map((c, index) => (
							<IonRow key={index} className="border-b-1 border-blue">
								<IonCol size="6">
									<p className="text-blue text-lg font-bold">{c.name}</p>
									<p>{c.role}</p>
									<p className="text-xs">{c.phone}</p>
									<p>
										<a className="text-xs" href={`mailto:${c.email}`}>
											{c.email}
										</a>
									</p>
								</IonCol>
								<IonCol size="6">
									<Button href={`mailto:${c.email}`} expand="block">
										{t('contactPersons.sendMail')}
									</Button>
									<Button href={`tel:${c.phone}`} expand="block">
										{t('contactPersons.call')}
									</Button>
								</IonCol>
							</IonRow>
						))
					)}
				</IonGrid>
			)}

			{caseData?.case.damage.contacts.length === 0 && <p className="text-center text-xl">{t('contactPersons.noContactsFound')}</p>}
		</BasePage>
	);
};

export default ContactPersonsOverview;
