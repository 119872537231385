import { combineReducers } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { RootState } from './store';
import { RESET_STATE_ACTION_TYPE } from './resetStateAction';
import apiDataSlice, { API_DATA_KEY } from './Slices/ApiData/apiDataSlice';
import localDataSlice, { LOCAL_DATA_KEY } from './Slices/LocalData/localDataSlice';

export const reducers = {
	[API_DATA_KEY]: apiDataSlice,
	[LOCAL_DATA_KEY]: localDataSlice,
};

export const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
	if (action.type === RESET_STATE_ACTION_TYPE) {
		state = {} as RootState;
	}

	return combinedReducer(state, action);
};
