import React from 'react';
import { Permissions } from '../GraphQL';
import arraysHasMatchingValue from '../Helpers/arraysHasMatchingValue';
import { Permissions as NewPermissions } from '@ssg/common/GraphQL/indexV2';


interface Props {
	permissions: Permissions[] | NewPermissions[];
	children: React.ReactNode;
	fallback?: React.ReactNode | null;
}

const RestrictedAreaContext = React.createContext<Permissions[]>([]);

export const RestrictedAreaProvider = RestrictedAreaContext.Provider;

const RestrictedArea: React.FC<Props> = ({ permissions, children, fallback = null }) => {
	const userPermissions = React.useContext(RestrictedAreaContext);
	const canSee = arraysHasMatchingValue(permissions as unknown as Permissions[], userPermissions);

	return canSee ? <>{children}</> : <>{fallback}</>;
};

export default RestrictedArea;
