import { ApolloQueryResult } from '@apollo/client';
import { Network } from '@capacitor/network';
import { UseRefetchOverrideProps, UseRefetchOverrideReturn } from './types';
import { useShowOfflineWarningMessage } from 'Hooks';
import { isConnected } from 'NetworkContext';

export const useRefetchOverride = <TQuery, TVariables>({ showOfflineWarningMessage, refetch }: UseRefetchOverrideProps<TQuery, TVariables>): UseRefetchOverrideReturn<TQuery, TVariables> => {
	const showOfflineWarningMessageFunc = useShowOfflineWarningMessage();

	return async (variables?: Partial<TVariables> | undefined): Promise<ApolloQueryResult<TQuery> | undefined> => {
		const connected = isConnected();
		if (connected) {
			const queryResult = await refetch(variables);
			return queryResult;
		}

		if (showOfflineWarningMessage) {
			showOfflineWarningMessageFunc();
		}

		return undefined;
	};
};

