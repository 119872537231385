import React from 'react';
import { IonDatetime, IonInput, IonItem, IonLabel } from '@ionic/react';
import Button from '@ssg/common/Components/Button';
import classnames from 'classnames';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CloseableModal from 'Components/CloseableModal';
import { BaseMachineFragment } from 'GQL';
import { isDatesBetweenDates } from 'Helpers/IsDatesBetweenDates';
import { ReserveMachineInput } from '../MachineActions';

export interface Props {
	reserveMachine(input: ReserveMachineInput): void;
	onDismiss(): void;
	caseNo?: string;
	machine: BaseMachineFragment;
	loading: boolean;
}

const ReserveMachineModal: React.FC<Props> = ({ reserveMachine, onDismiss, caseNo, machine, loading }) => {
	const { t } = useTranslation();

	const { register, handleSubmit, control } = useForm<ReserveMachineInput>({
		defaultValues: { caseNo: caseNo },
	});

	const startDate = useWatch({ control, name: 'startDate' });
	const endDate = useWatch({ control, name: 'endDate' });

	const isAlreadyBooked = isDatesBetweenDates(machine.reservationStart ?? null, machine.reservationEnd ?? null, new Date(startDate as string), new Date(endDate as string));

	return (
		<CloseableModal title={t('machines.reserveMachine')} onDismiss={onDismiss}>
			<form onSubmit={handleSubmit(reserveMachine)} className="flex flex-col gap-2">
				<IonItem
					className={classnames({
						hidden: typeof caseNo === 'string',
					})}
				>
					<IonLabel position="stacked">{t('case.caseNo')}</IonLabel>
					<IonInput ref={register} name="caseNo"></IonInput>
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">{t('machines.startDate')}</IonLabel>
					<Controller name="startDate" control={control} render={({ onChange, value }) => <IonDatetime presentation="date" onIonChange={onChange} value={value}></IonDatetime>} />
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">{t('machines.endDate')}</IonLabel>
					<Controller name="endDate" control={control} render={({ onChange, value }) => <IonDatetime presentation="date" onIonChange={onChange} value={value}></IonDatetime>} />
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">{t('machines.description')}</IonLabel>
					<IonInput ref={register} name="description"></IonInput>
				</IonItem>

				<Button success submit disabled={isAlreadyBooked} loading={loading}>
					{t('machines.reserveMachine')}
				</Button>
				{isAlreadyBooked && <p className="text-red">{t('machines.machineAlreadyReserved')}</p>}
			</form>
		</CloseableModal>
	);
};

export default ReserveMachineModal;
