export interface Question {
	questionId: string;
	question: string;
	helpText: string;
	answer: boolean;
}

export interface Category {
	categoryName: string;
	questions: Question[];
}

/* 
These interfaces are required so that we don't reference models in the web/admin/mobile projects.
 */

interface CategoryProp {
	id: string;
	categoryName: string;
}

interface QuestionProp {
	id: string;
	category: CategoryProp;
	question: string;
	helpText: string;
	defaultChecked: boolean;
}

export interface AnswerProp {
	answer: boolean;
	question: {
		id: string;
		question: string;
	};
}

interface Props {
	categories?: CategoryProp[];
	questions?: QuestionProp[];
	riskEvaluationAnswers?: AnswerProp[] | null;
}

function createQuestion(question: QuestionProp, answer: AnswerProp): Question {
	return {
		questionId: question.id,
		question: question.question,
		helpText: question.helpText,
		answer: answer.answer,
	};
}

export const getRiskEvaluationCategories = ({ categories, questions, riskEvaluationAnswers }: Props): Category[] => {
	if (!categories || !questions || riskEvaluationAnswers === null || typeof riskEvaluationAnswers === 'undefined') {
		return [];
	}

	const categoryMap = new Map<string, Category>();

	const addToMap = (category: CategoryProp, question: QuestionProp, answer: AnswerProp) => {
		if (categoryMap.has(category.id)) {
			categoryMap.set(category.id, {
				categoryName: category.categoryName,
				questions: [...(categoryMap.get(category.id)?.questions ?? []), createQuestion(question, answer)],
			});
		} else {
			categoryMap.set(category.id, {
				categoryName: category.categoryName,
				questions: [createQuestion(question, answer)],
			});
		}
	};

	if (riskEvaluationAnswers.length === 0) {
		categories.forEach((category: CategoryProp) => {
			questions.forEach((question: QuestionProp) => {
				if (question.category.id === category.id) {
					addToMap(category, question, { answer: false, question });
				}
			});
		});
		return Array.from(categoryMap.values());
	}

	// Enumerate each array to create a map of Categories -> Questions and Answers
	categories.forEach((category: CategoryProp) => {
		questions.forEach((question: QuestionProp) => {
			if (question.category.id === category.id) {
				if (riskEvaluationAnswers?.length > 0) {
					riskEvaluationAnswers.forEach((answer: AnswerProp) => {
						if (answer.question.id === question.id) {
							addToMap(category, question, answer);
						}
					});
				}
			}
		});
	});

	return Array.from(categoryMap.values());
};
