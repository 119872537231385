export default function dateToDateTimeString(input: Date | string | number, omitTime?: boolean): string {
	const d = new Date(input);
	const year = d.getFullYear();
	const month = `${('0' + (d.getMonth() + 1)).slice(-2)}`;
	const date = `${('0' + d.getDate()).slice(-2)}`;

	const hour = `${('0' + d.getHours()).slice(-2)}`;
	const minute = `${('0' + d.getMinutes()).slice(-2)}`;

	return omitTime ? `${date}-${month}-${year}` : `${date}-${month}-${year} ${hour}:${minute}`;
}
