import React from 'react';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { IonInput, IonItem, IonLabel } from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { AddMachineToCaseInput } from '../MachineActions';
import { MachineConsumptionType } from 'GQL';
import CloseableModal from 'Components/CloseableModal';
import Button from '@ssg/common/Components/Button';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { consumptionLimit } from './ChangeMachineLocationModal';
import Checkbox from 'Components/Checkbox';

export interface Props {
	addMachineToCase(input: AddMachineToCaseInput): void;
	onDismiss(): void;
	lastConsumption: number;
	consumptionType: string;
	caseNo?: string;
	loading: boolean;
}

const AddMachineToCaseModal: React.FC<Props> = ({ addMachineToCase, onDismiss, lastConsumption, consumptionType, caseNo, loading }) => {
	const { t } = useTranslation();
	const consumptionLimitFlag = useFlag(FeatureFlagEnums.CONSUMPTION_LIMIT);

	const [consumptionLess, setConsumptionLess] = React.useState(false);
	const [consumptionMore, setConsumptionMore] = React.useState<'ERROR' | 'OVERRIDE' | undefined>(undefined);

	const consumptionBlocker = (newConsumption: number): void => {
		setConsumptionLess(newConsumption < lastConsumption);
		if (consumptionLimitFlag) {
			setConsumptionMore((newConsumption - lastConsumption) > consumptionLimit ? 'ERROR' : undefined);
		}
	};
	const { register, handleSubmit, setValue } = useForm<AddMachineToCaseInput>({ defaultValues: { caseNo: caseNo } });

	return (
		<CloseableModal title={`${t('machines.addToCase')} ${caseNo ? caseNo : ''}`} onDismiss={onDismiss}>
			<div className="flex flex-col gap-2">
				<p>
					{t('machines.lastConsumption')}: {lastConsumption} {consumptionType === MachineConsumptionType.NONE ? `(${t('common.missingUnit')})` : consumptionType}
				</p>
				<Button icon={faArrowDown} primary onClick={() => setValue('consumption', lastConsumption)}>
					{t('machines.lastConsumption')}
				</Button>
			</div>

			<form onSubmit={handleSubmit(addMachineToCase)} className="flex flex-col gap-2">
				<IonItem>
					<IonLabel position="stacked" className="mb-2">
						{t('machines.consumption')}
					</IonLabel>
					<IonInput
						name="consumption"
						type="number"
						ref={register}
						onIonChange={e => {
							consumptionBlocker(Number(e.detail.value));
							setValue('consumption', Number(e.detail.value));
						}}
						className="ml-1"
						step="1"
					/>
					{consumptionLess && <p className="text-orange font-semibold">{t('machines.consumptionLess')}</p>}
					{consumptionMore &&
						<div className="py-2">
							{consumptionMore === 'ERROR' && <p className="text-orange font-semibold">{t('machines.consumptionMore', { number: consumptionLimit })}</p>}
							<Checkbox
								name="ignoreMePls"
								onChange={(e) => setConsumptionMore(e.currentTarget.checked ? 'OVERRIDE' : 'ERROR')}
								title="machines.consumptionMoreConfirm"
							/>
						</div>
					}
				</IonItem>

				<IonItem
					className={classnames({
						hidden: typeof caseNo === 'string',
					})}
				>
					<IonLabel position="stacked">{t('case.caseNo')}</IonLabel>
					<IonInput ref={register} name="caseNo"></IonInput>
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">{t('common.placement')}</IonLabel>
					<IonInput ref={register} name="placementDescription"></IonInput>
				</IonItem>

				<Button success submit loading={loading} disabled={consumptionLess || consumptionMore === 'ERROR'}>
					{t('machines.addToCase')}
				</Button>
			</form>
		</CloseableModal>
	);
};

export default AddMachineToCaseModal;
