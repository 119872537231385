import React from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useFlag } from '@unleash/proxy-client-react';
import CloseableModal from 'Components/CloseableModal';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

export interface Props {
	onDismiss: () => void;
	onScanSuccess: (id: string) => void;
}

const NOT_FOUND = 'SCANNED_TEXT_NOT_FOUND';

const ScannerModal: React.FC<Props> = ({ onDismiss, onScanSuccess }) => {
	const [data, setData] = React.useState(NOT_FOUND);
	const [html5QrcodeScanner, setHtml5QrcodeScanner] = React.useState<Html5QrcodeScanner>();

	const enabled = useFlag(FeatureFlagEnums.SCANNER_LIGHT);

	React.useEffect(() => {
		return () => {
			html5QrcodeScanner?.clear();
		};
	}, [html5QrcodeScanner]);

	React.useEffect(() => {
		if (data !== NOT_FOUND) {
			onScanSuccess(data.replace('ma:', '').replace('/', '-'));
		}
	}, [data, onScanSuccess]);

	const successHandler = React.useCallback(async (scannedString: string) => {
		console.log('id:', scannedString);

		setData(scannedString);
	}, []);

	React.useEffect(() => {
		if (typeof html5QrcodeScanner === 'undefined') {
			const newHtml5QrcodeScanner = new Html5QrcodeScanner(
				'reader',
				{
					fps: 10,
					qrbox: { width: 250, height: 250 },
					supportedScanTypes: [],
					showTorchButtonIfSupported: enabled,
				},
				/* verbose= */ false,
			);
			setHtml5QrcodeScanner(newHtml5QrcodeScanner);
		}
		if (typeof html5QrcodeScanner !== 'undefined') {
			html5QrcodeScanner.render(
				(data: string) => successHandler(data),
				(err: string) => console.log('err ->', err),
			);
		}
	}, [enabled, html5QrcodeScanner, successHandler]);

	return (
		<CloseableModal title="Scanning label" onDismiss={onDismiss}>
			<div className="mx-auto" style={{ width: 500, height: 600 }}>
				<div style={{ width: '500px' }} id="reader"></div>
			</div>
		</CloseableModal>
	);
};

export default ScannerModal;
