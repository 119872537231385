import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import { ApiMutationKey, DrivingSlip, useAppSelector, useSetDrivingSlipDrivingStarted } from 'Store';
import { useGetLocation } from './useGetLocation';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, useIonModal } from '@ionic/react';
import ScannerModal, { Props as ScannerModalProps } from 'Pages/Machines/Modals/ScannerModalTwo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { GetMobileV2DrivingSlip_drivingSlip_car } from 'GQL';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';

export interface Props {
	drivingSlip: DrivingSlip;
	onDismiss: () => void;
}


const DrivingStartedDrivingSlipModal: React.FC<Props> = ({ drivingSlip, onDismiss }) => {
	const { t } = useTranslation();
	const vehiclesMobileAppFlag = useFlag(FeatureFlagEnums.VEHICLES_MOBILE_APP);
	const {
		cars: {
			data: cars,
		},
	} = useAppSelector(state => state.apiData);

	const [presentScanner, dissmissScanner] = useIonModal(ScannerModal, {
		onDismiss: () => dissmissScanner(),
		onScanSuccess: (id: string) => {
			setSelectedCarNo(id);
			dissmissScanner();
		},
	} as ScannerModalProps);

	const [switchCar, setSwitchCar] = React.useState(false);
	const { locationCoordinates, coordinatesElement, gettingLocation, loadingElement } = useGetLocation();
	const [setDrivingStarted] = useSetDrivingSlipDrivingStarted();
	const [passenger, setPassenger] = React.useState(false);
	const [car, setCar] = React.useState<GetMobileV2DrivingSlip_drivingSlip_car | null>(drivingSlip.car);
	const [selectedCarNo, setSelectedCarNo] = React.useState('');
	const [carSearchText, setCarSearchText] = React.useState('');

	React.useEffect(() => {
		if (selectedCarNo.length > 0) {
			const car = cars.find(c => c.vehicleNumber === selectedCarNo.replace('p:', ''));
			if (car) {
				setCar(car);
				setSwitchCar(false);
			}
		}
	}, [cars, selectedCarNo]);

	const buttonDisabled: boolean = React.useMemo(() => {
		if (gettingLocation) return true;
		if (!vehiclesMobileAppFlag) return false;
		if (car === null && !passenger) return true;

		return false;
	}, [car, gettingLocation, passenger, vehiclesMobileAppFlag]);

	React.useEffect(() => {
		if (passenger) {
			setCar(null);
		} else {
			setCar(drivingSlip.car);
		}
	}, [drivingSlip.car, passenger]);

	return (
		<CloseableModal title={t('drivingSlips.startDrivingStarted') + '?'} onDismiss={onDismiss}>
			{vehiclesMobileAppFlag &&
				<div>
					<p className="font-bold">{t('drivingSlips.car')}</p>
					{switchCar || drivingSlip.car === null
						?
						<div>
							<IonRow >
								<IonCol size="8">
									<IonItem>
										<IonLabel position="stacked">{t('drivingSlips.selectCar')}</IonLabel>
										<IonSelect
											name="location"
											ref={null}
											onIonChange={e => {
												const car = cars.find(c => c.vehicleNumber === e.detail.value);
												if (car) {
													setCar(car);
													setSwitchCar(false);
												}
											}}
											placeholder={t('drivingSlips.selectCar')}
											interface="action-sheet"
											okText={t('common.ok')}
											cancelText={t('common.cancel')}
											interfaceOptions={{ cssClass: 'ssg-action-sheet' }}
											value={car?.vehicleNumber}
											disabled={passenger}
										>
											{cars
												.map(car => (
													<IonSelectOption key={car.vehicleNumber} value={car.vehicleNumber} children={`${car.brand} ${car.registrationNumber} (${car.vehicleNumber})`} />
												))}
										</IonSelect>
										{/* <IonInput value={carSearchText} onIonChange={e => setCarSearchText(e.detail.value ?? '')} name="maskinenummer" /> */}
									</IonItem>
								</IonCol>
								<IonCol size="4">
									<Button onClick={() => presentScanner()} expand='block' disabled={passenger}>
										{t('machines.scanCarLabel')}
									</Button>
								</IonCol>

							</IonRow>
							<IonItem className="my-4">
								<IonLabel>
									{t('drivingSlips.passenger')}
									<br />
									<small>{t('drivingSlips.passengerHelptext')}</small>
								</IonLabel>
								<IonCheckbox onIonChange={e => setPassenger(e.detail.checked)} />
							</IonItem>

						</div>
						:
						<div className="flex flex-row">
							{car &&
								<p className="font-semibold">{car.brand} {car.registrationNumber} ({car.vehicleNumber})</p>
							}
							<button onClick={() => setSwitchCar(true)} className="text-blue ml-2">
								<FontAwesomeIcon icon={faSync} size='lg' />
							</button>
						</div>
					}
				</div>
			}
			<p className="text-center">{t('drivingSlips.startDrivingStarted')}?</p>
			<Button
				expand="block"
				loading={gettingLocation}
				disabled={buttonDisabled}
				onClick={async () => {
					const drivingStarted = new Date().toISOString();
					setDrivingStarted({
						variables: { id: drivingSlip.id, drivingStarted, carNumber: car?.vehicleNumber, locationCoordinates: locationCoordinates, passenger },
						optimisticResponse: {
							setDrivingSlipDrivingStarted: {
								...drivingSlip,
								drivingStarted,
								car,
								passenger,
							},
						},
						queueOfflineMutationOptions: {
							key: ApiMutationKey.DrivingSlip_SetDrivingStarted,
							removePreviousEntry: true,
							findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
						},
					});

					onDismiss();
				}}
			>
				{t('drivingSlips.startDrivingStarted')}
			</Button>
			{loadingElement()}
			{coordinatesElement()}
			<Button expand="block" onClick={onDismiss}>
				{t('common.cancel')}
			</Button>
		</CloseableModal>
	);
};

export default DrivingStartedDrivingSlipModal;
