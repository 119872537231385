import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/react';
import { loader } from 'graphql.macro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CloseableModal from 'Components/CloseableModal';
import { GetMobileV2MachineGuideFiles, GetMobileV2MachineGuideFilesVariables } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';

const GET_GUIDES = loader('src/GQL/Machines/GetMobileV2MachineGuideFiles.gql');

interface Props {
	onDismiss: () => void;
}

const GuidesModal: React.FC<Props> = ({ onDismiss }) => {
	const { t } = useTranslation();

	const { data: guideFiles, loading: loadingGuideFiles } = useOfflineHandlingQuery<GetMobileV2MachineGuideFiles, GetMobileV2MachineGuideFilesVariables>(GET_GUIDES);

	const guides = useMemo(() => {
		return guideFiles?.machineGuideFiles ?? [];
	}, [guideFiles?.machineGuideFiles]);

	return (
		<CloseableModal title={t('common.guides')} onDismiss={onDismiss}>
			<IonList>
				{loadingGuideFiles ? (
					<IonItem>
						<IonLabel>
							<IonSkeletonText animated />
							<IonSkeletonText animated />
						</IonLabel>
					</IonItem>
				) : (
					guides.map((g, i) => {
						return (
							<IonItem key={i} href={g.url}>
								<IonLabel>
									<h2>{g.name}</h2>
									<p>{g.url}</p>
								</IonLabel>
							</IonItem>
						);
					})
				)}
			</IonList>
		</CloseableModal>
	);
};

export default GuidesModal;
