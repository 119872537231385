import React from 'react';
import { IonCol, useIonModal } from '@ionic/react';
import { faCommentAltEdit, faCommentAltPlus } from '@fortawesome/pro-regular-svg-icons';
import useDrivingSlipImages, { DrivingSlipFile } from '../useDrivingSlipImages';
import Button from 'Components/Button';
import useImageModal from '../useImageModal';
import ChangeCommentModal, { Props as ChangeCommentModalProps } from './ChangeCommentModal';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	onImageSelect(image: DrivingSlipFile): void;
	selectedImage?: DrivingSlipFile | null;
	noImageText: string;
	uploadButtonText: string;
	caseNo: string;
	drivingSlipId: string;
}

const DrivingSlipSingleImageSelect: React.FC<Props> = ({ caseNo, drivingSlipId, onImageSelect, selectedImage, noImageText, uploadButtonText }) => {
	const { isUploading, upload, changeComment, images } = useDrivingSlipImages(caseNo, drivingSlipId);

	const presentPhotoModal = useImageModal(onImageSelect, upload);

	const [presentChangeCommentModal, dismissChangeCommentModal] = useIonModal(ChangeCommentModal, {
		onDismiss: () => dismissChangeCommentModal(),
		fileId: selectedImage?.fileId ?? '',
		changeComment,
		oldComment: selectedImage?.comments ?? '',
	} as ChangeCommentModalProps);

	const findSelectedImage = React.useMemo(() => images.find(image => image.name === selectedImage?.name), [images, selectedImage?.name]);

	return (
		<IonCol size="12">
			{selectedImage == null && <p className="text-center">{noImageText}</p>}
			{selectedImage !== null && typeof selectedImage !== 'undefined' && (
				<div className="flex items-center justify-center">
					<div>
						<img className="w-96" src={findSelectedImage?.thumbnail} alt={selectedImage.name} />
					</div>

					{findSelectedImage?.comments ? (
						<div className="m-2 flex h-12 items-center rounded-md p-2">
							<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
								<TextButton
									iconClassName="text-2xl"
									icon={faCommentAltEdit}
									className="text-blue tex cursor-pointer"
									onClick={() => {
										presentChangeCommentModal();
									}}
								/>
							</div>
							<div>
								<p className="text-blue font-bold">{findSelectedImage.comments}</p>
							</div>
						</div>
					) : (
						<div className="m-2 flex h-12 items-center rounded-md p-2">
							<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
								<TextButton
									iconClassName="text-2xl"
									icon={faCommentAltPlus}
									className="text-blue tex cursor-pointer"
									onClick={() => {
										presentChangeCommentModal();
									}}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<Button
				loading={isUploading}
				disabled={isUploading}
				expand="block"
				onClick={async () => {
					presentPhotoModal();
				}}
			>
				{uploadButtonText}
			</Button>
		</IonCol>
	);
};

export default DrivingSlipSingleImageSelect;
