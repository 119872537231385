import React from 'react';

interface Props {
	title: string;
	children: React.ReactNode | string;
	className?: string;
}

const Section: React.FC<Props> = ({ title, children, className }) => (
	<div className={className}>
		<div>
			<p>{title}</p>
			<hr className="border-gray-300" />
		</div>
		{children}
	</div>
);

export default Section;
