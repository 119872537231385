export function resizeImage(url: string, format: string, width: number, height: number): Promise<string> {
	return new Promise((resolve, reject) => {
		try {
			const img = new Image();
			img.src = url;

			img.onload = function () {
				const canvas = document.createElement('canvas');

				const context = canvas.getContext('2d');
				if (context == null) return;

				canvas.width = width;
				canvas.height = height;

				context.drawImage(img, 0, 0, width, height);

				const newUrl = canvas.toDataURL(`image/${format}`);
				resolve(newUrl);
			};
		} catch (e) {
			reject(e);
		}
	});
}
