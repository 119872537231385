import React from 'react';
import { useTranslation } from 'react-i18next';

import BasePage from 'Components/Layout/BasePage';
import Section from 'Components/Section';
import FindMachine from './FindMachine';
import SelectedMachine from './SelectedMachine';

const MachinesOverview: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const [selectedMachineNo, setSelectedMachineNo] = React.useState<string>();

	return (
		<BasePage title={t('machines.overviewTitle')} shouldOfflineWarn>
			<Section title={t('machines.overviewTitle')} className="flex flex-col gap-2">
				<FindMachine onFind={nr => setSelectedMachineNo(nr)} />
				{typeof selectedMachineNo === 'string' && <SelectedMachine machineNo={selectedMachineNo} />}
			</Section>
		</BasePage>
	);
};

export default MachinesOverview;
