import { loader } from 'graphql.macro';
import {
	ApiQueryKey,
	DrivingSlip,
	QuestionnaireTemplateByDrivingSlip,
	QuestionnaireTemplateByDrivingSlipQuery,
	QuestionnaireTemplateByDrivingSlipQueryVariables,
	UseApiQueryReturn,
	setQuestionnaireTemplatesByDrivingSlips,
	toArraySafe,
	useAppDispatch,
	useAppSelector,
} from 'Store';
import { useMemo, useState } from 'react';
import { API_QUERIES } from '../../constants';
import { useApiQuery } from '../useApiQuery';
import { groupBy, orderBy } from 'lodash';

const GET_QUESTIONNAIRES_BY_DRIVING_SLIPS = loader('src/GQL/QuestionnaireTemplate/GetQuestionnaireTemplatesByDrivingSlips.gql');

export const useQuestionnaireTemplatesByDrivingSlipsQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.QuestionnaireTemplatesByDrivingSlips;

	const dispatch = useAppDispatch();

	const { data: drivingSlips } = useAppSelector(state => state.apiData.drivingSlips);

	const variables: QuestionnaireTemplateByDrivingSlipQueryVariables | undefined = useMemo(() => {
		if (drivingSlips.length > 0) {
			const grouped = groupBy(drivingSlips, (drivingSlip: DrivingSlip) => `${drivingSlip.category ?? ''}_${drivingSlip.case.damage.cause.id}`);
			return {
				drivingSlips: orderBy(Object.keys(grouped)).map(key => {
					const drivingSlip = grouped[key][0];
					return {
						categoryCode: drivingSlip.category,
						damageCauseId: drivingSlip.case.damage.cause.id,
					};
				}),
			} as QuestionnaireTemplateByDrivingSlipQueryVariables;
		}

		return undefined;
	}, [drivingSlips]);

	const { metadata } = useAppSelector(state => state.apiData.questionnaireTemplatesByDrivingSlips);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<QuestionnaireTemplateByDrivingSlipQuery, QuestionnaireTemplateByDrivingSlip, QuestionnaireTemplateByDrivingSlipQueryVariables>({
		apiQueryKey,
		debatch: true,
		canFetchQuery: canFetchQuery && variables !== undefined,
		documentNode: GET_QUESTIONNAIRES_BY_DRIVING_SLIPS,
		variables,
		dataSelector: query => toArraySafe(query?.questionnaireTemplatesByDrivingSlips),
		setCanFetchQuery,
		setData: partial => {
			dispatch(setQuestionnaireTemplatesByDrivingSlips({ ...partial }));
		},
		options: {
			skip: typeof variables === 'undefined',
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};

