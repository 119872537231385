import React from 'react';
import { useRef, useState, useMemo } from 'react';
import { IonAccordion, IonAccordionGroup, IonImg, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonThumbnail, useIonModal } from '@ionic/react';
import { faSpinner, faTrash, faFileDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactImageGalleryItem } from 'react-image-gallery';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import FullyCenteredDisplayText from 'Components/Layout/FullyCenteredDisplayText';
import { GetDrivingSlipFiles_drivingSlipFiles } from 'GQL';
import { FileType } from '@ssg/common/Helpers/fileTypeHelper';
import DrivingSlipFilesListElementLoading from './DrivingSlipFilesListElementLoading';
import { DrivingSlipFilesListProps } from './DrivingSlipFilesListProps';
import ImageGalleryModal, { Props as ImageGalleryModalProps } from 'Components/ImageGalleryModal';
import { useTranslation } from 'react-i18next';

function getModifiedDateAsString(file: GetDrivingSlipFiles_drivingSlipFiles): string {
	return dateToDateTimeString(file.metadata.find(m => m.key === 'Modified')?.value ?? file.created);
}

const DrivingSlipFilesImageList: React.FC<DrivingSlipFilesListProps> = ({ files, loadingUploadFile, loadingDeleteFile, loadingDownloadFile, uploadingFileType, onDelete, onDownload }) => {
	const { t } = useTranslation();

	const ionListRef = useRef<HTMLIonListElement>(null);
	const [galleryIndex, setGalleryIndex] = useState<number>(0);

	const galleryImages: ReactImageGalleryItem[] = useMemo(
		() =>
			files.map(
				file =>
					({
						original: file.thumbnail,
						thumbnail: file.thumbnail,
						thumbnailClass: 'w-20 h-full',
						originalTitle: `${file.name} | ${getModifiedDateAsString(file)}`,
						description: file.comments,
					}) as ReactImageGalleryItem,
			),
		[files],
	);

	const [presentImageGalleryModal, dismissImageGalleryModal] = useIonModal(ImageGalleryModal, {
		images: galleryImages,
		initialIndex: galleryIndex,
		onDismiss: () => dismissImageGalleryModal(),
	} as ImageGalleryModalProps);

	return (
		<>
			<IonAccordionGroup value="images">
				<IonAccordion toggleIconSlot="start" value="images">
					<IonItem slot="header">
						<IonLabel className="text-blue font-semibold">{t('common.pictures')}</IonLabel>
					</IonItem>
					<IonList ref={ionListRef} slot="content">
						{loadingUploadFile && uploadingFileType && (uploadingFileType & FileType.Image) === FileType.Image && <DrivingSlipFilesListElementLoading />}
						{!loadingUploadFile && files.length === 0 ? (
							<FullyCenteredDisplayText>{t('catalog.noFiles')}</FullyCenteredDisplayText>
						) : (
							files.map((file, index) => {
								return (
									<IonItemSliding key={index}>
										<IonItemOptions side="start">
											<IonItemOption color="primary" onClick={() => onDownload(file)} disabled={loadingDownloadFile}>
												{loadingDownloadFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faFileDownload} className="mr-2" />}

												{t('common.download')}
											</IonItemOption>
											<IonItemOption
												color="danger"
												onClick={async () => {
													const confirm = window.confirm(t('catalog.wantToDelete'));
													if (confirm) {
														await onDelete(file);
													}
													ionListRef.current?.closeSlidingItems();
												}}
												disabled={loadingDeleteFile}
											>
												{loadingDeleteFile ? <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" /> : <FontAwesomeIcon icon={faTrash} className="mr-2" />}
												{t('common.remove')}
											</IonItemOption>
										</IonItemOptions>
										<IonItem
											onClick={() => {
												setGalleryIndex(index);
												presentImageGalleryModal();
											}}
										>
											<IonThumbnail slot="start">
												<IonImg src={file.thumbnail} />
											</IonThumbnail>
											<IonLabel>
												<h2>{file.name}</h2>
												<h3>{file.url}</h3>
												<p>{getModifiedDateAsString(file)}</p>
												<p className="truncate">{file.comments}</p>
											</IonLabel>
										</IonItem>
									</IonItemSliding>
								);
							})
						)}
					</IonList>
				</IonAccordion>
			</IonAccordionGroup>
		</>
	);
};

export default DrivingSlipFilesImageList;
