import React from 'react';
import { FileType, getFileTypeFromUrl } from '@ssg/common/Helpers/fileTypeHelper';
import { loader } from 'graphql.macro';
import {
	FileInput,
	GetDrivingSlipFiles_drivingSlipFiles,
	UpdatePictureComment,
	UpdatePictureCommentVariables,
	GetCaseFiles,
	GetCaseFilesVariables,
	GetCaseFiles_caseFiles,
	UploadDrivingSlipFiles_uploadDrivingSlipFiles,
	FileMetadataField,
	GetCaseFiles_caseFiles_metadata,
} from 'GQL';
import { useOfflineHandlingMutation, useOfflineHandlingQuery } from 'Hooks';
import { ApiMutationKey, useModifyDrivingSlipFileMetadata, useUploadDrivingSlipFile } from 'Store';

const UPDATE_PICTURE_COMMENT = loader('src/GQL/Cases/UpdatePictureComment.gql');
const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');

export type DrivingSlipFile = GetDrivingSlipFiles_drivingSlipFiles;

export type CaseFileInput = FileInput & { blob: string };

interface UseDrivingSlipImagesResult {
	upload(fileInput: CaseFileInput): Promise<DrivingSlipFile>;
	uploadMultiple(filesInput: CaseFileInput[]): Promise<DrivingSlipFile[]>;
	changeComment(fileName: string, fileId: string, comment: string): Promise<string>;
	images: DrivingSlipFile[];
	isUploading: boolean;
}

function getFileModifiedDateTime(file: DrivingSlipFile): number {
	const modified = file.metadata.find(m => m.key === 'Modified')?.value;

	if (modified) {
		const date = new Date(modified);
		return date.getTime();
	}

	return 0;
}

function extractImages(files: DrivingSlipFile[]): DrivingSlipFile[] {
	return files.filter(file => getFileTypeFromUrl(file.url) === FileType.Image).sort((a, b) => getFileModifiedDateTime(b) - getFileModifiedDateTime(a));
}

export default function useDrivingSlipImages(caseNo: string, drivingSlipId: string): UseDrivingSlipImagesResult {
	const [isUploading, setIsUploading] = React.useState(false);

	// Get the driving slip files and extract the images
	const { data } = useOfflineHandlingQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, {
		variables: {
			caseErpNo: caseNo,
			folder: caseNo,
		},
	});

	const images = extractImages(data?.caseFiles ?? []);

	// Setup file upload
	const [uploadDsFile] = useUploadDrivingSlipFile();

	const uploadMultiple = async (filesInput: CaseFileInput[]): Promise<DrivingSlipFile[]> => {
		setIsUploading(false);

		const filesToReturn: UploadDrivingSlipFiles_uploadDrivingSlipFiles[] = [];
		await uploadDsFile({
			variables: {
				caseNo,
				drivingSlipId,
				files: filesInput.map(f => {
					const { blob, ...file } = f;
					return file;
				}),
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_UploadFile,
				removePreviousEntry: false,
			},
			forceQueue: true,
			onOptimisticResponseOverride(cache) {
				const newObj: GetCaseFiles_caseFiles[] = filesInput.map<GetCaseFiles_caseFiles>((f, i) => {
					const fileSplit = f.filename.split('.');
					const extension = fileSplit[fileSplit.length - 1];
					const comment = f.fileMetadata?.find(m => m.key === 'Comments');
					return {
						comments: comment ? comment.value : null,
						created: new Date().toISOString(),
						extension: extension,
						fileId: new Date().toISOString(),
						groupingKey: '',
						metadata: f.fileMetadata ?? [],
						name: f.filename,
						thumbnail: f.blob,
						url: f.blob + '.' + extension,
						__typename: 'ESDHFile',
					};
				});
				const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					query: GET_CASE_FILES,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
				});
				cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					query: GET_CASE_FILES,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
					data: {
						caseFiles: [...(cachedRequest?.caseFiles ?? []), ...newObj],
					},
				});
				filesToReturn.push(...newObj);
			},
		});

		setIsUploading(false);
		// if (connected) {
		// 	//refetch();
		// 	if (result?.data?.uploadDrivingSlipFiles == null) return [];
		// 	return extractImages(result.data.uploadDrivingSlipFiles);
		// }
		if (filesToReturn.length === 0) return [];
		return extractImages(filesToReturn);
	};

	const upload = async (fileInput: CaseFileInput) => {
		const result = await uploadMultiple([fileInput]);
		return result[0];
	};

	const [modifyDrivingSlipFileMetadata] = useModifyDrivingSlipFileMetadata();

	const [updatePictureComment] = useOfflineHandlingMutation<UpdatePictureComment, UpdatePictureCommentVariables>(UPDATE_PICTURE_COMMENT);

	const changeComment = async (fileName: string, fileId: string, comment: string) => {
		await modifyDrivingSlipFileMetadata({
			variables: {
				caseNo: caseNo,
				drivingSlipId: drivingSlipId,
				fileName: fileName,
				metadata: [
					{
						key: FileMetadataField.Comments,
						value: comment,
					},
				],
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_ModifyDrivingSlipFileMetadata,
				removePreviousEntry: true,
				findPreviousEntryPredicate(variables) {
					return variables.fileName === fileName;
				},
			},
			onOptimisticResponseOverride(cache) {
				const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					query: GET_CASE_FILES,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
				});
				// console.log('🚀 ~ onOptimisticResponseOverride ~ cachedRequest:', cachedRequest);

				if (cachedRequest == null || cachedRequest.caseFiles == null) return;
				// console.log('🚀 ~ onOptimisticResponseOverride ~ cachedRequest:', cachedRequest);

				const indexToChange = cachedRequest.caseFiles.findIndex(cf => cf.fileId === fileId);
				if (indexToChange === -1) return;
				const fileToChange = cachedRequest.caseFiles[indexToChange];
				const metaDataArr: GetCaseFiles_caseFiles_metadata[] = fileToChange.metadata.slice();
				const metaDataIndex = metaDataArr.findIndex(d => d.key === FileMetadataField.Comments);
				if (metaDataIndex > -1) {
					metaDataArr[metaDataIndex] = { key: FileMetadataField.Comments, value: comment };
				} else {
					metaDataArr.push({ key: FileMetadataField.Comments, value: comment });
				}

				const replaceFiles = [
					...cachedRequest.caseFiles.slice(0, indexToChange),
					{
						...fileToChange,
						metadata: metaDataArr,
						comments: comment,
					},
					...cachedRequest.caseFiles.slice(indexToChange + 1),
				];
				// console.log('🚀 ~ onOptimisticResponseOverride ~ replaceFiles:', replaceFiles);
				cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					query: GET_CASE_FILES,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
					data: {
						caseFiles: replaceFiles,
					},
				});
			},
		});

		return comment;
	};

	// const changeComment = async (fileId: string, comment: string): Promise<string> => {
	// 	await updatePictureComment({
	// 		variables: {
	// 			fileID: fileId,
	// 			comment: comment,
	// 		},
	// 		update: (cache, mutationResult) => {
	// 			if (mutationResult.data == null) return;

	// 			const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
	// 				query: GET_CASE_FILES,
	// 				variables: {
	// 					caseErpNo: caseNo,
	// 					folder: caseNo,
	// 				},
	// 			});

	// 			if (cachedRequest == null || cachedRequest.caseFiles == null) return;
	// 			const indexToChange = cachedRequest.caseFiles.findIndex(cf => cf.fileId === fileId);
	// 			const replaceFiles = [
	// 				...cachedRequest.caseFiles.slice(0, indexToChange),
	// 				{
	// 					...cachedRequest.caseFiles[indexToChange],
	// 					comments: comment,
	// 				},
	// 				...cachedRequest.caseFiles.slice(indexToChange + 1),
	// 			];
	// 			cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
	// 				query: GET_CASE_FILES,
	// 				variables: {
	// 					caseErpNo: caseNo,
	// 					folder: caseNo,
	// 				},
	// 				data: {
	// 					caseFiles: replaceFiles,
	// 				},
	// 			});
	// 		},
	// 	});

	// 	return comment;
	// };

	return {
		upload,
		uploadMultiple,
		changeComment,
		images,
		isUploading,
	};
}

