import React from 'react';
import Loading from '@ssg/common/Components/Loading';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetMachine, GetMachineVariables } from 'GQL';
import MachineActions from './MachineActions';
import SelectedMachineInfo from './SelectedMachineInfo';
import { useOfflineHandlingQuery } from 'Hooks';

interface Props {
	machineNo: string;
	caseNo?: string;
	// Used to limit the buttons available
	drivingslipView?: boolean;
}

const GET_MACHINE = loader('src/GQL/Machines/GetMachine.gql');

const SelectedMachine: React.FC<Props> = ({ machineNo, caseNo, drivingslipView = false }) => {
	const { t } = useTranslation();

	const { data, loading, refetch } = useOfflineHandlingQuery<GetMachine, GetMachineVariables>(GET_MACHINE, { variables: { machineNo } });

	if (loading) return <Loading />;
	if (!data?.machine) return t('machines.notFound');

	return (
		<div className="flex flex-col gap-2">
			<SelectedMachineInfo machine={data.machine} />
			<MachineActions drivingslipView={drivingslipView} machine={data.machine} caseNo={caseNo} refetch={refetch} />
		</div>
	);
};

export default SelectedMachine;
