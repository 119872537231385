import { GetMobileV2DrivingSlipsVariables, DrivingSlipStatus } from 'GQL';

const DRIVING_SLIPS_FETCH_LIMIT = 20;

export const DEFAULT_DRIVING_SLIPS_FILTERS: GetMobileV2DrivingSlipsVariables = {
	personalOnly: true,
	caseERPReferenceNo: undefined,
	damageCategories: undefined,
	damageCauses: undefined,
	departments: undefined,
	locations: undefined,
	postalCode: undefined,
	minEndDate: undefined,
	maxEndDate: undefined,
	minStartDate: undefined,
	maxStartDate: undefined,
	dateRange: undefined,
	debitors: undefined,
	status: DrivingSlipStatus._NULL_,
	offset: 0,
	limit: DRIVING_SLIPS_FETCH_LIMIT,
	mobile: true,
	excludeTotalCount: true, // We don't show total count on mobile (not yet anyway)
};

