import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from './Popover';

interface Props {
	className?: string;
	textClassName?: string;
	inputClassName?: string;
	title?: string;
	children?: React.ReactNode;
	innerRef?: React.LegacyRef<HTMLInputElement>;
	helpText?: string;
	showHelpText?: boolean;
	errorMessage?: string;
	required?: boolean;
	disabled?: boolean;
	checked?: boolean; // TODO: This should be defaultChecked instead of overriding built-in checked
	checkedControlled?: boolean;
	name: string;
	labelWeight?: 'NONE' | 'BOLD';
	labelInfoPopover?: React.ReactElement;
	popoverInteractive?: boolean;
	topDivClassName?: string;
}

const Checkbox: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	textClassName = 'text-blue', //Ugly no break fix
	inputClassName,
	title,
	children,
	innerRef,
	helpText,
	showHelpText,
	errorMessage,
	required = false,
	disabled = false,
	checked,
	checkedControlled,
	name,
	id,
	labelWeight = 'BOLD',
	labelInfoPopover,
	popoverInteractive = false,
	topDivClassName,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<div className={classNames('flex flex-col', topDivClassName)}>
				{showHelpText && helpText && <FormHelpText text={helpText} />}
				<div
					className={classNames('flex items-center', className, {
						'border-red -ml-1 border-2 px-1': errorMessage,
					})}
				>
					<input
						type="checkbox"
						ref={innerRef}
						className={classNames('rounded-default cursor-pointer ring-transparent focus:outline-none', inputClassName, {
							'text-blue': !disabled,
							'cursor-not-allowed bg-gray-300 text-gray-600': disabled,
						})}
						id={id ? id : name}
						name={name}
						disabled={disabled}
						defaultChecked={checked}
						checked={checkedControlled}
						{...restProps}
					/>

					<label
						className={classNames('pl-2', textClassName, {
							'cursor-default': disabled,
							'cursor-pointer': !disabled,
						})}
						htmlFor={id ? id : name}
					>
						{title && (
							<span
								className={classNames(textClassName, {
									'font-semibold': labelWeight === 'BOLD',
								})}
							>
								{t(title)}
								{required && <span className="text-red">*</span>}
							</span>
						)}
					</label>

					{typeof labelInfoPopover !== 'undefined' && (
						<Popover
							interactive={popoverInteractive}
							hideArrow
							placement="top"
							content={<div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">{labelInfoPopover}</div>}
						>
							<span>
								<FontAwesomeIcon icon={faInfoCircle} className="text-blue ml-1" />
							</span>
						</Popover>
					)}
				</div>
			</div>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};
export default Checkbox;
