import { faEnvelope, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonFab, IonFabButton, useIonModal } from '@ionic/react';
import Table from '@ssg/common/Components/Table';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BasePage from 'Components/Layout/BasePage';
import { GetMobileV2Case, Offers } from 'GQL';
import MailModal, { Props as MailModalProps } from './MailModal';
import { useOfflineHandlingQuery } from 'Hooks';

const GET_CASE = loader('src/GQL/Cases/GetMobileV2Case.gql');
const GET_OFFERS = loader('src/GQL/Offers/GetOffers.gql');

interface Props {
	caseNo: string;
	caseId: string;
}

const DrivingSlipOfferView: React.FC<Props> = ({ caseNo, caseId }) => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();

	const [mailDocumentNo, setMailDocumentNo] = React.useState<string | undefined>();

	const { data: caseData } = useOfflineHandlingQuery<GetMobileV2Case>(GET_CASE, {
		variables: {
			id: caseId,
		},
	});

	const { data: offers, loading: loadingOffers } = useOfflineHandlingQuery<Offers>(GET_OFFERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			caseERPReferenceNo: caseNo,
		},
	});

	const [presentMailModal, dismissMailModal] = useIonModal(MailModal, {
		onDismiss: () => dismissMailModal(),
		data: caseData?.case,
		config: {
			defaultTabIndex: 1,
			fileNames: [mailDocumentNo],
			message: 'Tilbudsmail',
			recipient: caseData?.case.debitor.email ?? '',
			subject: `Tilbud - ${mailDocumentNo}`,
		},
	} as MailModalProps);

	return (
		<BasePage title={t('offer.overview')}>
			<IonFab vertical="top" horizontal="end" slot="fixed">
				<IonFabButton href={`/drivingslips/${id}/createoffer`}>
					<FontAwesomeIcon icon={faPlus} size="lg" />
				</IonFabButton>
			</IonFab>

			<div>
				<p className="text-blue text-base font-semibold">{t('case.caseNo')}:</p>
				<p>{caseNo}</p>
			</div>

			<Table
				data={offers?.offers ?? []}
				loading={loadingOffers || typeof offers === 'undefined'}
				noDataFoundText={t('common.noDataFound')}
				columns={[
					{
						label: t('offer.documentNo'),
						selectFn: o => <p>{o.lines[0]?.documentNo}</p>,
					},
					{
						label: t('offer.lines'),
						selectFn: o => <p>{o.lines.filter(l => !l.header).length}</p>,
					},
					{
						label: t('offer.oppertunityStatus'),
						selectFn: o => <p>{t(`offer.opportunityStatus.${o.opportunityStatus ?? ''}`)}</p>,
					},
					{
						label: t('offer.total'),
						selectFn: o => <p>{o.lines.reduce((acc, currentObj) => acc + (currentObj?.lineAmount ?? 0), 0).toLocaleString()}</p>,
					},
					{
						label: 'offer.sendOffer',
						selectFn: o => (
							<FontAwesomeIcon
								icon={faEnvelope}
								size="lg"
								onClick={() => {
									setMailDocumentNo(o.lines[0]?.documentNo);
									presentMailModal();
								}}
							/>
						),
					},
				]}
				keySelector={o => o.lines[0]?.documentNo ?? ''}
			/>
		</BasePage>
	);
};

export default DrivingSlipOfferView;
