import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrivingSlipStatus } from 'GQL';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import { ApiMutationKey, DrivingSlip, useSetDrivingSlipStatus } from 'Store';

export interface Props {
	drivingSlip: DrivingSlip;
	onDismiss: () => void;
}

const OnHoldDrivingSlipModal: React.FC<Props> = ({ drivingSlip, onDismiss }) => {
	const { t } = useTranslation();

	const [setDrivingSlipStatus] = useSetDrivingSlipStatus();

	return (
		<CloseableModal title={t('drivingSlips.onHoldDrivingSlip') + '?'} onDismiss={onDismiss}>
			<p className="text-center">{t('drivingSlips.onHoldDrivingSlip')}?</p>
			<Button
				expand="block"
				onClick={async () => {
					setDrivingSlipStatus({
						variables: {
							id: drivingSlip.id,
							status: DrivingSlipStatus.ON_HOLD,
						},
						optimisticResponse: {
							setDrivingSlipStatus: {
								...drivingSlip,
								status: DrivingSlipStatus.ON_HOLD,
							},
						},
						queueOfflineMutationOptions: {
							key: ApiMutationKey.DrivingSlip_SetStatus,
							removePreviousEntry: true,
							findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
						},
					});

					onDismiss();
				}}
			>
				{t('drivingSlips.onHoldDrivingSlip')}
			</Button>
			<Button expand="block" onClick={onDismiss}>
				{t('common.cancel')}
			</Button>
		</CloseableModal>
	);
};

export default OnHoldDrivingSlipModal;
