import React from 'react';
import { IonCol, IonInput, IonLabel, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { MoistureMeassurementInput } from 'GQL';
import Button from 'Components/Button';

interface Props {
	meassurements: MoistureMeassurementInput[];
	onChange(meassurements: MoistureMeassurementInput[]): void;
}

const EMPTY_ROW = (): MoistureMeassurementInput => ({
	value: '',
	construction: '',
	controlValue: '',
	description: '',
	expectedFinalValue: '',
	material: '',
	method: '',
	referenceValue: '',
	referenceDate: '',
	controlDate: '',
});

const TABLE_COLUMNS: Array<keyof MoistureMeassurementInput> = [
	'construction',
	'material',
	'description',
	'method',
	'value',
	'expectedFinalValue',
	'referenceValue',
	'referenceDate',
	'controlValue',
	'controlDate',
];

const MoistureMeassurementForm: React.FC<Props> = ({ meassurements, onChange }) => {
	const { t } = useTranslation();

	function handleChange(idx: number, column: keyof MoistureMeassurementInput, value: string) {
		const copy = meassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	function addRow() {
		onChange([...meassurements, EMPTY_ROW()]);
	}

	function removeRow(idx: number) {
		onChange(meassurements.filter((_, i) => i !== idx));
	}

	return (
		<IonCol size="12">
			<IonLabel className="text-blue text-base font-semibold">{t('reports.moistureMeassurement.title')}</IonLabel>
			{meassurements.map((meassurement, idx) => (
				<IonRow key={idx} className="border-1 mb-3">
					{TABLE_COLUMNS.map((column, i) => (
						<IonCol key={i}>
							<div className="flex flex-col">
								<IonLabel className="text-xs">{t(`reports.moistureMeassurement.${column}`)}</IonLabel>

								<IonInput className="border-2 border-gray-800" value={meassurement[column]} onIonChange={e => handleChange(idx, column, e.detail.value || '')} />

								{i === TABLE_COLUMNS.length - 1 && (
									<p className="text-red cursor-pointer pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
										x <span className="text-sm">Fjern linje</span>
									</p>
								)}
							</div>
						</IonCol>
					))}
				</IonRow>
			))}
			<Button onClick={addRow}>{t('reports.addMoistureMeassurement')}</Button>
		</IonCol>
	);
};

export default MoistureMeassurementForm;
