import { ONE_MINUTE, ONE_SECOND } from '../../constants';

export const DB_NAME = '5c.mobile.mutation.queue';
export const DB_VERSION = undefined;
export const OBJECT_STORE_NAME = 'mutations-queue';
export const INDEXED_PROP = 'queueName';

export const TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS = ONE_SECOND * 10;
export const TIME_TO_WAIT_BETWEEN_ERROR_RETRIES_MS = ONE_MINUTE;

export const MAX_NUMBER_OF_RETRIES = 3;
