import { loader } from 'graphql.macro';
import { CaseAdminData, CaseAdminDataQuery, CaseAdminDataQueryVariables, setCaseAdminData, useAppDispatch, useAppSelector } from 'Store';
import { useApiQuery } from '../useApiQuery';
import { ApiQueryKey } from '../../types';
import { useState } from 'react';
import { UseApiQueryReturn } from '../types';
import { API_QUERIES } from '../../constants';

const GET_CASE_ADMIN_DATA = loader('src/GQL/Cases/GetMobileV2CaseAdminData.gql');

export const useCaseAdminDataQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.CaseAdminData;

	const dispatch = useAppDispatch();

	const { metadata } = useAppSelector(state => state.apiData.caseAdminData);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<CaseAdminDataQuery, CaseAdminData, CaseAdminDataQueryVariables>({
		apiQueryKey,
		canFetchQuery: canFetchQuery,
		documentNode: GET_CASE_ADMIN_DATA,
		dataSelector: query =>
			query ?? {
				damageCategories: [],
				damageCauses: [],
				departments: [],
				locations: [],
				drivingSlipCategories: [],
				drivingSlipItems: [],
			},
		setCanFetchQuery,
		setData: partial => {
			dispatch(setCaseAdminData({ ...partial }));
		},
		options: {
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'no-cache',
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};
