import { ESDHFileFragment } from 'GQL';

export const checkFilesExist = <TFile extends { fileName: string; folder?: string }, TFiles extends ESDHFileFragment>(files: TFile[], filesToCheck: TFiles[]): TFile[] => {
	const images: TFile[] = [];
	files.forEach(file => {
		const existingFile = filesToCheck.find(f => f.name === file.fileName);
		if (typeof existingFile !== 'undefined') {
			images.push({
				fileName: existingFile.name,
				folder: file.folder,
			} as TFile);
		}
	});
	return images;
};

export const tryGetFile = <TFiles extends ESDHFileFragment>(fileName: string, filesToCheck: TFiles[]): ESDHFileFragment | null => {
	return filesToCheck.find(f => f.name === fileName) ?? null;
};

