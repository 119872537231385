import { DefaultContext } from '@apollo/client';
import { Data } from './types';

export const createEmptyData = <TQuery, TData, TVariables>(data: TData): Data<TQuery, TData, TVariables> => ({
	data,
	loading: false,
	metadata: { lastFetched: null, origin: 'local' },
	fetchMore: () => Promise.resolve(undefined),
	refetch: () => Promise.resolve(undefined),
});

export const toArraySafe = <T>(data: T[] | undefined): T[] => data ?? [];

export const createQueryContext = ({ debatch }: { debatch: boolean }): DefaultContext => {
	return { debatch: debatch };
};
