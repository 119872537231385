import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements as defineIonicElements } from '@ionic/pwa-elements/loader';
import { FlagProvider } from '@unleash/proxy-client-react';
import { MicrosoftAuthProvider } from '@ssg/common/Components/MicrosoftAuth';
import ApolloAuthenticationWrapper from './Components/ApolloAuthenticationWrapper';
import reportWebVitals from './reportWebVitals';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import EnvironmentVariableContext, { EnvironmentVariableContextProvider } from '@ssg/common/EnvironmentVariableContext';
import useDependencyConfiguration, { OnlineState } from '@ssg/common/Hooks/useDependencyConfiguration';
import { Provider } from 'react-redux';
import { ApiMutationProvider, persistor, store } from './Store';
import { PersistGate } from 'redux-persist/integration/react';
import { NetworkContextProvider, isConnected } from 'NetworkContext';
import { UserContextProvider } from 'UserContext';

import './i18n';
import './index.css';

const AppWrapper: React.FC = (): React.ReactElement => {
	const { msAuthority, msClientId, msScopeBc, msScopeApp, baseGraphUrl } = useContext(EnvironmentVariableContext);

	const [configured, { unleashConfig, telemetryPlugin, appInsights, offlineListener }] = useDependencyConfiguration({ enableOfflineTelemetry: true });

	useEffect(() => {
		if (!offlineListener) {
			return;
		}

		navigator.connection?.addEventListener('change', () => {
			const onlineState: OnlineState = isConnected() ? OnlineState.Online : OnlineState.Offline;
			console.log('Updating offline listener', { onlineState });
			offlineListener?.setOnlineState(onlineState);
		});
	}, [offlineListener]);

	if (!(configured && unleashConfig && telemetryPlugin && appInsights)) {
		return <></>;
	}

	return (
		<FlagProvider config={unleashConfig}>
			<MicrosoftAuthProvider
				msalConfig={{
					auth: {
						clientId: msClientId,
						authority: msAuthority,
						redirectUri: window.location.origin,
					},
				}}
				msalScopesApp={[msScopeApp]}
				msalScopesBC={[msScopeBc]}
				autoLogin
			>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<ApolloAuthenticationWrapper
							graphUrl={baseGraphUrl}
							onError={errorMessage => {
								appInsights.trackException({
									exception: new Error(errorMessage),
								});
							}}
						>
							<UserContextProvider>
								<NetworkContextProvider>
									<ApiMutationProvider>
										<App telemetryPlugin={telemetryPlugin} />
									</ApiMutationProvider>
								</NetworkContextProvider>
							</UserContextProvider>
						</ApolloAuthenticationWrapper>
					</PersistGate>
				</Provider>
			</MicrosoftAuthProvider>
		</FlagProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<EnvironmentVariableContextProvider name="mobile">
			<AppWrapper />
		</EnvironmentVariableContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// Register a service worker which caches static files so that the user can
// navigate and reload the application without an active network connection.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Enable PWA elements for Ionic
defineIonicElements(window);
