import React from 'react';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { Assortments, AssortmentsVariables, GetSamplePriceForLine, GetSamplePriceForLineVariables, OfferLineInput } from 'GQL';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { OfferRessourceType } from '@ssg/common/GraphQL';
import numberRound from '@ssg/common/Helpers/roundNumber';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import classNames from 'classnames';
import { useOfflineHandlingQuery } from 'Hooks';

const GET_LINE_PRICE = loader('src/GQL/Offers/GetSamplePriceForLine.gql');
const GET_ASSORTMENTS = loader('src/GQL/Offers/GetAssortments.gql');

interface Props {
	line: OfferLineInput & { totalCost: number };
	indexString: string;
	removeLine(thisLine: OfferLineInput & { totalCost: number }): void;
	changes(thisLine: OfferLineInput & { totalCost: number }): void;
	jobTasks: SelectOption[];
	assortmentCode: string;
	caseNo: string;
	competitionOffer: boolean;
}

const OfferLine: React.FC<Props> = ({ line, indexString, removeLine, changes, jobTasks, assortmentCode, competitionOffer }) => {
	const calcCoverage = (salesPrice: number, quantity: number, totalCost: number): number => {
		const coverage = (100 * (salesPrice * quantity - totalCost)) / (salesPrice * quantity);
		return isFinite(coverage) ? numberRound(coverage) : 0;
	};

	const calcSalesPrice = (coverage: number, quantity: number, totalCost: number): number => {
		const totalSales = totalCost / (1 - coverage / 100) / quantity;
		return isFinite(totalSales) ? numberRound(totalSales) : 0;
	};

	const [thisLine, setThisLine] = React.useState(line);
	const [thisCoverage, setThisCoverage] = React.useState(calcCoverage(line.newUnitPrice, line.quantity, line.totalCost));

	const { data: assortmentData } = useOfflineHandlingQuery<Assortments, AssortmentsVariables>(GET_ASSORTMENTS, {
		variables: {
			assortmentCode: assortmentCode,
			type: line.type,
		},

		skip: line.type === OfferRessourceType.TEXT,
	});

	const assortments = React.useMemo(() => {
		return (assortmentData?.assortments ?? []).map((w): SelectOption => ({ value: w.no, label: w.description }) ?? []);
	}, [assortmentData]);

	React.useEffect(() => {
		changes(thisLine);
		!competitionOffer && setThisCoverage(calcCoverage(thisLine.newUnitPrice, thisLine.quantity, thisLine.totalCost));
	}, [thisLine, competitionOffer]);

	React.useEffect(() => {
		competitionOffer &&
			setThisLine({
				...thisLine,
				newUnitPrice: calcSalesPrice(thisCoverage, thisLine.quantity, thisLine.totalCost),
			});
	}, [thisCoverage, competitionOffer]);

	const { data: priceData, variables: priceVariables } = useOfflineHandlingQuery<GetSamplePriceForLine, GetSamplePriceForLineVariables>(GET_LINE_PRICE, {
		fetchPolicy: 'cache-and-network',
		variables: {
			offerLine: {
				planningDate: thisLine.planningDate,
				description: thisLine.description,
				quantity: thisLine.quantity,
				unitPrice: thisLine.unitPrice,
				newUnitPrice: thisLine.unitPrice,
				no: thisLine.no,
				workType: thisLine.workType,
				documentNo: thisLine.documentNo,
				systemId: thisLine.systemId,
				offerLineStatus: thisLine.offerLineStatus,
				eRPReferenceTask: thisLine.eRPReferenceTask,
				type: thisLine.type,
				option: thisLine.option,
				hideSum: thisLine.hideSum,
				header: thisLine.header,
				sortingIndex: thisLine.sortingIndex,
				lineNo: thisLine.lineNo,
				caseERPReferenceNo: thisLine.caseERPReferenceNo,
			},
		},
		skip: thisLine.description === '' || thisLine.no === '' || thisLine.planningDate === '' || thisLine.description === '' || thisLine.type === OfferRessourceType.TEXT,
	});

	const prevLineUpdateRef = React.useRef(thisLine);
	React.useEffect(
		() =>
			setThisLine(currentLine => {
				if (typeof priceData === 'undefined' || typeof priceVariables === 'undefined') {
					return currentLine;
				}

				const result = {
					...currentLine,
					newUnitPrice:
						priceVariables.offerLine.eRPReferenceTask === prevLineUpdateRef.current.eRPReferenceTask
							? prevLineUpdateRef.current.newUnitPrice
							: priceData.samplePriceForLine.lineAmount / currentLine.quantity, // Only reset unit price if task changed
					totalCost: priceData.samplePriceForLine.totalCost,
				};

				prevLineUpdateRef.current = result; // "Hack" to avoid setting newUnitPrice to 0
				return result;
			}),
		[priceData, priceVariables],
	);

	React.useEffect(() => {
		prevLineUpdateRef.current.newUnitPrice = thisLine.newUnitPrice; // "Hack" to avoid setting newUnitPrice to 0 in the effect above
	}, [thisLine.newUnitPrice]);

	const offerResourceTypes = enumToSelectOptions(OfferRessourceType, 'offer.resourceTypes.').filter(r => r.value !== 'GL_ACCOUNT');

	return (
		<div className="ml-3 flex">
			<div className="mr-1 self-end font-semibold">{indexString}</div>
			<div className="flex w-full flex-row space-x-2">
				<div className="w-28">
					<Dropdown
						title="common.type"
						name=""
						data={offerResourceTypes}
						onChange={e =>
							setThisLine({
								...thisLine,
								type: e.currentTarget.value as OfferRessourceType,
							})
						}
						value={thisLine.type}
						disabled={thisLine.documentNo !== null}
					/>
				</div>

				{line.type === OfferRessourceType.TEXT ? (
					<div className="flex-grow-default">
						<Input
							title="offer.lineText"
							name=""
							onChange={e =>
								setThisLine({
									...thisLine,
									description: e.currentTarget.value,
								})
							}
							value={thisLine.description}
						/>
					</div>
				) : (
					<div className="w-32">
						<Dropdown
							title="common.description"
							name=""
							data={[{ value: '', label: '' }, ...assortments]}
							onChange={e =>
								setThisLine({
									...thisLine,
									no: e.currentTarget.value,
								})
							}
							value={thisLine.no}
						/>
					</div>
				)}

				<div
					className={classNames('w-32', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Dropdown
						title="offer.jobTask"
						name=""
						data={[{ label: '', value: '' }, ...jobTasks]}
						onChange={e =>
							setThisLine({
								...thisLine,
								eRPReferenceTask: e.currentTarget.value,
								description: jobTasks.find(jt => jt.value === e.currentTarget.value)?.label ?? '',
							})
						}
						value={thisLine.eRPReferenceTask}
					/>
				</div>

				<div
					className={classNames('flex-grow-default', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						title="offer.lineText"
						name=""
						onChange={e =>
							setThisLine({
								...thisLine,
								description: e.currentTarget.value,
							})
						}
						value={thisLine.description}
					/>
				</div>

				<div
					className={classNames('w-24', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						readOnly
						title="common.price"
						name=""
						className="w-24 cursor-not-allowed bg-gray-300 lg:w-24"
						//onChange={(e) => setThisLine({...thisLine, totalCost: parseInt(e.currentTarget.value)})}
						value={priceData?.samplePriceForLine.totalCost ?? 0}
					/>
				</div>

				<div
					className={classNames('w-28', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						readOnly
						title="offer.unitPriceExample"
						name=""
						className="w-28 cursor-not-allowed bg-gray-300 lg:w-28"
						//onChange={(e) => setThisLine({...thisLine, totalCost: parseInt(e.currentTarget.value)})}
						value={(priceData?.samplePriceForLine.lineAmount ?? 0) / thisLine.quantity}
					/>
				</div>
				<div
					className={classNames('w-22', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						title="common.quantity"
						unit={assortmentData?.assortments?.find(a => a.no === thisLine.no)?.uom}
						name=""
						className="w-22 lg:w-22"
						onChange={e =>
							setThisLine({
								...thisLine,
								quantity: e.currentTarget.valueAsNumber,
							})
						}
						value={thisLine.quantity}
						unitClassName="w-22 lg:w-22"
					/>
				</div>
				<div
					className={classNames('w-24', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						title="offer.newUnitPrice"
						name=""
						readOnly={competitionOffer}
						className={classNames('w-full lg:w-full', {
							'cursor-not-allowed bg-gray-300': competitionOffer,
						})}
						onChange={e =>
							setThisLine({
								...thisLine,
								newUnitPrice: e.currentTarget.valueAsNumber,
							})
						}
						value={thisLine.newUnitPrice}
					/>
				</div>
				<div
					className={classNames('w-24', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						readOnly
						title="offer.lineSum"
						name=""
						className="cursor-not-allowed bg-gray-300"
						//onChange={(e) => setThisLine({...thisLine, totalCost: parseInt(e.currentTarget.value)})}
						value={thisLine.newUnitPrice * thisLine.quantity}
					/>
				</div>
				<div
					className={classNames('w-28', {
						hidden: thisLine.type === OfferRessourceType.TEXT,
					})}
				>
					<Input
						type="number"
						title="case.economy.coverage"
						name=""
						unit="%"
						readOnly={!competitionOffer}
						className={classNames({
							'cursor-not-allowed bg-gray-300': !competitionOffer,
						})}
						onChange={e => competitionOffer && setThisCoverage(Math.min(e.currentTarget.valueAsNumber, 99.99))}
						//onChange={(e) => setThisLine({...thisLine, newUnitPrice: parseInt(e.currentTarget.value)})}
						value={thisCoverage}
					/>
				</div>
				<div className="mt-8">
					<Checkbox
						name=""
						title="offer.optional"
						onChange={e =>
							setThisLine({
								...thisLine,
								option: e.currentTarget.checked,
							})
						}
						checkedControlled={thisLine.option}
					/>
				</div>
				<div className="flex-shrink-default text-red mb-3 flex flex-wrap content-end">
					<Button icon={faTimes} onClick={() => removeLine(thisLine)} />
				</div>
			</div>
		</div>
	);
};

export default OfferLine;
