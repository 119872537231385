import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import FormFieldHeader from './FormFieldHeader';

interface Props {
	className?: string;
	unitClassName?: string;
	innerRef?: React.Ref<HTMLInputElement>;
	required?: boolean;
	title?: string;
	labelInfoPopover?: React.ReactElement;
	errorMessage?: string;
	helpText?: string;
	showHelpText?: boolean;
	type?: string;
	unit?: string;
	name: string;
	small?: boolean;
	compact?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	header?: React.ReactElement;
}
export type InputProps = Props;

const Input: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
	className,
	unitClassName,
	innerRef,
	title,
	labelInfoPopover,
	required = false,
	disabled = false,
	readOnly = false,
	errorMessage,
	helpText,
	showHelpText,
	type,
	unit,
	name,
	small = false,
	compact = false,
	header,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			{title && <FormFieldHeader htmlFor={name} required={required} title={title} labelInfoPopover={labelInfoPopover} />}
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			{header}
			<input
				{...restProps}
				name={name}
				id={name}
				ref={innerRef}
				type={type ? type : 'text'}
				readOnly={readOnly}
				disabled={disabled}
				autoComplete="off"
				placeholder={restProps.placeholder ? t(restProps.placeholder) : undefined}
				className={classNames(
					'border-1 rounded-default block w-full border-gray-600 px-1 focus:outline-none',
					{
						'border-red border-2': errorMessage,
						'py-1': !compact,
						'py-0': compact,
						'w-40': small,
						'cursor-not-allowed bg-gray-300': disabled || readOnly,
					},
					className,
				)}
			/>
			{unit && (
				<div
					className={classNames('text-blue -mt-7 mb-3 w-full pr-2 pt-1 text-right text-xs font-medium', unitClassName, {
						'w-40': small,
					})}
				>
					{t(unit)}
				</div>
			)}
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};
export default Input;
