import { IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import Button from '@ssg/common/Components/Button';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GetMobileV2DrivingSlip_drivingSlip_case } from 'GQL';

export interface Props {
	close: () => void;
	case: GetMobileV2DrivingSlip_drivingSlip_case;
}

const TimeRegLockedModal: React.FC<Props> = ({ close, case: _case }) => {
	const { t } = useTranslation();

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{t('timeRegistration.cantRegisterOnThisCaseTitle')}</IonTitle>
					<IonButtons slot="end">
						<Button onClick={close} text={t('common.close')} />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<p>
					{t('timeRegistration.cantRegisterOnThisCaseDescription')}: <strong>{_case.erpNo}</strong>
				</p>
				<ProjectManager case={_case} />
			</IonContent>
		</>
	);
};

const ProjectManager: React.FC<Pick<Props, 'case'>> = ({ case: _case }) => {
	const { t } = useTranslation();

	if (_case.projectManager)
		return (
			<>
				<Trans i18nKey="timeRegistration.contactProjectManagerWithQuestions" values={{ projectManagerName: _case.projectManager.name }} />
				<p>
					{t('common.email')}: {_case.projectManager.email}
				</p>
				{_case.projectManager.phone && (
					<p>
						{t('common.phone')}: {_case.projectManager.phone}
					</p>
				)}
			</>
		);

	if (_case.caseManager)
		return (
			<>
				<Trans i18nKey="timeRegistration.contactCaseManagerWithQuestions" values={{ caseManagerName: _case.caseManager.name }} />
				<p>
					{t('common.email')}: {_case.caseManager.email}
				</p>
				{_case.caseManager.phone && (
					<p>
						{t('common.phone')}: {_case.caseManager.phone}
					</p>
				)}
			</>
		);

	return <p>{t('timeRegistration.contactProjectManagerWithQuestions')}</p>;
};

export default TimeRegLockedModal;
