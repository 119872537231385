import React from 'react';
import { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CloseableModal from './CloseableModal';

interface File {
	name: string;
	extension: string;
	data: number[];
	base64Data: string;
}

export interface Props {
	file?: File;
	onDismiss: () => void;
}

const mimeTypes: { [index: string]: string } = {
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',
	ppt: 'application/vnd.ms-powerpoint',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const DocumentViewerModal: React.FC<Props> = ({ file, onDismiss }) => {
	const [doc, setDoc] = useState<IDocument>();

	useEffect(() => {
		if (file) {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(file.data)]));
			const mimeType = mimeTypes[file.extension];
			if (!mimeType) {
				console.error('Mime type not supported: ' + mimeType);
				return;
			}
			setDoc({
				uri: url,
				fileType: file.extension,
				fileName: file.name,
				fileData: `data:${mimeType};base64,${file.base64Data}`,
			});
		}
	}, [file]);

	return (
		<CloseableModal title={doc?.fileName ?? ''} onDismiss={onDismiss}>
			{doc && <DocViewer pluginRenderers={DocViewerRenderers} documents={[doc]} className="border-1 border-gray-400" />}
			{!doc && (
				<div className="text-center">
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
				</div>
			)}
		</CloseableModal>
	);
};

export default DocumentViewerModal;
