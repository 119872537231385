import { IonCol, IonInput, IonLabel } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	dehumidifierNumbers: string[];
	setDehumidifierNumbers: React.Dispatch<React.SetStateAction<string[]>>;
}

const DehumidifierNumbersForm: React.FC<Props> = ({ dehumidifierNumbers, setDehumidifierNumbers }) => {
	const { t } = useTranslation();

	return (
		<>
			<IonCol size="12">
				<IonLabel className="text-blue text-base font-semibold">{t('reports.dehumidifierNumbers')}</IonLabel>
			</IonCol>

			{Array(6)
				.fill(null)
				.map((_, idx) => (
					<IonCol size="4" key={idx}>
						<IonInput
							value={dehumidifierNumbers[idx]}
							onIonChange={e =>
								setDehumidifierNumbers(nums => {
									const copy = [...nums];

									if (e.detail.value != null) {
										copy[idx] = e.detail.value;
									}

									return copy;
								})
							}
							class="rounded-md border-2 border-gray-400 p-1"
						/>
					</IonCol>
				))}
		</>
	);
};

export default DehumidifierNumbersForm;
