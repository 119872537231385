import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonItem, ActionSheetButton, useIonActionSheet } from '@ionic/react';

import en from '@ssg/common/Components/Flags/en.svg';
import da from '@ssg/common/Components/Flags/da.svg';
import no from '@ssg/common/Components/Flags/no.svg';
import se from '@ssg/common/Components/Flags/se.svg';

export interface LanguageSelectOption {
	twoLetterCode: string;
	displayName: string;
	flag: string;
	isSelected: boolean;
}

const LanguageSelect: React.FC = () => {
	const { i18n, t } = useTranslation();
	const [presentLanguageOptions] = useIonActionSheet();

	const languages = [
		{ twoLetterCode: 'da', displayName: 'Dansk', flag: da },
		{ twoLetterCode: 'en', displayName: 'English', flag: en },
		{ twoLetterCode: 'no', displayName: 'Norsk', flag: no },
		{ twoLetterCode: 'se', displayName: 'Svenska', flag: se },
	];

	const availableLanguages: string[] = i18n.services.languageUtils.supportedLngs;
	const currentLanguage = i18n.language || window.localStorage.i18nextLng || '';

	const languageSelectOptions: LanguageSelectOption[] = [];
	availableLanguages.forEach(availableLanguage => {
		languages.forEach(language => {
			if (language.twoLetterCode === availableLanguage.toLowerCase()) {
				languageSelectOptions.push({
					twoLetterCode: language.twoLetterCode,
					displayName: language.displayName,
					flag: language.flag,
					isSelected: language.twoLetterCode === currentLanguage.toLowerCase(),
				});
			}
		});
	});

	const selectedLanguage = languageSelectOptions.find(language => language.isSelected);

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	const buttons: ActionSheetButton[] = languageSelectOptions
		.sort((a, b) => a.twoLetterCode.localeCompare(b.twoLetterCode))
		.map(languageSelectOption => ({
			text: languageSelectOption.displayName,
			handler: () => changeLanguage(languageSelectOption.twoLetterCode),
			role: languageSelectOption.isSelected ? 'selected' : undefined,
			icon: languageSelectOption.flag,
		}));

	const cancelButton: ActionSheetButton = {
		text: t('common.cancel'),
		role: 'cancel',
	};

	const handleClick = () => {
		presentLanguageOptions({
			buttons: buttons.concat(cancelButton),
			header: t('common.changeLanguage'),
		});
	};

	return (
		<IonItem button onClick={handleClick}>
			<IonIcon slot="start" src={selectedLanguage?.flag} title={selectedLanguage?.twoLetterCode}></IonIcon>
			{selectedLanguage?.displayName}
		</IonItem>
	);
};

export default LanguageSelect;
