import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	text: string;
}

const FormHelpText: React.FC<Props> = ({ text }): React.ReactElement => {
	const { t } = useTranslation();

	return <div className="text-blue w-full">{t(text)}</div>;
};

export default FormHelpText;
