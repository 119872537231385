import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
	icon: IconDefinition;
	text: string;
	danger?: boolean;
	success?: boolean;
	warning?: boolean;
}

const Alert: React.FC<Props> = ({ icon, text, danger = false, success = false, warning = false }): React.ReactElement => {
	const { t } = useTranslation();
	return (
		<div
			className={classNames('border-1 mb-2 rounded-md bg-opacity-25 p-2 text-sm', {
				'bg-red border-red text-red': danger,
				'bg-green border-green-dark text-green-dark': success,
				'bg-yellow border-yellow text-blue': warning,
			})}
		>
			<p className="font-semibold">
				<FontAwesomeIcon icon={icon} className="mr-3" />
				{t(text)}
			</p>
		</div>
	);
};

export default Alert;
