import React from 'react';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StartHibernationInput } from '../MachineActions';
import Button from '@ssg/common/Components/Button';
import CloseableModal from 'Components/CloseableModal';

export interface Props {
	startHibernation(input: StartHibernationInput): void;
	onDissmis(): void;
	loading: boolean;
}

const StartHibernationModal: React.FC<Props> = ({ startHibernation, onDissmis, loading }) => {
	const { t } = useTranslation();

	const { register, handleSubmit } = useForm<StartHibernationInput>();
	const [hibernationReason, setHibernationReasen] = React.useState('');

	return (
		<CloseableModal title={t('machines.startHibernation')} onDismiss={onDissmis}>
			<form onSubmit={handleSubmit(startHibernation)} className="flex flex-col gap-2">
				<IonItem>
					<IonLabel position="stacked">
						{t('machines.hibernationReason')}
						<span className="text-red">*</span>
					</IonLabel>
					<IonInput ref={register} name="hibernationReason" onIonChange={e => setHibernationReasen(e.detail.value ?? '')}></IonInput>
				</IonItem>

				<Button success submit disabled={hibernationReason.length === 0} loading={loading}>
					{t('machines.startHibernation')}
				</Button>
			</form>
		</CloseableModal>
	);
};

export default StartHibernationModal;
