import React from 'react';
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import CloseableModal from 'Components/CloseableModal';
import { CompletionEntry } from '@ssg/common/Helpers/Questionnaire/CompletionEntry';

export interface Props {
	incompleteEntries: CompletionEntry[];
	onEntryClick: (entry: CompletionEntry) => void;
	onDismiss: () => void;
}

const DrivingSlipQuestionnaireCompletionStatusModal: React.FC<Props> = ({ incompleteEntries, onEntryClick, onDismiss }) => {
	const { t } = useTranslation();
	return (
		<CloseableModal title={t('questionnaire.completionStatus')} onDismiss={onDismiss}>
			<div>
				<p className="text-orange text-center">
					<FontAwesomeIcon icon={faExclamationTriangle} />
				</p>
				<p className="text-center text-lg font-bold">{t('questionnaire.sectionsIncomplete')}</p>
				<p className="text-center">{t('questionnaire.sectionClickToNavigate')}</p>

				<div className="mt-4">
					{incompleteEntries.map(entry => (
						<IonBreadcrumbs key={entry.sectionName} onClick={() => onEntryClick(entry)} className="cursor-pointer">
							<IonBreadcrumb>{entry.sectionName}</IonBreadcrumb>
							<IonBreadcrumb>{entry.lastChoiceLabel}</IonBreadcrumb>
						</IonBreadcrumbs>
					))}
				</div>
			</div>
		</CloseableModal>
	);
};

export default DrivingSlipQuestionnaireCompletionStatusModal;
