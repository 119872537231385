import { createSlice } from '@reduxjs/toolkit';
import { GetMobileV2DrivingSlipsVariables } from 'GQL';
import { DEFAULT_DRIVING_SLIPS_FILTERS } from './constants';
import { Metadata } from '../ApiData';
import { CurrentUser } from 'UserContext';

export const LOCAL_DATA_KEY = 'localData';

export interface LocalDataState {
	currentUser: CurrentUser | null;
	drivingSlipsFilters: GetMobileV2DrivingSlipsVariables;
	networkConnectionStatus: boolean;
	prefetchedDrivingSlipsMetadata: {
		metadata: Metadata;
		relatedDrivingSlipsVariables: {
			seriesId: string;
			metadata: Metadata;
		}[];
		questionnaireTemplateVariables: {
			drivingSlipCategoryCode?: string | null;
			damageCauseId: string;
			metadata: Metadata;
		}[];
	};
	selectedDrivingSlipId?: string;
}

const initialState: LocalDataState = {
	currentUser: null,
	drivingSlipsFilters: DEFAULT_DRIVING_SLIPS_FILTERS,
	networkConnectionStatus: false,
	prefetchedDrivingSlipsMetadata: {
		metadata: {
			lastFetched: null,
		},
		relatedDrivingSlipsVariables: [],
		questionnaireTemplateVariables: [],
	},
	selectedDrivingSlipId: undefined,
};

const localDataSlice = createSlice({
	name: LOCAL_DATA_KEY,
	initialState,
	reducers: {
		setCurrentUser: (
			state,
			action: {
				payload: CurrentUser | null;
			},
		) => {
			state.currentUser = action.payload;
		},
		setDrivingSlipsFilters: (
			state,
			action: {
				payload: Partial<LocalDataState['drivingSlipsFilters']>;
			},
		) => {
			state.drivingSlipsFilters = {
				...state.drivingSlipsFilters,
				...action.payload,
			};
		},
		setNetworkConnectionStatus: (
			state,
			action: {
				payload: boolean;
			},
		) => {
			state.networkConnectionStatus = action.payload;
		},
		setPrefetchedDrivingSlipsMetadata: (
			state,
			action: {
				payload: Partial<LocalDataState['prefetchedDrivingSlipsMetadata']>;
			},
		) => {
			state.prefetchedDrivingSlipsMetadata = {
				...state.prefetchedDrivingSlipsMetadata,
				...action.payload,
			};
		},
		setSelectedDrivingSlipId: (
			state,
			action: {
				payload: string | undefined;
			},
		) => {
			state.selectedDrivingSlipId = action.payload;
		},
	},
});

export default localDataSlice.reducer;

export const { setCurrentUser, setDrivingSlipsFilters, setNetworkConnectionStatus, setPrefetchedDrivingSlipsMetadata, setSelectedDrivingSlipId } = localDataSlice.actions;
