import React from 'react';
import classNames from 'classnames';

interface Props {
	className?: string;
}

export const H1: React.FC<Props> = ({ children, className }): React.ReactElement => {
	return <p className={classNames('text-2xl', className)}>{children}</p>;
};

export const H3: React.FC<Props> = ({ children, className }): React.ReactElement => {
	return <p className={classNames('text-xl', className)}>{children}</p>;
};

export const P: React.FC<Props> = ({ children, className }): React.ReactElement => {
	return <p className={classNames('text-base', className)}>{children}</p>;
};
