import { loader } from 'graphql.macro';
import { useAppDispatch, useAppSelector, toArraySafe, RiskEvaluationCategories, RiskEvaluationCategoriesQuery, RiskEvaluationCategoriesQueryVariables, setRiskEvaluationCategories } from 'Store';
import { useApiQuery } from '../useApiQuery';
import { ApiQueryKey } from '../../types';
import { useState } from 'react';
import { UseApiQueryReturn } from '../types';
import { API_QUERIES } from '../../constants';

const GET_RISK_EVALUATION_CATEGORIES = loader('src/GQL/RiskEvaluation/GetRiskEvaluationCategories.gql');

export const useRiskEvaluationCategoriesQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.RiskEvaluationCategories;

	const dispatch = useAppDispatch();

	const { metadata } = useAppSelector(state => state.apiData.riskEvaluationCategories);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<RiskEvaluationCategoriesQuery, RiskEvaluationCategories, RiskEvaluationCategoriesQueryVariables>({
		apiQueryKey,
		canFetchQuery: canFetchQuery,
		documentNode: GET_RISK_EVALUATION_CATEGORIES,
		dataSelector: query => toArraySafe(query?.riskEvaluationCategories),
		setCanFetchQuery,
		setData: partial => {
			dispatch(setRiskEvaluationCategories({ ...partial }));
		},
		options: {
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'no-cache',
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};

