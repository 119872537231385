import { useIonToast } from '@ionic/react';
import { useUnleashClient } from '@unleash/proxy-client-react';
import { setNetworkConnectionStatus, useAppDispatch } from 'Store';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NetworkContextValues {
	connected: boolean;
	type: string;
}

const NetworkContext = React.createContext<NetworkContextValues>({
	connected: true,
	type: '4g',
});

export const getNetworkContext = (networkInfo: NetworkInformation): NetworkContextValues => {
	if (typeof networkInfo.effectiveType !== 'undefined' && typeof networkInfo.downlink !== 'undefined' && typeof networkInfo.rtt !== 'undefined') {
		if (networkInfo.effectiveType !== '4g' && networkInfo.effectiveType !== '3g') {
			return {
				connected: false,
				type: networkInfo.effectiveType,
			};
		}
		if (networkInfo.downlink === 0) {
			return {
				connected: false,
				type: networkInfo.effectiveType,
			};
		}
		if (networkInfo.downlink === 10 && networkInfo.rtt === 0) {
			return {
				connected: false,
				type: networkInfo.effectiveType,
			};
		}
		if (networkInfo.downlink <= 1.5 && networkInfo.rtt >= 600) {
			return {
				connected: false,
				type: networkInfo.effectiveType,
			};
		}

		return {
			connected: true,
			type: networkInfo.effectiveType,
		};
	}

	return {
		connected: false,
		type: '4g',
	};
};

export const isConnected = () => {
	const connection = navigator.connection;
	if (connection) {
		return getNetworkContext(connection).connected;
	}

	return false;
};
export const NetworkContextProvider: React.FC = ({ children }) => {
	const [present, dismiss] = useIonToast();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [context, setContext] = React.useState<NetworkContextValues>(() => {
		const conn = navigator.connection;
		if (conn) {
			return getNetworkContext(conn);
		}
		return {
			connected: false,
			type: '4g',
		};
	});

	const client = useUnleashClient();

	React.useEffect(() => {
		navigator.connection?.addEventListener('change', (event) => {
			const target = event.currentTarget as NetworkInformation;
			const context = getNetworkContext(target);
			setContext(context);
		});
	}, [client]);

	React.useEffect(() => {
		const message = context.connected ? t('backgroundSync.nowOnline') : t('backgroundSync.nowOffline');
		const color = context.connected ? 'success' : 'warning';
		dispatch(setNetworkConnectionStatus(context.connected));
		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: message,
			duration: 3000,
			color: color,
			position: 'bottom',
		});
	}, [context.connected]);

	return (
		<NetworkContext.Provider value={context}>
			{children}
		</NetworkContext.Provider>
	);
};

export default NetworkContext;