import { loader } from 'graphql.macro';
import { ApiQueryKey, DrivingSlips, DrivingSlipsQuery, DrivingSlipsQueryVariables, UseApiQueryReturn, setDrivingSlips, toArraySafe, useAppDispatch, useAppSelector } from 'Store';
import { useState } from 'react';
import { API_QUERIES } from '../../constants';
import { useApiQuery } from '../useApiQuery';

const GET_DRIVING_SLIPS = loader('src/GQL/DrivingSlips/GetMobileV2DrivingSlips.gql');

export const useDrivingSlipsQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.DrivingSlips;

	const dispatch = useAppDispatch();

	const { drivingSlipsFilters } = useAppSelector(state => state.localData);

	const { metadata } = useAppSelector(state => state.apiData.drivingSlips);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<DrivingSlipsQuery, DrivingSlips, DrivingSlipsQueryVariables>({
		apiQueryKey,
		debatch: true,
		canFetchQuery: canFetchQuery,
		documentNode: GET_DRIVING_SLIPS,
		variables: drivingSlipsFilters,
		dataSelector: query => toArraySafe(query?.drivingSlips),
		setCanFetchQuery,
		setData: partial => {
			dispatch(setDrivingSlips({ ...partial }));
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};
