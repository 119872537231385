import { faExclamationTriangle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '@ssg/common/Components/Alert';
import Button from '@ssg/common/Components/Button';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import { GeoCoordinateInput } from 'GQL';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const options: PositionOptions = {
	enableHighAccuracy: true,
	timeout: 10000,
	maximumAge: 0,
};

const optionsLowAcuracy: PositionOptions = {
	enableHighAccuracy: false,
	timeout: 10000,
	maximumAge: 0,
};

const getLocationLowAccuracy = (locationCallback: (coords: GeolocationCoordinates) => void, errorCallback: (e: GeolocationPositionError) => void) => {
	navigator.geolocation.getCurrentPosition(
		p => {
			locationCallback(p.coords);
			console.log('low accuracy', p.coords);
		},
		e => {
			console.log('low accuracy error', e);
			errorCallback(e);
		},
		optionsLowAcuracy,
	);
};

const getLocation = (locationCallback: (coords: GeolocationCoordinates) => void, errorCallback: (e: GeolocationPositionError) => void) => {
	navigator.geolocation.getCurrentPosition(
		p => {
			locationCallback(p.coords);
			console.log('high accuracy', p.coords);
		},
		e => {
			console.log('high accuracy error', e);
			getLocationLowAccuracy(locationCallback, errorCallback);
		},
		options,
	);
};

export function useGetLocation() {
	const mobileTrackLocationDataFlag = useFlag(FeatureFlagEnums.MOBILE_TRACK_LOCATION_DATA);
	const { t } = useTranslation();
	const { environmentTag } = useContext(EnvironmentVariableContext);
	const [locationCoordinates, setLocationCoordinates] = React.useState<GeoCoordinateInput>();
	const [gettingLocation, setGettingLocation] = React.useState(mobileTrackLocationDataFlag);
	const [permissionDeniedError, setPermissionDeniedError] = React.useState(false);

	React.useEffect(() => {
		if (mobileTrackLocationDataFlag) {
			getLocation(coords => {
				setLocationCoordinates({ latitude: coords.latitude, longitude: coords.longitude });
				setGettingLocation(false);
			},
				(e) => {
					setGettingLocation(false);
					if (e.code === 1) {
						console.log('PERMISSION DENIED');
						setPermissionDeniedError(true);
					}
				},
			);
		}
	}, [mobileTrackLocationDataFlag]);

	function coordinatesElement(): JSX.Element {
		if (mobileTrackLocationDataFlag && environmentTag !== 'PROD') {
			return <p>Kun synligt i test: Get location long: {locationCoordinates?.longitude} lat: {locationCoordinates?.latitude}</p>;
		}

		return (<></>);
	}

	function loadingElement(): JSX.Element {
		if (mobileTrackLocationDataFlag && gettingLocation) {
			return <p><FontAwesomeIcon icon={faSpinner} className='animate-spin' />Finder lokation ...</p>;
		}

		if (mobileTrackLocationDataFlag && permissionDeniedError) {
			return <div
				className="border-1 mb-2 rounded-md bg-opacity-25 p-2 text-sm bg-yellow border-yellow text-blue w-full flex flex-row items-center"
			>
				<FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
				<p className="font-semibold">
					<p className='whitespace-pre-wrap'>{t('error.locationBlocked')}</p>
				</p>
			</div>;
		}

		return (<></>);
	}


	return {
		locationCoordinates,
		coordinatesElement,
		gettingLocation,
		loadingElement,
	};
}
