import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonMenu, IonList, IonMenuToggle, IonIcon, IonLabel, IonPage, IonButtons, IonItem, IonFooter } from '@ionic/react';
import { menu, personCircle, logOut, car, time, cart, briefcase, cubeOutline, trashBin } from 'ionicons/icons';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import SmallLogo from '@ssg/common/Components/Logo/SmallLogo';
import Routes from 'Routes';
import NetworkInfoFooter from '../NetworkInfoFooter';
import LanguageSelect from '../LanguageSelect';
import Button from '../Button';
import PWAInstallerFooter from '../PWAInstallerFooter';
import classNames from 'classnames';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { ApiMutationContext, resetStateAction } from 'Store';
import { useDispatch } from 'react-redux';
import Loading from '@ssg/common/Components/Loading';
import NetworkContext, { isConnected } from 'NetworkContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faWifi } from '@fortawesome/pro-regular-svg-icons';
import { faCloudUploadAlt, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApolloError } from '@apollo/client';
import newGuid from '@ssg/common/Helpers/guidHelper';

const getNetwork = () => {
	const conn = navigator.connection;
	if (conn) {
		alert(JSON.stringify({ effectiveType: conn.effectiveType, downlink: conn.downlink, rtt: conn.rtt }));
	}
};

const Main: React.FC<{ appInsights: ReactPlugin }> = ({ appInsights, children }): React.ReactElement => {
	const { t } = useTranslation();

	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);

	const {
		offlineMutationQueueEvents: { queueUpdated, onStart, onCompleteAll, onError },
		mutationCounter,
	} = React.useContext(ApiMutationContext);
	const [queueCount, setQueueCount] = useState(mutationCounter);
	const [sync, setSync] = useState(false);

	React.useEffect(() => {
		queueUpdated(count => {
			setQueueCount(count);
		});
	}, [queueUpdated]);

	React.useEffect(() => {
		onStart(_ => {
			setSync(true);
		});
	}, [onStart]);

	React.useEffect(() => {
		onCompleteAll(() => {
			setSync(false);
		});
	}, [onCompleteAll]);

	React.useEffect(() => {
		onError(queueEntry => {
			const canRetry = queueEntry.metadata.numberOfRetries < queueEntry.metadata.maxNumberOfRetries;
			const severityLevel = canRetry ? SeverityLevel.Error : SeverityLevel.Critical;
			const apolloError = queueEntry?.error as ApolloError;

			const errorMessage = typeof apolloError !== 'undefined' && 'message' in apolloError ? apolloError.message : 'unknown';
			const stack = typeof apolloError !== 'undefined' && 'stack' in apolloError ? apolloError.message : '';
			const toString = typeof apolloError !== 'undefined' && apolloError?.toString();

			const exception = new Error(`Queued mutation failed. Error: ${errorMessage}`);

			const apolloErrorCorrelationId = newGuid();
			appInsights?.trackException({
				exception: exception,
				severityLevel,
				properties: {
					apolloErrorCorrelationId: apolloErrorCorrelationId,
					stack: stack,
					toString: toString,
				},
			});

			if (!canRetry) {
				appInsights.trackTrace({
					message: `Queued mutation has exceeded max. no. of retries. Key: ${queueEntry.data.key}. Max. retries: ${queueEntry.metadata.maxNumberOfRetries}. Error: ${errorMessage}`,
					severityLevel,
					properties: {
						apolloErrorCorrelationId: apolloErrorCorrelationId,
						stack: stack,
						toString: toString,
					},
				});
			}
		});
	}, [appInsights, onError]);

	React.useEffect(() => {
		if (queueCount === 0) {
			setSync(false);
		}
	}, [queueCount]);

	const dispatch = useDispatch();

	const networkContext = React.useContext(NetworkContext);

	const { logout, account } = useMicrosoftAuth();

	const accountName = account?.name || '';

	const { environmentTag, siteUrlWeb } = useContext(EnvironmentVariableContext);

	//useInitNetworkConnectionStatus();

	const [clearingCache, setClearingCache] = useState<boolean>(false);

	const [version, setVersion] = useState<string>('0.0.0.0');

	useEffect(() => {
		if (isConnected()) {
			fetch('/version.json')
				.then(response => response.text())
				.then(data => setVersion(data))
				.catch(reason => console.log(reason));
		}
	}, []);

	const clearCache = async () => {
		const { connected } = networkContext;
		if (!connected) {
			alert(t('common.clearCacheOnlineOnly'));
			return;
		}

		setClearingCache(true);

		dispatch(resetStateAction());

		setTimeout(() => {
			// Force navigate back to home page
			window.location.href = Routes.Default;
		}, 2000);
	};

	return (
		<>
			{clearingCache && <Loading />}

			<IonMenu content-id="main-content">
				<IonHeader>
					<IonToolbar color="primary">
						<IonTitle>{t('common.menu')}</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<IonList>
						<IonMenuToggle auto-hide="false">
							<IonItem button routerLink={Routes.DrivingSlipsOverview}>
								<IonIcon slot="start" icon={car} />
								<IonLabel>{t('drivingSlips.overviewTitle')}</IonLabel>
							</IonItem>
							{newTimeregistration
								? (
									<IonItem button routerLink={Routes.TimeRegistrationOverview}>
										<IonIcon slot="start" icon={time} />
										<IonLabel>{t('timeRegistration.overviewTitle')}</IonLabel>
									</IonItem>
								) : (
									<IonItem button routerLink={Routes.TimeRegistrationOverview}>
										<IonIcon slot="start" icon={time} />
										<IonLabel>{t('timeRegistration.overviewTitle')}</IonLabel>
									</IonItem>
								)
							}
							<IonItem button routerLink={Routes.RequisitionsOverview}>
								<IonIcon slot="start" icon={cart} />
								<IonLabel>{t('requisitions.overviewTitle')}</IonLabel>
							</IonItem>
							<IonItem button routerLink={Routes.MachinesOverview}>
								<IonIcon slot="start" icon={briefcase} />
								<IonLabel>{t('machines.overviewTitle')}</IonLabel>
							</IonItem>
							<IonItem button href={`${siteUrlWeb}/movables`}>
								<IonIcon slot="start" icon={cubeOutline} />
								<IonLabel>{t('common.moveables')}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					</IonList>
				</IonContent>

				<div className="my-4 flex justify-center font-semibold">
					<small>version: {version}</small>
				</div>

				<IonFooter>
					<IonItem button>
						<IonIcon slot="start" icon={personCircle}></IonIcon>
						<IonLabel>{accountName}</IonLabel>
					</IonItem>

					<LanguageSelect />

					<IonItem button onClick={clearCache}>
						<IonIcon slot="start" icon={trashBin}></IonIcon>
						<IonLabel>{t('common.clearCache')}</IonLabel>
					</IonItem>

					<IonItem button onClick={() => logout()}>
						<IonIcon slot="start" icon={logOut}></IonIcon>
						<IonLabel>{t('common.logout')}</IonLabel>
					</IonItem>
				</IonFooter>
			</IonMenu>
			<IonPage className="ion-page" id="main-content">
				<IonHeader>
					<IonToolbar color="primary">
						<IonButtons slot="start">
							<IonMenuToggle>
								<Button>
									<IonIcon slot="icon-only" icon={menu} />
								</Button>
							</IonMenuToggle>
						</IonButtons>
						<IonTitle>
							<Link to={Routes.DrivingSlipsOverview}>
								<SmallLogo className="inline h-9 w-9 object-contain"></SmallLogo>
								<span className="ml-5 align-middle text-2xl font-semibold italic">5C</span>
							</Link>
						</IonTitle>
						<IonButtons slot="secondary">
							<div onClick={() => getNetwork()}>
								<FontAwesomeIcon icon={networkContext.connected ? faWifi : faWifiSlash} size="lg" className={networkContext.connected ? 'text-white' : 'text-red'} />
							</div>
							<div className="ml-2 mr-4 relative">
								<FontAwesomeIcon
									icon={sync ? faCloudUploadAlt : faCloud}
									className={classNames('text-white', {
										'animate-pulse': sync,
									})}
									size="lg"
								/>
								<div
									className="h-5 w-5 justify-center items-center flex text-white rounded-full absolute top-0 -mt-2 right-0 border-white border-2 bg-blue"
									style={{ marginRight: -9, fontSize: 10 }}
								>
									<p>{queueCount}</p>
								</div>
							</div>
							<div className="h-8 pr-4">
								<p
									className={classNames('rounded-default w-18 border-2 border-white pt-px text-center font-bold', {
										'bg-orange': environmentTag === 'TEST' || environmentTag === 'DEV',
										'text-blue bg-white': environmentTag === 'PROD',
									})}
								>
									{environmentTag}v2
								</p>
							</div>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent className="ion-padding">{children}</IonContent>

				<NetworkInfoFooter />
				<PWAInstallerFooter />
			</IonPage>
		</>
	);
};

export default Main;
