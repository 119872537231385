import React, { useContext, useEffect, useState, useRef } from 'react';
import { IonFooter, IonToolbar, IonTitle, IonProgressBar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ApiMutationContext } from 'Store';
//import { useConnectionStatusChangeAlert } from 'Hooks';
//import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const NetworkInfoFooter: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	//const { environmentTag } = useContext(EnvironmentVariableContext);
	const { offlineMutationQueueEvents } = useContext(ApiMutationContext);

	const [showProgressBar, setShowProgressBar] = useState<{ totalCount: number, currentCount: number } | undefined>(undefined);

	const timeoutRef = useRef<number>();

	useEffect(() => {
		window.clearTimeout(timeoutRef.current);

		offlineMutationQueueEvents.onStart((_, totalCount, currentCount) => {
			setShowProgressBar({ totalCount, currentCount });
		});

		offlineMutationQueueEvents.onCompleteAll(() => {
			timeoutRef.current = window.setTimeout(() => {
				setShowProgressBar(undefined);
			}, 1000);
		});
	}, [offlineMutationQueueEvents]);

	//useConnectionStatusChangeAlert();

	// Don't show in stage/prod
	if (true) {
		return <></>;
	}

	// return (
	// 	<IonFooter>
	// 		<IonToolbar>
	// 			<IonTitle size="small" className="text-center">
	// 				{t('backgroundSync.syncInProgress')} {showProgressBar.currentCount}/{showProgressBar.totalCount}
	// 			</IonTitle>
	// 			<IonProgressBar type="indeterminate" className="mb-1" />
	// 		</IonToolbar>
	// 	</IonFooter>
	// );
};

export default NetworkInfoFooter;
