import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonAccordion,
	IonAccordionGroup,
	IonChip,
	IonCol,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonRow,
	IonSkeletonText,
	IonTitle,
	IonToolbar,
	useIonModal,
} from '@ionic/react';
import { checkmarkCircle, warning } from 'ionicons/icons';
import DrivingSlipQuestionnaireChoice from './DrivingSlipQuestionnaireChoice';
import { buildCompletablePathOfChoicesFromSection, buildCompletablePathOfChoicesFromChoice, choiceTypeHasSelectableOptions } from '@ssg/common/Helpers/Questionnaire/QuestionnaireChoicesBuilder';
import {
	BaseQuestionnaireTemplateFragment_sections as Section,
	BaseQuestionnaireTemplateFragment_sections_choices as Choice,
	GetDrivingSlipFiles_drivingSlipFiles,
	FileMetadataField,
	GetDrivingSlipFiles,
	GetDrivingSlipFilesVariables,
	QuestionnaireChoiceType,
	GetDrivingSlipFiles_drivingSlipFiles_metadata,
	GetMobileV2DrivingSlipFile,
	GetMobileV2DrivingSlipFileVariables,
} from 'GQL';
import { SelectOption, formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { SelectableChoice } from './SelectableChoice';
import Button from 'Components/Button';
import { stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import ImageEditModal, { Props as ImageEditModalProps } from 'Components/ImageEditModal';
import AddCommentsToFilesModal, { AddCommentsToFilesModalREST, AddingFilesType, Props as ChosenImagesModalProps, PropsRest } from 'Components/AddCommentsToFilesModal';
import { useSinglePhotoCapture, UserPhoto } from '../../../../Hooks/usePhotoCapture';
import { getQuestionnaireCompletionEntriesForSection } from '@ssg/common/Helpers/Questionnaire/QuestionnaireCompletionEntriesBuilder';
import { CameraSource } from '@capacitor/camera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { getFileName, usePhotoChooser } from '../../../../Hooks/usePhotoChooser';
import { UploadDrivingSlipFilesProps } from './DrivingSlipQuestionnaireOverview';
import { loader } from 'graphql.macro';
import { ISyncedChoices, QuestionnaireSyncContext } from './QuestionnaireSyncContext';
import { ApiMutationKey, useModifyDrivingSlipFileMetadata } from 'Store';
import { useLazyQuery } from '@apollo/client';
import { isConnected } from 'NetworkContext';
import Loading from '@ssg/common/Components/Loading';
import { useUploadDrivingSlipFilesRest } from 'Hooks/useUploadDrivingSlipFilesRest';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_DRIVING_SLIP_FILES = loader('src/GQL/Files/GetDrivingSlipFiles.gql');
const GET_MOBILE_V2_DRIVING_SLIP_FILE = loader('src/GQL/Files/GetMobileV2DrivingSlipFile.gql');

function choiceToSelectableChoice(allChoices: Choice[], choice: Choice): SelectableChoice {
	const selectOptions = getSelectOptionsForChoice(allChoices, choice);
	return { ...choice, selectOptions };
}

function getSelectOptionsForChoice(allChoices: Choice[], choice: Choice): SelectOption[] {
	return choiceTypeHasSelectableOptions(choice.type) ? allChoices.filter(c => c.parentId === choice.id).map(c => ({ label: c.label, value: c.value }) as SelectOption) : [];
}

interface Props {
	section: Section;
	rootIdPrefix: string;
	onChangeChoice: (sectionName: string, choiceId: string, newValue: any) => void;
	disabledChoices?: boolean;
	files: GetDrivingSlipFiles_drivingSlipFiles[];
	filesLoading: boolean;
	onFileUpload: (props: UploadDrivingSlipFilesProps) => Promise<void>;
	onFileUploadLoading: boolean;
	caseNo: string;
	drivingSlipSeries: string;
}

const choicesToSelectableChoices = (sectionChoices: Choice[], choices: Choice[]): SelectableChoice[] => choices.map(choice => choiceToSelectableChoice(sectionChoices, choice));

const DrivingSlipQuestionnaireSection: React.FC<Props> = ({
	section,
	rootIdPrefix,
	onChangeChoice,
	disabledChoices,
	files,
	filesLoading,
	onFileUpload,
	onFileUploadLoading,
	caseNo,
	drivingSlipSeries,
}) => {
	const { t } = useTranslation();

	const { syncedChoices } = useContext(QuestionnaireSyncContext);


	const [selectableChoices, setSelectableChoices] = useState<SelectableChoice[]>(choicesToSelectableChoices(section.choices, buildCompletablePathOfChoicesFromSection(rootIdPrefix, section)));

	// Disgusting questionare no choices fix
	React.useEffect(() => {
		if (selectableChoices.length === 0) {
			setSelectableChoices(choicesToSelectableChoices(section.choices, buildCompletablePathOfChoicesFromSection(rootIdPrefix, section)));
		}
	}, [rootIdPrefix, section, selectableChoices.length]);
	const accordionRef = useRef<HTMLIonAccordionGroupElement>(null);

	const [photoToEdit, setPhotoToEdit] = useState<UserPhoto>();
	const { takePhoto } = useSinglePhotoCapture();
	const { photos, choosePhotos, resetPhotos } = usePhotoChooser();

	const uploadPhotos = (photos: UserPhoto[], comments?: string) => {
		const files = photos.map(photo => {
			const blob = photo.webviewPath;
			const fileAsBytes = stringToByteArray(photo.base64EncodedData);
			const filename = getFileName(photo);
			return { filename, fileData: fileAsBytes, blob };
		});

		return onFileUpload({ sectionName: section.label, files, comments });
	};

	const [presentImageEditModal, dismissImageEditModal] = useIonModal(ImageEditModal, {
		initialPhoto: photoToEdit,
		onSubmit: async (photo: UserPhoto, photoHasChanged: boolean, comments?: string) => {
			setPhotoToEdit(undefined);

			dismissImageEditModal();

			if (photoHasChanged) {
				// Upload new photo
				await uploadPhotos([photo], comments);
				return;
			}
			if (photo.metadata) {
				const originalComments = photo.metadata.comments;
				if (originalComments !== comments && comments) {
					// Otherwise only update the comments
					await modifyDrivingSlipFileMetadata({
						variables: {
							caseNo: caseNo,
							drivingSlipId: drivingSlipSeries,
							fileName: photo.metadata?.fileName as string,
							metadata: [
								{
									key: FileMetadataField.Comments,
									value: comments,
								},
							],
						},
						queueOfflineMutationOptions: {
							key: ApiMutationKey.DrivingSlip_ModifyDrivingSlipFileMetadata,
							removePreviousEntry: true,
							findPreviousEntryPredicate(variables) {
								return variables.fileName === photo.metadata?.fileName;
							},
						},
						onOptimisticResponseOverride(cache) {
							const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
								query: GET_DRIVING_SLIP_FILES,
								variables: {
									caseNo: caseNo,
									drivingSlipId: drivingSlipSeries,
								},
							});
							// console.log('🚀 ~ onOptimisticResponseOverride ~ cachedRequest:', cachedRequest);

							if (cachedRequest == null || cachedRequest.drivingSlipFiles == null) return;
							// console.log('🚀 ~ onOptimisticResponseOverride ~ cachedRequest:', cachedRequest);

							const fileId = photo.metadata?.fileId;
							if (!fileId) return;

							const indexToChange = cachedRequest.drivingSlipFiles.findIndex(cf => cf.fileId === fileId);
							if (indexToChange === -1) return;
							const fileToChange = cachedRequest.drivingSlipFiles[indexToChange];
							const metaDataArr: GetDrivingSlipFiles_drivingSlipFiles_metadata[] = fileToChange.metadata.slice();
							const metaDataIndex = metaDataArr.findIndex(d => d.key === FileMetadataField.Comments);
							if (metaDataIndex > -1) {
								metaDataArr[metaDataIndex] = { key: FileMetadataField.Comments, value: comments };
							} else {
								metaDataArr.push({ key: FileMetadataField.Comments, value: comments });
							}

							const replaceFiles = [
								...cachedRequest.drivingSlipFiles.slice(0, indexToChange),
								{
									...fileToChange,
									metadata: metaDataArr,
									comments: comments,
								},
								...cachedRequest.drivingSlipFiles.slice(indexToChange + 1),
							];
							// console.log('🚀 ~ onOptimisticResponseOverride ~ replaceFiles:', replaceFiles);
							cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
								query: GET_DRIVING_SLIP_FILES,
								variables: {
									caseNo: caseNo,
									drivingSlipId: drivingSlipSeries,
								},
								data: {
									drivingSlipFiles: replaceFiles,
								},
							});
						},
						// update: (cache, mutationResult) => {
						// 	if (mutationResult.data == null) return;

						// 	const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						// 		query: GET_DRIVING_SLIP_FILES,
						// 		variables: {
						// 			caseNo: caseNo,
						// 			drivingSlipId: drivingSlipSeries,
						// 		},
						// 	});

						// 	if (cachedRequest == null || cachedRequest.drivingSlipFiles == null) return;

						// 	const fileId = photo.metadata?.fileId;
						// 	if (!fileId) return;

						// 	const indexToChange = cachedRequest.drivingSlipFiles.findIndex(cf => cf.fileId === fileId);
						// 	if (indexToChange === -1) return;
						// 	console.log('index found');
						// 	const replaceFiles = [
						// 		...cachedRequest.drivingSlipFiles.slice(0, indexToChange),
						// 		{
						// 			...cachedRequest.drivingSlipFiles[indexToChange],
						// 			...mutationResult.data.modifyDrivingSlipFileMetadata,
						// 		},
						// 		...cachedRequest.drivingSlipFiles.slice(indexToChange + 1),
						// 	];
						// 	cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						// 		query: GET_DRIVING_SLIP_FILES,
						// 		variables: {
						// 			caseNo: caseNo,
						// 			drivingSlipId: drivingSlipSeries,
						// 		},
						// 		data: {
						// 			drivingSlipFiles: replaceFiles,
						// 		},
						// 	});
						// },
					});
				}
			}
		},
		onDismiss: () => {
			setPhotoToEdit(undefined);
			dismissImageEditModal();
		},
	} as ImageEditModalProps);

	const [presentAddCommentsToFilesModal, dismissAddCommentsToFilesModal] = useIonModal(AddCommentsToFilesModal, {
		addingFilesType: AddingFilesType.Photos,
		initialFiles: photos,
		onSubmit: async (photos: (UserPhoto & { blob: string })[], comments?: string) => {
			dismissAddCommentsToFilesModal();
			resetPhotos();
			uploadPhotos(photos, comments);
		},
		onDismiss: () => {
			dismissAddCommentsToFilesModal();
			resetPhotos();
		},
	} as ChosenImagesModalProps);

	const [getDrivingSlipFile, { loading: fileToDownloadLoading }] = useLazyQuery<GetMobileV2DrivingSlipFile, GetMobileV2DrivingSlipFileVariables>(GET_MOBILE_V2_DRIVING_SLIP_FILE, {
		fetchPolicy: 'no-cache',
	});

	const getFileForEdit = async (file: GetDrivingSlipFiles_drivingSlipFiles) => {
		if (!file.thumbnail.includes('blob')) {
			if (isConnected()) {
				const downloadedFileData = await getDrivingSlipFile({
					variables: {
						fileName: file.name,
						caseNo: caseNo,
						drivingSlipId: drivingSlipSeries,
					},
				});
				const downloadedFile = downloadedFileData.data?.drivingSlipFile;
				if (downloadedFile) {
					const url = window.URL.createObjectURL(new Blob([new Uint8Array(downloadedFile.fileData)]));
					setPhotoToEdit({
						filepath: '', // Needs to be set but won't be used downstream
						format: file.extension,
						webviewPath: url,
						base64EncodedData: downloadedFile.fileDataBase64,
						metadata: {
							fileId: file.fileId,
							fileName: file.name,
							comments: file.comments ?? undefined,
						},
					});
					return;
				}
			}
		}
		setPhotoToEdit({
			filepath: '', // Needs to be set but won't be used downstream
			format: file.extension,
			webviewPath: file.thumbnail,
			base64EncodedData: '',
			metadata: {
				fileId: file.fileId,
				fileName: file.name,
				comments: file.comments ?? undefined,
			},
		});
	};

	const [modifyDrivingSlipFileMetadata] = useModifyDrivingSlipFileMetadata();

	useEffect(() => {
		if (photoToEdit) {
			presentImageEditModal();
		}
	}, [photoToEdit, presentImageEditModal]);

	useEffect(() => {
		if (selectableChoices.length === 0) {
			return;
		}

		let choicesToOpen: string[] | undefined;
		if (disabledChoices) {
			choicesToOpen = selectableChoices.map(choice => choice.id);
		} else {
			// TODO: this isn't perfect as if a choice has multiple next choices then only the last choice will be opened.
			// Therefore we need a better method of determining what choices to show.
			const lastChoiceId = selectableChoices[selectableChoices.length - 1]?.id;
			choicesToOpen = [lastChoiceId];
		}

		// Add a timeout to open the accordion as it produces a cleaner effect
		setTimeout(() => {
			setAccordionIds(choicesToOpen);
		}, 500);
	}, [disabledChoices, selectableChoices]);

	const setAccordionIds = (ids: string[] | undefined) => {
		if (accordionRef.current) {
			accordionRef.current.value = ids;
		}
	};


	const sectionIsComplete: boolean = useMemo(() => {
		const incompleteEntries = getQuestionnaireCompletionEntriesForSection(section, rootIdPrefix, files, t('common.pictures'), true, syncedChoices).filter(e => !e.isComplete);

		return incompleteEntries.length === 0;
	}, [files, rootIdPrefix, section, syncedChoices, t]);

	const syncChoices = (section: Section, choice: SelectableChoice) => {
		const results = syncedChoices?.filter((r: ISyncedChoices) => r.section === section.label && r.label === choice.label && r.value !== null);
		return (
			<>
				{results?.length ? (
					results.map(result => {
						const timestamp = Date.parse(result?.date);
						const date = new Date(timestamp).toLocaleString();

						return (
							<div key={result.id} className="mt-2 flex w-full flex-col">
								<IonChip className="flex h-12 cursor-auto flex-col items-start justify-center py-8" color="warning">
									<IonLabel className="mb-2">
										{t('common.date')}: {date} | {t('common.by')}: {result?.driver}
									</IonLabel>
									<IonLabel>
										{t('questionnaire.answer')}: {result?.value}
									</IonLabel>
								</IonChip>
							</div>
						);
					})
				) : (
					<IonChip className="mt-2" color="warning">
						<IonLabel>{t('questionnaire.noPreviousAnswers')}</IonLabel>
					</IonChip>
				)}

				<IonAccordion value={choice.id} key={choice.id}>
					<IonItem slot="header">
						<IonLabel>
							{choice.label}
							{!results?.length ? choice.isOptional !== true && <span className="text-red">*</span> : null}
						</IonLabel>
					</IonItem>
					<IonItem slot="content" style={{ '--border-style': 'none' }}>
						<DrivingSlipQuestionnaireChoice
							key={choice.id}
							choice={choice}
							onChangeChoice={(newValue: any) => {
								// Update choice with the new value
								if (choice.type === QuestionnaireChoiceType.RADIO_GROUP && choice.isMultiSelectAllowed === true) {
									choice.multiSelectValues = newValue;
								} else {
									choice.value = newValue;
								}

								// Update parent state tabs ref with new value
								onChangeChoice(section.label, choice.id, newValue);

								// Clear any choices after the position of our current choice
								const currentChoiceIndex = selectableChoices.findIndex(vc => vc.id === choice.id);
								selectableChoices.length = currentChoiceIndex + 1; // Performant way to clear the remainder of the array

								const nextChoices = choicesToSelectableChoices(section.choices, buildCompletablePathOfChoicesFromChoice(section.choices, choice));

								// If there are no next choices then set choices to our cleared-down array and return
								if (nextChoices.length === 0) {
									setSelectableChoices([...selectableChoices]);
									return;
								}

								setSelectableChoices(current => [...current, ...nextChoices]);
							}}
							isDisabled={disabledChoices}
						/>
					</IonItem>
				</IonAccordion>
			</>
		);
	};

	// New Rest Upload
	const fileUploadMobileV2 = useFlag(FeatureFlagEnums.FILE_UPLOAD_MOBILE_V2);

	const { uploadDrivingSlipFile, addFilesToCache } = useUploadDrivingSlipFilesRest();

	const [presentAddCommentsToPhotoModalRest, dismissAddCommentsToPhotoModalRest] = useIonModal(AddCommentsToFilesModalREST, {
		addingFilesType: AddingFilesType.Photos,
		onSubmit: async (files: File[], comments?: string) => {
			const metaData = [{ key: FileMetadataField.QuestionnaireSection, value: section.label }];
			if (comments) {
				metaData.push({ key: FileMetadataField.Comments, value: comments });
			}
			const newNames = await addFilesToCache(caseNo, drivingSlipSeries, metaData, files);
			dismissAddCommentsToPhotoModalRest();
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileName = newNames[i];
				await uploadDrivingSlipFile(caseNo, drivingSlipSeries, metaData, fileName, file, i);
			}
		},
		onDismiss: () => dismissAddCommentsToPhotoModalRest(),
		type: { capture: 'environment', multiple: false },
		skipComments: true,
	} as PropsRest);

	const [presentAddCommentsToPhotosModalRest, dismissAddCommentsToPhotosModalRest] = useIonModal(AddCommentsToFilesModalREST, {
		addingFilesType: AddingFilesType.Photos,
		onSubmit: async (files: File[], comments?: string) => {
			const metaData = [{ key: FileMetadataField.QuestionnaireSection, value: section.label }];
			if (comments) {
				metaData.push({ key: FileMetadataField.Comments, value: comments });
			}
			const newNames = await addFilesToCache(caseNo, drivingSlipSeries, metaData, files);
			dismissAddCommentsToPhotosModalRest();
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileName = newNames[i];
				await uploadDrivingSlipFile(caseNo, drivingSlipSeries, metaData, fileName, file, i);
			}
		},
		onDismiss: () => dismissAddCommentsToPhotosModalRest(),
		type: { capture: undefined, multiple: true },
	} as PropsRest);

	return (
		<>
			{fileToDownloadLoading && <Loading />}
			<IonHeader className="mb-3">
				<IonToolbar className="border-b-2 border-slate-500">
					<IonTitle>{section.label}</IonTitle>
				</IonToolbar>
			</IonHeader>
			{!disabledChoices && (
				<div className="mt-2 flex justify-center top-0">
					{sectionIsComplete ? (
						<IonChip color="success" className="cursor-auto">
							<IonIcon icon={checkmarkCircle} color="success" />
							<IonLabel>{t('questionnaire.sectionComplete')}</IonLabel>
						</IonChip>
					) : (
						<IonChip color="warning" className="cursor-auto">
							<IonIcon icon={warning} color="warning" />
							<IonLabel>{t('questionnaire.sectionIncomplete')}</IonLabel>
						</IonChip>
					)}
				</div>
			)}
			<IonAccordionGroup multiple ref={accordionRef}>
				{selectableChoices.map(choice => (
					<div key={choice.id}>{syncChoices(section, choice)}</div>
				))}
			</IonAccordionGroup>

			{/* Always open the photos accordion by default */}
			<IonAccordionGroup className="border-1 mt-2 border-gray-500" value="accordion-photos">
				<IonAccordion value="accordion-photos">
					<IonItem slot="header" color="light">
						<IonLabel>
							{t('common.pictures')}
							{section.photoRequired && <span className="text-red">*</span>}
							{` (${files.length})`}
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						{files.length === 0 && <p className="text-blue mb-2 text-center text-sm">{t('common.noPhotos')}</p>}
						<IonGrid>
							<IonRow>
								{files.map(file => {
									const uploadedDate = formatTimestamp(new Date(file.created));
									const uploadedBy = file.metadata.find(x => x.key === 'UploadedBy')?.value ?? t('common.unknown');
									return (
										<IonCol
											size="6"
											key={file.name}
											onClick={async () => getFileForEdit(file)}
											className="my-1 border-0 border-gray-200"
										>
											<IonImg src={file.thumbnail} className="border-1 border-gray-300" />
											<p className="line-clamp-1 border-l-1 border-r-1 border-b-1 border-gray-300 p-1 text-xs">{file.comments}</p>
											<p className="line-clamp-1 border-l-1 border-r-1 border-b-1 border-gray-300 p-1 text-xs">
												<span className="mr-1 font-semibold">{t('common.uploadedDate')}:</span>
												<span>{uploadedDate}</span>
											</p>
											<p className="line-clamp-1 border-l-1 border-r-1 border-b-1 border-gray-300 p-1 text-xs">
												<span className="mr-1 font-semibold">{t('common.uploadedBy')}</span>
												<span>{uploadedBy}</span>
											</p>
										</IonCol>
									);
								})}
								{(onFileUploadLoading || filesLoading) && (
									<IonCol size="6">
										<IonSkeletonText animated className="h-22" />
									</IonCol>
								)}
							</IonRow>
						</IonGrid>
						{fileUploadMobileV2
							?
							(
								<>
									<Button
										expand="block"
										onClick={presentAddCommentsToPhotoModalRest}
									>
										<FontAwesomeIcon icon={faCamera} className="mr-2" />
										{t('common.takeSinglePicture')}
									</Button>
									<Button
										expand="block"
										onClick={presentAddCommentsToPhotosModalRest}
									>
										<FontAwesomeIcon icon={faUpload} className="mr-2" />
										{t('common.uploadMultiplePictures')}
									</Button>
								</>
							)
							:
							(
								<>
									<Button
										expand="block"
										onClick={async () => {
											// Go straight to camera, no prompt.
											const result = await takePhoto({
												photoSource: CameraSource.Camera,
											});
											if (result.success && result.photo) {
												// Upload photo directly. Bypass the edit screen.
												await uploadPhotos([result.photo]);
											}
										}}
									>
										<FontAwesomeIcon icon={faCamera} className="mr-2" />
										{t('common.takeSinglePicture')}
									</Button>
									<Button
										expand="block"
										onClick={async () => {
											presentAddCommentsToFilesModal();
											const result = await choosePhotos(true);
											if (!result.success) {
												dismissAddCommentsToFilesModal();
											}
										}}
									>
										<FontAwesomeIcon icon={faUpload} className="mr-2" />
										{t('common.uploadMultiplePictures')}
									</Button>
								</>
							)
						}
					</div>
				</IonAccordion>
			</IonAccordionGroup>

			<span className="text-red text-xs">*{t('common.required')}</span>

			{!disabledChoices && (
				<div className="mt-2 flex justify-center top-0">
					{sectionIsComplete ? (
						<IonChip color="success" className="cursor-auto">
							<IonIcon icon={checkmarkCircle} color="success" />
							<IonLabel>{t('questionnaire.sectionComplete')}</IonLabel>
						</IonChip>
					) : (
						<IonChip color="warning" className="cursor-auto">
							<IonIcon icon={warning} color="warning" />
							<IonLabel>{t('questionnaire.sectionIncomplete')}</IonLabel>
						</IonChip>
					)}
				</div>
			)}
		</>
	);
};

export default DrivingSlipQuestionnaireSection;
