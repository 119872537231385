import React from 'react';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SetUnavailableInput } from '../MachineActions';
import Button from '@ssg/common/Components/Button';
import CloseableModal from 'Components/CloseableModal';

export interface Props {
	setUnavailable(input: SetUnavailableInput): void;
	onDissmis(): void;
	loading: boolean;
}

const SetUnavailableModal: React.FC<Props> = ({ setUnavailable, onDissmis, loading }) => {
	const { t } = useTranslation();

	const { register, handleSubmit } = useForm<SetUnavailableInput>();
	const [unavailableReason, setUnavailableReason] = React.useState('');

	return (
		<CloseableModal title={t('machines.setUnavailable')} onDismiss={onDissmis}>
			<form onSubmit={handleSubmit(setUnavailable)} className="flex flex-col gap-2">
				<IonItem>
					<IonLabel position="stacked">
						{t('machines.unavailableReasonQuestion')}
						<span className="text-red">*</span>
					</IonLabel>
					<IonInput ref={register} name="unavailableReason" onIonChange={e => setUnavailableReason(e.detail.value ?? '')}></IonInput>
				</IonItem>

				<Button success submit disabled={unavailableReason.length === 0} loading={loading}>
					{t('machines.setUnavailable')}
				</Button>
			</form>
		</CloseableModal>
	);
};

export default SetUnavailableModal;
