import { useIonModal } from '@ionic/react';
import { ESDHFileFragment } from '@ssg/common/GraphQL';
import { stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import { FileMetadataField } from 'GQL';
import { TakePhotoOptions, UserPhoto, useSinglePhotoCapture } from 'Hooks/usePhotoCapture';
import ImageEditModal, { Props as ImageViewerModalProps } from 'Components/ImageEditModal';
import { CaseFileInput } from './useDrivingSlipImages';

type UseImageModalResult = (options?: TakePhotoOptions) => Promise<void>;

export default function useImageModal(onPhotoTaken: (file: ESDHFileFragment) => void, upload: (fileInput: CaseFileInput) => Promise<ESDHFileFragment>): UseImageModalResult {
	const { photo, takePhoto } = useSinglePhotoCapture();

	const [presentPhotoModal, dismissPhotoModal] = useIonModal(ImageEditModal, {
		initialPhoto: photo,
		onSubmit: async (photo: UserPhoto, photoHasChanged: boolean, comments?: string) => {
			dismissPhotoModal();
			const fileAsBytes = stringToByteArray(photo.base64EncodedData);
			const fileExtension = photo.format.startsWith('.') ? photo.format : `.${photo.format}`;
			const filename = photo.filepath + fileExtension;
			const fileMetadata = comments ? [{ key: FileMetadataField.Comments, value: comments }] : undefined;
			const file = await upload({
				filename: filename,
				fileData: fileAsBytes,
				fileMetadata,
				blob: photo.webviewPath,
			});
			onPhotoTaken?.(file);
		},
		onDismiss: () => dismissPhotoModal(),
	} as ImageViewerModalProps);

	const presentPhotoModalWithValidation = async (options?: TakePhotoOptions) => {
		const result = await takePhoto(options);
		if (result.success) {
			presentPhotoModal();
		}
	};

	return presentPhotoModalWithValidation;
}

