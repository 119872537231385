import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import { DrivingSlipStatus } from 'GQL';
import { ApiMutationKey, DrivingSlip, useSetDrivingSlipStatus } from 'Store';
import { useGetLocation } from './useGetLocation';

export interface Props {
	drivingSlip: DrivingSlip;
	onDismiss: () => void;
}

const StartDrivingSlipModal: React.FC<Props> = ({ drivingSlip, onDismiss }) => {
	const { t } = useTranslation();
	const { locationCoordinates, coordinatesElement, gettingLocation, loadingElement } = useGetLocation();

	const [setDrivingSlipStatus] = useSetDrivingSlipStatus();

	return (
		<CloseableModal title={t('drivingSlips.arrivedAndStart')} onDismiss={onDismiss}>
			<p className="text-center">{t('drivingSlips.wantToStart')}?</p>
			<Button
				expand="block"
				loading={gettingLocation}
				disabled={gettingLocation}
				onClick={async () => {
					setDrivingSlipStatus({
						variables: {
							id: drivingSlip.id,
							status: DrivingSlipStatus.STARTED,
							locationCoordinates: locationCoordinates,
						},
						optimisticResponse: {
							setDrivingSlipStatus: {
								...drivingSlip,
								status: DrivingSlipStatus.STARTED,
							},
						},
						queueOfflineMutationOptions: {
							key: ApiMutationKey.DrivingSlip_SetStatus,
							removePreviousEntry: true,
							findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
						},
					});

					onDismiss();
				}}
			>
				{t('drivingSlips.startDrivingSlip')}
			</Button>
			{loadingElement()}
			{coordinatesElement()}
			<Button expand="block" onClick={onDismiss}>
				{t('common.cancel')}
			</Button>
		</CloseableModal>
	);
};

export default StartDrivingSlipModal;
