import React from 'react';
import { AnswerProp, Category, getRiskEvaluationCategories } from '@ssg/common/Helpers/riskEvaluationHelper';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonCheckbox, IonContent, IonSkeletonText, IonTextarea, useIonPopover, useIonToast } from '@ionic/react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sanitizeHtml from 'sanitize-html';
import { RiskEvaluationStatus } from 'GQL';
import FullyCenteredDisplayText from 'Components/Layout/FullyCenteredDisplayText';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import { ApiMutationKey, DrivingSlip, useAppSelector, useSetDrivingSlipRiskEvaluationStatus, useUpdateCaseRiskEvaluationAnswers } from 'Store';

export interface Props {
	drivingSlip: DrivingSlip;
	riskEvaluationAnswers: AnswerProp[] | null;
	riskEvaluationStatus: RiskEvaluationStatus;
	riskEvaluationComment: string | null;
	onDismiss: () => void;
}

const DrivingSlipRiskEvaluationModal: React.FC<Props> = ({ drivingSlip, riskEvaluationAnswers, riskEvaluationStatus, riskEvaluationComment, onDismiss }): React.ReactElement => {
	const { t } = useTranslation();

	const {
		riskEvaluationCategories: { data: categoriesResult, loading: categoriesResultLoading },
		riskEvaluationQuestions: { data: questionsResult, loading: questionsResultLoading },
	} = useAppSelector(state => state.apiData);

	const [setRiskEvaluationStatus] = useSetDrivingSlipRiskEvaluationStatus();
	const [updateCaseRiskEvaluationAnswers] = useUpdateCaseRiskEvaluationAnswers();

	const [editMode, setEditMode] = useState<boolean>(false);
	const [categories, setCategories] = useState<Category[]>([]);
	const [comment, setComment] = useState<string | null | undefined>(riskEvaluationComment);

	const popoverHelpText = useRef<string>('');
	const Popover = () => (
		<IonContent className="ion-padding">
			<div
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(popoverHelpText.current, {
						allowedAttributes: {
							'*': ['style', 'href', 'rel', 'target'],
						},
					}),
				}}
			/>
		</IonContent>
	);

	const [present, dismiss] = useIonToast();
	const [presentPopover] = useIonPopover(Popover);

	useEffect(() => {
		setCategories(
			getRiskEvaluationCategories({
				categories: categoriesResult,
				questions: questionsResult,
				riskEvaluationAnswers: riskEvaluationAnswers,
			}),
		);
	}, [categoriesResult, questionsResult, riskEvaluationAnswers]);

	const handleSetAnswer = (questionId: string, checked: boolean) => {
		setCategories(current =>
			current.map(category => {
				return {
					...category,
					questions: category.questions.map(question => {
						if (question.questionId === questionId) {
							return { ...question, answer: checked };
						}

						return question;
					}),
				};
			}),
		);
	};

	const handleOnSave = async () => {
		const riskEvaluationAnswers = categories
			.flatMap(c => c.questions)
			.filter(a => a !== null && typeof a !== 'undefined')
			.map(q => ({ question: q.questionId, answer: q.answer }));

		await updateCaseRiskEvaluationAnswers({
			variables: {
				id: drivingSlip.case.id,
				riskEvaluationAnswers: riskEvaluationAnswers,
				riskEvaluationComment: comment,
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.Case_UpdateRiskEvaluationAnswers,
				removePreviousEntry: false, // TODO: Check caseId & drivingSlipId
			},
		});

		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: t('common.saved'),
			duration: 3000,
			color: 'success',
			position: 'bottom',
		});

		if (riskEvaluationStatus === RiskEvaluationStatus.ACCEPTED) {
			onDismiss();
		} else {
			setEditMode(false);
		}
	};

	const handleOnAccept = async () => {
		await setRiskEvaluationStatus({
			variables: {
				id: drivingSlip.id,
				riskEvaluationStatus: RiskEvaluationStatus.ACCEPTED,
			},
			optimisticResponse: {
				setDrivingSlipRiskEvaluationStatus: {
					...drivingSlip,
					riskEvaluationStatus: RiskEvaluationStatus.ACCEPTED,
				},
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_SetRiskEvaluationStatus,
				removePreviousEntry: true,
				findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
			},
		});

		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: t('common.accepted'),
			duration: 3000,
			color: 'success',
			position: 'bottom',
		});

		onDismiss();
	};

	return (
		<CloseableModal title={t('case.riskEvaluation')} onDismiss={onDismiss}>
			<label className="text-blue font-semibold">{t('case.caseNo')}:</label>
			<p>{drivingSlip.case.erpNo}</p>

			{(categoriesResultLoading || questionsResultLoading) && (
				<div className="mt-2">
					<IonSkeletonText animated className="w-full pt-5" />
					<IonSkeletonText animated className="w-full pt-5" />
				</div>
			)}

			{!categoriesResultLoading && !questionsResultLoading && categories.length > 0 ? (
				<div className="mt-2 flex flex-wrap text-xs">
					{categories.map((c, cIndex) => (
						<div className="w-full" key={cIndex}>
							<label className="text-blue font-semibold">{c.categoryName}</label>
							{c.questions.map((q, qIndex) => (
								<div key={qIndex} className="flex w-full justify-between pb-1">
									<span
										onClick={(e: any) => {
											popoverHelpText.current = q.helpText;
											presentPopover({
												event: e,
											});
										}}
									>
										{q.question}
										<FontAwesomeIcon icon={faInfoCircle} className="text-blue ml-1" />
									</span>

									{editMode ? (
										<IonCheckbox checked={q.answer} onIonChange={e => handleSetAnswer(q.questionId, e.detail.checked)} className="float-right" />
									) : (
										<span className="float-right font-semibold">{q.answer ? t('common.yes') : t('common.no')}</span>
									)}
								</div>
							))}
						</div>
					))}

					<div className="w-full">
						{(typeof riskEvaluationComment !== 'undefined' || editMode) && <label className="text-blue font-semibold">{t('common.other')}</label>}
						{editMode ? <IonTextarea value={comment} placeholder={t('common.otherComments')} onIonChange={e => setComment(e.detail.value)} /> : <p>{riskEvaluationComment}</p>}
					</div>

					{editMode ? (
						<>
							<Button className="mt-2 w-full" onClick={handleOnSave}>
								{t('common.save')}
							</Button>

							<Button
								className="w-full"
								onClick={() => {
									setEditMode(false);
									setCategories(
										getRiskEvaluationCategories({
											categories: categoriesResult,
											questions: questionsResult,
											riskEvaluationAnswers: riskEvaluationAnswers,
										}),
									);
								}}
							>
								{t('common.cancel')}
							</Button>
						</>
					) : (
						<>
							<Button expand="block" className="mt-2 w-full" onClick={() => setEditMode(true)}>
								{t('case.updateRiskEvaluation')}
							</Button>

							<Button expand="block" className="w-full" onClick={handleOnAccept} disabled={riskEvaluationStatus === RiskEvaluationStatus.ACCEPTED}>
								{riskEvaluationStatus === RiskEvaluationStatus.ACCEPTED ? t('common.accepted') : t('case.acceptRiskEvaluation')}
							</Button>
						</>
					)}
				</div>
			) : (
				<FullyCenteredDisplayText>{t('case.noRiskEvaluation')}</FullyCenteredDisplayText>
			)}
		</CloseableModal>
	);
};

export default DrivingSlipRiskEvaluationModal;
