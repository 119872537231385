import React from 'react';
import { IonButtons, IonContent, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import Button from './Button';

interface Props {
	title: string;
	onDismiss: () => void;
}

const CloseableModal: React.FC<Props> = ({ title, onDismiss, children }) => {
	return (
		<>
			<IonToolbar color="primary">
				<IonTitle>{title}</IonTitle>
				<IonButtons slot="end">
					<Button onClick={() => onDismiss()}>
						<IonIcon name="close" slot="icon-only"></IonIcon>
					</Button>
				</IonButtons>
			</IonToolbar>
			<IonContent className="ion-padding">{children}</IonContent>
		</>
	);
};

export default CloseableModal;
