import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';

export interface LabelValuePair {
	label: string;
	value: any;
}

export const SelectFilter: React.FC<{
	label: string;
	value: any;
	data: LabelValuePair[];
	onChange: (value: any) => void;
	multiple?: boolean;
}> = ({ label, value, data, onChange, multiple }): React.ReactElement => {
	const { t } = useTranslation();
	return (
		<>
			<label className="text-blue mt-2 px-5 font-semibold">{label}</label>
			<IonList>
				<IonItem>
					<IonSelect
						placeholder={label}
						multiple={multiple}
						className="w-full"
						onIonChange={e => {
							onChange(e.detail.value);
						}}
						value={value}
						okText={t('common.ok')}
						cancelText={t('common.cancel')}
					>
						{data.map(datum => (
							<IonSelectOption key={datum.value} value={datum.value}>
								{datum.label}
							</IonSelectOption>
						))}
					</IonSelect>
				</IonItem>
			</IonList>
		</>
	);
};
