import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconDefinition, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface Props {
	className?: string;
	text?: string;
	tooltip?: string;
	primary?: boolean;
	secondary?: boolean;
	danger?: boolean;
	success?: boolean;
	search?: boolean;
	submit?: boolean;
	icon?: IconDefinition;
	link?: boolean;
	textClassName?: string;
	iconClassName?: string;
	iconSize?: FontAwesomeIconProps['size'];
	disabled?: boolean;
	innerRef?: React.LegacyRef<HTMLButtonElement>;
	loading?: boolean;
	loadingColor?: string;
	fullWidth?: boolean;
	id?: string;
}

const Button: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = ({
	className,
	tooltip,
	text,
	children,
	icon,
	textClassName,
	iconClassName,
	iconSize,
	loadingColor,
	id,

	primary = false,
	secondary = false,
	danger = false,
	success = false,
	search = false,
	submit = false,
	link = false,
	fullWidth = false,

	disabled = false,
	loading = false,
	innerRef,

	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		// <div className={classNames('flex flex-row items-center', {
		//     'w-full': fullWidth,
		// })}>
		<button
			ref={innerRef}
			type={submit ? 'submit' : 'button'}
			{...restProps}
			className={classNames('rounded-default relative flex items-center justify-center px-5 py-2 font-medium hover:bg-opacity-95 focus:outline-none', className, {
				'bg-blue text-white': primary,
				'border-default border-blue text-blue bg-white': secondary,
				'bg-green text-white': success,
				'bg-red text-white': danger,
				'bg-white text-black': search,
				'w-full justify-center': fullWidth,
				underline: link,
				'cursor-not-allowed opacity-50': disabled,
				'cursor-wait': loading,
			})}
			disabled={disabled || loading}
			title={tooltip ? t(tooltip) : undefined}
			id={id}
		>
			<>
				{icon && (
					<div className={classNames('flex w-6 justify-center', iconClassName, { invisible: loading })}>
						<FontAwesomeIcon icon={icon} size={iconSize} />
					</div>
				)}
				{text && <div className={classNames('whitespace-nowrap', textClassName, { 'ml-1': icon, invisible: loading })}>{t(text)}</div>}
				{loading && (
					<div className="absolute">
						<FontAwesomeIcon icon={faSpinner} className={classNames('animate-spin', loadingColor)} size="lg" />
					</div>
				)}
			</>
			{children}
		</button>

		//     {loading && <FontAwesomeIcon icon={faSpinner} className={classNames('animate-spin ml-2', loadingColor)} size="lg" />}
		// </div>
	);
};

export default Button;
