import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	text: string;
}

const FormErrorText: React.FC<Props> = ({ text }): React.ReactElement => {
	const { t } = useTranslation();

	return <p className="text-red text-sm font-semibold">{t(text)}</p>;
};

export default FormErrorText;
