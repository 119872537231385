import React, { useEffect, useMemo } from 'react';
import { useFetch } from './Hooks/useFetch';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';

interface Dict<T> {
	[key: string]: T;
}

export interface ConfigApiResponse {
	name: string;
	environmentName: string;
	values: Dict<string>;
}

export interface EnvironmentVariableData {
	appInsightsConnectionString: string;
	baseUrl: string;
	baseApiUrl: string;
	baseGraphUrl: string;
	defaultCountry: string;
	environmentTag: string;
	msAuthority: string;
	msClientId: string;
	msScopeApp: string;
	msScopeBc: string;
	siteUrlAdmin: string;
	siteUrlWeb: string;
	timeApproveUrl: string;
	unleashFrontendToken: string;
}

declare const window: any;
const baseUrl = (window?._env_?.API_DOMAIN ?? '') as string; // If value is '' then it uses the proxy defined in package.json.
const baseApiUrl = baseUrl + '/api';
const baseGraphUrl = baseUrl + '/graph';

const emptyEnvironmentVariables: EnvironmentVariableData = {
	appInsightsConnectionString: '',
	baseUrl: baseUrl,
	baseApiUrl: baseApiUrl,
	baseGraphUrl: baseGraphUrl,
	defaultCountry: '',
	environmentTag: '',
	msAuthority: '',
	msClientId: '',
	msScopeApp: '',
	msScopeBc: '',
	siteUrlAdmin: '',
	siteUrlWeb: '',
	timeApproveUrl: '',
	unleashFrontendToken: '',
};

const EnvironmentVariableContext = React.createContext<EnvironmentVariableData>({ ...emptyEnvironmentVariables });

export const EnvironmentVariableContextProvider: React.FC<{
	name: string;
}> = ({ name, children }) => {
	const url: string | undefined = useMemo(() => {
		return baseApiUrl + '/config/' + name;
	}, [name]);

	// TODO: Implement local storage caching mechanism for fetched result (mainly for Mobile site)
	const { data: configData, error: configError } = useFetch<ConfigApiResponse>(url, { cache: 'default' });

	const [environmentVariableValues, setEnvironmentVariableValues] = useStorageState<EnvironmentVariableData>(window.localStorage, 'environmentVariableContext', { ...emptyEnvironmentVariables });

	useEffect(() => {
		if (environmentVariableValues.msClientId === '' && configError) {
			alert('An error occurred fetching config from API.');
		}

		if (configData) {
			const configVariables: EnvironmentVariableData = {
				appInsightsConnectionString: configData.values['appInsightsConnectionString'],
				baseUrl: baseUrl,
				baseApiUrl: baseApiUrl,
				baseGraphUrl: baseGraphUrl,
				defaultCountry: configData.values['defaultCountry'],
				environmentTag: configData.values['environmentTag'],
				msAuthority: configData.values['msAuthority'],
				msClientId: configData.values['msClientId'],
				msScopeApp: configData.values['msScopeApp'],
				msScopeBc: configData.values['msScopeBc'],
				siteUrlAdmin: configData.values['siteUrlAdmin'],
				siteUrlWeb: configData.values['siteUrlWeb'],
				timeApproveUrl: configData.values['timeApproveUrl'],
				unleashFrontendToken: configData.values['unleashFrontendToken'],
			};

			if (JSON.stringify(configVariables) !== JSON.stringify(environmentVariableValues)) {
				console.log('Setting config variables from API');
				setEnvironmentVariableValues(configVariables);
			}
		}
	}, [configData, configError, environmentVariableValues, setEnvironmentVariableValues]);

	return <EnvironmentVariableContext.Provider value={environmentVariableValues}>{children}</EnvironmentVariableContext.Provider>;
};

export default EnvironmentVariableContext;
