import React from 'react';

export function useStorageState<T>(storage: Storage, key: string | undefined, initialValue: T, parse: (value: string) => T = JSON.parse): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
	const [storedValue, setStoredValue] = React.useState<T>(() => {
		if (typeof key !== 'string' || key.length === 0) {
			return initialValue;
		}
		try {
			// Get from storage by key and parse if found
			const item = storage.getItem(key);
			return item ? parse(item) : initialValue;
		} catch (error) {
			// If an error occurred, log it and return initial value
			console.error(error);
			return initialValue;
		}
	});

	const clearStorageValue = React.useCallback(() => {
		if (key == null) return;
		storage.removeItem(key);
	}, [storage, key]);

	// When storedValue changes, persist it in storage
	React.useEffect(() => {
		if (typeof key === 'string' && key.length > 0) {
			storage.setItem(key, JSON.stringify(storedValue));
		}
	}, [storage, key, storedValue]);

	return [storedValue, setStoredValue, clearStorageValue];
}
