import React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonFab, IonFabButton, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonRow, useIonModal } from '@ionic/react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetMobileV2DrivingSlip_drivingSlip_case, GetMobileV2Requisitions, GetMobileV2RequisitionsVariables } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';
import Loading from '@ssg/common/Components/Loading';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import BasePage from 'Components/Layout/BasePage';
import FullyCenteredDisplayText from 'Components/Layout/FullyCenteredDisplayText';
import CreateRequisitionModal, { Props as CreateRequisitionModalProps } from './CreateRequisitionModal';

const GET_REQUISITIONS = loader('src/GQL/Requisitions/GetMobileV2Requisitions.gql');

const FETCH_LIMIT = 20;

const RequisitionsOverview: React.FC<{
	caseData?: GetMobileV2DrivingSlip_drivingSlip_case;
}> = ({ caseData }): React.ReactElement => {
	const { t } = useTranslation();

	const {
		data: requisitionsData,
		loading: requisitionsLoading,
		fetchMore,
	} = useOfflineHandlingQuery<GetMobileV2Requisitions, GetMobileV2RequisitionsVariables>(
		GET_REQUISITIONS,
		{
			variables: {
				caseId: caseData?.id,
				limit: FETCH_LIMIT,
				offset: 0,
				excludeTotalCount: true,
			},
		},
		true,
	);

	const requisitions = React.useMemo(() => requisitionsData?.requisitions ?? [], [requisitionsData?.requisitions]);

	const [presentCreateRequisitionModal, dismissCreateRequisitionModal] = useIonModal(CreateRequisitionModal, {
		caseData: caseData,
		onDismiss: () => {
			dismissCreateRequisitionModal();
		},
	} as CreateRequisitionModalProps);

	return (
		<BasePage title={`${t('requisitions.overviewTitle')} ${typeof caseData !== 'undefined' ? `- ${caseData.erpNo}` : ''}`} shouldOfflineWarn>
			<IonFab vertical="top" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => presentCreateRequisitionModal()}>
					<FontAwesomeIcon icon={faPlus} size="lg" />
				</IonFabButton>
			</IonFab>
			{requisitionsLoading && typeof requisitionsData === 'undefined' ? (
				<div>
					<Loading />
				</div>
			) : (
				<>
					<IonList>
						{requisitions.map(r => (
							<div key={r.id} className="border-b-1 mb-2 border-gray-200">
								<IonGrid className="text-sm">
									<IonRow class="ion-justify-content-start">
										<IonCol size="6">
											<p className="text-blue font-semibold">{t('common.type')}</p>
											<p>{t(`requisitions.type.${r.type}`)}</p>
										</IonCol>
										<IonCol size="6">
											<p className="text-blue font-semibold">{t('requisitions.requisitionId')}</p>
											<p>{r.orderNumber}</p>
										</IonCol>
										<IonCol size="6">
											<p className="text-blue font-semibold">{t('common.created')}</p>
											<p>{dateToDateTimeString(r.createdAt)}</p>
										</IonCol>
										<IonCol size="6">
											<p className="text-blue font-semibold">{t('common.subcontractor')}</p>
											<div>
												<p className="font-semibold">{r.vendorName ?? r.vendor?.company}</p>
												<p>
													<a href={`mailto:${r.vendorEmail ?? r.vendor?.email}`} className="hover:underline">
														{r.vendorEmail ?? r.vendor?.email}
													</a>
												</p>
												<p>
													<a href={`tel:${r.vendorPhoneNumber ?? r.vendor?.phone}`} className="hover:underline">
														{r.vendorPhoneNumber ?? r.vendor?.phone}
													</a>
												</p>
											</div>
										</IonCol>
										<IonCol size="6">
											<p className="text-blue font-semibold">{t('common.description')}</p>
											<p>{r.description}</p>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
						))}
					</IonList>
					<IonInfiniteScroll
						onIonInfinite={async (ev: CustomEvent<void>) => {
							const event = ev as CustomEvent<void>;
							const target = event?.target;

							await fetchMore({
								offset: requisitionsData?.requisitions.length,
							});

							if (target) {
								(target as any).complete();
							}
						}}
					>
						<IonInfiniteScrollContent></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</>
			)}
			{!requisitionsLoading && !requisitions.length && <FullyCenteredDisplayText>{t('requisitions.noRequisitions')}</FullyCenteredDisplayText>}
		</BasePage>
	);
};

export default RequisitionsOverview;
