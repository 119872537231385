import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import FormFieldHeader from './FormFieldHeader';

interface Props {
	className?: string;
	innerRef?: React.Ref<HTMLTextAreaElement>;
	required?: boolean;
	title: string;
	errorMessage?: string;
	helpText?: string;
	showHelpText?: boolean;
	name: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
}
const Textarea: React.FC<Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
	className,
	innerRef,
	title,
	required = false,
	errorMessage,
	helpText,
	showHelpText,
	name,
	defaultValue,
	disabled = false,
	placeholder,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<FormFieldHeader htmlFor={name} required={required} title={title} />
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<textarea
				{...restProps}
				defaultValue={defaultValue}
				name={name}
				id={name}
				ref={innerRef}
				disabled={disabled}
				className={classNames('border-1 re rounded-default block w-full resize-none border-gray-600 bg-white bg-transparent p-1 text-sm focus:outline-none', className, {
					'border-red border-2': errorMessage,
					'cursor-not-allowed bg-gray-200': disabled || restProps.readOnly,
				})}
				placeholder={placeholder ? t(placeholder) : undefined}
			/>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};

export default Textarea;
