import React, { useContext, useMemo } from 'react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import CloseableModal from 'Components/CloseableModal';
import {
	CreateCaseRequisition,
	CreateCaseRequisitionVariables,
	CreateDepartmentRequisition,
	CreateDepartmentRequisitionVariables,
	GetMobileV2CasesForCatalogSuggestions,
	GetMobileV2CasesForCatalogSuggestionsVariables,
	GetMobileV2CasesForCatalogSuggestions_cases,
	GetCatalogsWithCraftsmen,
	GetCatalogsWithCraftsmenVariables,
	GetMobileV2Departments,
	GetMobileV2Requisitions,
	GetMobileV2RequisitionsVariables,
	GetMobileV2Requisitions_requisitions,
	GetVendors,
	GetVendorsVariables,
	GetVendors_vendors,
	RequisitionType,
} from 'GQL';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import Radio from '@ssg/common/Components/Radio';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import InputPhone from '@ssg/common/Components/InputPhone';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateRequisitionSchema } from '../../Schemas/CreateRequisitionSchema';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../Components/Button';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useOfflineHandlingMutation, useOfflineHandlingQuery } from 'Hooks';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_CASES_FOR_CATALOG_SUGGESTIONS = loader('src/GQL/Cases/GetMobileV2CasesForCatalogSuggestions.gql');
const GET_DEPARTMENTS = loader('src/GQL/Departments/GetMobileV2Departments.gql');
const GET_VENDORS = loader('src/GQL/Requisitions/GetVendors.gql');
const CREATE_CASE_REQUISITION = loader('src/GQL/Requisitions/CreateCaseRequisition.gql');
const CREATE_DEPARTMENT_REQUISITION = loader('src/GQL/Requisitions/CreateDepartmentRequisition.gql');
const GET_REQUISITIONS = loader('src/GQL/Requisitions/GetMobileV2Requisitions.gql');
const GET_CATALOGS_WITH_CRAFTSMEN = loader('src/GQL/Catalogs/GetCatalogsWithCraftsmen.gql');

interface CreateRequisitionVariables {
	requisitionConnectType: string;
	//id: string;
	vendor: string;
	email: string;
	phone: string;
	description: string;
	caseId: string;
	departmentId: string;
	drivingSlipId: string;
	requisitionType: string;
	sendEmail: boolean;
}

interface CraftsmanInfo {
	id: string;
	name: string;
	email: string;
	phone: string;
	remark: string;
	type: string;
	phoneCount: number;
}

export interface Props {
	caseData?: GetMobileV2CasesForCatalogSuggestions_cases;
	limit: number;
	offset: number;
	onDismiss: () => void;
}

const CreateRequisitionModal: React.FC<Props> = ({ caseData, limit, offset, onDismiss }) => {
	const { t } = useTranslation();
	const { defaultCountry } = useContext(EnvironmentVariableContext);

	const [reqNumber, setReqNumber] = React.useState<GetMobileV2Requisitions_requisitions | undefined>(undefined);
	const [casesSearchText, setCasesSearchText] = React.useState('');
	const [selectedCase, setSelectedCase] = React.useState<GetMobileV2CasesForCatalogSuggestions_cases | undefined>(caseData);

	const [vendorSearchText, setVendorSearchText] = React.useState('');
	const [selectedVendor, setSelectedVendor] = React.useState<GetVendors_vendors | undefined>(undefined);
	const [selectedCraftsman, setSelectedCraftsman] = React.useState<CraftsmanInfo | undefined>(undefined);
	const [_, setCreatedRequisition] = React.useState<GetMobileV2Requisitions_requisitions | undefined>(undefined);
	const [acceptUnknownDebitor, setAcceptUnknownDebitor] = React.useState(true);

	const [country, setCountry] = React.useState(defaultCountry);
	const [phonenumber, setPhonenumber] = React.useState('');

	const [createCaseRequisition, { loading: createCaseRequisitionLoading }] = useOfflineHandlingMutation<CreateCaseRequisition, CreateCaseRequisitionVariables>(CREATE_CASE_REQUISITION);
	const [createDepartmentRequisition] = useOfflineHandlingMutation<CreateDepartmentRequisition, CreateDepartmentRequisitionVariables>(CREATE_DEPARTMENT_REQUISITION);

	const { register, handleSubmit, setValue, control, errors, trigger } = useForm<CreateRequisitionVariables>({
		mode: 'onChange',
		resolver: yupResolver(CreateRequisitionSchema),
		defaultValues: {
			requisitionType: 'SUBCONTRACTOR',
			requisitionConnectType: 'case',
			caseId: caseData?.id,
			phone: '',
		},
	});

	const phonenumberHandler = React.useCallback(
		(thisPhonenumber: string): void => {
			const countryCodedPhoneNumber = thisPhonenumber.includes('+45') ? thisPhonenumber : '+45' + thisPhonenumber;
			const number = formatPhoneNumberIntl(countryCodedPhoneNumber).replaceAll(/(\s|-)+/g, '');

			setValue('phone', number, {
				shouldValidate: thisPhonenumber.length > 0,
			});
			setPhonenumber(number);
		},
		[setValue],
	);

	const requisitionType: string = useWatch({
		control,
		name: 'requisitionType',
		defaultValue: 'SUBCONTRACTOR',
	});
	const selectedType: string = useWatch({
		control,
		name: 'requisitionConnectType',
		defaultValue: 'case',
	});

	const { data: casesData, loading: loadingCases } = useOfflineHandlingQuery<GetMobileV2CasesForCatalogSuggestions, GetMobileV2CasesForCatalogSuggestionsVariables>(
		GET_CASES_FOR_CATALOG_SUGGESTIONS,
		{
			variables: { erpReferenceNo: casesSearchText },
			skip: casesSearchText.length === 0 || typeof caseData !== 'undefined',
		},
	);

	const cases = useMemo(() => {
		return casesData?.cases.map((c): SelectOption => ({ label: c.erpNo, value: c.id })) ?? [];
	}, [casesData?.cases]);

	const { data: departments } = useOfflineHandlingQuery<GetMobileV2Departments>(GET_DEPARTMENTS);
	const { data: vendors } = useOfflineHandlingQuery<GetVendors, GetVendorsVariables>(GET_VENDORS, {
		variables: {
			company: vendorSearchText,
		},
		skip: vendorSearchText.length === 0,
	});

	const { data: catalogsData, loading: loadingCatalogsData } = useOfflineHandlingQuery<GetCatalogsWithCraftsmen, GetCatalogsWithCraftsmenVariables>(GET_CATALOGS_WITH_CRAFTSMEN, {
		variables: {
			address: {
				road: selectedCase?.damage?.contact.address.road.trim() ?? '',
				houseNumber: selectedCase?.damage?.contact.address.houseNumber.trim() ?? '',
				addressLineAlt: selectedCase?.damage?.contact.address.addressLineAlt?.trim() ?? '',
				postalCode: selectedCase?.damage?.contact.address.postalCode.trim() ?? '',
				city: selectedCase?.damage?.contact.address.city.trim() ?? '',
				country: selectedCase?.damage?.contact.address.country.trim() ?? '',
			},
			debitors: selectedCase !== undefined ? [selectedCase.debitor.id] : null,
		},
		skip: typeof selectedCase === 'undefined' || requisitionType !== 'SUBCONTRACTOR',
	});

	const catalogCraftsmen = React.useMemo(() => catalogsData?.catalogs?.flatMap(c => c.craftsmen) ?? [], [catalogsData?.catalogs]);

	const craftsmenInfo: CraftsmanInfo[] = React.useMemo(
		() =>
			catalogCraftsmen.map(cc => {
				const informations = cc.informations.slice();
				const informationsCount = informations.slice().length;
				if (informations.length === 0) {
					informations.push({ phoneNumber: '', remark: '' });
				}

				return {
					id: cc.id,
					name: cc.contactName,
					email: cc.email ?? '',
					phone: informations[0].phoneNumber,
					remark: informations[0].remark ?? '',
					type: cc.type,
					phoneCount: informationsCount,
				};
			}),
		[catalogCraftsmen],
	);

	React.useEffect(() => {
		if (typeof selectedCraftsman === 'undefined') {
			setValue('email', '', { shouldValidate: false });
			setValue('vendor', '', { shouldValidate: false });
			setVendorSearchText('');
			phonenumberHandler('');
		} else {
			setValue('email', selectedCraftsman.email, {
				shouldValidate: true,
			});
			setValue('vendor', selectedCraftsman.name, {
				shouldValidate: true,
			});
			setVendorSearchText(selectedCraftsman.name);
			phonenumberHandler(selectedCraftsman.phone ?? '');
		}
	}, [phonenumberHandler, selectedCraftsman, setValue]);

	React.useEffect(() => {
		setValue('email', selectedVendor?.email);
		phonenumberHandler(selectedVendor?.phone ?? '');
	}, [phonenumberHandler, selectedVendor, setValue]);

	React.useEffect(() => {
		setSelectedCase(caseData);
		setValue('caseId', caseData?.id);
	}, [caseData, setValue]);

	React.useEffect(() => setAcceptUnknownDebitor(!selectedCase?.debitor.unknown), [selectedCase]);

	const onSubmit = async (data: CreateRequisitionVariables) => {
		switch (data.requisitionConnectType) {
			case 'case':
				try {
					await postCreateCaseRequisition({
						caseId: data.caseId,
						requisition: {
							description: data.description,
							type: data.requisitionType as RequisitionType,
							vendorName: data.vendor,
							vendorEmail: data.email,
							vendorNo: selectedVendor?.erpReferenceNo,
							vendorPhoneNumber: data.phone,
						},
						shouldSendEmail: data.sendEmail,
					});
				} catch (e) {
					console.log(e);
				}
				break;
			case 'department':
				try {
					await postCreateDepartmentRequisition({
						departmentId: data.departmentId,
						requisition: {
							description: data.description,
							type: data.requisitionType as RequisitionType,
							vendorName: data.vendor,
							vendorEmail: data.email,
							vendorNo: selectedVendor?.erpReferenceNo,
							vendorPhoneNumber: data.phone,
						},
						shouldSendEmail: data.sendEmail,
					});
				} catch (e) {
					console.log(e);
				}
				break;
		}
	};

	const postCreateCaseRequisition = async ({ caseId, requisition, shouldSendEmail }: CreateCaseRequisitionVariables) => {
		await createCaseRequisition({
			variables: {
				caseId,
				requisition,
				shouldSendEmail,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null) {
					return;
				}

				setCreatedRequisition(data.createCaseRequisition);

				setReqNumber(data.createCaseRequisition);

				const cachedRequest = cache.readQuery<GetMobileV2Requisitions, GetMobileV2RequisitionsVariables>({
					query: GET_REQUISITIONS,
					variables: {
						caseId: caseData?.id,
						limit: limit,
						offset: offset,
						excludeTotalCount: true,
					},
				});

				if (cachedRequest === null || cachedRequest.requisitions === null) {
					return;
				}

				cache.writeQuery<GetMobileV2Requisitions, GetMobileV2RequisitionsVariables>({
					query: GET_REQUISITIONS,
					variables: {
						caseId: caseData?.id,
						limit: limit,
						offset: offset,
						excludeTotalCount: true,
					},
					data: {
						requisitions: [...cachedRequest.requisitions, data.createCaseRequisition],
					},
				});
			},
		});
	};

	const postCreateDepartmentRequisition = async ({ departmentId, requisition, shouldSendEmail }: CreateDepartmentRequisitionVariables) => {
		await createDepartmentRequisition({
			variables: {
				departmentId,
				requisition,
				shouldSendEmail,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null) {
					return;
				}
				setReqNumber(data.createDepartmentRequisition);

				// Update cache
				const cachedRequestMine = cache.readQuery<GetMobileV2Requisitions>({
					query: GET_REQUISITIONS,
					variables: {
						caseId: caseData?.id,
						limit: limit,
						offset: offset,
						excludeTotalCount: true,
					},
				});

				if (cachedRequestMine !== null) {
					cache.writeQuery({
						query: GET_REQUISITIONS,
						variables: {
							caseId: caseData?.id,
							limit: limit,
							offset: offset,
							excludeTotalCount: true,
						},
						data: {
							requisitions: [...cachedRequestMine.requisitions, data.createDepartmentRequisition],
						},
					});
				}

				const cachedRequestAll = cache.readQuery<GetMobileV2Requisitions>({
					query: GET_REQUISITIONS,
				});

				if (cachedRequestAll !== null) {
					cache.writeQuery({
						query: GET_REQUISITIONS,
						variables: {
							caseId: caseData?.id,
							limit: limit,
							offset: offset,
							excludeTotalCount: true,
						},
						data: {
							requisitions: [...cachedRequestAll.requisitions, data.createDepartmentRequisition],
						},
					});
				}
			},
		});
	};

	return (
		<CloseableModal title={t('requisitions.createRequisition')} onDismiss={onDismiss}>
			{typeof reqNumber === 'undefined' ? (
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={typeof caseData !== 'undefined' ? 'hidden' : 'visible'}>
						<Radio
							name="requisitionConnectType"
							title="requisitions.requisitionTo"
							options={[{ label: 'common.case', value: 'case' }]}
							dValue={selectedType}
							innerRef={register}
							disabled={typeof caseData !== 'undefined'}
						/>
					</div>
					<p className={typeof caseData !== 'undefined' ? 'text-blue whitespace-no-wrap visible text-base font-semibold' : 'hidden'}>
						{t('case.caseNo')} {caseData?.erpNo}
					</p>
					{selectedType === 'department' && (
						<Dropdown
							name="departmentId"
							title="common.department"
							onBlur={() => undefined}
							data={[
								{ label: '', value: '' },
								...(departments?.departments ?? [])
									.slice()
									.sort((a, b) => a.name.localeCompare(b.name))
									.map(l => ({ label: l.name, value: l.id })),
							]}
							innerRef={register}
							required
							errorMessage={errors.departmentId?.message}
						/>
					)}

					{selectedType === 'case' && (
						<div className={typeof caseData !== 'undefined' ? 'hidden' : 'visible'}>
							<SearchableSelect
								name="caseId"
								title="common.case"
								searchFn={searchText => setCasesSearchText(searchText)}
								onSelect={value => {
									setSelectedCase(casesData?.cases.find(c => c.id === value));
									setValue('caseId', value, {
										shouldValidate: true,
									});
								}}
								onBlur={() => undefined}
								minInputLength={2}
								options={cases}
								isLoading={loadingCases}
								control={control}
								required
								initialSelection={cases.find(c => c.value === caseData?.id)}
								errorMessage={errors.caseId?.message}
								readOnly={typeof caseData !== 'undefined'}
							/>
						</div>
					)}

					{selectedCase?.debitor.unknown && (
						<div className="border-1 border-blue rounded-b-default bg-blue z-50 -mt-1 border-t-0 bg-opacity-95 p-1">
							<p className="font-medium text-white">{t('requisitions.caseNoDebitor')}</p>
							<Checkbox
								name="createAnyway"
								title="requisitions.acceptNoDebitor"
								className="text-sm text-white"
								defaultChecked={false}
								onChange={e => setAcceptUnknownDebitor(e.currentTarget.checked)}
								textClassName="text-white"
							/>
						</div>
					)}

					<Radio
						name="requisitionType"
						title="common.type"
						options={[
							{
								label: 'common.subcontractor',
								value: 'SUBCONTRACTOR',
							},
							{ label: 'common.materiale', value: 'MATERIAL' },
						]}
						dValue={'SUBCONTRACTOR'}
						innerRef={register}
					/>

					{requisitionType === 'SUBCONTRACTOR' && (
						<div>
							<FormFieldHeader title="requisitions.catalogSuggestions" />
							{loadingCatalogsData ? (
								<FontAwesomeIcon icon={faSpinner} className="text-blue animate-spin" size="lg" />
							) : (
								<>
									{craftsmenInfo?.length > 0 ? (
										<Dropdown
											title=""
											name=""
											data={[
												{
													label: `${craftsmenInfo.length.toString()} ${t('requisitions.options')}`,
													value: '',
												},
												...craftsmenInfo.map(ci => ({
													label: `${ci.name} - ${ci.type} (${ci.phoneCount} ${t('common.phoneNumbers')})`,
													value: ci.id,
												})),
											]}
											onChange={e =>
												setSelectedCraftsman(selectedCraftsman?.id !== e.currentTarget.value ? craftsmenInfo.find(ci => ci.id === e.currentTarget.value) : undefined)
											}
										/>
									) : (
										<div>{t('common.none')}</div>
									)}
								</>
							)}
						</div>
					)}

					<SearchableSelect
						name="vendor"
						title="common.subcontractor"
						searchFn={searchText => {
							setVendorSearchText(searchText);
							setValue('vendor', searchText);
						}}
						onSelect={value => {
							setSelectedVendor(vendors?.vendors.find(v => v.erpReferenceNo === value));
							setValue('vendor', vendors?.vendors.find(v => v.erpReferenceNo === value)?.company ?? '', { shouldValidate: true });
						}}
						onBlur={() => undefined}
						minInputLength={2}
						options={
							vendors?.vendors.map(v => ({
								label: v.company ?? '',
								value: v.erpReferenceNo,
							})) ?? []
						}
						isLoading={loadingCases}
						control={control}
						required
						errorMessage={errors.vendor?.message}
						readOnly={typeof selectedCraftsman !== 'undefined'}
					/>

					<InputPhone
						title="requisitions.vendorPhone"
						innerRef={register}
						required
						name="phone"
						errorMessage={errors.phone?.message}
						control={control}
						trigger={trigger}
						searchFn={(number): void => phonenumberHandler(number)}
						onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
						defaultCountry={country}
						defaultPhoneNumber={phonenumber}
					/>

					<Input title="requisitions.vendorEmail" name="email" required innerRef={register} errorMessage={errors.email?.message} className="w-full lg:w-full " />

					<Checkbox name="sendEmail" title="requisitions.sendEmail" innerRef={register} defaultChecked={false} className="mt-2" />

					<Textarea title="common.description" name="description" required innerRef={register} errorMessage={errors.description?.message} className="h-32 w-full lg:w-full" />

					<div className="mt-2">
						<Button color="success" submit expand="block" disabled={!acceptUnknownDebitor} loading={createCaseRequisitionLoading}>
							{t('requisitions.createRequisition')}
						</Button>
					</div>
				</form>
			) : (
				<div>
					<p className="text-center text-xl font-bold"> {t('requisitions.created')}:</p>
					<p className="text-center text-3xl font-bold">{reqNumber.orderNumber}</p>
					<Button color="success" expand="block" onClick={() => onDismiss()}>
						{t('common.close')}
					</Button>
				</div>
			)}
		</CloseableModal>
	);
};

export default CreateRequisitionModal;
