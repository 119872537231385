import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { IonButton } from '@ionic/react';

interface Props {
	color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | string;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
	expand?: 'full' | 'block';
	className?: string;
	title?: string;
	submit?: boolean;
	routerLink?: string;
	routerDirection?: 'none' | 'forward' | 'back' | 'root';
	href?: string;
	hrefTarget?: '_blank' | '_self' | '_parent' | '_top';
	id?: string;
}

const Button: React.FC<PropsWithChildren<Props>> = ({
	color,
	disabled,
	loading,
	onClick,
	children,
	expand,
	className,
	title,
	submit,
	routerLink,
	routerDirection,
	href,
	hrefTarget,
	id,
}): React.ReactElement => (
	<IonButton
		type={submit ? 'submit' : 'button'}
		color={color}
		disabled={disabled}
		onClick={() => onClick?.()}
		expand={expand}
		className={className}
		title={title}
		routerLink={routerLink}
		routerDirection={routerDirection}
		href={href}
		target={hrefTarget}
		id={id}
	>
		{loading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : children}
	</IonButton>
);

export default Button;
