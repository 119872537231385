import * as yup from 'yup';
import { TimeTrackingSupplement } from '../../GraphQL/indexV2';

yup.setLocale({
	mixed: {
		required: 'error.required',
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
		positive: 'error.positive',
	},
	boolean: {
		isValue: 'error.required',
	},
});

const addonLinesSchema = yup.object({
	paymentSupplementCode: yup.string().required(),

	paymentSupplementWorkTypeCode: yup.string(),

	paymentSupplementName: yup.string(),

	supplementType: yup.string(),

	supplementUom: yup.string(),

	hours: yup
		.number()
		.positive()
		.required()
		.when('supplementUom', {
			is: (supplementUom: string) => supplementUom === 'STK',
			then: yup.number().oneOf([1], "Værdi skal være '1' "),
			otherwise: yup.number().positive().required(),
		}),
});

export const TimeRegistrationSchemaNew = yup.object().shape({
	substitute: yup.boolean(),

	user: yup.string().when('substitute', {
		is: (substitute: boolean) => substitute,
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	date: yup.string().required(),

	startDate: yup.string().required(),

	endDate: yup.string().required(),

	isPartOfRange: yup.boolean(),

	includesLunch: yup.boolean().nullable(true).test('validate-hours-on-lunch', function (value, ctx) {
		const { hours } = this.parent;
		if (typeof value === 'undefined') {
			return true; // Skip validation if value is undefined
		}
		if (value) {
			const hoursBeforeLunch = hours + 0.5;
			if (typeof hours === 'undefined' || hoursBeforeLunch <= 0.5) {
				return ctx.createError({ message: 'Skal være større end 30 min da frokost er inkluderet' });
			}
		}
		return true;
	}),

	rangeEndDate: yup.string().when('isPartOfRange', {
		is: (isPartOfRange: boolean) => isPartOfRange,
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	startTime: yup.string().required(),

	endTime: yup
		.string()
		.required()
		.test({
			name: 'endTimeAfterStartTime',
			message: 'Skal være større end start tid',
			test: function () {
				const { startTime, endTime, addonLines } = this.parent;

				if (addonLines?.some(s => s.supplementUom === 'STK')) return true;

				if (!startTime || !endTime) return true;

				const startTimeDate = startTime.split('D')[1];
				const endTimeDate = endTime.split('D')[1];

				if (startTimeDate !== endTimeDate) return true;

				const startTimeTimeOnly = startTime.split('D')[0];
				const endTimeDateTimeOnly = endTime.split('D')[0];
				const startTimeAsMinutes = convertToMinutes(startTimeTimeOnly);
				const endTimeAsMinutes = convertToMinutes(endTimeDateTimeOnly);

				return startTimeAsMinutes < endTimeAsMinutes;
			},
		}),

	// Disable required hours when supplement contains a standAlone type
	hours: yup.number().when('addonLines', {
		is: (addonLines: TimeTrackingSupplement[]) => addonLines?.some(s => s.supplementUom === 'STK'),
		then: yup.number().nullable(true),
		otherwise: yup.number().positive().required(),
	}),

	task: yup.string().required(),

	case: yup.string().when('task', {
		is: (task: string) => task !== 'INTERN' && task !== '',
		then: yup.string().required(),
		otherwise: yup.string().nullable(true),
	}),

	drivingSlip: yup.string().when('task', {
		is: (task: string) => task === 'DRIVINGSLIP',
		then: yup.string().required(),
		otherwise: yup.string().nullable(true),
	}),

	workTypeCode: yup.string().required(),

	jobNo: yup.string().required(),

	jobTaskName: yup.string(),

	jobTaskNo: yup.string().required(),

	debitor: yup.string().nullable(true),

	remark: yup.string().nullable(true),

	addonLines: yup.array().when({
		is: (addonLines: TimeTrackingSupplement[]) => addonLines !== null && addonLines.length > 0,
		then: yup.array().of(addonLinesSchema).required(),
		otherwise: yup.array(),
	}),
});

function convertToMinutes(timeStr) {
	const [hours, minutes] = timeStr.split(':').map(Number);
	return hours * 60 + minutes;
}
