import { loader } from 'graphql.macro';
import { ApiQueryKey, DrivingSlipOrNull, DrivingSlipQuery, DrivingSlipQueryVariables, UseApiQueryReturn, setDrivingSlip, useAppDispatch, useAppSelector } from 'Store';
import { useState } from 'react';
import { API_QUERIES } from '../../constants';
import { useApiQuery } from '../useApiQuery';

const GET_DRIVING_SLIP = loader('src/GQL/DrivingSlips/GetMobileV2DrivingSlip.gql');

export const useDrivingSlipQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.DrivingSlip;

	const dispatch = useAppDispatch();

	const { selectedDrivingSlipId } = useAppSelector(state => state.localData);

	const { metadata } = useAppSelector(state => state.apiData.drivingSlip);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<DrivingSlipQuery, DrivingSlipOrNull, DrivingSlipQueryVariables>({
		apiQueryKey,
		debatch: true,
		canFetchQuery: (selectedDrivingSlipId?.length ?? 0) > 0,
		documentNode: GET_DRIVING_SLIP,
		variables: selectedDrivingSlipId
			? {
					id: selectedDrivingSlipId,
			  }
			: undefined,
		dataSelector: query => query?.drivingSlip ?? null,
		setCanFetchQuery,
		setData: partial => {
			dispatch(setDrivingSlip({ ...partial }));

			// TODO: Update the Cache object too
		},
		options: {
			skip: typeof selectedDrivingSlipId === 'undefined',
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};
