import React from 'react';

interface Props {
	value: string;
}

const UnitOfMeasurement: React.FC<Props> = ({ value }): React.ReactElement => {
	const sanitised = value?.toLowerCase().trim();
	if (sanitised === 'm2' || sanitised === 'm3') {
		return (
			<>
				{sanitised[0]}
				<sup>{sanitised[1]}</sup>
			</>
		);
	}

	return <>{sanitised}</>;
};

export default UnitOfMeasurement;
