import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrivingSlipStatus } from 'GQL';
import { History } from 'history';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import Routes from 'Routes';
import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { ApiMutationKey, DrivingSlip, useSetDrivingSlipStatus } from 'Store';
import { useGetLocation } from './useGetLocation';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

export interface Props {
	drivingSlip: DrivingSlip;
	history: History;
	onDismiss: () => void;
}

const FinishDrivingSlipModal: React.FC<Props> = ({ drivingSlip, history, onDismiss }) => {
	const { t } = useTranslation();

	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);

	const { locationCoordinates, coordinatesElement, gettingLocation, loadingElement } = useGetLocation();

	const [setDrivingSlipStatus, { loading }] = useSetDrivingSlipStatus();

	const [noDriving, setNoDriving] = React.useState(drivingSlip.passenger);

	const setDrivingslipCompleted = async () => {
		try {
			await setDrivingSlipStatus({
				variables: {
					id: drivingSlip.id,
					status: DrivingSlipStatus.COMPLETED,
					applyDrivingCost: !noDriving,
					locationCoordinates: locationCoordinates,
				},
				optimisticResponse: {
					setDrivingSlipStatus: {
						...drivingSlip,
						status: DrivingSlipStatus.COMPLETED,
					},
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_SetStatus,
					removePreviousEntry: true,
					findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
				},
			});

			onDismiss();

			if (newTimeregistration) {
				history.push(`${Routes.DrivingSlipTimeRegistration.replace(':id', drivingSlip.id)}`);
			} else {
				history.push(`${Routes.DrivingSlipTimeRegistration.replace(':id', drivingSlip.id)}`);
			}

		} catch (e) {
			console.log(e);
		}
	};

	return (
		<CloseableModal title={t('drivingSlips.finishDrivingSlip') + '?'} onDismiss={onDismiss}>
			<p className="text-center">{t('drivingSlips.wantToFinish')}?</p>

			<IonItem className="my-4">
				<IonLabel>
					{t('drivingSlips.dontRegisterDriving')}
					<br />
					<small>{t('drivingSlips.dontRegisterDrivingHelptext')}</small>
				</IonLabel>
				<IonCheckbox onIonChange={e => setNoDriving(e.detail.checked)} checked={noDriving} />
			</IonItem>
			<Button
				expand="block"
				loading={loading || gettingLocation}
				disabled={loading || gettingLocation}
				onClick={() => {
					setDrivingslipCompleted();
				}}
			>
				{t('drivingSlips.finishDrivingSlip')}
			</Button>
			{loadingElement()}
			{coordinatesElement()}
			<Button expand="block" onClick={onDismiss}>
				{t('common.cancel')}
			</Button>
		</CloseableModal>
	);
};

export default FinishDrivingSlipModal;
