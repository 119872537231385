import React from 'react';
import classnames from 'classnames';

interface Props {
	classNames?: string;
	extraProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
	dynamicClassNameTh?: string;
}

const TableRow: React.FC<Props> = ({ children, classNames, dynamicClassNameTh, extraProps }) => {
	return (
		<tr {...extraProps} className={classnames(classNames, dynamicClassNameTh)}>
			{children}
		</tr>
	);
};
export default TableRow;
