import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	text?: string;
	apolloError?: unknown;
}

const UnhandledError: React.FC<Props> = ({ text, apolloError }): React.ReactElement => {
	const { t } = useTranslation();

	useEffect(() => console.error('An unknown error occurred.', apolloError), [apolloError]);

	return (
		<div className="flex h-full flex-col items-center justify-center">
			<p className="text-blue text-lg">
				{text ?? t('common.genericErrorMessage')}
				<FontAwesomeIcon icon={faFrown} className="ml-2" />
			</p>

			{apolloError && process.env.NODE_ENV === 'development' && (
				<div className="mt-2 w-4/5 overflow-auto text-center" style={{ maxHeight: '400px' }}>
					<p className="mt-2 text-xs">{JSON.stringify(apolloError)}</p>
				</div>
			)}
		</div>
	);
};

export default UnhandledError;
