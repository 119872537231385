import React from 'react';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button';
import BigLogo from '@ssg/common/Components/Logo/BigLogo';

// TODO: Refactor into a Common login page that works across all apps
const Login: React.FC = () => {
	const { t } = useTranslation();
	const { login } = useMicrosoftAuth();

	return (
		<div className="mt-32 flex flex-col items-center justify-center p-5">
			<BigLogo className="h-20 object-contain" black />
			<p className="text-blue text-5xl font-semibold italic">5C</p>

			<p className="text-blue text-l mt-5 text-center font-semibold">
				Her kan du oprette en ny sag. Er du eksisterende <br />
				kunde eller bruger af 5C kan du logge på nedenunder.
			</p>
			<div className="mt-24 flex flex-row items-center">
				<Button color="success" onClick={() => login()}>
					{t('common.login')}
				</Button>
			</div>
		</div>
	);
};

export default Login;
