import { loader } from 'graphql.macro';
import { useAppDispatch, useAppSelector, toArraySafe, CarsQuery, Cars, CarsQueryVariables, setCars } from 'Store';
import { useApiQuery } from '../useApiQuery';
import { ApiQueryKey } from '../../types';
import { useState } from 'react';
import { UseApiQueryReturn } from '../types';
import { API_QUERIES } from '../../constants';
import { GetVehiclesDocument } from '@ssg/common/GraphQL/indexV2';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

export const useCarQuery = (): UseApiQueryReturn => {
	const apiQueryKey = ApiQueryKey.Cars;

	const dispatch = useAppDispatch();
	const newPlannerVehiclesFlag = useFlag(FeatureFlagEnums.VEHICLES_MOBILE_APP);

	const { metadata } = useAppSelector(state => state.apiData.cars);

	// Set to false as we're offline first, this prevents immediate querying
	const [canFetchQuery, setCanFetchQuery] = useState<boolean>(false);

	useApiQuery<CarsQuery, Cars, CarsQueryVariables>({
		apiQueryKey,
		canFetchQuery: canFetchQuery,
		documentNode: GetVehiclesDocument,
		dataSelector: query => toArraySafe(query?.vehicles),
		setCanFetchQuery,
		setData: partial => {
			dispatch(setCars({ ...partial }));
		},
		options: {
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'no-cache',
			skip: !newPlannerVehiclesFlag,
		},
		variables: {
			isCar: true,
		},
	});

	return {
		apiQueryKey,
		apiQuery: API_QUERIES[apiQueryKey],
		metadata,
		setCanFetchQuery,
	};
};

