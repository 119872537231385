import React, { VideoHTMLAttributes, useEffect, useRef } from 'react';

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
	srcObject: MediaStream | null;
}

export default function Video({ srcObject, ...props }: Props): JSX.Element {
	const refVideo = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!refVideo.current) {
			return;
		}

		refVideo.current.srcObject = srcObject;
	}, [srcObject]);

	return <video ref={refVideo} {...props} />;
}
