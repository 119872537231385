import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export const useShowOfflineWarningMessage = (): (() => void) => {
	const { t } = useTranslation();
	const [present, dismiss] = useIonToast();

	return () => {
		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: 'You are offline. Please try again later.',
			duration: 3000,
			color: 'warning',
			position: 'bottom',
			id: 'fetch-override-offline-warning-message',
		});
	};
};
