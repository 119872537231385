import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { GetQuestionnaireTemplateByDrivingSlip, GetQuestionnaireTemplateByDrivingSlipVariables, GetSeriesDrivingSlips, GetSeriesDrivingSlipsVariables } from 'GQL';
import { useApolloClient } from '@apollo/client';
import { DrivingSlip, LocalDataState, Metadata, ONE_DAY, ONE_SECOND, setPrefetchedDrivingSlipsMetadata, useAppDispatch, useAppSelector } from 'Store';
import { canFetch } from 'Store/Slices/ApiData/Queries/ApiQueryManager/helper';
import { DateTime } from 'luxon';
import { isConnected } from 'NetworkContext';

const GET_SERIES_DRIVING_SLIPS = loader('src/GQL/DrivingSlips/GetSeriesDrivingSlips.gql');
const GET_QUESTIONNAIRE_BY_DRIVING_SLIP = loader('src/GQL/QuestionnaireTemplate/GetQuestionnaireTemplateByDrivingSlip.gql');

export function useDrivingSlipsDataPrefetcher(drivingSlips: DrivingSlip[]): void {
	const dispatch = useAppDispatch();

	const apolloClient = useApolloClient();

	const { metadata, relatedDrivingSlipsVariables, questionnaireTemplateVariables } = useAppSelector(state => state.localData.prefetchedDrivingSlipsMetadata);

	useEffect(() => {
		// Small buffer time just to throttle the amount of times the prefetching function gets called
		const lastFetchedInterval = ONE_SECOND * 10;

		const relatedDrivingSlipsInterval = ONE_DAY;
		const questionnaireTemplatesInterval = ONE_DAY;

		const createMetadata = (): Metadata => ({
			lastFetched: DateTime.now().toJSDate(),
			origin: 'remote',
		});

		const prefetchRelatedDrivingSlips = async () => {
			const uniqueSeriesIds = Array.from(new Set(drivingSlips.map(ds => ds.series)));

			const latestMetadatas: LocalDataState['prefetchedDrivingSlipsMetadata']['relatedDrivingSlipsVariables'] = [];

			for (const seriesId of uniqueSeriesIds) {
				const connected = isConnected();
				if (!connected) {
					console.warn('Prefetching driving slips data was interrupted by network connectivity');
					return latestMetadatas;
				}

				let metadata = relatedDrivingSlipsVariables.find(x => x.seriesId === seriesId)?.metadata;

				if (!metadata || canFetch(relatedDrivingSlipsInterval, metadata)) {
					apolloClient.query<GetSeriesDrivingSlips, GetSeriesDrivingSlipsVariables>({
						query: GET_SERIES_DRIVING_SLIPS,
						fetchPolicy: 'network-only', // Always fetch latest data,
						variables: {
							seriesId: seriesId,
							excludeTotalCount: true,
						},
					});

					metadata = createMetadata();
				}

				latestMetadatas.push({ seriesId, metadata });
			}

			return latestMetadatas;
		};

		const prefetchQuestionnaireTemplates = async () => {
			const getTemplateVariables: GetQuestionnaireTemplateByDrivingSlipVariables[] = [];

			for (const drivingSlip of drivingSlips) {
				const getTemplateVariable: GetQuestionnaireTemplateByDrivingSlipVariables = {
					damageCauseId: drivingSlip.case.damage.cause.id,
					drivingSlipCategoryCode: drivingSlip.category,
				};

				if (!getTemplateVariables.some(x => x.damageCauseId === getTemplateVariable.damageCauseId && x.drivingSlipCategoryCode === getTemplateVariable.drivingSlipCategoryCode)) {
					getTemplateVariables.push(getTemplateVariable);
				}
			}

			const latestMetadatas: LocalDataState['prefetchedDrivingSlipsMetadata']['questionnaireTemplateVariables'] = [];

			for (const variables of getTemplateVariables) {
				const connected = isConnected();
				if (!connected) {
					console.warn('Prefetching driving slips data was interrupted by network connectivity');
					return latestMetadatas;
				}

				let metadata = questionnaireTemplateVariables.find(x => x.damageCauseId === variables.damageCauseId && x.drivingSlipCategoryCode === variables.drivingSlipCategoryCode)?.metadata;

				if (!metadata || canFetch(questionnaireTemplatesInterval, metadata)) {
					apolloClient.query<GetQuestionnaireTemplateByDrivingSlip, GetQuestionnaireTemplateByDrivingSlipVariables>({
						query: GET_QUESTIONNAIRE_BY_DRIVING_SLIP,
						fetchPolicy: 'network-only', // Always fetch latest data,
						variables: variables,
					});

					metadata = createMetadata();
				}

				latestMetadatas.push({ ...variables, metadata });
			}

			return latestMetadatas;
		};

		const prefetch = async () => {
			if (!canFetch(lastFetchedInterval, metadata)) return;

			const relatedDrivingSlipsTask = prefetchRelatedDrivingSlips();
			//const questionnaireTemplatesTask = prefetchQuestionnaireTemplates();

			const relatedDrivingSlipsMetadata = await relatedDrivingSlipsTask;
			//const relatedQuestionnaireTemplatesMetadata = await questionnaireTemplatesTask;

			dispatch(
				setPrefetchedDrivingSlipsMetadata({
					metadata: createMetadata(),
					relatedDrivingSlipsVariables: relatedDrivingSlipsMetadata,
					//questionnaireTemplateVariables: relatedQuestionnaireTemplatesMetadata,
				}),
			);
		};

		if (apolloClient && drivingSlips.length > 0) {
			prefetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, drivingSlips, metadata, questionnaireTemplateVariables, relatedDrivingSlipsVariables]);
}

