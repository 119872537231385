import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetMobileV2CurrentUser } from './GQL';
import { RestrictedAreaProvider } from '@ssg/common/Components/RestrictedArea';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { setCurrentUser, useAppDispatch, useAppSelector } from 'Store';

const GET_CURRENT_USER = loader('src/GQL/Users/GetMobileV2CurrentUser.gql');

export type CurrentUser = GetMobileV2CurrentUser['currentUser'];

interface UserContextValues {
	user: CurrentUser | null;
	loading: boolean;
}

const UserContext = React.createContext<UserContextValues>({
	user: null,
	loading: false,
});

export const UserContextProvider: React.FC = ({ children }) => {
	const { account } = useMicrosoftAuth();
	const updateContext = useUnleashContext();

	const dispatch = useAppDispatch();
	const currentUser = useAppSelector(state => state.localData.currentUser);

	const [getCurrentUser, { loading }] = useLazyQuery<GetMobileV2CurrentUser>(GET_CURRENT_USER, { context: { debatch: true } });

	const prevAccountUsername = React.useRef<string | undefined>(currentUser?.id);

	React.useEffect(() => {
		const setUser = async () => {
			if (account != null && prevAccountUsername.current !== account.username) {
				const { data: user } = await getCurrentUser();

				if (user?.currentUser) {
					dispatch(setCurrentUser(user.currentUser));
				}

				prevAccountUsername.current = account.username;
			}
		};

		setUser();
	}, [account, dispatch, getCurrentUser]);

	const context: UserContextValues = React.useMemo(
		() => ({
			user: currentUser,
			loading,
		}),
		[currentUser, loading],
	);

	React.useEffect(() => {
		updateContext({ userId: context.user?.id });
	}, [context, updateContext]);

	return (
		<UserContext.Provider value={context}>
			<RestrictedAreaProvider value={context.user?.permissions ?? []}>{children}</RestrictedAreaProvider>
		</UserContext.Provider>
	);
};

export default UserContext;
