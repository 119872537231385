import { GraphQLError } from 'graphql';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import { env } from 'process';

export interface SelectOption {
	value: string;
	label: string;
}

export const formatDate = (date: Date): string => {
	const day = `${('0' + date.getDate()).slice(-2)}`;
	const month = `${('0' + (date.getMonth() + 1)).slice(-2)}`;
	const year = date.getFullYear();

	return `${day}-${month}-${year}`;
};

export const formatTimestamp = (date: Date): string => {
	const formattedDate = formatDate(date);

	const hour = `${('0' + date.getHours()).slice(-2)}`;
	const minute = `${('0' + date.getMinutes()).slice(-2)}`;

	return `${formattedDate} - ${hour}:${minute}`;
};

export const formatTimestampOnly = (date: Date): string => {
	const hour = `${('0' + date.getHours()).slice(-2)}`;
	const minute = `${('0' + date.getMinutes()).slice(-2)}`;

	return `${hour}:${minute}`;
};

export const createErrorLink = (onGraphQLError: (error: GraphQLError) => void, logToConsole: boolean): ApolloLink => {
	return onError(({ graphQLErrors, networkError }) => {
		if (networkError && logToConsole) {
			console.log(`[Network error]: ${networkError}`);
		}

		if (graphQLErrors && graphQLErrors.length > 0) {
			graphQLErrors.forEach(graphQLError => {
				if (logToConsole) {
					const { message, locations, path } = graphQLError;
					console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
				}

				onGraphQLError(graphQLError);
			});
		}
	});
};

interface ErrorWithReasonPhrase {
	reasonPhrase: string;
}

export type GraphQLErrorPartial = Pick<GraphQLError, 'message' | 'extensions'>;

export const tryGetMessageFromGraphQLError = (error: GraphQLErrorPartial): string | undefined => {
	const errorWithReasonPhrase = error.extensions?.data as ErrorWithReasonPhrase | undefined;
	if (errorWithReasonPhrase) {
		return errorWithReasonPhrase.reasonPhrase;
	}

	// The error.message will contain stacktrace information -- only display in development
	if (env.NODE_ENV === 'development') {
		return error.message;
	}

	return undefined;
};

export const dynamicSort =
	<TValue, K extends keyof TValue>(property: K, ascending = true) =>
	(a: TValue, b: TValue): number => {
		const sortOrder = ascending ? 1 : -1;
		const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
