import React from 'react';
import classNames from 'classnames';

interface Props {
	noPadding?: boolean;
}

const BoxContainer: React.FC<Props> = ({ noPadding = false, children }): React.ReactElement => {
	return <div className={classNames('flex w-full flex-wrap overflow-hidden', {
		'px-10 pb-10': !noPadding,
	})}>
		{children}
	</div>;
};

export default BoxContainer;
