import { useContext, useEffect, useRef, useState } from 'react';
import { IConfig } from '@unleash/proxy-client-react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, IConfiguration } from '@microsoft/applicationinsights-web';
import { OfflineChannel } from '@microsoft/applicationinsights-offlinechannel-js';

import EnvironmentVariableContext from '../EnvironmentVariableContext';

/* 
https://www.npmjs.com/package/@microsoft/applicationinsights-offlinechannel-js
*/

// Note: This must match that of `eStorageProviders` in '@microsoft/applicationinsights-offlinechannel-js'
enum StorageProviders {
	LocalStorage = 1,
	SessionStorage = 2,
	IndexedDb = 3,
}

export enum OnlineState {
	Online = 1,
	Offline = 2,
}

export interface OfflineListener {
	setOnlineState: (state: OnlineState) => void;
}

interface Props {
	disableTelemetry?: boolean;
	enableOfflineTelemetry?: boolean;
}

function useDependencyConfiguration({ disableTelemetry, enableOfflineTelemetry }: Props): [
	boolean,
	{
		unleashConfig?: IConfig;
		telemetryPlugin?: ReactPlugin;
		appInsights?: ApplicationInsights;
		offlineListener?: OfflineListener;
	},
] {
	const envVar = useContext(EnvironmentVariableContext);

	const [configured, setConfigured] = useState<boolean>(false);

	const unleashConfigRef = useRef<IConfig>();
	const telemetryPluginRef = useRef<ReactPlugin>(new ReactPlugin());
	const appInsightsRef = useRef<ApplicationInsights>();
	const offlineListenerRef = useRef<OfflineListener>();

	useEffect(() => {
		if (envVar.unleashFrontendToken && envVar.appInsightsConnectionString) {
			// Set up unleash
			unleashConfigRef.current = {
				url: 'https://eu.app.unleash-hosted.com/eudd0012/api/frontend',
				clientKey: envVar.unleashFrontendToken ?? '',
				//refreshInterval: 30,
				disableRefresh: true,
				appName: 'frontend',
			};

			let offlineChannel: OfflineChannel | undefined = undefined;
			let extensionConfig: IConfiguration['extensionConfig'];

			if (enableOfflineTelemetry) {
				offlineChannel = new OfflineChannel();
				extensionConfig = {
					// Add config for offline support channel
					[offlineChannel.identifier]: {
						providers: [StorageProviders.LocalStorage, StorageProviders.IndexedDb],
						minPersistenceLevel: 2, // only events with PersistenceLevel >=2 will be saved/sent
						autoClean: true, // This removes tracking events that have remained idle in storage for more than 7 days
					},
				};
			}

			// Set up telemetry
			appInsightsRef.current = new ApplicationInsights({
				config: {
					connectionString: envVar.appInsightsConnectionString,
					enableAutoRouteTracking: true,
					disableExceptionTracking: false,
					disableTelemetry: disableTelemetry,
					extensions: [telemetryPluginRef.current],
					extensionConfig: extensionConfig,
				},
			});

			appInsightsRef.current.loadAppInsights();

			if (enableOfflineTelemetry && offlineChannel) {
				// This is to make sure offline channel is initialized after sender channel
				appInsightsRef.current.addPlugin(offlineChannel);
				offlineListenerRef.current = offlineChannel.getOfflineListener() as OfflineListener;
			}

			// We're good to go
			setConfigured(true);
		}
	}, [envVar.appInsightsConnectionString, envVar.unleashFrontendToken, disableTelemetry, enableOfflineTelemetry]);

	return [
		configured,
		{
			unleashConfig: unleashConfigRef.current,
			telemetryPlugin: telemetryPluginRef.current,
			appInsights: appInsightsRef.current,
			offlineListener: offlineListenerRef.current,
		},
	];
}

export default useDependencyConfiguration;
