export const isDatesBetweenDates = (startDate: string | Date | null, endDate: string | Date | null, checkDateStart: Date | undefined, checkDateEnd: Date | undefined): boolean => {
	if (startDate === null || endDate === null || typeof checkDateStart === 'undefined' || typeof checkDateEnd === 'undefined') return false;

	const startDateFormat = new Date(startDate);
	const endDateFormat = new Date(endDate);

	return (
		(checkDateStart >= startDateFormat && checkDateStart <= endDateFormat) || // Date start overlaps with existing date
		(checkDateEnd >= startDateFormat && checkDateEnd <= endDateFormat) || // Date end overlaps with existing date
		(checkDateStart <= startDateFormat && checkDateEnd >= endDateFormat)
	); // Existing date is "inside" date start/end
};
