import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Popover from './Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	title: string;
	smallTitle?: boolean;
	xsmallTitle?: boolean;
	titleClass?: string;
	required?: boolean;
	htmlFor?: string;
	labelClass?: string;
	labelWeight?: 'NONE' | 'SEMIBOLD' | 'BOLD';
	labelInfoPopover?: React.ReactElement;
}

const FormFieldHeader: React.FC<Props> = ({
	title,
	required = false,
	htmlFor,
	smallTitle = false,
	titleClass,
	xsmallTitle = false,
	labelClass,
	labelWeight = 'SEMIBOLD',
	labelInfoPopover,
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames(titleClass, {
				'mt-2 block': typeof titleClass === 'undefined',
			})}
		>
			<label
				htmlFor={htmlFor}
				className={classNames('text-blue whitespace-no-wrap', labelClass, {
					'font-semibold': labelWeight === 'SEMIBOLD',
					'font-bold': labelWeight === 'BOLD',
					'text-base': !smallTitle && !xsmallTitle,
					'text-sm': smallTitle,
					'text-xs': xsmallTitle,
				})}
			>
				{t(title)}
				{required && <span className="text-red">*</span>}
			</label>

			{typeof labelInfoPopover !== 'undefined' && (
				<Popover hideArrow placement="top" content={<div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">{labelInfoPopover}</div>}>
					<span>
						<FontAwesomeIcon icon={faInfoCircle} className="text-blue ml-1" />
					</span>
				</Popover>
			)}
		</div>
	);
};

export default FormFieldHeader;
