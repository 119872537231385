import { ApolloCache, DefaultContext, DocumentNode, OperationVariables, TypedDocumentNode, useApolloClient, useMutation } from '@apollo/client';
import { useShowOfflineWarningMessage } from 'Hooks';
import { MutationFunc, UseOfflineMutationReturn, UseOfflineMutationOptions, MutationFuncOptions } from './types';
import { isFunction } from 'lodash';
import { useContext } from 'react';
import { ApiMutation, ApiMutationContext, QueueOfflineMutationOptions } from 'Store';
import newGuid from '@ssg/common/Helpers/guidHelper';
import { isConnected } from 'NetworkContext';

export const useOfflineHandlingMutation = <TMutation, TVariables extends OperationVariables = OperationVariables>(
	documentNode: DocumentNode | TypedDocumentNode<TMutation, TVariables>,
	baseOptions?: UseOfflineMutationOptions<TMutation, TVariables>,
): UseOfflineMutationReturn<TMutation, TVariables> => {
	const { queueOfflineMutation } = useContext(ApiMutationContext);

	const [mutation, { client, loading, error }] = useMutation<TMutation, TVariables>(documentNode, baseOptions);
	const { cache } = useApolloClient();


	const showOfflineWarningMessageFunc = useShowOfflineWarningMessage();

	const overrideMutation: MutationFunc<TMutation, TVariables, DefaultContext, ApolloCache<any>> = async (options?: MutationFuncOptions<TMutation, TVariables, DefaultContext, ApolloCache<any>>) => {
		// Unique id for the mutation
		const transactionId = newGuid();

		let overrideOptimisticResponse = false;

		// If an optimistic override is provided then perform that instead of using Apollo's default behaviour
		if (options?.onOptimisticResponseOverride) {
			overrideOptimisticResponse = true;

			options.onOptimisticResponseOverride?.(cache);
			// client.cache.recordOptimisticTransaction(cache => {
			// }, transactionId);
		}

		const connected = isConnected();

		if (connected) {
			// Return the Apollo mutation if we are online, removing any optimistic response if it has been explicitly overridden
			const result = await mutation({
				...options,
				optimisticResponse: overrideOptimisticResponse ? undefined : options?.optimisticResponse,
			});
			return result;
		}

		// When we're offline and not overriding the optimistic update
		// then perform an optimistic transaction and log the transaction id.
		// This will use default Apollo behaviour.
		if (!overrideOptimisticResponse && options?.optimisticResponse) {
			const optimisticData = isFunction(options.optimisticResponse) ? options.optimisticResponse(options.variables ?? ({} as any)) : options.optimisticResponse;

			cache.writeQuery<TMutation, TVariables>({
				query: documentNode,
				variables: options.variables,
				data: optimisticData,
			});
			// client.cache.recordOptimisticTransaction(cache => {
			// }, transactionId);
		}

		// Queue the mutation for reprocessing if an API mutation key is provided.
		if (options?.queueOfflineMutationOptions) {
			const { key, ...restQueueOptions } = options.queueOfflineMutationOptions;
			queueOfflineMutation(
				{ key, variables: options?.variables } as ApiMutation,
				{
					...restQueueOptions,
					optimisticTransactionId: transactionId,
				} as QueueOfflineMutationOptions<TVariables>,
			);
		}

		// Perform any additional callbacks.
		options?.onOfflineCallback?.({ ...options });

		// Finally, show the offline warning message if specified.
		if (options?.showOfflineWarningMessage) {
			showOfflineWarningMessageFunc();
		}

		return undefined;
	};

	return [overrideMutation, { loading, error }];
};
