import React from 'react';
import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import CloseableModal from './CloseableModal';
import 'react-image-gallery/styles/css/image-gallery.css';

export interface Props {
	images: ReactImageGalleryItem[];
	initialIndex: number;
	onDismiss: () => void;
}

const ImageGalleryModal: React.FC<Props> = ({ images, initialIndex, onDismiss }) => {
	const { t } = useTranslation();

	const galleryRef = React.useRef<ImageGallery>(null);
	const [galleryIndex, setGalleryIndex] = React.useState<number>(initialIndex);

	const renderItem = (item: ReactImageGalleryItem) => (
		<div className="image-gallery-image">
			<img src={item.original} alt={item.originalAlt} srcSet={item.srcSet} sizes={item.sizes} />
			{item.description && <span className="image-gallery-description bottom-0 text-xs text-gray-400">{item.originalTitle}</span>}
		</div>
	);

	const comments = React.useMemo(() => images[galleryIndex].description, [galleryIndex, images]);

	return (
		<CloseableModal title={t('common.pictures')} onDismiss={onDismiss}>
			<div className="flex h-full flex-col">
				<ImageGallery items={images} ref={galleryRef} showPlayButton={false} showFullscreenButton={true} startIndex={galleryIndex} renderItem={renderItem} onSlide={e => setGalleryIndex(e)} />
				<IonLabel className="text-blue font-semibold">{t('common.comments')}</IonLabel>
				<p>{comments ?? t('common.noComments')}</p>
			</div>
		</CloseableModal>
	);
};

export default ImageGalleryModal;
