import { useState } from 'react';
import { isPlatform, useIonViewWillLeave } from '@ionic/react';
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor, CapacitorException } from '@capacitor/core';
import compressImage from '@ssg/common/Helpers/compressImage';
import { isConnected } from 'NetworkContext';
import heic2any from 'heic2any';

export interface TakePhotoOptions {
	photoSource: CameraSource;
}

export function useSinglePhotoCapture(): {
	photo?: UserPhoto;
	takePhoto: (options?: TakePhotoOptions) => Promise<{ success: boolean; photo?: UserPhoto }>;
} {
	const [photo, setPhoto] = useState<UserPhoto>();

	// useIonViewDidEnter(() => {
	// 	console.log('ionViewDidEnter event fired');
	// });

	// useIonViewDidLeave(() => {
	// 	console.log('ionViewDidLeave event fired');
	// });

	// useIonViewWillEnter(() => {
	// 	console.log('ionViewWillEnter event fired');
	// });

	useIonViewWillLeave(() => {
		setPhoto(undefined);
	});
	return {
		photo: photo,
		takePhoto: (options?: TakePhotoOptions) =>
			takePhoto(options).then(newPhoto => {
				if (newPhoto) {
					setPhoto(newPhoto);
					return { success: true, photo: newPhoto };
				}
				return { success: false };
			}),
	};
}

async function takePhoto(options?: TakePhotoOptions): Promise<UserPhoto | undefined> {
	const source = options?.photoSource ?? CameraSource.Prompt;
	try {
		const cameraPhoto = await Camera.getPhoto({
			resultType: CameraResultType.Uri,
			source: source,
			quality: 50,
			allowEditing: true,
			webUseInput: true,
			correctOrientation: true,
		});
		const fileName = new Date().toISOString().replaceAll('T', '_').replaceAll(':', '-').split('.')[0];
		const savedFileImage = await savePicture(cameraPhoto, fileName);

		return savedFileImage;
	} catch (error: unknown) {
		// It may be that the user cancelled the photo capture
		if (error instanceof CapacitorException && error.message === 'User cancelled photos app') {
			console.warn(error);
			return;
		}

		console.error(error);
		throw error;
	}
}

export async function savePicture(photo: Photo | GalleryPhoto, fileName: string): Promise<UserPhoto> {
	let base64Data: string | undefined;
	// "hybrid" will detect Cordova or Capacitor;
	if (isPlatform('hybrid')) {
		const file = await Filesystem.readFile({
			path: photo.path as string,
		});
		base64Data = file.data;
	} else {
		let blob: Blob | undefined = undefined;
		try {
			blob = await blobFromPath(photo.webPath as string);
			const file = new File([blob], fileName, {
				type: `image/${photo.format}`,
			});

			const compressedFile = await compressImage(file);
			base64Data = await base64FromBlob(compressedFile);
		} catch (error) {
			console.error('Unable to compress photo. Attempting to convert from HEIC to JPEG...', error);

			if (blob) {
				try {
					const imageType = 'image/jpeg';
					const conversionResult = await heic2any({
						blob,
						toType: imageType,
						quality: 0.5, // cuts the quality and size by half. No need to use our `compressImage` function.
					});

					const jpegBlob = Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
					photo.webPath = URL.createObjectURL(jpegBlob);
					base64Data = await base64FromBlob(jpegBlob);
				} catch (error) {
					console.error('Unable to convert from HEIC to JPEG. Falling back to original version.', error);
					base64Data = await base64FromPath(photo.webPath as string);
				}
			} else {
				base64Data = await base64FromPath(photo.webPath as string);
			}
		}
	}

	if (!base64Data) {
		throw new Error('No base64 data for image');
	}

	const connected = isConnected();

	if (connected && isPlatform('hybrid')) {
		// This function fails when called offline
		const savedFile = await Filesystem.writeFile({
			path: fileName,
			data: base64Data,
			directory: Directory.Data,
		});

		// Display the new image by rewriting the 'file://' path to HTTP
		// Details: https://ionicframework.com/docs/building/webview#file-protocol
		return {
			filepath: savedFile.uri,
			format: photo.format,
			webviewPath: Capacitor.convertFileSrc(savedFile.uri),
			base64EncodedData: getBase64EncodedData(base64Data),
		};
	}

	// Use webPath to display the new image instead of base64 since it's
	// already loaded into memory
	return {
		filepath: fileName,
		format: photo.format,
		webviewPath: photo.webPath as string,
		base64EncodedData: getBase64EncodedData(base64Data),
	};
}

export interface UserPhoto {
	filepath: string;
	format: string;
	webviewPath: string;
	base64EncodedData: string;
	metadata?: {
		fileId: string;
		fileName: string;
		comments?: string;
	};
}

export async function blobFromPath(path: string): Promise<Blob> {
	const response = await fetch(path);
	return response.blob();
}

export async function base64FromPath(path: string): Promise<string> {
	const blob = await blobFromPath(path);
	return base64FromBlob(blob);
}

export async function base64FromBlob(blob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			} else {
				reject('method did not return a string');
			}
		};
		reader.readAsDataURL(blob);
	});
}

export function getBase64EncodedData(base64String: string): string {
	return base64String.split(',')[1];
}

