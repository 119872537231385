import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { ESDHFileFragment } from '@ssg/common/GraphQL';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'Components/Button';
import BasePage from 'Components/Layout/BasePage';
import UserContext from '../../../../UserContext';
import DehumidifierNumbersForm from './Inputs/DehumidifierNumbersForm';
import ReadonlyText from './Inputs/ReadonlyText';
import SignatureInput from './Inputs/SignatureInput';
import TextAreaInput from './Inputs/TextAreaInput';
import { updateReportsCache, updateReportsCacheNew } from './updateReportsCache';
import useDrivingSlipImages from './useDrivingSlipImages';
import * as yup from 'yup';
import Checkbox from 'Components/Checkbox';
import { ApiMutationKey, useCreateFalckDehumidifierReport } from 'Store';
import { isConnected } from 'NetworkContext';
import dateToReportNameString from 'Helpers/dateHelper';

interface Props {
	caseId: string;
	caseNo: string;
	drivingSlipId: string;
	address: string;
	defaultCustomerEmail: string;
}


function notNullOrEmpty(value: unknown): value is string {
	return typeof value === 'string' && value.length > 0;
}

const DehumidifierInfomation: React.FC<{ price: number }> = ({ price }) => (
	<IonCol size="12" className="flex flex-col gap-2">
		<p className="font-bold">Information om affugterudlejning</p>
		<p>I har i dag haft besøg af en Falck leverandør, som har leveret og opsat én eller flere aﬀugtere på jeres adresse.</p>
		<p>I bedes være opmærksom på følgende:</p>
		<ul className="list-disc pl-4">
			<li>Har I et abonnement der dækker redningsarbejde på denne adresse, er kørsel samt op-/nedtagning af aﬀugter dækket af abonnementet.</li>
			<li>
				Den daglige leje samt evt. tilsyn og specialydelser i forbindelse med udlejningen er IKKE dækket af	abonnementet og vil blive faktureret efter gældende tarif fra Falck leverandør.
			</li>
			<li>Kondensaffugter {price},- /dag + moms (ved special affugter kan prisen kan oplyses af leverandøren) - der tages forbehold for evt. prisstigninger.</li>
			<li>Hvis affugteropgaven er dækket af jeres hus- eller indboforsikring, skal leverandøren der udfører opgaven underrettes.</li>
		</ul>
	</IonCol>
);

const GeneralRentalConditions: React.FC = () => (
	<IonCol size="12" className="flex flex-col gap-2">
		<p className="font-bold">Generelle lejebetingelser</p>
		<p>
			Materiellet betragtes som udlejet fra den dag det er leveret, til og med den dag, det er returneret eller der er bestilt
			afhentning hos Falcks leverandør. Lejeren er ansvarlig for enhver skade, der i udlejningstiden måtte tilføjes materiellet.
			Hvis det lejede materiel ødelægges eller bortkommer, erstattes det af lejeren med dagsprisen. Skader (udover normal
			slitage) ydes af lejeren i form af erstatning af reparationsudgifter + eventuel værdiforringelse. Reparationer må IKKE
			udføres af lejeren. Eventuelle skader på lejerens ejendom, opstået direkte eller indirekte på grund af det lejede
			materiel er Falck uvedkommende.
		</p>
		<p>Falcks leverandør vil fremsende faktura direkte til jer eller jeres forsikringsselskab.</p>
		<p>
			Aftalt arbejde ud over abonnementsdækket arbejde, er et forhold mellem abonnent og Falcks leverandør. Klager over pris, arbejdets kvalitet m.v. er Falck uvedkommende.
		</p>
		<p>De bør være opmærksom på, at affugteren har et strømforbrug på mellem 800 og 2000 Watt, hvorfor andre brugsgenstande ikke kan tilsluttes samme sikringsgruppe.</p>
		<p>Der indvilliges i ovenstående betingelser. Der udfyldes en kopi af denne kontrakt til lejeren.</p>
	</IonCol>
);

const FalckDehumidifierReport: React.FC<Props> = ({ caseId, caseNo, drivingSlipId, address, defaultCustomerEmail }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const userContext = React.useContext(UserContext);

	// Form values
	const [subscriptionNumber, setSubscriptionNumber] = React.useState('');
	const [dehumidifierNumbers, setDehumidifierNumbers] = React.useState<string[]>([]);
	const [signatureFile, setSignatureFile] = React.useState<ESDHFileFragment>();
	const [customerEmail, setCustomerEmail] = React.useState<string>(defaultCustomerEmail);
	const [sendReportToCustomer, setSendReportToCustomer] = React.useState<boolean>(false);

	// Errors
	const [subscriptionNumberError, setSubscriptionNumberError] = React.useState(false);
	const [signatureFileError, setSignatureFileError] = React.useState(false);
	const [customerEmailError, setCustomerEmailError] = React.useState(false);

	const { upload, isUploading } = useDrivingSlipImages(caseNo, drivingSlipId);

	const [createFalckDehumidifierReport, { loading: falckCreateLoading }] = useCreateFalckDehumidifierReport();

	async function submitReport(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		// Reset errors
		setSubscriptionNumberError(false);
		setSignatureFileError(false);
		setCustomerEmailError(false);

		// Validate values and set errors if any
		if (subscriptionNumber.length === 0) setSubscriptionNumberError(true);
		if (signatureFile == null) setSignatureFileError(true);
		if (sendReportToCustomer && !yup.string().email().isValidSync(customerEmail)) setCustomerEmailError(true);

		// Exit the function if any errors
		if (subscriptionNumber.length === 0 || signatureFile == null || (sendReportToCustomer && !yup.string().email().isValidSync(customerEmail))) return;

		try {
			await createFalckDehumidifierReport({
				variables: {
					caseId,
					formData: {
						dehumidifierNumbers: dehumidifierNumbers.filter(notNullOrEmpty),
						subscriptionNumber,
						customerEmail: sendReportToCustomer ? customerEmail : undefined,
						signature: {
							fileName: signatureFile.name,
						},
					},
				},
				onOptimisticResponseOverride: (cache) => {
					const created = new Date().toISOString();
					const createdSplit = dateToReportNameString(created);
					const extension = 'pdf';
					const report = {
						name: `Tryg fugtrapport_${caseNo}_${createdSplit}.${extension} (Oprettet Offline, kan ikke tilgås endnu)`,
						comments: null,
						created: created,
						extension: extension,
						fileId: created,
						groupingKey: '',
						metadata: [{ key: 'DokumentType', value: 'Rapport' }],
						thumbnail: '',
						url: 'report.' + extension,
						__typename: 'ESDHFile',
					};
					if (!isConnected()) {
						updateReportsCacheNew(caseNo, report, cache);
					}
					// const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// });
					// console.log(cachedRequest);
					// console.log([report, ...(cachedRequest?.caseFiles ?? [])]);
					// cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// 	data: {
					// 		caseFiles: [report, ...(cachedRequest?.caseFiles ?? [])],
					// 	},
					// });
				},
				update: updateReportsCache(caseNo, result => result.createFalckDehumidifierReport),
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateFalckDehumidifierReport,
					removePreviousEntry: false,
				},
			});

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}

	return (
		<BasePage title={t('reports.reportTypes.falckDehumidifier')}>
			<form onSubmit={submitReport}>
				<IonGrid className="mb-4 text-sm">
					<IonRow>
						<TextAreaInput
							label={t('reports.subscriptionNumber')}
							value={subscriptionNumber}
							onChange={setSubscriptionNumber}
							rows={1}
							required={true}
							errorMessage={subscriptionNumberError ? t('reports.error.subscriptionNumber') : ''}
						/>

						<DehumidifierNumbersForm dehumidifierNumbers={dehumidifierNumbers} setDehumidifierNumbers={setDehumidifierNumbers} />

						<ReadonlyText label={t('reports.address')} value={address} />

						<DehumidifierInfomation price={198} />

						<GeneralRentalConditions />

						<ReadonlyText label={t('reports.date')} value={formatDate(new Date())} />

						<ReadonlyText label={t('reports.deliveredBy')} value={userContext.user?.name ?? ''} />

						<SignatureInput
							label={t('reports.customerSignature')}
							signatureFile={signatureFile}
							isUploading={isUploading}
							onClear={() => setSignatureFile(undefined)}
							onSubmit={async signature => {
								const fileAsBytes = stringToByteArray(signature.base64);
								const filename = `signature-${Date.now()}.${signature.extension}`;
								const file = await upload({
									filename: filename,
									fileData: fileAsBytes,
									blob: signature.base64,
								});
								setSignatureFile(file);
							}}
							errorMessage={signatureFileError ? t('reports.error.signature') : undefined}
						/>

						<IonCol size="12">
							<Checkbox title={t('reports.sendReportToCustomer')} name="sendReportToCustomer" onChange={e => setSendReportToCustomer(e.target.checked)} />
						</IonCol>

						{sendReportToCustomer && (
							<TextAreaInput
								label={t('reports.customerEmail')}
								required
								rows={1}
								onChange={setCustomerEmail}
								value={customerEmail}
								errorMessage={customerEmailError ? t('reports.error.customerEmail') : ''}
							/>
						)}

						<IonCol size="12">
							<Button expand="block" loading={falckCreateLoading} submit>
								{t('reports.createReport')}
							</Button>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasePage>
	);
};

export default FalckDehumidifierReport;
