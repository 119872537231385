import { isUndefined } from 'lodash';

const storage = window.localStorage || window.sessionStorage;

export const getFromStorage = <T>(key: string, defaultValue?: T): T | undefined => {
	if (typeof window === 'undefined') {
		return defaultValue;
	}

	try {
		// Get from local storage by key
		const item = storage.getItem(key);
		// Parse stored json or if none return initialValue
		return item ? JSON.parse(item) : defaultValue;
	} catch (error) {
		// If error also return initialValue
		console.log(error);
		return defaultValue;
	}
};

export const setInStorage = <T>(key: string, value: T): void => {
	try {
		// Null is handled properly in JSON functions. Undefined isn't.
		const safeValueToStore = isUndefined(value) ? null : value;

		if (typeof window !== 'undefined') {
			storage.setItem(key, JSON.stringify(safeValueToStore));
		}
	} catch (error) {
		console.log(error);
	}
};
