import React, { useEffect, useState } from 'react';
import { useInterval } from 'Hooks';
import { INTERVAL_DELAY_IN_MS } from '../../constants';
import { canFetchQuery } from './helper';
import {
	useCaseAdminDataQuery,
	useCollectionItemsQuery,
	useDebitorsQuery,
	useDrivingSlipQuery,
	useDrivingSlipsQuery,
	useQuestionnaireTemplatesByDrivingSlipsQuery,
	useRiskEvaluationCategoriesQuery,
	useRiskEvaluationQuestionsQuery,
} from '..';
import { useAppSelector } from 'Store';
import { useCarQuery } from '../Vehicles';

/**
 * Initializes all API queries & manages their polling state.
 *
 * @remarks
 * Periodically assesses whether we can query the API based on network connection & last fetched date.
 */
export const ApiQueryManager: React.FC = () => {
	const [online, setOnline] = useState<boolean>(false);
	const [intervalDelay, setIntervalDelay] = useState<number>(0);

	const connected = useAppSelector(state => state.localData.networkConnectionStatus);

	useEffect(() => {
		setOnline(connected);
		setIntervalDelay(0); // Begin the interval immediately if we are online.
	}, [connected]);

	const queries = [
		useCaseAdminDataQuery(),
		useCollectionItemsQuery(),
		//useDebitorsQuery(),
		useDrivingSlipQuery(),
		useDrivingSlipsQuery(),
		useQuestionnaireTemplatesByDrivingSlipsQuery(),
		useRiskEvaluationCategoriesQuery(),
		useRiskEvaluationQuestionsQuery(),
		useCarQuery(),
	];

	useInterval(
		() => {
			// Slow down the interval to the desired limit
			if (intervalDelay !== INTERVAL_DELAY_IN_MS) {
				setIntervalDelay(INTERVAL_DELAY_IN_MS);
			}

			// Process what to fetch
			for (const query of queries) {
				if (canFetchQuery(query.apiQuery, query.metadata)) {
					console.log(`Local data for query "${query.apiQueryKey}" has expired or has never been fetched. Setting can fetch to true.`);
					query.setCanFetchQuery(true);
				}
			}
		},
		online ? intervalDelay : null,
	);

	return <></>;
};
