import React, { CSSProperties, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { ControlProps, ValueType } from 'react-select';
import { GetCaseFiles, GetCaseFilesVariables, GetMobileV2Case_case, SearchMobileV2Users, SearchMobileV2UsersVariables, SendCaseEmail, SendCaseEmailVariables } from 'GQL';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Tabs from '@ssg/common/Components/Tabs';
import Textarea from '@ssg/common/Components/Textarea';
import Loading from '@ssg/common/Components/Loading';
import Checkbox from '@ssg/common/Components/Checkbox';
import CreateableSelect from 'react-select/creatable';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import CloseableModal from 'Components/CloseableModal';
import { useOfflineHandlingMutation, useOfflineHandlingQuery } from 'Hooks';

const SEND_EMAIL = loader('src/GQL/Cases/SendCaseEmail.gql');
const SEARCH_USERS = loader('src/GQL/Users/SearchMobileV2Users.gql');
const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');

export interface Props {
	onDismiss: () => void;
	data?: GetMobileV2Case_case;
	config?: {
		subject: string;
		recipient: string;
		message: string;
		fileNames: string[];
		defaultTabIndex: number;
	};
}

interface TabContent {
	category: string;
	content: File[];
}

type File = NonNullable<GetCaseFiles['caseFiles']>[number];

interface ICaseMail {
	mailTo: string[];
	mailCC: string[];
	mailSubject: string;
	mailBody: string;
	mailAttachments: File[];
}

const TABS = ['common.pictures', 'common.offer', 'case.screenings', 'case.otherFiles', 'common.emails'];

const MailModal: React.FC<Props> = ({ onDismiss, data, config }): React.ReactElement => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState<string>(TABS[typeof config !== 'undefined' ? config.defaultTabIndex : 0]);
	const [attachments, setAttachments] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>('');

	const { data: caseFilesData, loading: caseFilesDataLoading } = useOfflineHandlingQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, {
		variables: { caseErpNo: data?.erpNo },
	});

	const isImage = (extension: string): boolean => {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'bmp', 'svg', 'tif', 'tiff', 'webp'];
		return imageExtensions.includes(extension);
	};

	const isOffer = (groupingKey: string): boolean => {
		return groupingKey.startsWith('Q');
	};

	const isScreening = (groupingKey: string): boolean => {
		return groupingKey.startsWith('SCR');
	};

	const isEmail = (extension: string): boolean => {
		return extension === 'eml';
	};

	const images = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isImage(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const offerFiles = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isOffer(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const screeningFiles = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isScreening(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const otherFiles = React.useMemo(
		() =>
			caseFilesData?.caseFiles
				.filter(f => !isScreening(f.groupingKey) && !isImage(f.extension) && !isOffer(f.groupingKey) && !isEmail(f.extension))
				.sort((a, b) => b.created.localeCompare(a.created)) ?? [],
		[caseFilesData],
	);
	const emails = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isEmail(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);

	const tabContent: TabContent[] = React.useMemo(
		() => [
			{ category: 'common.pictures', content: images },
			{ category: 'common.offer', content: offerFiles },
			{ category: 'case.screenings', content: screeningFiles },
			{ category: 'case.otherFiles', content: otherFiles },
			{ category: 'common.emails', content: emails },
		],
		[emails, images, offerFiles, otherFiles, screeningFiles],
	);

	const { data: users } = useOfflineHandlingQuery<SearchMobileV2Users, SearchMobileV2UsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: searchText,
		},
		skip: searchText === '',
	});

	const mails = users?.searchUsers
		// Remove duplicate users
		?.filter((v, i, a) => a.findIndex(user => user.email === v.email) === i)
		// Map users to select options
		?.map((u): SelectOption => ({ value: u.email, label: u.name }));

	const [sendEmail, { loading: sendEmailLoading }] = useOfflineHandlingMutation<SendCaseEmail, SendCaseEmailVariables>(SEND_EMAIL);

	const { handleSubmit, register, setValue, control } = useForm<ICaseMail>({
		reValidateMode: 'onChange',
		defaultValues: {
			mailTo: typeof config !== 'undefined' ? [config.recipient] : undefined,
		},
	});

	const onSubmit = async (mail: ICaseMail) => {
		try {
			// Send mail
			await sendEmail({
				variables: {
					caseId: data?.id ?? '',
					to: mail.mailTo,
					cc: mail.mailCC ?? [],
					bcc: ['5c-mail-in@ssg.dk'],
					subject: mail.mailSubject,
					body: mail.mailBody,
					// Case mail specific
					includeCaseAgreements: false,
					quote: true,
					attachements: attachments.map(a => decodeURI(a)),
				},
			});

			onDismiss();
		} catch (e) {
			console.log(e);
		}
	};

	function chooseAttachments(file: File) {
		if (attachments.some(a => file.url.includes(a))) {
			setAttachments(attachments.filter(a => !file.url.includes(a)));
			return;
		}

		const attachment = file.url.replace('https://ssgcloud.sharepoint.com/sites/SSGESDH', '');

		setAttachments([...attachments, attachment]);
	}

	const setRecieversHandler = (value: ValueType<SelectOption, true>): void => {
		const values = Array.isArray(value) ? value.map(v => v.value) : [];
		setValue('mailTo', values, { shouldValidate: true });
		setSearchText('');
	};

	React.useEffect(() => {
		typeof config !== 'undefined' && setValue('mailTo', config.recipient);
	}, []);
	React.useEffect(() => {
		if (typeof config !== 'undefined' && typeof caseFilesData !== 'undefined') {
			config.fileNames.forEach(n => {
				const caseFile = caseFilesData.caseFiles.find(f => f.name.includes(n));
				typeof caseFile !== 'undefined' && chooseAttachments(caseFile);
			});
		}
	}, [caseFilesData, config]);

	const setCCHandler = (value: ValueType<SelectOption, true>): void => {
		const values = Array.isArray(value) ? value.map(v => v.value) : [];
		setValue('mailCC', values, { shouldValidate: true });
	};

	React.useEffect(() => {
		const checkboxes = document.querySelectorAll('#tabs input[type="checkbox"') as NodeListOf<HTMLInputElement>;

		checkboxes.forEach(box => {
			box.checked = false;
			attachments?.forEach(att => {
				if (att.includes(encodeURI(box.id))) {
					box.checked = true;
				}
			});
		});
	}, [activeTab, attachments]);

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: CSSProperties, state: ControlProps<{ label: string; value: string }, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	return (
		<CloseableModal title={t('case.sendCaseMail')} onDismiss={onDismiss}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormFieldHeader title="common.mailTo" htmlFor="mailTo" required />
				<Controller
					control={control}
					name="mailTo"
					defaultValue={null}
					render={() => (
						<CreateableSelect
							isMulti
							name="mailTo"
							options={mails}
							closeMenuOnSelect={false}
							formatCreateLabel={inputValue => `${t('mail.addMail')} "${inputValue}"`}
							noOptionsMessage={() => t('mail.addDifferentMail')}
							placeholder={<div>{t('mail.searchForMail')}</div>}
							styles={customStyles}
							inputRef={register}
							onChange={val => setRecieversHandler(val)}
							onInputChange={val => setSearchText(val)}
							defaultValue={
								typeof config !== 'undefined'
									? {
											value: config.recipient,
											label: config.recipient,
									  }
									: undefined
							}
							className="border-1 rounded-default border-gray-600"
						/>
					)}
				/>

				<FormFieldHeader title="mail.CC" htmlFor="mailCC" />
				<Controller
					control={control}
					name="mailCC"
					defaultValue={null}
					render={() => (
						<CreateableSelect
							isMulti
							name="mailCC"
							options={mails}
							closeMenuOnSelect={false}
							formatCreateLabel={inputValue => `${t('mail.addMail')} "${inputValue}"`}
							noOptionsMessage={() => t('mail.addDifferentMail')}
							placeholder={<div>{t('mail.searchForMail')}</div>}
							styles={customStyles}
							inputRef={register}
							onChange={val => setCCHandler(val)}
							onInputChange={val => setSearchText(val)}
							className="border-1 rounded-default border-gray-600"
						/>
					)}
				/>

				<Input
					title="mail.subject"
					name="mailSubject"
					required
					innerRef={register}
					defaultValue={typeof config !== 'undefined' ? config.subject : `Sag fra SSG ${data?.erpNo} / Skadesnr. ${data?.debitor.policeNumber}`}
				/>

				<Textarea title="common.message" name="mailBody" rows={6} required innerRef={register} defaultValue={config?.message} />

				<Tabs titles={TABS} active={activeTab} onClick={setActiveTab}>
					<div id="tabs" className="relative h-full w-full">
						{caseFilesDataLoading ? (
							<Loading />
						) : (
							tabContent
								.filter(tab => tab.category === activeTab)
								.map((tab, index) => (
									<div className="flex flex-row" key={index}>
										{tab.content?.length === 0 ? (
											<div className="text-blue ml-2 flex justify-center font-medium">{`${t('mail.noFile')} ${t(tab.category)}`}</div>
										) : (
											tab.content?.map((file, index) => {
												return (
													<div className="m-2 w-40 cursor-pointer" key={index}>
														<div onClick={() => chooseAttachments(file)} className="border-1 h-40 w-40 overflow-hidden border-gray-300">
															<div className="h-40">
																<img src={file.thumbnail} alt={file.name} className="h-40 w-40 object-cover object-left-top" />
															</div>
														</div>
														<Checkbox
															name={file.name}
															id={file.name}
															title={file.name.replace('Sales Quote - ', '')}
															textClassName="w-40 truncate text-blue"
															checked={typeof attachments.find(a => file.url.includes(a)) !== 'undefined'}
															onClick={() => chooseAttachments(file)}
														/>
													</div>
												);
											})
										)}
									</div>
								))
						)}
					</div>
				</Tabs>
				<div className="mt-3">
					<Button submit success text="case.sendCaseMail" loading={sendEmailLoading} />
				</div>
			</form>
		</CloseableModal>
	);
};

export default MailModal;
