import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { useRejectedTimeTrackingsForUserQuery } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Button from '@ssg/common/Components/Button';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

interface Props {
	userId: string | undefined;
}

const RejectedTimeRegistrationWarningModalNew: React.FC<Props> = ({ userId }) => {
	const { siteUrlWeb } = useContext(EnvironmentVariableContext);
	const { t } = useTranslation();

	const RejectedTimeRegistrationWarningFlag = useFlag(FeatureFlagEnums.REJECTED_TIME_REGISTRATION_WARNING);
	
	const { rejectedTimeTrackingDataRaw } = useRejectedTimeTrackingsForUserQuery(userId ?? '', RejectedTimeRegistrationWarningFlag);

	const [showRejectedRegistrationWarningModal, setShowRejectedRegistrationWarningModal] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!RejectedTimeRegistrationWarningFlag) {
			return;
		}
		if (typeof rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser !== 'undefined') {
			const hasRejected = rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser.length > 0;
			const showRejectModalIfNotSubstitute = hasRejected;
			setShowRejectedRegistrationWarningModal(showRejectModalIfNotSubstitute);
		}
	}, [RejectedTimeRegistrationWarningFlag, rejectedTimeTrackingDataRaw]);

	return (
		<>
			<IonModal isOpen={showRejectedRegistrationWarningModal}>
				<IonHeader>
					<IonToolbar>
						<IonTitle className="md pt-4 pb-2 text-center">{t('timeRegistration.rejectedTimeRegistrations')}</IonTitle>
						<IonButtons slot="end">
							<Button text={t('common.close')} onClick={() => setShowRejectedRegistrationWarningModal(false)} />
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<p>{t('timeRegistration.rejectedTimeRegistrationWarningModal.pendingRejectedReigstrations')}</p>

					<div className="mt-2 flex justify-between">
						<Button
							primary
							text="timeRegistration.rejectedTimeRegistrationWarningModal.openWebApplication"
							onClick={() => {
								window.open(`${siteUrlWeb}timeregistration?=rejectedhours`);
							}}
						/>
						<Button
							primary
							text="Ok"
							onClick={() => setShowRejectedRegistrationWarningModal(false)}
						/>
					</div>
				</IonContent>
			</IonModal>
		</>
	);
};

export default RejectedTimeRegistrationWarningModalNew;
