import React from 'react';
import { BaseDebitorFragment } from '../GraphQL';

interface Props {
	requiredDebitorNos: string[];
	requiredDebitorGroup: string;
	debitor: BaseDebitorFragment;
	fallback?: React.ReactNode | null;
}

const DebitorRestrictedArea: React.FC<Props> = ({ children, requiredDebitorNos, requiredDebitorGroup, debitor, fallback = null }) => {
	const sameDebitor = requiredDebitorNos.includes(debitor.debitorId);
	const sameGroup = requiredDebitorGroup === debitor.debitorGroup;

	const canSee = sameDebitor || sameGroup;

	return canSee ? <>{children}</> : <>{fallback}</>;
};

export default DebitorRestrictedArea;
