import React from 'react';
import classnames from 'classnames';

interface Props {
	classNames?: string;
	extraProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
}

const TableBody: React.FC<Props> = ({ children, classNames, extraProps }) => {
	return (
		<tbody {...extraProps} className={classnames(classNames, 'ease transition duration-100')}>
			{children}
		</tbody>
	);
};

export default TableBody;
