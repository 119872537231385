import React from 'react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonSearchbar, IonSkeletonText, IonToolbar } from '@ionic/react';
import { GetMobileV2CaseAdminData_drivingSlipItems } from '../GQL';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@unleash/proxy-client-react';

interface Props {
	items: GetMobileV2CaseAdminData_drivingSlipItems[];
	onSelectionCancel: () => void;
	onSelectionChange: (item: string) => void;
	buttons?: React.ReactNode;
	listLoading: boolean;
}

const SearchableList: React.FC<Props> = ({ items, onSelectionCancel, onSelectionChange, buttons, listLoading }): JSX.Element => {
	const { t } = useTranslation();

	const [filteredItems, setFilteredItems] = React.useState<GetMobileV2CaseAdminData_drivingSlipItems[]>(items);
	const [searchQuery, setSearchQuery] = React.useState('');

	const getAllDSItemsFlag = useFlag(FeatureFlagEnums.GET_ALL_DS_ITEMS);
	const searchbarInput = (ev: any) => {
		setSearchQuery(ev.target.value);
	};

	React.useEffect(() => {
		if (searchQuery === undefined || searchQuery === null) {
			setFilteredItems(items);
		} else {
			const normalizedQuery = searchQuery.toLowerCase();
			setFilteredItems(
				items.filter(item => {
					return item.description.toLowerCase().includes(normalizedQuery);
				}),
			);
		}
	}, [items, searchQuery]);

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonSearchbar placeholder={t('common.search')} onIonInput={searchbarInput} value={searchQuery}></IonSearchbar>
					<IonButtons slot="end">{getAllDSItemsFlag && buttons}</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonList>
					{filteredItems.map(item => (
						<IonItem
							key={item.no + item.filterCode}
							type="button"
							onClick={() => {
								onSelectionChange(item.no);
								onSelectionCancel();
							}}
						>
							<IonLabel>
								{item.description} ({item.baseUOM})
							</IonLabel>
						</IonItem>
					))}
					{listLoading && (
						<>
							<IonSkeletonText animated className="w-1/4 pt-5" />
							<IonSkeletonText animated className="w-full pt-5" />
							<IonSkeletonText animated className="w-1/2 pt-5" />
							<IonSkeletonText animated className="w-3/4 pt-5" />
						</>
					)}
				</IonList>
			</IonContent>
		</>
	);
};

export default SearchableList;
