import { CaseAgreementCategory } from '../../GraphQL';

export interface CompletionEntry {
	sectionName: string;
	sectionCaseAgreementCategory: CaseAgreementCategory;
	isComplete: boolean;
	photoRequired: boolean;
	shouldSaveInScalePoint: boolean;
	triggersTrackTwoChange: boolean;
	lastChoiceLabel: string;
	lastChoiceValue: string | null;
	choices: CompletionEntryChoice[];
}

export interface CompletionEntryChoice {
	choiceLabel: string;
	choiceValue: string | null;
	choiceUnitOfMeasure: string | null;
}

export const createContentForCompletionEntry = (entry: CompletionEntry): string =>
	entry.choices
		.map(c => {
			const uom = c.choiceUnitOfMeasure ? ` (${c.choiceUnitOfMeasure})` : '';
			return `[${c.choiceLabel}] ${c.choiceValue}${uom}`;
		})
		.join(' >> ');

export const createContentForCaseAgreement = (entry: CompletionEntry): string =>
	entry.choices
		.filter(c => c.choiceValue != null) // Prevent creating case agreements with null values
		.map(c => {
			const uom = c.choiceUnitOfMeasure ? ` (${c.choiceUnitOfMeasure})` : '';
			return `[${c.choiceLabel}] ${c.choiceValue}${uom}`;
		})
		.join(' >> ');
